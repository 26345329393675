import Model from "../Model";
import {ApiSubscribableCallbacks, SearchResult} from "startupbox-react";
import ApiError from "./ApiError";
import TrancheStatus from "./TrancheStatus";
import Content from "./Content";
import Binary from "./Binary";
import Visibilities from "./Visibilities";
import BlockchainMintable from "./BlockchainMintable";
import BlockchainMintStatus from "./BlockchainMintStatus";
import PayoutSearchRequest from "./PayoutSearchRequest";
import Payout from "./Payout";
import SalesChannel from "./SalesChannel";
import User from "./User";

let Rel = {
  self: 'self',
  modify: 'modify',
  modifyStatus: 'modifyStatus',
  modifyMintStatus: 'modifyMintStatus',
  modifyName: 'modifyName',
  modifyBadge: 'modifyBadge',
  modifyMedia: 'modifyMedia',
  modifyFeatured: 'modifyFeatured',
  delete: 'delete',
  sendReviewRequestNotification: 'sendReviewRequestNotification',
  giftToUser: 'giftToUser',
  payout_add: 'payout_add',
  payout_search: 'payout_search',
};

export const TRANCHE_STANDARD_FIELDS = 'coverImage,promotionalImages,promotionalVideos,ipfsMedia,pdsFiles,terms,terms.body,manageableMetadataAttributeGroup,visibilities';

export default class Tranche extends BlockchainMintable {

  key?: string;
  horseKey?: string;
  partnerKey?: string;

  paymentAccountProductId?: string;
  name?: string;
  absoluteOwnershipPercent?: number;
  startAt?: Date;
  endAt?: Date;
  minSubscriberCount?: number;
  minSubscriberCountAt?: Date;
  activatedAt?: Date;
  unitPrice?: number;
  unitCount?: number;
  remainingUnitCount?: number = 0;
  limitPerUser?: number;
  terms?: Content = new Content();
  visibilities = new Visibilities();
  salesChannel?: SalesChannel;
  status?: TrancheStatus;

  enclosingPackKey?: string;
  enclosingPackName?: string;

  coverImage?: Binary;
  promotionalImages?: Array<Binary>;
  promotionalVideos?: Array<Binary>;
  pdsFiles?: Array<Binary>;
  externalLink?: string;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  getCountText() {
    return this.remainingUnitCount + '/' + this.unitCount;
  }

  hasTerms() {
    return this.terms && this.terms.body && this.terms.body.blocks && this.terms.body.blocks.length > 0;
  }

  // ................................................................................................................................................

  reload(callbacks: ApiSubscribableCallbacks<Tranche, ApiError>, fields: string | null) {
    this.href(Rel.self, true)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  isModifyAllowed() {
    return !!this.href(Rel.modify);
  }

  save(callbacks: ApiSubscribableCallbacks<Tranche, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }

  isModifyStatusAllowed() {
    return !!this.href(Rel.modifyStatus);
  }

  modifyStatus(status: TrancheStatus | null, callbacks: ApiSubscribableCallbacks<Tranche, ApiError>, fields: string | null) {
    this.href(Rel.modifyStatus, true)
      .field(fields)
      .patch({status: status})
      .subscribe(callbacks);
  }

  isModifyMintStatusAllowed() {
    return !!this.href(Rel.modifyMintStatus);
  }

  modifyMintStatus(mintStatus: BlockchainMintStatus | null, callbacks: ApiSubscribableCallbacks<Tranche, ApiError>, fields: string | null) {
    this.href(Rel.modifyMintStatus, true)
      .field(fields)
      .patch({mintStatus: mintStatus})
      .subscribe(callbacks);
  }

  isModifyNameAllowed() {
    return !!this.href(Rel.modifyName);
  }

  modifyName(name: string | null, callbacks: ApiSubscribableCallbacks<Tranche, ApiError>, fields: string | null) {
    this.href(Rel.modifyName, true)
      .field(fields)
      .patch({name: name})
      .subscribe(callbacks);
  }

  isModifyBadgeAllowed() {
    return !!this.href(Rel.modifyBadge);
  }

  modifyBadge(badgeLabel: string | null, callbacks: ApiSubscribableCallbacks<Tranche, ApiError>, fields: string | null) {
    this.href(Rel.modifyBadge, true)
      .field(fields)
      .patch({badgeLabel: badgeLabel})
      .subscribe(callbacks);
  }

  isModifyMediaAllowed() {
    return !!this.href(Rel.modifyMedia);
  }

  modifyMedia(coverImage: Binary | undefined, promotionalImages: Array<Binary> | undefined, promotionalVideos: Array<Binary> | undefined, callbacks: ApiSubscribableCallbacks<Tranche, ApiError>, fields: string | null) {
    this.href(Rel.modifyMedia, true)
      .field(fields)
      .patch({coverImage: coverImage, promotionalImages: promotionalImages, promotionalVideos: promotionalVideos})
      .subscribe(callbacks);
  }

  isModifyFeaturedAllowed() {
    return !!this.href(Rel.modifyFeatured);
  }

  modifyFeatured(featured: boolean | null, callbacks: ApiSubscribableCallbacks<Tranche, ApiError>, fields: string | null) {
    this.href(Rel.modifyFeatured, true)
      .field(fields)
      .patch({featured: featured})
      .subscribe(callbacks);
  }

  isDeleteAllowed() {
    return !!this.href(Rel.delete);
  }

  delete(callbacks: ApiSubscribableCallbacks<null, ApiError>) {
    this.href(Rel.delete, true)
      .delete()
      .subscribe(callbacks);
  }

  isSendReviewRequestNotificationAllowed() {
    return !!this.href(Rel.sendReviewRequestNotification);
  }

  sendReviewRequestNotification(callbacks: ApiSubscribableCallbacks<Tranche, ApiError>, fields: string | null) {
    this.href(Rel.sendReviewRequestNotification, true)
      .field(fields)
      .post({})
      .subscribe(callbacks);
  }

  isGiftToUserAllowed() {
    return !!this.href(Rel.giftToUser);
  }

  giftToUser(user: User, callbacks: ApiSubscribableCallbacks<Tranche, ApiError>, fields: string | null) {
    this.href(Rel.giftToUser, true)
      .field(fields)
      .post({user: user})
      .subscribe(callbacks);
  }

  isSalesChannelPack() {
    return this.salesChannel && this.salesChannel.key === 'PACK';
  }

  isInPack() {
    return !!this.enclosingPackKey;
  }

  isExternalTranche() {
    return !!this.externalLink;
  }

  // race winning ...................................................................................................................................

  isAddPayoutAllowed() {
    return !!this.href(Rel.payout_add);
  }

  addPayout(payout: Payout, callbacks: ApiSubscribableCallbacks<Payout, ApiError>, fields: string | null) {
    this.href(Rel.payout_add, true)
      .field(fields)
      .post(payout)
      .subscribe(callbacks);
  }

  isSearchPayoutAllowed() {
    return !!this.href(Rel.payout_search);
  }

  searchPayouts(request: PayoutSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Payout>, ApiError>, fields: string | null) {
    this.href(Rel.payout_search, true)!
      .param('status', request.status)
      .param('sort', request.sort || 'id,desc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

}
