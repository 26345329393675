import ApiIndex from "../models/ApiIndex";
import Membership from "../models/Membership";
import {
    ApiResponse,
    Button,
    Confirmable,
    ImageWithUpload,
    InputDatePicker, InputInlineEditor,
    InputText,
    InputWrap,
    StatusButton, VideoWithUpload
} from "startupbox-react";
import MembershipStatus from "../models/MembershipStatus";
import MembershipDialog from "./MembershipDialog";
import MembershipNameDialog from "./MembershipNameDialog";
import MembershipBadgeDialog from "./MembershipBadgeDialog";
import SectionHeader from "../components/SectionHeader";
import MembershipMediaDialog from "./MembershipMediaDialog";
import React from "react";
import Horse from "../models/Horse";
import Partner from "../models/Partner";
import Panel from '../components/Panel';

const MembershipDetailsPanel = (props: {
    index: ApiIndex,
    membership: Membership,
    horse?: Horse,
    partner?: Partner,
    onSave: (response: ApiResponse<Membership>) => void,
    onDelete: () => void,
    onChangeStatus: (status: MembershipStatus) => void
}) => {

    let index = props.index;
    let membership = props.membership;
    let horse = props.horse;
    let partner = props.partner;
    let onSave = props.onSave;
    let onDelete = props.onDelete;
    let onChangeStatus = props.onChangeStatus;

    return (

        <Panel>

            {
                membership.isModifyAllowed() &&
                <Confirmable
                    renderConfirm={(onOk, onCancel) => {
                        return (
                            <MembershipDialog
                                index={index}
                                membership={membership}
                                horse={horse}
                                partner={partner}
                                onSave={(response) => {
                                    onSave(response);
                                    onOk();
                                }}
                                onDelete={() => {
                                    onDelete();
                                    onOk();
                                }}
                                onCancel={onCancel}/>
                        );
                    }}
                    renderTrigger={(onShow) => {
                        return (
                            <Button
                                icon="external-link"
                                bsStyle="primary"
                                bsSize="xs"
                                className="pull-right"
                                onClick={onShow}>Edit</Button>
                        );
                    }}/>
            }

            <div className="row">
                <div className="col-md-6">
                    <InputText
                        id="membership.name"
                        label="Membership Name"
                        value={membership.name}
                        readOnly/>
                </div>
                <div className="col-md-6">
                    {
                        !membership.isModifyAllowed() && membership.isModifyNameAllowed() &&
                        <Confirmable
                            renderConfirm={(onOk, onCancel) => {
                                return (
                                    <MembershipNameDialog
                                        membership={membership}
                                        onSave={(response) => {
                                            onSave(response);
                                            onOk();
                                        }}
                                        onCancel={onCancel}/>
                                );
                            }}
                            renderTrigger={(onShow) => {
                                return (
                                    <Button
                                        icon="external-link"
                                        bsStyle="primary"
                                        bsSize="xs"
                                        className="pull-right"
                                        onClick={onShow}>Edit</Button>
                                );
                            }}/>
                    }
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <InputText
                        id="membership.badgeLabel"
                        label="Badge"
                        value={membership.badgeLabel}
                        readOnly/>
                </div>
                <div className="col-md-6">
                    {
                        !membership.isModifyAllowed() && membership.isModifyBadgeAllowed() &&
                        <Confirmable
                            renderConfirm={(onOk, onCancel) => {
                                return (
                                    <MembershipBadgeDialog
                                        membership={membership}
                                        onSave={(response) => {
                                            onSave(response);
                                            onOk();
                                        }}
                                        onCancel={onCancel}/>
                                );
                            }}
                            renderTrigger={(onShow) => {
                                return (
                                    <Button
                                        icon="external-link"
                                        bsStyle="primary"
                                        bsSize="xs"
                                        className="pull-right"
                                        onClick={onShow}>Edit</Button>
                                );
                            }}/>
                    }
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <InputDatePicker
                        id="membership.startAt"
                        label="Start Date"
                        value={membership.startAt}
                        dateFormat="DD/MM/YYYY"
                        readOnly/>
                </div>
                <div className="col-xs-6 text-right">
                    <InputDatePicker
                        id="membership.endAt"
                        label="End Date"
                        value={membership.endAt}
                        dateFormat="DD/MM/YYYY"
                        readOnly/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <InputText
                        id="membership.unitCount"
                        label="Number of Units"
                        value={membership.getCountText()}
                        readOnly/>
                </div>
                <div className="col-xs-6 text-right">
                    <InputText
                        id="membership.unitPrice"
                        label="Unit Price"
                        value={membership.unitPrice}
                        format="currency"
                        readOnly/>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6">
                    <InputText
                        id="membership.limitPerUser"
                        label="Limit per User"
                        value={membership.limitPerUser ? membership.limitPerUser : 'Unlimited'}
                        readOnly/>
                </div>
                <div className="col-md-6 text-right">
                    <InputText
                        id="membership.paymentAccountProductId"
                        label="Stripe Product Id"
                        value={membership.paymentAccountProductId ? membership.paymentAccountProductId : 'Not Created Yet'}
                        readOnly/>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-6">
                    <InputText
                        id="membership.appCreditAmount"
                        label="App Credit"
                        value={membership.appCreditAmount}
                        format="currency"
                        readOnly/>
                </div>
                <div className="col-xs-6 text-right">
                    <InputWrap id="status" label="Status">
                        <div>
                            <StatusButton
                                id="status"
                                value={membership.status}
                                options={index.membershipStatuses}
                                onEdit={membership.isModifyStatusAllowed() ? onChangeStatus : null}/>
                        </div>
                    </InputWrap>
                </div>
            </div>

            <SectionHeader
                title="Media"
                divider
                actions={
                    <>
                        {
                            !membership.isModifyAllowed() && membership.isModifyMediaAllowed() &&
                            <Confirmable
                                renderConfirm={(onOk, onCancel) => {
                                    return (
                                        <MembershipMediaDialog
                                            membership={membership}
                                            onSave={(response) => {
                                                onSave(response);
                                                onOk();
                                            }}
                                            onCancel={onCancel}/>
                                    );
                                }}
                                renderTrigger={(onShow) => {
                                    return (
                                        <Button
                                            icon="external-link"
                                            bsStyle="primary"
                                            bsSize="xs"
                                            className="pull-right"
                                            onClick={onShow}>Edit</Button>
                                    );
                                }}/>
                        }
                    </>
                }/>

            <ImageWithUpload
                id="membership.coverImage"
                label="Cover Image"
                files={membership.coverImage ? [membership.coverImage] : []}
                className="images-across-1"
                lightbox
                readOnly/>

            <VideoWithUpload
                id="membership.promotionalVideos"
                label="Promotional Videos"
                multiple
                files={membership.promotionalVideos || []}
                readOnly/>

            <ImageWithUpload
                id="membership.promotionalImages"
                label="Promotional Images"
                multiple
                lightbox
                files={membership.promotionalImages || []}
                readOnly/>

            <SectionHeader title="Terms" divider/>

            {
                membership.hasTerms() ?
                    <InputInlineEditor
                        id="membership.terms.body"
                        elementId="details-terms"
                        label="Terms"
                        value={membership.terms?.body}
                        readOnly/> :
                    <InputText
                        id="membership.terms.body"
                        label="Terms"
                        value={null}
                        readOnly/>
            }

        </Panel>

    );

}

export default MembershipDetailsPanel;