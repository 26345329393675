import Model from '../Model';
import {ApiSubscribableCallbacks, SearchResult} from "startupbox-react";
import HorseRacingStatus from "./HorseRacingStatus";
import HorseStatus from "./HorseStatus";
import Binary from "./Binary";
import LegalOwner from "./LegalOwner";
import ApiError from "./ApiError";
import HorseAttributeGroup from "./HorseAttributeGroup";
import Content from "./Content";
import LegalOwnerSearchRequest from "./LegalOwnerSearchRequest";
import MedicalRecordSearchRequest from "./MedicalRecordSearchRequest";
import TrainingRecordSearchRequest from "./TrainingRecordSearchRequest";
import MedicalRecord from "./MedicalRecord";
import TrainingRecord from "./TrainingRecord";
import PostSearchRequest from "./PostSearchRequest";
import Post from "./Post";
import CollectibleSearchRequest from "./CollectibleSearchRequest";
import Collectible from "./Collectible";
import BlockchainMintable from "./BlockchainMintable";
import BlockchainMintStatus from "./BlockchainMintStatus";
import VisibilityLevel from "./VisibilityLevel";
import PremiumContentConfiguration from "./PremiumContentConfiguration";
import PremiumContentTier from "./PremiumContentTier";
import Membership from "./Membership";
import MembershipSearchRequest from "./MembershipSearchRequest";

let Rel = {
  self: 'self',
  modify: 'modify',
  modifyRacingStatus: 'modifyRacingStatus',
  modifyStatus: 'modifyStatus',
  modifyMintStatus: 'modifyMintStatus',
  modifyFeatured: 'modifyFeatured',
  sendReviewRequestNotification: 'sendReviewRequestNotification',
  collectible_add: 'collectible_add',
  collectible_find: 'collectible_find',
  collectible_search: 'collectible_search',
  legalOwner_add: 'legalOwner_add',
  legalOwner_search: 'legalOwner_search',
  medicalRecord_add: 'medicalRecord_add',
  medicalRecord_search: 'medicalRecord_search',
  post_add: 'post_add',
  post_search: 'post_search',
  trainingRecord_add: 'trainingRecord_add',
  trainingRecord_search: 'trainingRecord_search',
  membership_find: 'membership_find',
  membership_add: 'membership_add',
  membership_search: 'membership_search',
};

export const HORSE_STANDARD_FIELDS =
  'thumbnailImage,coverImage,ipfsMedia,overview,overview.body,manageableAttributeGroups,manageableMetadataAttributeGroup,pedigreeImages,' +
  'applePremiumContentConfiguration,googlePremiumContentConfiguration,' +
  'applePremiumContentDefaultTierForSinglePost,googlePremiumContentDefaultTierForSinglePost';

export default class Horse extends BlockchainMintable {

  key?: string;

  name?: string;
  shortDescription?: string;
  summaryDescription?: string;
  microchipNumber?: string;
  overview?: Content;
  viewableAttributeGroups: Array<HorseAttributeGroup> = [];
  manageableAttributeGroups: Array<HorseAttributeGroup> = [];
  racingStatus?: HorseRacingStatus;
  status?: HorseStatus;

  thumbnailImage?: Binary;
  coverImage?: Binary;
  pedigreeImages?: Array<Binary>;

  partnerKey?: string;
  partnerName?: string;
  partnerTagline?: string;
  partnerSummaryDescription?: string;
  partnerThumbnailImage?: Binary;

  applePremiumContentConfiguration?: PremiumContentConfiguration;
  googlePremiumContentConfiguration?: PremiumContentConfiguration;

  applePremiumContentDefaultProductIdForSinglePost?: string;
  applePremiumContentDefaultTierForSinglePost?: PremiumContentTier;

  googlePremiumContentDefaultProductIdForSinglePost?: string;
  googlePremiumContentDefaultTierForSinglePost?: PremiumContentTier;

  visibilityLevelsForAttributes: Array<VisibilityLevel> = [];
  visibilityLevelsForPosts: Array<VisibilityLevel> = [];
  visibilityLevelsForTrancheOwners: Array<VisibilityLevel> = [];

  legalOwners?: Array<LegalOwner>;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  getCountText() {
    return '1/1';
  }

  // ................................................................................................................................................

  reload(callbacks: ApiSubscribableCallbacks<Horse, ApiError>, fields: string | null) {
    this.href(Rel.self, true)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  isModifyAllowed() {
    return !!this.href(Rel.modify);
  }

  save(callbacks: ApiSubscribableCallbacks<Horse, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }

  isModifyRacingStatusAllowed() {
    return !!this.href(Rel.modifyRacingStatus);
  }

  modifyRacingStatus(status: HorseRacingStatus | null, callbacks: ApiSubscribableCallbacks<Horse, ApiError>, fields: string | null) {
    this.href(Rel.modifyRacingStatus, true)
      .field(fields)
      .patch({racingStatus: status})
      .subscribe(callbacks);
  }

  isModifyStatusAllowed() {
    return !!this.href(Rel.modifyStatus);
  }

  modifyStatus(status: HorseStatus | null, callbacks: ApiSubscribableCallbacks<Horse, ApiError>, fields: string | null) {
    this.href(Rel.modifyStatus, true)
      .field(fields)
      .patch({status: status})
      .subscribe(callbacks);
  }

  isModifyMintStatusAllowed() {
    return !!this.href(Rel.modifyMintStatus);
  }

  modifyMintStatus(mintStatus: BlockchainMintStatus | null, callbacks: ApiSubscribableCallbacks<Horse, ApiError>, fields: string | null) {
    this.href(Rel.modifyMintStatus, true)
      .field(fields)
      .patch({mintStatus: mintStatus})
      .subscribe(callbacks);
  }

  isModifyFeaturedAllowed() {
    return !!this.href(Rel.modifyFeatured);
  }

  modifyFeatured(featured: boolean | null, callbacks: ApiSubscribableCallbacks<Horse, ApiError>, fields: string | null) {
    this.href(Rel.modifyFeatured, true)
      .field(fields)
      .patch({featured: featured})
      .subscribe(callbacks);
  }

  isSendReviewRequestNotificationAllowed() {
    return !!this.href(Rel.sendReviewRequestNotification);
  }

  sendReviewRequestNotification(callbacks: ApiSubscribableCallbacks<Horse, ApiError>, fields: string | null) {
    this.href(Rel.sendReviewRequestNotification, true)
      .field(fields)
      .post({})
      .subscribe(callbacks);
  }

  // collectible ....................................................................................................................................

  isAddCollectibleAllowed() {
    return !!this.href(Rel.collectible_add);
  }

  addCollectible(collectible: Collectible, callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.collectible_add, true)
      .field(fields)
      .post(collectible)
      .subscribe(callbacks);
  }

  findCollectibleByKey(key: string, callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.collectible_find, true)
      .replace('aggregateKey', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  searchCollectibles(request: CollectibleSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Collectible>, ApiError>, fields: string | null) {
    this.href(Rel.collectible_search, true)!
      .param('keywords', request.keywords)
      .param('key', request.key)
      .param('name', request.name)
      .param('sort', request.sort || 'name,asc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // legal owner ....................................................................................................................................

  isAddLegalOwnerAllowed() {
    return !!this.href(Rel.legalOwner_add);
  }

  addLegalOwner(legalOwner: LegalOwner, callbacks: ApiSubscribableCallbacks<LegalOwner, ApiError>, fields: string | null) {
    this.href(Rel.legalOwner_add, true)
      .field(fields)
      .post(legalOwner)
      .subscribe(callbacks);
  }

  searchLegalOwners(request: LegalOwnerSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<LegalOwner>, ApiError>, fields: string | null) {
    this.href(Rel.legalOwner_search, true)!
      .param('keywords', request.keywords)
      .param('key', request.key)
      .param('name', request.name)
      .param('sort', request.sort || 'name,asc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // medical record ................................................................................................................................

  isAddMedicalRecordAllowed() {
    return !!this.href(Rel.medicalRecord_add);
  }

  addMedicalRecord(medicalRecord: MedicalRecord, callbacks: ApiSubscribableCallbacks<MedicalRecord, ApiError>, fields: string | null) {
    this.href(Rel.medicalRecord_add, true)
      .field(fields)
      .post(medicalRecord)
      .subscribe(callbacks);
  }

  searchMedicalRecords(request: MedicalRecordSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<MedicalRecord>, ApiError>, fields: string | null) {
    this.href(Rel.medicalRecord_search, true)!
      .param('keywords', request.keywords)
      .param('key', request.key)
      .param('sort', request.sort || 'date,desc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // post ...........................................................................................................................................

  isAddPostAllowed() {
    return !!this.href(Rel.post_add);
  }

  addPost(post: Post, callbacks: ApiSubscribableCallbacks<Post, ApiError>, fields: string | null) {
    this.href(Rel.post_add, true)
      .field(fields)
      .post(post)
      .subscribe(callbacks);
  }

  searchPosts(request: PostSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Post>, ApiError>, fields: string | null) {
    this.href(Rel.post_search, true)!
      .param('keywords', request.keywords)
      .param('key', request.key)
      .param('sort', request.sort || 'date,desc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // training record ................................................................................................................................

  isAddTrainingRecordAllowed() {
    return !!this.href(Rel.trainingRecord_add);
  }

  addTrainingRecord(trainingRecord: TrainingRecord, callbacks: ApiSubscribableCallbacks<TrainingRecord, ApiError>, fields: string | null) {
    this.href(Rel.trainingRecord_add, true)
      .field(fields)
      .post(trainingRecord)
      .subscribe(callbacks);
  }

  searchTrainingRecords(request: TrainingRecordSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<TrainingRecord>, ApiError>, fields: string | null) {
    this.href(Rel.trainingRecord_search, true)!
      .param('keywords', request.keywords)
      .param('key', request.key)
      .param('sort', request.sort || 'date,desc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // Memberships ................................................................................................................................
  addMembership(membership: Membership, callbacks: ApiSubscribableCallbacks<Membership, ApiError>, fields: string | null) {
    this.href(Rel.membership_add, true)
        .field(fields)
        .post(membership)
        .subscribe(callbacks);
  }

  searchMemberships(request: MembershipSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Membership>, ApiError>, fields: string | null) {
    this.href(Rel.membership_search, true)!
        .param('keywords', request.keywords)
        .param('key', request.key)
        .param('sort', request.sort || 'startAt,desc')
        .param('page', request.page)
        .param('size', request.size)
        .field(fields)
        .get()
        .subscribe(callbacks);
  }

  findMembershipByKey(key: string, callbacks: ApiSubscribableCallbacks<Membership, ApiError>, fields: string | null) {
    this.href(Rel.membership_find, true)
        .replace('aggregateKey', key)
        .field(fields)
        .get()
        .subscribe(callbacks);
  }
}
