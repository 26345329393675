import React from 'react';
import PartnerHorseAbstractPage, {PartnerHorseAbstractPageProps, PartnerHorseAbstractPageState} from './PartnerHorseAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Horse from "../models/Horse";
import Partner from "../models/Partner";
import {ApiErrorResponse} from "../models/ApiError";
import {ApiResponse, ApiSearchResponse, Button, Confirmable, GlobalMessage, SearchResult, StatusButton, Str, Table, TableHeaderCell} from "startupbox-react";
import Routes from "../Routes";
import SectionHeader from "../components/SectionHeader";
import Payout, {PAYOUT_STANDARD_FIELDS} from "../models/Payout";
import Tranche from "../models/Tranche";
import PayoutSearchRequest from "../models/PayoutSearchRequest";
import FontAwesome from "react-fontawesome";
import PayoutDialog from "../payout/PayoutDialog";

let FIELDS = PAYOUT_STANDARD_FIELDS;

export type PartnerHorseTranchePayoutsPageProps = PartnerHorseAbstractPageProps & {};

export type PartnerHorseTranchePayoutsPageState = PartnerHorseAbstractPageState & {
  tranche: Tranche | null | undefined;
  payouts?: SearchResult<Payout> | null;
}

export default class PartnerHorseTranchePayoutsPage extends PartnerHorseAbstractPage<PartnerHorseTranchePayoutsPageProps, PartnerHorseTranchePayoutsPageState> {

  constructor(props: PartnerHorseTranchePayoutsPageProps) {
    super(props);
    this.activeTab = 'ownerships';
  }

  onLoadHorse(horse: Horse | undefined) {
    this.loadTranche();
  }

  loadTranche() {
    this.state.index?.findTrancheByKey(this.props.params.trancheKey, {
      next: (response => {
        this.setTranche(response, () => {
          this.searchPayouts(1);
        });
      }),
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, FIELDS);
  }

  searchPayouts(page: number) {
    let searchRequest = new PayoutSearchRequest();
    searchRequest.page = page - 1;
    this.state.tranche?.searchPayouts(searchRequest, {
      next: (response: ApiSearchResponse<Payout>) => {
        let payouts = response.resource;
        this.setPayouts(payouts);
      },
      error: this.onApiError
    }, null);
  }

  onBack() {
    this.navigate(Routes.partner.horse_tranche(this.getPartnerKey(), this.getHorseKey(), this.props.params.trancheKey))
  }

  setTranche(response: ApiResponse<Tranche>, callback?: () => void) {
    let tranche = response.resource;
    this.setState({
      tranche: tranche
    }, callback);
  }

  setPayouts(payouts: SearchResult<Payout>) {
    this.setState({
      payouts: payouts
    });
  }

  onView(payout: Payout) {
    this.navigate(Routes.partner.horse_tranche_payout(this.getPartnerKey(), this.getHorseKey(), this.props.params.trancheKey, payout.key!));
  }

  renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {

    let tranche = this.state.tranche;
    let payouts = this.state.payouts;

    if (!tranche) {
      return <></>;
    }

    return <>

      <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

      <SectionHeader
        title="Payouts"
        description={
          <>
            The payouts of this tranche are shown below. Payouts are collections of entitlement distributions to owners. Owners are only allowed
            to view entitlements for payouts that are ready.
          </>
        }
        actions={
          <span className="pull-right">
            <Button
              icon="chevron-left"
              iconPosition="left"
              bsStyle="default"
              bsSize="xs"
              onClick={this.onBack}>Back To Tranche</Button>
            {
              tranche.isAddPayoutAllowed() &&
              <Confirmable
                renderConfirm={(onOk) => {
                  let payout = new Payout();
                  return (
                    <PayoutDialog
                      tranche={tranche!}
                      payout={payout}
                      onClose={() => {
                        this.searchPayouts(1);
                        onOk();
                      }}
                    />
                  );
                }}
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="plus"
                      bsStyle="primary"
                      bsSize="xs"
                      onClick={onShow}>Add Payout</Button>
                  );
                }}/>
            }
          </span>
        }/>

      <Table
        items={payouts}
        onPaginate={this.searchPayouts}
        blankMessage="No race winnings found"
        renderHeaderRow={() => {
          return (
            <tr>
              <TableHeaderCell label="Event Date" className="col-md-2"/>
              <TableHeaderCell label="Payment Date" className="col-md-2"/>
              <TableHeaderCell label="Total Amount" className="text-right"/>
              <TableHeaderCell label="BTX Amount" className="text-right"/>
              <TableHeaderCell label="Status" className="col-md-2 text-right"/>
              <TableHeaderCell label="&nbsp;" className="col-chevron"/>
            </tr>
          );
        }}
        renderItemRow={(payout: Payout, r: number) => {
          return (
            <tr key={r} className="clickable" onClick={this.fn(this.onView, payout)}>
              <td>{Str.formatDate(payout.date, "DD/MM/YYYY")}</td>
              <td>{Str.formatDate(payout.paymentDate, "DD/MM/YYYY")}</td>
              <td className="text-right">${Str.formatNumber(payout.totalAmount, 2, '.', ',')}</td>
              <td className="text-right">{payout && <>${Str.formatNumber(payout.remainingAmount, 2, '.', ',')}</>}</td>
              <td className="text-right">
                <StatusButton
                  id={'status-' + r}
                  value={payout.status}/>
              </td>
              <td className="text-right">
                <FontAwesome name="chevron-right"/>
              </td>
            </tr>
          );
        }}
      />

    </>;

  }

};
