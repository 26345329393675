import Model from '../Model';
import {ApiSubscribableCallbacks} from "startupbox-react";
import ApiError from "./ApiError";
import UserImportRunStatus from "./UserImportRunStatus";
import UserImportRunUser from "./UserImportRunUser";

let Rel = {
  self: 'self',
  modify: 'modify',
  modifyStatus: 'modifyStatus',
};

export const USER_IMPORT_RUN_STANDARD_FIELDS = 'users';

export default class UserImportRun {

  key?: string;

  createdAt?: Date;
  status?: UserImportRunStatus;
  users?: Array<UserImportRunUser>;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  isModifyAllowed() {
    return !!this.href(Rel.modify);
  }

  save(callbacks: ApiSubscribableCallbacks<UserImportRun, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }

  isModifyStatusAllowed() {
    return !!this.href(Rel.modifyStatus);
  }

  modifyStatus(status: UserImportRunStatus | null, callbacks: ApiSubscribableCallbacks<UserImportRun, ApiError>, fields: string | null) {
    this.href(Rel.modifyStatus, true)
      .field(fields)
      .patch({status: status})
      .subscribe(callbacks);
  }

}
