import React from 'react';
import Context from '../Context';
import Page, {PageProps, PageState} from '../Page';
import Routes from '../Routes';

export type LogoutPageProps = PageProps & {};

export type LogoutPageState = PageState & {};

export default class LogoutPage extends Page<LogoutPageProps, LogoutPageState> {

  constructor(props: LogoutPageProps) {
    super(props);
    if (Context.isAuthenticated()) {
      setTimeout(() => {
        this.navigateToDefault();
      }, 200);
    }
  }

  navigateToDefault() {
    this.navigate(Routes.main.home());
  }

  render() {

    return (

      <div>

        {
          !Context.isAuthenticated() &&
          <p className="text-center marginTop50 marginBottom100">You have successfully logged out and may safely close this browser tab.</p>
        }

      </div>

    );

  }

}