import React from 'react';
import {Button} from "startupbox-react";

type DownloadPanelProps = {
  className?: string;
  style?: any;
};

const DownloadPanel = (props: DownloadPanelProps) => {

  let onNavigateToGooglePlay = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.btxtechnology.consumer';
  }

  let onNavigateToAppleAppStore = () => {
    window.location.href = 'https://apps.apple.com/au/app/BTX/id1623291390?mt=8';
  }


  return (
    <div style={{margin: '0 30px'}}>
      <h1 className="title text-center">Download App</h1>
      <p className="lead text-center">Please download the BTX Mobile App from the Apple Store or Google Play for a full immersive mobile experience.</p>
      <div className="actions text-center">
        <Button
          bsStyle="primary"
          bsSize="large"
          style={{minWidth: '200px'}}
          onClick={onNavigateToAppleAppStore}>Apple App Store</Button>
        <Button
          bsStyle="primary"
          bsSize="large"
          style={{minWidth: '200px'}}
          onClick={onNavigateToGooglePlay}>Google Play</Button>
      </div>
    </div>
  );

};

export default DownloadPanel;