import React from 'react';
import FontAwesome from 'react-fontawesome';
import {ApiResponse, Button, Confirmable, Table, TableHeaderCell} from 'startupbox-react';
import SectionHeader from '../components/SectionHeader';
import './HorsePanel.css';
import HorseAttribute from "../models/HorseAttribute";
import HorseAttributeGroup from "../models/HorseAttributeGroup";
import HorseAttributeDialog from "./HorseAttributeDialog";
import Horse from "../models/Horse";
import HorseAttributeValue from "./HorseAttributeValue";
import NotSpecified from "../components/NotSpecified";
import Panel from "../components/Panel";

type HorseAttributeGroupPanelProps = {
  horse: Horse,
  group: HorseAttributeGroup,
  horseFields: string | null,
  disabled?: boolean;
  onReload?: (response: ApiResponse<Horse>) => void,
};

const HorseAttributeGroupPanel = (props: HorseAttributeGroupPanelProps) => {

  let horse = props.horse;
  let group = props.group;
  let horseFields = props.horseFields;
  let attributes = group.attributes;
  let isSaveAttributeAllowed = !!props.onReload && !props.disabled && horse.isModifyAllowed();
  let onReload = props.onReload;

  return (

    <Panel>
      <SectionHeader
        title={group.name || 'Attributes'}
        actions={
          <>
            <Confirmable
              renderConfirm={(onOk, onCancel) => {
                let horseAttribute = new HorseAttribute();
                return (
                  <HorseAttributeDialog
                    horse={horse}
                    group={group}
                    attribute={horseAttribute}
                    fields={horseFields}
                    onClose={(response: ApiResponse<Horse>) => {
                      onReload?.(response);
                      onOk();
                    }}/>
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <Button
                    icon="plus"
                    bsStyle="primary"
                    bsSize="xs"
                    onClick={onShow}
                    disabled={!isSaveAttributeAllowed}>Add Attribute</Button>
                );
              }}/>
          </>
        }/>
      <Table
        items={attributes}
        blankMessage="No attributes found"
        renderHeaderRow={() => {
          return (
            <tr>
              <TableHeaderCell label="Name" className="col-md-4"/>
              <TableHeaderCell label="Value"/>
              <TableHeaderCell label="Visibility" className="col-md-2"/>
              <TableHeaderCell label="&nbsp;" className="col-chevron"/>
            </tr>
          );
        }}
        renderItemRow={(attribute: HorseAttribute, i: number) => {
          return (
            <Confirmable
              key={i}
              renderConfirm={(onOk, onCancel) => {
                return (
                  <tr className="clickable">
                    <td>
                      <HorseAttributeDialog
                        horse={horse}
                        group={group}
                        attribute={attribute}
                        i={i}
                        fields={horseFields}
                        onClose={(response: ApiResponse<Horse>) => {
                          onReload?.(response);
                          onOk();
                        }}/>
                    </td>
                  </tr>
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <tr className="clickable" onClick={isSaveAttributeAllowed ? onShow : undefined}>
                    <td><strong>{attribute.name || <NotSpecified/>}</strong></td>
                    <td>
                      <HorseAttributeValue
                        attribute={attribute}/>
                    </td>
                    <td>{attribute.visibilityLevel ? attribute.visibilityLevel?.name : <NotSpecified/>}</td>
                    <td className="text-right text-muted">
                      {isSaveAttributeAllowed && <FontAwesome name="external-link"/>}
                    </td>
                  </tr>
                );
              }}/>
          );
        }}
      />
    </Panel>

  );

};

export default HorseAttributeGroupPanel;
