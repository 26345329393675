import Model from "../Model";
import Wallet from "./Wallet";
import AppCreditType from "./AppCreditType";

// let Rel = {
//   self: 'self',
// };

export const APP_CREDIT_STANDARD_FIELDS = '';

export default class AppCredit {

  key?: string;

  date?: Date;
  amount?: number;
  type?: AppCreditType;
  description?: string;

  walletOwnerName?: string;
  wallet?: Wallet;

  href = Model.hrefFunction();

  // ................................................................................................................................................

}
