import PushNotificationPreparationTiming from "./PushNotificationPreparationTiming";
import PushNotificationPreparationStatus from "./PushNotificationPreparationStatus";

export default class PushNotificationPreparation {

  title?: string | null;
  body?: string | null;
  status?: PushNotificationPreparationStatus | null;
  timing?: PushNotificationPreparationTiming | null;

}