import {ApiSubscribableCallbacks} from "startupbox-react";
import ApiError from "./ApiError";
import Binary from "./Binary";
import BlockchainAttributeGroup from "./BlockchainAttributeGroup";
import BlockchainMintStatus from "./BlockchainMintStatus";

export default class BlockchainMintable {

  blockchainId?: number;
  blockchainTransactionHash?: string;
  ipfsUri?: string;
  viewUrl?: string;
  ipfsMedia?: Binary;
  manageableMetadataAttributeGroup?: BlockchainAttributeGroup;
  mintStatus?: BlockchainMintStatus = {key: 'NOT_MINTED'};

  featured?: boolean;

  badgeLabel?: string;

  getCountText() {
    return '';
  }

  isModifyAllowed() {
    return false;
  }

  isModifyMintStatusAllowed() {
    return false;
  }

  isMetadataModifyAllowed() {
    return this.mintStatus && (this.mintStatus?.key === 'NOT_MINTED' || this.mintStatus?.key === 'MINTING_FAILED');
  }

  isNotMintedOrFailed() {
    return this.mintStatus && (this.mintStatus?.key === 'NOT_MINTED' || this.mintStatus?.key === 'MINTING_FAILED');
  }

  isMinting() {
    return this.mintStatus!.key === 'MINTING' || this.mintStatus!.key === 'MINTING_ACTUALS';
  }

  isMinted() {
    return this.mintStatus!.key === 'MINTED';
  }

  reload(callbacks: ApiSubscribableCallbacks<BlockchainMintable, ApiError>, fields: string | null) {
  }

  save(callbacks: ApiSubscribableCallbacks<BlockchainMintable, ApiError>, fields: string | null) {
  }

  getFeaturedOption() {
    return this.getFeaturedOptions().find((option) => option.key === (this.featured + ''))!;
  }

  getFeaturedOptions() {
    return [
      {key: 'true', name: 'Yes', meta: {stereotype: 'primary'}} as any,
      {key: 'false', name: 'No', meta: {stereotype: 'default'}} as any
    ];
  }

}
