import React from 'react';
import {Button, Form, FullScreenModal, GlobalMessage, InputDatePicker, InputInlineEditor, InputSelect, InputText} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import Horse from "../models/Horse";
import ApiIndex from "../models/ApiIndex";
import Post, {POST_STANDARD_FIELDS} from "../models/Post";
import {acceptedFileMimeTypes} from "../utils/acceptedFileMimeTypes";
import PushNotificationPreparation from '../models/PushNotificationPreparation';
import Partner from "../models/Partner";
import VisibilityLevel from "../models/VisibilityLevel";
import PremiumContentConfigurationPanel from "../premium/PremiumContentConfigurationPanel";
import SectionHeader from "../components/SectionHeader";

export type PostDialogProps = DialogProps & {
  index: ApiIndex;
  horse?: Horse;
  partner?: Partner;
  post: Post;
  visibilityLevels: VisibilityLevel[];
  visibilityLevelLabelField?: string;
  onClose: () => void;
};

export type PostDialogState = DialogState & {
  post: Post;
};

export default class PostDialog extends Dialog<PostDialogProps, PostDialogState> {

  constructor(props: PostDialogProps) {

    super(props);

    let post = this.props.post;
    if (!post.pushNotificationPreparation) {
      post.pushNotificationPreparation = new PushNotificationPreparation();
    }

    this.setInitialState({
      post: post
    });

  }

  getApplePremiumContentDefaultProductId() {
    if (this.props.horse) {
      return this.props.horse.applePremiumContentDefaultProductIdForSinglePost;
    }
    else if (this.props.partner) {
      return this.props.partner.applePremiumContentDefaultProductIdForSinglePost;
    }
    return null;
  }

  getApplePremiumContentDefaultTier() {
    if (this.props.horse) {
      return this.props.horse.applePremiumContentDefaultTierForSinglePost;
    }
    else if (this.props.partner) {
      return this.props.partner.applePremiumContentDefaultTierForSinglePost;
    }
    return null;
  }

  getGooglePremiumContentDefaultProductId() {
    if (this.props.horse) {
      return this.props.horse.googlePremiumContentDefaultProductIdForSinglePost;
    }
    else if (this.props.partner) {
      return this.props.partner.googlePremiumContentDefaultProductIdForSinglePost;
    }
    return null;
  }

  getGooglePremiumContentDefaultTier() {
    if (this.props.horse) {
      return this.props.horse.googlePremiumContentDefaultTierForSinglePost;
    }
    else if (this.props.partner) {
      return this.props.partner.googlePremiumContentDefaultTierForSinglePost;
    }
    return null;
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let post = this.state.post;
      if (post.key) {
        post.save({
          next: this.onClose,
          error: this.onApiError
        }, POST_STANDARD_FIELDS);
      }
      else if (this.props.horse) {
        let horse = this.props.horse;
        horse.addPost(post, {
          next: this.onClose,
          error: this.onApiError
        }, POST_STANDARD_FIELDS);
      }
      else if (this.props.partner) {
        let partner = this.props.partner;
        partner.addPost(post, {
          next: this.onClose,
          error: this.onApiError
        }, POST_STANDARD_FIELDS);
      }
      else if (this.props.index) {
        let index = this.props.index;
        index.addNews(post, {
          next: this.onClose,
          error: this.onApiError
        }, POST_STANDARD_FIELDS);
      }
    }
  }

  isDeleteAllowed() {
    let post = this.state.post;
    return post.isDeleteAllowed();
  }

  onDelete() {
    let post = this.state.post;
    post.delete({
      next: this.onClose,
      error: this.onApiError
    });
  }

  onClose() {
    this.props.onClose();
  }

  isNews() {
    return !this.props.horse;
  }

  setPost(post: Post) {
    this.setState({
      post: post
    })
  }

  renderWithIndex(index: ApiIndex) {

    let post = this.state.post;
    let visibilityLevels = this.props.visibilityLevels;
    let visibilityLevelLabelField = this.props.visibilityLevelLabelField;

    return (

      <FullScreenModal title={!post.key ? 'New Post' : 'Edit Post'} onHide={this.onClose}>

        <p className="lead">Please enter the details of the post below.</p>

        <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

        <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

          <Panel>

            <InputText
              id="post.title"
              label="Post Title"
              value={post.title}
              error={this.state.errors?.['request.title']}
              onChange={(e) => {
                post.title = e.target.value;
                if (!post.isPushNotificationStarted()) {
                  post.pushNotificationPreparation!.title = post.title;
                }
                this.setState({});
              }}
              className="lg"
              required/>

            <InputInlineEditor
              tools="traditional"
              id="post.content.body"
              elementId="dialog-content"
              label="Notes"
              help="A freeform rich content of this post comprising of text, images and file attachments."
              value={post.content?.body}
              error={this.state.errors?.['post.content.body']}
              uploadFileAcceptedMimeTypes={acceptedFileMimeTypes}
              onChange={this.onFieldChange}/>

            <div className="row">
              <div className="col-md-6">
                <InputDatePicker
                  id="post.date"
                  label="Date"
                  placeholder="Now"
                  value={post.date}
                  error={this.state.errors?.['request.date']}
                  dateFormat="DD/MM/YYYY"
                  onChange={this.onFieldChange}
                  required/>
              </div>
              <div className="col-md-6">
                <InputSelect
                  id="post.status"
                  label="Status"
                  value={post.status}
                  error={this.state.errors?.['request.status']}
                  options={index.postStatuses}
                  onChange={this.onFieldChange}/>
              </div>
            </div>

          </Panel>

          <Panel>

            <SectionHeader title="Push Notification"/>

            <div className="row">
              <div className="col-md-12">
                <InputSelect
                  id="post.pushNotificationPreparation.timing"
                  label="Timing"
                  value={post.pushNotificationPreparation?.timing}
                  error={this.state.errors?.['request.pushNotificationPreparation.timing']}
                  options={index.pushNotificationPreparationTimings}
                  onChange={this.onFieldChange}
                  readOnly={post.isPushNotificationStarted()}/>
              </div>
            </div>

            {
              post.isPushNotificationRequired() && <>
                <div className="row">
                  <div className="col-md-12">
                    <InputText
                      id="post.pushNotificationPreparation.title"
                      label="Title"
                      value={post.pushNotificationPreparation?.title}
                      error={this.state.errors?.['request.pushNotificationPreparation.title']}
                      onChange={this.onFieldChange}
                      className="lg"
                      readOnly={post.isPushNotificationStarted()}/>
                  </div>
                </div>
              </>
            }

          </Panel>

          <Panel>

            <SectionHeader title="Access"/>

            <div className="row">
              <div className="col-md-12">
                <InputSelect
                  id="post.visibilityLevel"
                  label="Visibility Level"
                  labelField={visibilityLevelLabelField}
                  value={post.visibilityLevel}
                  error={this.state.errors?.['request.visibilityLevel']}
                  options={visibilityLevels}
                  onChange={this.onFieldChange}
                  readOnly={post.published}/>
              </div>
              <div className="col-md-6">
              </div>
            </div>

          </Panel>

          {
            post.isPremium() &&
            <PremiumContentConfigurationPanel
              index={index}
              prefix="post"
              entity={post}
              title={null}
              description={null}
              applePremiumContentDefaultProductId={this.getApplePremiumContentDefaultProductId()}
              applePremiumContentDefaultTier={this.getApplePremiumContentDefaultTier()}
              googlePremiumContentDefaultProductId={this.getGooglePremiumContentDefaultProductId()}
              googlePremiumContentDefaultTier={this.getGooglePremiumContentDefaultTier()}
              errors={this.state.errors}
              onFieldChange={!post.published ? this.onFieldChange : undefined}/>
          }

          <div className="actions">
            <Button
              bsStyle="primary"
              bsSize="large"
              type="submit">Save Changes</Button>
            <Button
              onClick={this.onClose}
              bsSize="large">Cancel</Button>
            {
              this.isDeleteAllowed() &&
              <Button
                onClick={this.onDelete}
                bsSize="large"
                bsStyle="default"
                className="pull-right">Delete</Button>
            }
          </div>

        </Form>

      </FullScreenModal>

    );

  }

}
