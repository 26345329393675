import React from 'react';
import {Nav, Navbar, NavItem} from 'react-bootstrap';
import {Link} from 'react-router';
import {Button, Form, InputText, MockupFragment, StatusButton, Table, TableHeaderCell} from 'startupbox-react';
import Divider from '../../app/components/Divider';

export const FundProductMockup_secondary_nav = () => {

  return (

    <MockupFragment
      wireframe={true}>

      <Navbar id="secondary-nav">
        <div className="row">
          <div className="col-md-12">
            <ul className="nav navbar-nav">
              <li><Link to="">Trainer Home</Link></li>
              <li><Link to="">Horses</Link></li>
              <li><Link to="" className="active">Funds</Link></li>
              <li><Link to="">Teams</Link></li>
            </ul>
          </div>
        </div>
      </Navbar>

    </MockupFragment>

  );

}

export const FundProductMockup_list_page = () => {

  return (

    <MockupFragment
      wireframe={true}
      wireframeLeftNotes={[]}
      wireframeRightNotes={[]}>

      <div className="panel-body">

        <h3>Funds</h3>

        <Form className="form-inline">
          <InputText placeholder="Name" disabled/>
          <InputText placeholder="Status" disabled/>
          <span className="actions">
            <Button icon="search" bsStyle="primary" disabled>Search</Button>
            <Button icon="search" bsStyle="primary" className="pull-right" disabled>Add</Button>
          </span>
        </Form>

        <Table
          items={[
            {name: 'Win Melbourne Cup', status: {key: 'Active'}},
            {name: 'Win Everest Cup', status: {key: 'Active'}},
          ]}
          renderHeaderRow={() => {
            return (
              <tr>
                <TableHeaderCell label="Name" sort="name"/>
                <TableHeaderCell label="Status" sort="status" className="col-md-2 text-right"/>
              </tr>
            );
          }}
          renderItemRow={(fund, i) => {
            return (
              <tr key={i} className="clickable">
                <td>
                  {fund.name}
                </td>
                <td className="text-right">
                  <StatusButton id={'status-' + i} value={fund.status}/>
                </td>
              </tr>
            );
          }}
        />

      </div>

    </MockupFragment>

  );

}

export const FundProductMockup_details_tab = () => {

  return (

    <MockupFragment
      wireframe={true}
      wireframeLeftNotes={[]}
      wireframeRightNotes={[]}>

      <div className="panel-body">

        <h3>Win Melbourne Cup</h3>

        <FundTabs activeTab="details"/>

        <InputText label="Purpose" readOnly/>
        <InputText label="Start date" readOnly/>
        <InputText label="End date" readOnly/>
        <InputText label="Target date" readOnly/>
        <InputText label="Number of units (eg 1000 units)" readOnly/>
        <InputText label="Price per unit (eg $1000)" readOnly/>
        <InputText label="Target amount (computed)" readOnly/>

        <InputText label="Featured" readOnly/>
        <InputText label="Visibility Status published" readOnly/>

        <Divider/>

        <InputText label="Cover image" readOnly/>
        <InputText label="Promotional Images" readOnly/>
        <InputText label="Overview?" readOnly/>
        <InputText label="Terms" readOnly/>
        <InputText label="PDS" readOnly/>
        <InputText label="Blockchain fields (IPFS etc)" readOnly/>
        <InputText label="Mint status" readOnly/>


      </div>

    </MockupFragment>

  );

}

export const FundProductMockup_horses_tab = () => {

  return (

    <MockupFragment
      wireframe={true}
      wireframeLeftNotes={[]}
      wireframeRightNotes={[]}>

      <div className="panel-body">

        <h3>Win Melbourne Cup</h3>

        <FundTabs activeTab="horses"/>

        <Form className="form-inline">
          <InputText placeholder="Name" disabled/>
          <InputText placeholder="Status" disabled/>
          <span className="actions">
            <Button icon="search" bsStyle="primary" disabled>Search</Button>
            <Button icon="search" bsStyle="primary" className="pull-right" disabled>Add</Button>
          </span>
        </Form>

        <Table
          items={[
            {name: 'Dillingr Win Melbourne Cup Tranche', status: {key: 'Minted'}},
            {name: 'Strawberry Rock Win Melbourne Cup Tranche', status: {key: 'Not minted'}},
          ]}
          renderHeaderRow={() => {
            return (
              <tr>
                <TableHeaderCell label="Name" sort="name"/>
                <TableHeaderCell label="Mint Status" sort="status" className="col-md-2 text-right"/>
              </tr>
            );
          }}
          renderItemRow={(tranche, i) => {
            return (
              <tr key={i} className="clickable">
                <td>
                  {tranche.name}
                </td>
                <td className="text-right">
                  <StatusButton id={'status-' + i} value={tranche.status}/>
                </td>
              </tr>
            );
          }}
        />

      </div>

    </MockupFragment>

  );

}

export const FundProductMockup_investors_tab = () => {

  return (

    <MockupFragment
      wireframe={true}
      wireframeLeftNotes={[]}
      wireframeRightNotes={[]}>

      <div className="panel-body">

        <h3>Win Melbourne Cup</h3>

        <FundTabs activeTab="investors"/>

        <Form className="form-inline">
          <InputText placeholder="Name" disabled/>
          <InputText placeholder="Status" disabled/>
          <span className="actions">
            <Button icon="search" bsStyle="primary" disabled>Search</Button>
            <Button icon="search" bsStyle="primary" className="pull-right" disabled>Add</Button>
          </span>
        </Form>

        <Table
          items={[
            {name: 'Fred Smith', units: '10/10', status: {key: 'Paid'}},
            {name: 'John Adams', units: '25/25', status: {key: 'Paid'}},
          ]}
          renderHeaderRow={() => {
            return (
              <tr>
                <TableHeaderCell label="Name"/>
                <TableHeaderCell label="Units"/>
                <TableHeaderCell label="Status" className="col-md-2 text-right"/>
              </tr>
            );
          }}
          renderItemRow={(user, i) => {
            return (
              <tr key={i} className="clickable">
                <td>
                  {user.name}
                </td>
                <td>
                  {user.units}
                </td>
                <td className="text-right">
                  <StatusButton id={'status-' + i} value={user.status}/>
                </td>
              </tr>
            );
          }}
        />

      </div>

    </MockupFragment>

  );

}

export const FundProductMockup_ownerships_tab = () => {

  return (

    <MockupFragment
      wireframe={true}
      wireframeLeftNotes={[]}
      wireframeRightNotes={[]}>

      <div className="panel-body">

        <h3>Legal Owners</h3>

        <FundTabs activeTab="investors"/>

        <Table
          items={[
            {name: 'BTX', percentage: '5%'},
            {name: ' - Delingr Premiere Tranche', percentage: '3.75%', status: {key: 'Minted'}},
            {name: ' - Delingr Win Melbourne Cup Tranche (fund tranche)', percentage: '1.25%', status: {key: 'Minted'}},
          ]}
          renderHeaderRow={() => {
            return (
              <tr>
                <TableHeaderCell label="Name"/>
                <TableHeaderCell label="Status"/>
                <TableHeaderCell label="Percentage" className="text-right"/>
              </tr>
            );
          }}
          renderItemRow={(item, i) => {
            return (
              <tr key={i} className="clickable">
                <td>
                  {item.name}
                </td>
                <td>
                  {item.status && <StatusButton id={'status-' + i} value={item.status}/>}
                </td>
                <td className="text-right">
                  {item.percentage}
                </td>
              </tr>
            );
          }}
        />

      </div>

    </MockupFragment>

  );

}


export const FundProductMockup_tranche_details_tab = () => {

  return (

    <MockupFragment
      wireframe={true}
      wireframeLeftNotes={[]}
      wireframeRightNotes={[]}>

      <div className="panel-body">

        <h3>Dellingr</h3>

        <HorseTabs activeTab="ownerships"/>

        <div className="row">
          <div className="col-md-6">
            <InputText label="Tranche Name" value="Dillingr Win Melbourne Cup Tranche" readOnly/>
          </div>
          <div className="col-md-6 text-right">
            <InputText label="Fund" value="Win Melbourne Cup" readOnly/>
          </div>
        </div>

        <p>Existing tranche fields goes here</p>

      </div>

    </MockupFragment>

  );

}

// ................................................................................................................................................

const FundTabs = (props) => {

  return (
    <div id="tabs" className="tabs">
      <Nav id="tabs-nav" bsStyle="pills" justified activeKey={props.activeTab} onSelect={null}>
        <NavItem eventKey="details">Details</NavItem>
        <NavItem eventKey="horses">Horses</NavItem>
        <NavItem eventKey="posts">Posts</NavItem>
        <NavItem eventKey="investors">Investors</NavItem>
      </Nav>
    </div>
  );

}

// ................................................................................................................................................

const HorseTabs = (props) => {

  return (
    <div id="tabs" className="tabs">
      <Nav id="tabs-nav" bsStyle="pills" justified activeKey={props.activeTab} onSelect={null}>
        <NavItem eventKey="details">Details</NavItem>
        <NavItem eventKey="ownerships">Ownerships</NavItem>
        <NavItem eventKey="collectibles">Collectibles</NavItem>
        <NavItem eventKey="posts">Posts</NavItem>
        <NavItem eventKey="medicals">Medical</NavItem>
        <NavItem eventKey="trainings">Training</NavItem>
      </Nav>
    </div>
  );

}
