import React from 'react';
import {ApiResponse, Button, Form, FullScreenModal, GlobalMessage, ImageWithUpload, InputInlineEditor, InputSelect, InputText, InputTextArea} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import Horse from "../models/Horse";
import ApiIndex from "../models/ApiIndex";
import Divider from "../components/Divider";
import {acceptedFileMimeTypes, acceptedImageMimeTypes} from "../utils/acceptedFileMimeTypes";
import Binary from "../models/Binary";
import Partner from "../models/Partner";
import PremiumContentConfigurationPanel from "../premium/PremiumContentConfigurationPanel";

export type HorseDialogProps = DialogProps & {
  partner: Partner;
  horse: Horse;
  fields: string | null;
  onSave: (response: ApiResponse<Horse>) => void;
  onCancel: () => void;
};

export type HorseDialogState = DialogState & {
  horse: Horse;
};

export default class HorseDialog extends Dialog<HorseDialogProps, HorseDialogState> {

  constructor(props: HorseDialogProps) {
    super(props);
    this.setInitialState({
      horse: this.props.horse
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let horse = this.state.horse;
      if (!horse.key) {
        this.props.partner.addHorse(horse, {
          next: this.onSave,
          error: this.onApiError
        }, this.props.fields);
      }
      else {
        horse.save({
          next: this.onSave,
          error: this.onApiError
        }, this.props.fields);
      }
    }
  }

  onSave(response: ApiResponse<Horse>) {
    this.enable();
    this.props.onSave(response);
  }

  onCancel() {
    this.enable();
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let partner = this.props.partner;
    let horse = this.state.horse;

    return (

      <div>

        <FullScreenModal title={!horse.key ? 'New Horse' : 'Edit Horse'} onHide={this.onCancel}>

          <p className="lead">Please enter the details of this horse below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <InputText
                id="horse.name"
                label="Horse Name"
                help="The name of the horse."
                value={horse.name}
                error={this.state.errors?.['request.name']}
                onChange={this.onFieldChange}
                className="lg"
                required/>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="horse.microchipNumber"
                    label="Microchip Number"
                    value={horse.microchipNumber}
                    error={this.state.errors?.['request.microchipNumber']}
                    onChange={this.onFieldChange}
                    required/>
                </div>
                <div className="col-md-6">
                  <InputSelect
                    id="horse.racingStatus"
                    label="Racing Status"
                    value={horse.racingStatus}
                    error={this.state.errors?.['request.racingStatus']}
                    options={index.horseRacingStatuses}
                    onChange={this.onFieldChange}
                    required/>
                </div>
              </div>

              <Divider/>

              <ImageWithUpload
                id="horse.coverImage"
                label="Cover Image"
                files={horse.coverImage ? [horse.coverImage] : []}
                className="images-across-1"
                accept={acceptedImageMimeTypes}
                lightbox
                upload
                onChange={(e: any) => {
                  let images: Array<Binary> = e.target.value;
                  let coverImage = (images && images.length > 0) ? images[0] : undefined;
                  if (!coverImage || coverImage.externalReference) {
                    e.target.value = coverImage;
                    this.onFieldChange(e);
                  }
                }}/>

              <InputTextArea
                id="horse.summaryDescription"
                label="Summary Description"
                help="A short one paragraph summary description without formatting to display alongside the profile of this horse."
                value={horse.summaryDescription}
                error={this.state.errors?.['request.summaryDescription']}
                onChange={this.onFieldChange}/>

              <InputInlineEditor
                tools="traditional"
                id="horse.overview.body"
                elementId="dialog-overview"
                label="Overview"
                help="A freeform rich content overview of this horse comprising of text, images and file attachments."
                value={horse.overview?.body}
                error={this.state.errors?.['request.overview.body']}
                uploadFileAcceptedMimeTypes={acceptedFileMimeTypes}
                onChange={this.onFieldChange}/>

              <ImageWithUpload
                id="horse.pedigreeImages"
                label="Pedigree"
                multiple
                lightbox
                upload
                files={horse.pedigreeImages || []}
                accept={acceptedImageMimeTypes}
                error={this.state.errors?.['request.pedigreeImages']}
                onChange={(e: any) => {
                  let images: Array<Binary> = e.target.value;
                  e.target.value = images;
                  this.onFieldChange(e);
                }}/>

            </Panel>

            <PremiumContentConfigurationPanel
              index={index}
              prefix="horse"
              entity={horse}
              title={null}
              description={null}
              applePremiumContentDefaultProductId={undefined}
              applePremiumContentDefaultTier={partner.applePremiumContentDefaultTierForHorse}
              googlePremiumContentDefaultProductId={undefined}
              googlePremiumContentDefaultTier={partner.googlePremiumContentDefaultTierForHorse}
              errors={this.state.errors}
              onFieldChange={this.onFieldChange}/>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Save Changes</Button>
              <Button
                onClick={this.onCancel}
                bsSize="large">Cancel</Button>
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
