import React from 'react';
import {ApiSearchResponse, Button, Confirmable, Form, InputText, Pagination, SearchResult} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import ApiIndex from "../models/ApiIndex";
import BannerSearchRequest from "../models/BannerSearchRequest";
import Banner, {BANNER_STANDARD_FIELDS} from "../models/Banner";
import BannerDialog from "./BannerDialog";
import BannerPanel from "./BannerPanel";

export type BannersPageProps = PageProps & {};

export type BannersPageState = PageState & {
  bannerSearchRequest: BannerSearchRequest;
  banners?: SearchResult<Banner> | null;
};

export default class BannersPage extends Page<BannersPageProps, BannersPageState> {

  constructor(props: BannersPageProps) {
    super(props);
    this.setInitialState({
      bannerSearchRequest: new BannerSearchRequest()
    });
  }

  onEnter() {
    this.setState({
      bannerSearchRequest: {
        keywords: this.query('keywords'),
        page: parseInt(this.query('page') || '0'),
      },
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('keywords', this.state.bannerSearchRequest.keywords)
      .param('sort', this.state.bannerSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Banners');
    this.searchBanners(this.state.bannerSearchRequest.page + 1);
  }

  searchBanners(page: number) {
    let bannerSearchRequest = this.state.bannerSearchRequest;
    bannerSearchRequest.page = page - 1;
    this.state.index?.searchBanners(bannerSearchRequest, {
      next: this.setBanners,
      error: this.onApiError
    }, BANNER_STANDARD_FIELDS);
  }

  setBanners(response: ApiSearchResponse<Banner>) {
    this.setState({
      banners: response.resource
    })
  }

  renderBanners(index: ApiIndex, banners: SearchResult<Banner>) {

    return <>

      <div className="row">
        {
          banners.values.map((banner: Banner, b: number) => {
            return (
              <div key={b} className="col-sm-6 col-sm-offset-0 col-md-6 col-md-offset-0">
                <Confirmable
                  renderTrigger={(onShow) => {
                    return (
                      <BannerPanel
                        banner={banner}
                        onClick={onShow}/>
                    );
                  }}
                  renderConfirm={(onOk) => {
                    return (
                      <BannerDialog
                        index={index}
                        banner={banner}
                        onSave={() => {
                          this.searchBanners(1);
                          onOk();
                        }}
                        onCancel={() => {
                          this.searchBanners(1);
                          onOk();
                        }}/>
                    );
                  }}/>
              </div>
            );
          })
        }
      </div>

      {
        banners &&
        <Pagination
          pageable={banners as any}
          load={this.onEnter}/>
      }

    </>;

  }

  renderWithIndex(index: ApiIndex) {

    let banners = this.state.banners;

    return (

      <div className="row">

        <div className="col-md-12">

          <PageHeader
            title="Banners"
            lead="This area allows you to manage the BTX Banners which is visible to all users on
            the platform. From here you may add a new banner or edit the details of an existing
            banner. Only one active banner is permissible at a given time."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="bannerSearchRequest.keywords"
              placeholder="Keywords"
              value={this.state.bannerSearchRequest.keywords}
              onChange={this.onFieldChange}
              className="wide"/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              {
                index.isCreateBannerAllowed() &&
                <Confirmable
                  renderTrigger={(onShow) => {
                    return (
                      <Button
                        icon="plus"
                        bsStyle="default"
                        className="pull-right"
                        onClick={onShow}>Add</Button>
                    );
                  }}
                  renderConfirm={(onOk) => {
                    return (
                      <BannerDialog
                        index={index}
                        banner={new Banner()}
                        onSave={() => {
                          this.searchBanners(1);
                          onOk();
                        }}
                        onCancel={() => {
                          this.searchBanners(1);
                          onOk();
                        }}/>
                    );
                  }}/>
              }
            </span>
          </Form>

          <br/>

          {banners && this.renderBanners(index, banners)}

        </div>

      </div>

    );

  }

}
