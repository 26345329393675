import React, {ReactNode, useEffect} from 'react';
import {Navbar} from 'react-bootstrap';
import {Link} from 'react-router';
import Context from '../Context';
import Events from '../Events';
import {RouteProps} from '../Page';
import Routes from '../Routes';
import Footer from './Footer';
import PrimaryNav from './PrimaryNav';
import AccessDeniedPanel from "../components/AccessDeniedPanel";

type ToolPageLayoutProps = {
  params: { [key: string]: any };
  route: RouteProps;
  children: ReactNode;
}

const ToolPageLayout = (props: ToolPageLayoutProps) => {

  useEffect(() => {
    Events.system.sectionTitle.publish('Tool');
  }, []);

  let getLinkClassName = (value: string, excludes?: string[]) => {
    let path = window.location.pathname;
    if (path.indexOf(value) !== -1) {
      if (excludes) {
        for (let exclude of excludes) {
          if (path.indexOf(exclude) !== -1) {
            return undefined;
          }
        }
      }
      return 'active';
    }
    return undefined
  }

  let isAllowed = Context.isAdmin();

  return (

    <div>

      <PrimaryNav/>

      <Navbar id="secondary-nav">
        <div className="row">
          <div className="col-md-12">
            {
              isAllowed &&
              <ul className="nav navbar-nav">
                <li>
                  <Link
                    to={Routes.tool.hubSpotAsyncMessages()}
                    className={getLinkClassName('hubspot')}>HubSpot</Link>
                </li>
                <li>
                  <Link
                      to={Routes.tool.legalOwner()}
                      className={getLinkClassName('legalOwners')}>Legal Owners</Link>
                </li>
                <li>
                  <Link
                    to={Routes.tool.settings()}
                    className={getLinkClassName('settings')}>Settings</Link>
                </li>
              </ul>
            }
          </div>
        </div>
      </Navbar>

      <div id="main-content" className="has-secondary-nav">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {isAllowed && props.children}
              {!isAllowed && <AccessDeniedPanel/>}
            </div>
          </div>
        </div>
      </div>

      <Footer/>

    </div>

  );

};

export default ToolPageLayout;
