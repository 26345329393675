import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, Confirmable, Form, InputText, SearchResult, StatusButton, Table, TableHeaderCell} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from './PartnerAbstractPage';
import PackSearchRequest from "../models/PackSearchRequest";
import Partner from "../models/Partner";
import ApiIndex from "../models/ApiIndex";
import Pack, {PACK_LIST_FIELDS} from "../models/Pack";
import Routes from "../Routes";
import FontAwesome from "react-fontawesome";
import PackDialog from "../pack/PackDialog";

let FIELDS = PACK_LIST_FIELDS;

export type PartnerPacksPageProps = PartnerAbstractPageProps & {};

export type PartnerPacksPageState = PartnerAbstractPageState & {
  packSearchRequest: PackSearchRequest;
  packs: SearchResult<Pack> | null;
}

export default class PartnerPacksPage extends PartnerAbstractPage<PartnerPacksPageProps, PartnerPacksPageState> {

  constructor(props: PartnerPacksPageProps) {
    super(props);
    this.setInitialState({
      packSearchRequest: new PackSearchRequest(),
      packs: null,
    });
  }

  onEnter() {
    this.setState({
      packSearchRequest: {
        keywords: this.query('keywords'),
        sort: this.query('sort'),
        page: parseInt(this.query('page') || '0'),
      }
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('keywords', this.state.packSearchRequest.keywords)
      .param('sort', this.state.packSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoadPartner(partner: Partner) {
    this.searchPacks(this.state.packSearchRequest.page + 1);
  }

  searchPacks(page: number) {
    let partner = this.state.partner;
    if (partner) {
      let packSearchRequest = this.state.packSearchRequest;
      packSearchRequest.page = page - 1;
      partner.searchPacks(packSearchRequest, {
        next: this.setPacks,
        error: this.onApiError
      }, FIELDS);
    }
  }

  onSave(response: ApiResponse<Pack>) {
    this.onView(response.resource!);
  }

  onView(pack: Pack) {
    let partner = this.state.partner;
    if (partner) {
      this.navigate(Routes.partner.pack(partner.key!, pack.key!));
    }
  }

  setPacks(response: ApiSearchResponse<Pack>) {
    this.setState({
      packs: response.resource
    })
  }

  renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title={partner.name}
            subTitle="Packs"
            lead="This area allows you to manage the packs offered for sale. Users of the app may purchase these packs to view, own and trade."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="packSearchRequest.keywords"
              placeholder="Keywords"
              value={this.state.packSearchRequest.keywords}
              onChange={this.onFieldChange}
              className="wide"/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              {
                partner.isAddPackAllowed() &&
                <Confirmable
                  renderTrigger={(onShow) => {
                    return (
                      <Button
                        icon="plus"
                        bsStyle="default"
                        className="pull-right"
                        onClick={onShow}>Add</Button>
                    );
                  }}
                  renderConfirm={(onOk, onCancel) => {
                    return (
                      <PackDialog
                        partner={partner}
                        pack={new Pack()}
                        onSave={(response) => {
                          this.onSave(response);
                          onOk();
                        }}
                        onDelete={() => {
                          this.searchPacks(1);
                          onOk();
                        }}
                        onCancel={onCancel}/>
                    );
                  }}/>
              }
            </span>
          </Form>

          <br/>

          <Table
            items={this.state.packs}
            sort={this.state.packSearchRequest.sort}
            onReload={this.onEnter}
            blankMessage="No packs found"
            renderHeaderRow={() => {
              return (
                <tr>
                  <TableHeaderCell label="Name" sort="name"/>
                  <TableHeaderCell label="Status" sort="status" className="col-md-2 text-right"/>
                  <TableHeaderCell label="&nbsp;" className="col-chevron"/>
                </tr>
              );
            }}
            renderItemRow={(pack: Pack, p: number) => {
              return (
                <tr key={p} className="clickable" onClick={this.fn(this.onView, pack)}>
                  <td>
                    <strong>{pack.name}</strong>
                  </td>
                  <td className="text-right">
                    <StatusButton
                      id={'status-' + p}
                      value={pack.status}
                      options={index.packStatuses}/>
                  </td>
                  <td className="text-right">
                    <FontAwesome name="chevron-right"/>
                  </td>
                </tr>
              );
            }}
          />

        </div>

      </div>

    );

  }

};