import React from 'react';
import {Button, DialogPanel, FullScreenModal} from 'startupbox-react';
import Page, {PageProps, PageState} from '../Page';
import ApiIndex from "../models/ApiIndex";

export type FrankieOneMockPageProps = PageProps & {};

export type FrankieOneMockPageState = PageState & {};

export default class FrankieOneMockPage extends Page<FrankieOneMockPageProps, FrankieOneMockPageState> {

  constructor(props: FrankieOneMockPageProps) {
    super(props);
    this.setInitialState({});
  }

  onEnter() {
    this.setState({}, this.onLoad);
  }

  renderWithIndex(index: ApiIndex) {

    let frankieOneAccessKey = this.query('frankieOneAccessKey');

    let onSuccess = () => {
      let frankieOneResult = {
        applicant: {
          entityId: Date.now() + '',
        }
      };
      this.state.index?.markUserIdentityVerified(frankieOneAccessKey, frankieOneResult, {
        next: (response) => {
          window.location.href = '/frankie-one/success?frankieOneAccessKey=' + frankieOneAccessKey;
        },
        error: this.onApiError
      }, null);
    }

    let onFailure = () => {
      window.location.href = '/frankie-one/failure?frankieOneAccessKey=' + frankieOneAccessKey;
    }

    return (

      <FullScreenModal className="centered-title">

        <DialogPanel>

          <h2 className="marginTop50">BTX Frankie One Mock</h2>
          <p>This page is a mock of Frankie One for use during development. In production, the real Frankie One Smart UI will display instead.</p>

          <div className="actions">
            <Button
              bsStyle="primary"
              onClick={onSuccess}>Mock Success</Button>
            <Button
              bsStyle="primary"
              onClick={onFailure}>Mock Failure</Button>
          </div>

        </DialogPanel>

      </FullScreenModal>

    );

  }

}
