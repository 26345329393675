import React, {ReactNode, useEffect} from 'react';
import {Navbar} from 'react-bootstrap';
import Context from '../Context';
import Events from '../Events';
import Footer from './Footer';
import PrimaryNav from './PrimaryNav';
import {RouteProps} from "../Page";
import AccessDeniedPanel from "../components/AccessDeniedPanel";

type MainPageLayoutProps = {
  params: { [key: string]: any };
  route: RouteProps;
  children: ReactNode;
}

const MainPageLayout = (props: MainPageLayoutProps) => {

  useEffect(() => {
    Events.system.sectionTitle.publish('');
  }, []);

  // let getLinkClassName = (value: string, excludes?: string[]) => {
  //   let path = window.location.pathname;
  //   if (path.indexOf(value) !== -1) {
  //     if (excludes) {
  //       for (let exclude of excludes) {
  //         if (path.indexOf(exclude) !== -1) {
  //           return undefined;
  //         }
  //       }
  //     }
  //     return 'active';
  //   }
  //   return undefined
  // }

  let isAllowed = true;
  let full = props.route.full;
  let hideSecondaryNav = props.route.hideSecondaryNav;

  return (

    <div>

      <PrimaryNav/>

      {
        Context.isAuthenticated() && !hideSecondaryNav &&
        <Navbar id="secondary-nav">
          <div className="row">
            <div className="col-md-12">
              <ul className="nav navbar-nav">
              </ul>
            </div>
          </div>
        </Navbar>
      }

      <div id="main-content" className={!hideSecondaryNav ? 'has-secondary-nav' : undefined}>
        {
          full &&
          <div className="container-full">
            {isAllowed && props.children}
            {!isAllowed && <AccessDeniedPanel/>}
          </div>
        }
        {
          !full &&
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {isAllowed && props.children}
                {!isAllowed && <AccessDeniedPanel/>}
              </div>
            </div>
          </div>
        }
      </div>

      <Footer/>

    </div>

  );

};

export default MainPageLayout;