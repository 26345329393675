import React from 'react';
import {ApiResponse, Button, FileWithUpload, Form, FullScreenModal, GlobalMessage, InputText, InputSelect} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import ApiIndex from "../models/ApiIndex";
import LegalDocument, {LEGAL_DOCUMENT_STANDARD_FIELDS} from "../models/LegalDocument";
import Binary from "../models/Binary";

export type LegalDocumentDialogProps = DialogProps & {
  index: ApiIndex;
  legalDocument: LegalDocument;
  onSave: (response: ApiResponse<LegalDocument>) => void;
  onDelete?: () => void;
  onCancel: () => void;
};

export type LegalDocumentDialogState = DialogState & {
  legalDocument: LegalDocument;
};

let FIELDS = LEGAL_DOCUMENT_STANDARD_FIELDS;

export default class LegalDocumentDialog extends Dialog<LegalDocumentDialogProps, LegalDocumentDialogState> {

  constructor(props: LegalDocumentDialogProps) {
    super(props);
    let legalDocument = this.props.legalDocument;
    this.setInitialState({
      legalDocument: legalDocument
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let legalDocument = this.state.legalDocument;
      if (legalDocument.key) {
        legalDocument.save({
          next: this.onSave,
          error: this.onApiError
        }, FIELDS);
      }
      else {
        let index = this.props.index;
        index.createLegalDocument(legalDocument, {
          next: this.onSave,
          error: this.onApiError
        }, FIELDS);
      }
    }
  }

  isModifyAllowed() {
    let legalDocument = this.state.legalDocument;
    return !legalDocument.key || legalDocument.isModifyAllowed();
  }

  onSave(response: ApiResponse<LegalDocument>) {
    this.enable();
    this.props.onSave(response);
  }

  isDeleteAllowed() {
    let legalDocument = this.state.legalDocument;
    return this.props.onDelete && legalDocument.isDeleteAllowed();
  }

  onDelete() {
    let legalDocument = this.state.legalDocument;
    legalDocument.delete({
      next: this.props.onDelete!,
      error: this.onApiError
    });
  }

  onCancel() {
    this.enable();
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let legalDocument = this.state.legalDocument;

    return (

      <div>

        <FullScreenModal title={!legalDocument.key ? 'New Legal Document' : 'Edit Legal Document'} onHide={this.onCancel}>

          <p className="lead">Please enter the details of the legal document below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled() || !this.isModifyAllowed()}>

            <Panel>

              <InputText
                id="legalDocument.title"
                label="Title"
                value={legalDocument.title}
                error={this.state.errors?.['request.title']}
                onChange={this.onFieldChange}
                className="lg"
                required/>

              <InputSelect
                id="legalDocument.type"
                label="Type"
                value={legalDocument.type}
                error={this.state.errors?.['request.type']}
                onChange={this.onFieldChange}
                options={index.legalDocumentTypes}/>

              <FileWithUpload
                id="legalDocument.files"
                label="File"
                files={legalDocument.files || []}
                multiple={false}
                upload
                onChange={(e: any) => {
                  let files: Array<Binary> = e.target.value;
                  e.target.value = files;
                  this.onFieldChange(e);
                }}/>

            </Panel>

            <div className="actions">
              {
                this.isModifyAllowed() &&
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  type="submit">Save Changes</Button>
              }
              <Button
                onClick={this.onCancel}
                disabled={false}
                bsSize="large">{this.isModifyAllowed() ? 'Cancel' : 'Close'}</Button>
              {
                this.isDeleteAllowed() &&
                <Button
                  onClick={this.onDelete}
                  bsSize="large"
                  bsStyle="default"
                  className="pull-right">Delete</Button>
              }
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
