import StatisticItem from "./StatisticItem";

export default class Statistic {

  items?: Array<StatisticItem<string, number>>;

  // ................................................................................................................................................

  getMaxValue(maxThresholds: Array<number>) {

    let items = this.items;
    let max = 0;

    if (!items) {
      return 0;
    }

    // obtain the max from the actual data
    for (const item of items) {
      if (item.value && item.value > max) {
        max = item.value;
      }
    }

    // round up to the next threshold
    for (const maxThreshold of maxThresholds) {
      if (max <= maxThreshold) {
        return maxThreshold;
      }
    }

    return max;

  }

  getStepSize(maxThresholds: Array<number>) {
    return this.getMaxValue(maxThresholds) / 5;
  }

  isSinglePointOnly() {

    let items = this.items;

    if (items) {
      return items.length === 1;
    }

    return false;

  }

}
