import React from 'react';
import FontAwesome from 'react-fontawesome';
import {ApiResponse, ApiSearchResponse, Button, Confirmable, Form, InputSelect, InputText, SearchResult, StatusButton, Table, TableHeaderCell} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Routes from '../Routes';
import Partner from "../models/Partner";
import ApiIndex from "../models/ApiIndex";
import PartnerSearchRequest from "../models/PartnerSearchRequest";
import Address from "../models/Address";
import PartnerDialog from "./PartnerDialog";
import Content from "../models/Content";

let FIELDS = '';

export type PartnersPageProps = PageProps & {};

export type PartnersPageState = PageState & {
  partnerSearchRequest: PartnerSearchRequest;
  partners?: SearchResult<Partner> | null;
};

export default class PartnersPage extends Page<PartnersPageProps, PartnersPageState> {

  constructor(props: PartnersPageProps) {
    super(props);
    this.setInitialState({
      partnerSearchRequest: new PartnerSearchRequest(),
      partners: null,
    });
  }

  onEnter() {
    this.setState({
      partnerSearchRequest: {
        name: this.query('name'),
        type: this.query('type'),
        status: this.query('status'),
        sort: this.query('sort'),
        page: parseInt(this.query('page') || '0'),
      }
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('name', this.state.partnerSearchRequest.name)
      .param('type', this.state.partnerSearchRequest.type)
      .param('status', this.state.partnerSearchRequest.status)
      .param('sort', this.state.partnerSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Partners');
    this.state.index?.searchPartners(this.state.partnerSearchRequest, {
      next: this.setPartners,
      error: this.onApiError
    }, null);
  }

  onSave(response: ApiSearchResponse<Partner>) {
    let partner = response.resource;
    this.navigate(Routes.partner.index(partner.key));
  }

  onView(resource: Partner) {
    this.navigate(Routes.partner.index(resource.key!));
  }

  setPartners(response: ApiSearchResponse<Partner>) {
    this.setState({
      partners: response.resource
    })
  }

  renderWithIndex(index: ApiIndex) {

    return (

      <div className="row">

        <div className="col-md-12">

          <PageHeader
            title="Partners"
            lead="This area allows you to manage the partners registered on the
            system. From here you may add a new partner, edit the details of an existing
            partner or disable their account."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="partnerSearchRequest.name"
              placeholder="Name"
              value={this.state.partnerSearchRequest.name}
              onChange={this.onFieldChange}
              className="wide"/>
            <InputSelect
              id="partnerSearchRequest.type"
              blank="Type"
              value={this.state.partnerSearchRequest.type}
              onChange={this.onFieldChange}
              options={index.partnerTypes}
              className="wide"/>
            <InputSelect
              id="partnerSearchRequest.status"
              blank="Status"
              value={this.state.partnerSearchRequest.status}
              onChange={this.onFieldChange}
              options={index.partnerStatuses}/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              <Confirmable
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="plus"
                      bsStyle="default"
                      className="pull-right"
                      onClick={onShow}>Add</Button>
                  );
                }}
                renderConfirm={(onOk, onCancel) => {
                  let newPartner = new Partner();
                  newPartner.profile = new Content();
                  newPartner.address = new Address();
                  return (
                    <PartnerDialog
                      partner={newPartner}
                      fields={FIELDS}
                      onSave={(response: ApiResponse<Partner>) => {
                        this.onSave(response);
                        onOk();
                      }}
                      onCancel={onCancel}/>
                  );
                }}/>
            </span>
          </Form>

          <br/>

          <Table
            items={this.state.partners}
            sort={this.state.partnerSearchRequest.sort}
            onReload={this.onEnter}
            blankMessage="No partners found"
            renderHeaderRow={() => {
              return (
                <tr>
                  <TableHeaderCell label="Name" sort="name"/>
                  <TableHeaderCell label="Type" sort="type" className="col-md-2"/>
                  <TableHeaderCell label="Status" sort="status" className="col-md-2 text-right"/>
                  <TableHeaderCell label="&nbsp;" className="col-chevron"/>
                </tr>
              );
            }}
            renderItemRow={(partner: Partner, i: number) => {
              return (
                <tr key={i} className="clickable" onClick={this.fn(this.onView, partner)}>
                  <td>{partner.name}</td>
                  <td>{partner.type?.name}</td>
                  <td className="text-right">
                    <StatusButton
                      id={'status-' + i}
                      value={partner.status}/>
                  </td>
                  <td className="text-right">
                    <FontAwesome name="chevron-right"/>
                  </td>
                </tr>
              );
            }}
          />

        </div>

      </div>

    );

  }

}
