import {SearchResult, StatusButton, Str, Table, TableHeaderCell} from "startupbox-react";
import React from "react";
import PushNotification from "../models/PushNotification";

type PushNotificationTableProps = {
  pushNotifications: SearchResult<PushNotification> | PushNotification[];
};

const PushNotificationTable = (props: PushNotificationTableProps) => {

  let pushNotifications = props.pushNotifications;

  return (

    <Table
      items={pushNotifications}
      // onPaginate={this.search}
      blankMessage="No push notifications found"
      renderHeaderRow={() => {
        return (
          <tr>
            <TableHeaderCell label="Title & Body"/>
            <TableHeaderCell label="Date Sent" className="col-md-2"/>
            <TableHeaderCell label="Date Read" className="col-md-2"/>
            <TableHeaderCell label="Status" sort="status" className="col-md-2 text-right"/>
          </tr>
        );
      }}
      renderItemRow={(pushNotification: PushNotification, p: number) => {
        return (
          <tr key={p}>
            <td>
              <div>{pushNotification.title}</div>
              <div className="text-muted">{pushNotification.body}</div>
            </td>
            <td>
              <div>{Str.formatDate(pushNotification.sentAt, "DD/MM/YYYY")}</div>
              <div className="text-muted">{Str.formatDate(pushNotification.sentAt, "hh:mm a")}</div>
              {!pushNotification.sentAt && <div className="text-muted">Pending</div>}
            </td>
            <td>
              <div>{Str.formatDate(pushNotification.readAt, "DD/MM/YYYY")}</div>
              <div className="text-muted">{Str.formatDate(pushNotification.readAt, "hh:mm a")}</div>
              {!pushNotification.readAt && <div className="text-muted">Unread</div>}
            </td>
            <td className="text-right">
              <StatusButton
                id={'status-' + p}
                value={pushNotification.status}/>
            </td>
          </tr>
        );
      }}
    />

  );

};

export default PushNotificationTable;