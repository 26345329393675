export default class CollectibleSearchRequest {

  keywords?: string;
  key?: string;
  name?: string;
  templateKey?: string;
  mintStatus?: string;
  btx?: boolean;

  sort?: string;
  page = 0;
  size?: string | number;

}
