import React from 'react';
import {ApiResponse} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import User from "../models/User";
import ApiIndex from "../models/ApiIndex";
import DeviceTabs from "../device/DeviceTabs";
import Routes from "../Routes";
import {ApiErrorResponse} from "../models/ApiError";
import Device from "../models/Device";
import DevicePanel from "../device/DevicePanel";

export type UserDeviceAbstractPageProps = PageProps & {};

export type UserDeviceAbstractPageState = PageState & {
  user?: User;
  device?: Device;
};

export default class UserDeviceAbstractPage<P extends UserDeviceAbstractPageProps, S extends UserDeviceAbstractPageState> extends Page<P, S> {

  activeTab: string | null = null;

  constructor(props: P) {
    super(props);
    let initialState = {} as S;
    this.setInitialState(initialState);
    this.reload = this.reload.bind(this);
    this.onNavigateToUser = this.onNavigateToUser.bind(this);
    this.getUserKey = this.getUserKey.bind(this);
    this.setUser = this.setUser.bind(this);
    this.setDevice = this.setDevice.bind(this);
    this.onSelectTab = this.onSelectTab.bind(this);
    this.getDeviceFields = this.getDeviceFields.bind(this);
  }

  onLoad() {
    this.loadUser();
  }

  reload() {
    this.loadUser();
  }

  loadUser() {
    this.state.index?.findUserByKey(this.getUserKey(), {
      next: this.setUser,
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, 'avatar');
  }

  loadDevice() {
    this.state.index?.findDeviceByKey(this.getDeviceKey(), {
      next: this.setDevice,
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, this.getDeviceFields());
  }

  // noinspection JSUnusedLocalSymbols
  onLoadUserAndDevice(user: User, device: Device) {
    // override this in sub-classes if required
  }

  setUser(response: ApiResponse<User>) {
    let user = response.resource!;
    this.publish(Events.system.pageTitle, user.name);
    this.setState({
      mode: '',
      user: user
    }, () => {
      this.loadDevice();
    });
  }

  setDevice(response: ApiResponse<Device>) {
    let device = response.resource!;
    this.setState({
      mode: '',
      device: device
    }, () => {
      this.onLoadUserAndDevice(this.state.user!, device);
    });
  }

  onNavigateToUser() {
    this.navigate(Routes.admin.user(this.getUserKey()));
  }

  getUserKey() {
    return this.props.params.key;
  }

  getDeviceKey() {
    return this.props.params.deviceKey;
  }

  onSelectTab(tab: string) {
    this.navigate(Routes.admin.user_device(this.getUserKey(), this.getDeviceKey(), tab));
  }

  getDeviceFields() {
    return '';
  }

  renderWithIndex(index: ApiIndex) {

    let user = this.state.user;
    let device = this.state.device;

    if (!user || !device) {
      return <></>;
    }

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title={user.name}
            subTitle="Device"
            avatar={user.avatar || null}
            lead={
              <>
                You are viewing the device called <strong>{device.getBrandAndModelName()}</strong> for user <strong>{user.name}</strong> on the
                system. <a href="/" className="back" onClick={this.fn(this.onNavigateToUser)}>Back to user profile.</a>
              </>
            }/>

          <DevicePanel
            device={device!}
            onView={null}/>

          <DeviceTabs
            device={device!}
            active={this.activeTab!}
            onSelect={this.onSelectTab}/>

          {this.renderWithIndexUserAndDevice(index, user!, device!)}

        </div>

      </div>

    );

  }

  renderWithIndexUserAndDevice(index: ApiIndex, user: User, device: Device) {
    return <></>;
  }

};
