import React from 'react';
import {ApiResponse, Button, Form, FullScreenModal, GlobalMessage, ImageWithUpload, InputDatePicker, InputInlineEditor, InputText, VideoWithUpload} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import ApiIndex from "../models/ApiIndex";
import Membership, {MEMBERSHIP_STANDARD_FIELDS} from "../models/Membership";
import {acceptedFileMimeTypes, acceptedImageMimeTypes} from "../utils/acceptedFileMimeTypes";
import Binary from "../models/Binary";
import SectionHeader from "../components/SectionHeader";
import Horse from "../models/Horse";
import Partner from "../models/Partner";

export type MembershipDialogProps = DialogProps & {
  index: ApiIndex;
  membership: Membership;
  horse?: Horse;
  partner?: Partner;
  onSave: (response: ApiResponse<Membership>) => void;
  onDelete?: () => void;
  onCancel: () => void;
};

export type MembershipDialogState = DialogState & {
  membership: Membership;
};

export default class MembershipDialog extends Dialog<MembershipDialogProps, MembershipDialogState> {

  constructor(props: MembershipDialogProps) {
    super(props);
    let membership = this.props.membership;
    this.setInitialState({
      membership: membership
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let membership = this.state.membership;
      if (membership.key) {
        membership.save({
          next: this.onSave,
          error: this.onApiError
        }, MEMBERSHIP_STANDARD_FIELDS);
      }
      else if (this.props.horse) {
        let horse = this.props.horse;
        horse.addMembership(membership, {
          next: this.onSave,
          error: this.onApiError
        }, MEMBERSHIP_STANDARD_FIELDS);
      }
      else if (this.props.partner) {
        let partner = this.props.partner;
        partner.addMembership(membership, {
          next: this.onSave,
          error: this.onApiError
        }, MEMBERSHIP_STANDARD_FIELDS);
      }
      else {
        let index = this.props.index;
        index.createMembership(membership, {
          next: this.onSave,
          error: this.onApiError
        }, MEMBERSHIP_STANDARD_FIELDS);
      }
    }
  }

  isModifyAllowed() {
    let membership = this.state.membership;
    return !membership.key || membership.isModifyAllowed();
  }

  onSave(response: ApiResponse<Membership>) {
    this.enable();
    this.props.onSave(response);
  }

  isDeleteAllowed() {
    let membership = this.state.membership;
    return this.props.onDelete && membership.isDeleteAllowed();
  }

  onDelete() {
    let membership = this.state.membership;
    membership.delete({
      next: this.props.onDelete!,
      error: this.onApiError
    });
  }

  onCancel() {
    this.enable();
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let membership = this.state.membership;

    return (

      <div>

        <FullScreenModal title={!membership.key ? 'New Membership' : 'Edit Membership'} onHide={this.onCancel}>

          <p className="lead">Please enter the details of the membership below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled() || !this.isModifyAllowed()}>

            <Panel>

              <InputText
                id="membership.name"
                label="Membership Name"
                value={membership.name}
                error={this.state.errors?.['request.name']}
                onChange={this.onFieldChange}
                className="lg"
                required/>

              <InputText
                id="membership.badgeLabel"
                label="Badge Label"
                help="An optional promotional text for marketing purposes. eg Selling Fast, Exclusive to BTX etc."
                value={membership.badgeLabel}
                error={this.state.errors?.['request.badgeLabel']}
                onChange={this.onFieldChange}/>

              <div className="row">
                <div className="col-md-6">
                  <InputDatePicker
                    id="membership.startAt"
                    label="Start Date"
                    value={membership.startAt}
                    error={this.state.errors?.['request.startAt']}
                    dateFormat="DD/MM/YYYY"
                    onChange={this.onFieldChange}
                    required/>
                </div>
                <div className="col-md-6">
                  <InputDatePicker
                    id="membership.endAt"
                    label="End Date"
                    value={membership.endAt}
                    error={this.state.errors?.['request.endAt']}
                    dateFormat="DD/MM/YYYY"
                    onChange={this.onFieldChange}/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="membership.unitCount"
                    label="Number of Units"
                    value={membership.unitCount}
                    format="number"
                    error={this.state.errors?.['request.unitCount']}
                    onChange={this.onFieldChange}
                    className="lg"/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="membership.unitPrice"
                    label="Unit Price"
                    value={membership.unitPrice}
                    format="currency"
                    error={this.state.errors?.['request.unitPrice']}
                    onChange={this.onFieldChange}
                    className="lg"
                    required/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="membership.limitPerUser"
                    label="Limit per User"
                    help="Leave empty for no restriction."
                    value={membership.limitPerUser}
                    format="number"
                    error={this.state.errors?.['request.limitPerUser']}
                    onChange={this.onFieldChange}
                    className="lg"/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="membership.appCreditAmount"
                    label="App Credit"
                    help="Will be credited to the user's account."
                    value={membership.appCreditAmount}
                    format="currency"
                    error={this.state.errors?.['request.appCreditAmount']}
                    onChange={this.onFieldChange}
                    className="lg"/>
                </div>
              </div>

              <SectionHeader title="Media" divider/>

              <ImageWithUpload
                id="membership.coverImage"
                label="Cover Image"
                files={membership.coverImage ? [membership.coverImage] : []}
                className="images-across-1"
                accept={acceptedImageMimeTypes}
                lightbox
                upload
                onChange={(e) => {
                  let images: Array<any> = e.target.value;
                  let coverImage = (images && images.length > 0) ? images[0] : undefined;
                  if (!coverImage || coverImage.externalReference) {
                    e.target.value = coverImage;
                    this.onFieldChange(e);
                  }
                }}/>

              <VideoWithUpload
                id="membership.promotionalVideos"
                label="Promotional Videos"
                files={membership.promotionalVideos || []}
                className="videos-across-3"
                accept="video/mp4"
                multiple
                upload
                onChange={(e: any) => {
                  let videos: Array<Binary> = e.target.value;
                  e.target.value = videos;
                  this.onFieldChange(e);
                }}/>

              <ImageWithUpload
                id="membership.promotionalImages"
                label="Promotional Images"
                files={membership.promotionalImages || []}
                className="images-across-3"
                accept={acceptedImageMimeTypes}
                lightbox
                multiple
                upload
                onChange={(e: any) => {
                  let images: Array<Binary> = e.target.value;
                  e.target.value = images;
                  this.onFieldChange(e);
                }}/>

              <SectionHeader title="Terms" divider/>

              <InputInlineEditor
                tools="traditional"
                id="membership.terms.body"
                elementId="dialog-terms"
                label="Terms"
                help="A freeform rich content of the terms of this membership comprising of text, images and file attachments."
                value={membership.terms?.body}
                error={this.state.errors?.['request.terms.body']}
                uploadFileAcceptedMimeTypes={acceptedFileMimeTypes}
                onChange={this.onFieldChange}/>

            </Panel>

            <div className="actions">
              {
                this.isModifyAllowed() &&
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  type="submit">Save Changes</Button>
              }
              <Button
                onClick={this.onCancel}
                disabled={false}
                bsSize="large">{this.isModifyAllowed() ? 'Cancel' : 'Close'}</Button>
              {
                this.isDeleteAllowed() &&
                <Button
                  onClick={this.onDelete}
                  bsSize="large"
                  bsStyle="default"
                  className="pull-right">Delete</Button>
              }
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
