type Meta = {
  accessDescription?: string;
  horseAttributeDescription?: string;
  horsePostDescription?: string;
  membershipPostDescription?: string;
  value?: number;
  premium: false;
};

export default class VisibilityLevel {

  key?: string | null;
  name?: string | null;
  meta: Meta = {
    premium: false
  };

  constructor(key?: string) {
    this.key = key;
  }

  static getLevel(value: number | undefined, levels: Array<VisibilityLevel>) {
    if (levels) {
      for (let level of levels) {
        if (level.meta.value === value) {
          return level;
        }
      }
    }
    return null;
  }

  static getLabel(level: VisibilityLevel | null | undefined, labelField?: string | null | undefined) {
    if (level) {
      if (!labelField) {
        return level.name;
      }
      else {
        let labelFieldParts = labelField.split('.');
        let value: any = level;
        for (const labelFieldPart of labelFieldParts) {
          value = value[labelFieldPart];
        }
        return value;
      }
    }
  }

}