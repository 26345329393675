import React from 'react';
import FontAwesome from 'react-fontawesome';
import {ApiSearchResponse, Button, Form, InputText, SearchResult, Table, TableHeaderCell} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Routes from '../Routes';
import Setting from "../models/Setting";
import ApiIndex from "../models/ApiIndex";
import SettingSearchRequest from "../models/SettingSearchRequest";

export type SettingsPageProps = PageProps & {};

export type SettingsPageState = PageState & {
  settingSearchRequest: SettingSearchRequest;
  settings?: SearchResult<Setting> | null;
  videos: Array<any>
};

export default class SettingsPage extends Page<SettingsPageProps, SettingsPageState> {

  constructor(props: SettingsPageProps) {
    super(props);
    this.setInitialState({
      settingSearchRequest: new SettingSearchRequest(),
      videos: []
    });
  }

  onEnter() {
    this.setState({
      settingSearchRequest: {
        name: this.query('name'),
        value: this.query('value'),
        sort: this.query('sort'),
        page: this.query('page'),
      },
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('name', this.state.settingSearchRequest.name)
      .param('value', this.state.settingSearchRequest.value)
      .param('sort', this.state.settingSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Settings');
    this.state.index?.searchSettings(this.state.settingSearchRequest, {
      next: this.setSettings,
      error: this.onApiError
    }, null);
  }

  onSave() {
    this.onLoad();
  }

  onView(resource: Setting) {
    this.navigate(Routes.tool.setting(resource.key));
  }

  setSettings(response: ApiSearchResponse<Setting>) {
    this.setState({
      settings: response.resource
    })
  }

  onAddVideo(acceptedFiles: Array<any>) {
    let videos = this.state.videos;
    for (let file of acceptedFiles) {
      videos.push(file);
    }
    this.setState({
      videos: videos
    });
  }

  onRemoveVideo(file: any, i: number) {
    let videos = this.state.videos;
    videos.splice(i, 1);
    this.setState({
      videos: videos
    });
  }

  onSortVideos(e: any) {
    this.setState({
      videos: e.target.value
    });
  }

  renderWithIndex(index: ApiIndex) {

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title="Settings"
            lead="This area allows you to manage the system settings. Be careful what you
            do here as changes to these system settings will affect how the system behaves."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="settingSearchRequest.name"
              placeholder="Name"
              value={this.state.settingSearchRequest.name}
              onChange={this.onFieldChange}
              className="wide"/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              <Button
                icon="plus"
                bsStyle="default"
                className="pull-right"
                onClick={this.fn(this.onView, {key: 'add'})}>Add</Button>
            </span>
          </Form>

          <br/>

          <Table
            items={this.state.settings}
            sort={this.state.settingSearchRequest.sort}
            onReload={this.onEnter}
            blankMessage="No settings found"
            renderHeaderRow={() => {
              return (
                <tr>
                  <TableHeaderCell label="Name" sort="name"/>
                  <TableHeaderCell label="Value" sort="value" className="col-md-5"/>
                  <TableHeaderCell label="&nbsp;" className="col-chevron"/>
                </tr>
              );
            }}
            renderItemRow={(setting: Setting, i: number) => {
              return (
                <tr key={i} className="clickable" onClick={this.fn(this.onView, setting)}>
                  <td>{setting.name}</td>
                  <td>{setting.value}</td>
                  <td className="text-right">
                    <FontAwesome name="chevron-right"/>
                  </td>
                </tr>
              );
            }}
          />

        </div>

      </div>

    );

  }

}
