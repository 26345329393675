import Model from '../Model';
import {ApiSubscribableCallbacks, SearchResult} from "startupbox-react";
import UserStatus from "./UserStatus";
import UserType from "./UserType";
import Binary from "./Binary";
import ApiError from "./ApiError";
import WalletSearchRequest from "./WalletSearchRequest";
import Wallet from "./Wallet";
import PartnerCollaborator from "./PartnerCollaborator";
import PartnerCollaboratorSearchRequest from "./PartnerCollaboratorSearchRequest";
import Address from "./Address";
import PaymentMethods from "./PaymentMethods";
import BankDetails from "./BankDetails";
import DeviceSearchRequest from "./DeviceSearchRequest";
import Device from "./Device";
import PushNotificationSearchRequest from "./PushNotificationSearchRequest";
import PushNotification from "./PushNotification";
import Statistic from "./Statistic";
import UserStatisticSearchRequest from "./UserStatisticSearchRequest";
import SubscriptionStatisticSearchRequest from "./SubscriptionStatisticSearchRequest";
import DeviceStatisticSearchRequest from "./DeviceStatisticSearchRequest";

let Rel = {
  self: 'self',
  modify: 'modify',
  modifyStatus: 'modifyStatus',
  bankDetails_find: 'bankDetails_find',
  bankDetails_modify: 'modifyBankDetails',
  collaborator_search: 'collaborator_search',
  device_search: 'device_search',
  pushNotification_search: 'pushNotification_search',
  wallet_search: 'wallet_search',
  defaultWallet_create: 'defaultWallet_create',
  deviceStatistic_find: 'deviceStatistic_find',
  subscriptionStatistic_find: 'subscriptionStatistic_find',
  userStatistic_find: 'userStatistic_find',
};

export default class User {

  key?: string;
  myFrankieOneAccessKey?: string;

  onboardingAccessKey?: string;

  firstName?: string;
  middleName?: string;
  lastName?: string;
  name?: string;
  username?: string;
  emailAddress?: string;
  password?: string;
  phoneNumber?: string;

  avatar?: Binary;
  address?: Address;
  bankDetails?: BankDetails;
  dob?: Date;

  admin: boolean = false;
  sysadmin: boolean = false;
  identityVerified: boolean = false;
  ageVerified: boolean = false;
  australianResidencyDeclared: boolean = false;
  noCriminalRecordDeclared: boolean = false;
  compliant: boolean = false;

  applePremiumContentSingleAllowed?: boolean = false;
  applePremiumContentRecurringAllowed?: boolean = false;
  googlePremiumContentSingleAllowed?: boolean = false;
  googlePremiumContentRecurringAllowed?: boolean = false;

  type?: UserType;
  status?: UserStatus;

  campaign?: string;
  referredBy?: string;

  frankieOneEntityId?: string;
  frankieOneEntityActionRecommended?: string;

  hubSpotId?: string;

  wallets?: Array<Wallet>;
  paymentMethods?: PaymentMethods;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  isActive() {
    return this.status?.key === 'ACTIVE';
  }

  // ................................................................................................................................................

  save(callbacks: ApiSubscribableCallbacks<User, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }

  isModifyStatusAllowed() {
    return !!this.href(Rel.modifyStatus);
  }

  modifyStatus(status: UserStatus | null, callbacks: ApiSubscribableCallbacks<User, ApiError>, fields: string | null) {
    this.href(Rel.modifyStatus, true)
      .field(fields)
      .patch({status: status})
      .subscribe(callbacks);
  }

  // bank details ..................................................................................................................................

  findBankDetails(callbacks: ApiSubscribableCallbacks<BankDetails, ApiError>, fields: string | null) {
    this.href(Rel.bankDetails_find, true)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  modifyBankDetails(bankDetails: BankDetails, callbacks: ApiSubscribableCallbacks<BankDetails, ApiError>, fields: string | null) {
    this.href(Rel.bankDetails_modify, true)
      .field(fields)
      .patch(bankDetails)
      .subscribe(callbacks);
  }

  // collaborators ..................................................................................................................................

  searchCollaborators(params: PartnerCollaboratorSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<PartnerCollaborator>, ApiError>, fields: string | null) {
    this.href(Rel.collaborator_search, true)
      .param('sort', params.sort || 'partner.name,asc')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // devices ........................................................................................................................................

  searchDevices(params: DeviceSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Device>, ApiError>, fields: string | null) {
    this.href(Rel.device_search, true)
      .param('key', params.key)
      .param('physicalIdentifier', params.physicalIdentifier)
      .param('sessionIdentifier', params.sessionIdentifier)
      .param('platformIdentifier', params.platformIdentifier)
      .param('clientVersion', params.clientVersion)
      .param('platformType', params.platformType)
      .param('brand', params.brand)
      .param('modelName', params.modelName)
      .param('osName', params.osName)
      .param('osVersion', params.osVersion)
      .param('userAgent', params.userAgent)
      .param('active', params.active)
      .param('sort', params.sort || 'id,asc')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // push notification ..............................................................................................................................

  searchPushNotifications(params: PushNotificationSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<PushNotification>, ApiError>, fields: string | null) {
    this.href(Rel.pushNotification_search, true)
      .param('key', params.key)
      .param('title', params.title)
      .param('body', params.body)
      .param('status', params.status)
      .param('sort', params.sort || 'id,desc')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // statistic ......................................................................................................................................

  findDeviceStatistic(params: DeviceStatisticSearchRequest, callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) {
    this.href(Rel.deviceStatistic_find, true)
      .param('statisticType', params.statisticType)
      .param('fromYYYYMM', params.fromYYYYMM)
      .param('platformType', params.platformType)
      .param('active', params.active)
      .field('items')
      .get()
      .subscribe(callbacks);
  }

  findSubscriptionStatistic(params: SubscriptionStatisticSearchRequest, callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) {
    this.href(Rel.subscriptionStatistic_find, true)
      .param('statisticType', params.statisticType)
      .param('fromYYYYMM', params.fromYYYYMM)
      .param('status', params.status)
      .field('items')
      .get()
      .subscribe(callbacks);
  }

  findUserStatistic(params: UserStatisticSearchRequest, callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) {
    this.href(Rel.userStatistic_find, true)
      .param('statisticType', params.statisticType)
      .param('fromYYYYMM', params.fromYYYYMM)
      .param('status', params.status)
      .field('items')
      .get()
      .subscribe(callbacks);
  }

  // wallet .........................................................................................................................................

  searchWallets(params: WalletSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Wallet>, ApiError>, fields: string | null) {
    this.href(Rel.wallet_search, true)
      .param('name', params.name)
      .param('sort', params.sort || 'user.name,asc')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  isCreateDefaultWalletAllowed() {
    return !!this.href(Rel.defaultWallet_create);
  }

  createDefaultWallet(callbacks: ApiSubscribableCallbacks<User, ApiError>, fields: string | null) {
    this.href(Rel.defaultWallet_create, true)
      .field(fields)
      .post({})
      .subscribe(callbacks);
  }

}
