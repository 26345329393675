import PaymentMethodCreditCard from "../models/PaymentMethodCreditCard";
import React from "react";
import PaymentMethod from "../models/PaymentMethod";
import Panel from "../components/Panel";

export const PaymentMethodPanel = (props: { paymentMethod: PaymentMethod }) => {

  let paymentMethod = props.paymentMethod;
  let type = paymentMethod.type;

  if (type?.key === 'CREDIT_CARD') {
    return (
      <Panel>
        {
          type?.key === 'CREDIT_CARD' &&
          <PaymentMethodCreditCardDetails
            paymentMethod={paymentMethod as PaymentMethodCreditCard}/>
        }
      </Panel>
    );
  }

  return <></>;

}

const PaymentMethodCreditCardDetails = (props: { paymentMethod: PaymentMethodCreditCard }) => {

  let paymentMethod = props.paymentMethod;

  return (

    <>
      <h4 className="marginBottom5">{paymentMethod.brand?.name} ending in {paymentMethod.number}</h4>
      <div className="text-muted">{paymentMethod.name}</div>
      <div className="text-muted">Key: {paymentMethod.key}</div>
      <div className="text-muted">External Reference Standard Account: {paymentMethod.externalReference}</div>
      <div className="text-muted">External Reference Trust Account: {paymentMethod.externalReferenceTrust}</div>
      <div className="text-muted">{paymentMethod.status?.name}</div>
    </>

  );

}