import React from 'react';
import PageHeader from '../components/PageHeader';
import Panel from '../components/Panel';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import {ApiResponse, Button, Form, GlobalMessage, InputText, InputTextArea, Str} from 'startupbox-react';
import ApiIndex from "../models/ApiIndex";
import {ApiErrorResponse} from "../models/ApiError";
import HubSpotAsyncMessage from "../models/HubSpotAsyncMessage";

export type HubSpotAsyncMessagePageProps = PageProps & {};

export type HubSpotAsyncMessagePageState = PageState & {
  hubSpotAsyncMessage: HubSpotAsyncMessage;
};

// noinspection UnnecessaryLocalVariableJS
export default class HubSpotAsyncMessagePage extends Page<HubSpotAsyncMessagePageProps, HubSpotAsyncMessagePageState> {

  constructor(props: HubSpotAsyncMessagePageProps) {
    super(props);
    this.setInitialState({
      hubSpotAsyncMessage: new HubSpotAsyncMessage()
    });
  }

  onLoad() {
    let key = this.props.params.key;
    this.state.index?.findHubSpotAsyncMessageByKey(key, {
      next: this.setHubSpotAsyncMessage,
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, null);
  }

  onClose() {
    this.back();
  }

  setHubSpotAsyncMessage(response: ApiResponse<HubSpotAsyncMessage>) {
    let message = response.resource!;
    this.publish(Events.system.pageTitle, message.description);
    this.setState({
      mode: '',
      hubSpotAsyncMessage: message
    });
  }

  renderWithIndex(index: ApiIndex) {

    let hubSpotAsyncMessage = this.state.hubSpotAsyncMessage;

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title="View HubSpot Async Message"
            lead="Please review the details of this message below."/>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onClose)} disabled={this.isDisabled()}>

            <Panel>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="hubSpotAsyncMessage.createdAt"
                    label="Created At"
                    value={Str.formatDateTime(hubSpotAsyncMessage.createdAt)}
                    onChange={this.onFieldChange}
                    disabled/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="hubSpotAsyncMessage.processedAt"
                    label="Processed At"
                    value={Str.formatDateTime(hubSpotAsyncMessage.processedAt)}
                    onChange={this.onFieldChange}
                    disabled/>
                </div>
              </div>

              <InputText
                id="hubSpotAsyncMessage.description"
                label="Description"
                value={hubSpotAsyncMessage.description}
                onChange={this.onFieldChange}
                disabled/>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="hubSpotAsyncMessage.type"
                    label="Type"
                    value={hubSpotAsyncMessage.type?.name}
                    onChange={this.onFieldChange}
                    disabled/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="hubSpotAsyncMessage.entityKey"
                    label="Entity Key"
                    value={hubSpotAsyncMessage.entityKey}
                    onChange={this.onFieldChange}
                    disabled/>
                </div>
              </div>

              <InputText
                id="hubSpotAsyncMessage.status"
                label="Status"
                value={hubSpotAsyncMessage.status?.name}
                onChange={this.onFieldChange}
                disabled/>

              <InputTextArea
                id="hubSpotAsyncMessage.body"
                label="Body"
                value={hubSpotAsyncMessage.body}
                onChange={this.onFieldChange}
                disabled/>

            </Panel>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Close</Button>
            </div>

          </Form>

        </div>

      </div>

    );

  }

};