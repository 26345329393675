import {fn, SearchResult, StatusButton, Str, Table, TableHeaderCell} from "startupbox-react";
import Collectible from "../models/Collectible";
import FontAwesome from "react-fontawesome";
import React from "react";

type CollectibleTableProps = {
  collectibles: SearchResult<Collectible> | null | undefined;
  sort?: string;
  onReload?: () => void;
  onPaginate?: (page: number) => void;
  onView: (collectible: Collectible) => void;
};

const CollectibleTable = (props: CollectibleTableProps) => {

  return (
    <Table
      items={props.collectibles}
      sort={props.sort}
      onReload={props.onReload}
      onPaginate={props.onPaginate}
      blankMessage="No collectibles found"
      renderHeaderRow={() => {
        return (
          <tr>
            <TableHeaderCell label="Date" className="col-md-2"/>
            <TableHeaderCell label="Title"/>
            <TableHeaderCell label="Status" className="col-md-2 text-right"/>
            <TableHeaderCell label="&nbsp;" className="col-chevron"/>
          </tr>
        );
      }}
      renderItemRow={(collectible: Collectible, c: number) => {
        return (
          <tr key={c} className="clickable" onClick={fn(props.onView, collectible)}>
            <td>{Str.formatDate(collectible.startAt, "DD/MM/YYYY")}</td>
            <td>{collectible.name} <span className="text-muted">({collectible.getCountText()})</span></td>
            <td className="text-right">
              <StatusButton
                id={'status-' + c}
                value={collectible.mintStatus}/>
            </td>
            <td className="text-right">
              <FontAwesome name="chevron-right"/>
            </td>
          </tr>
        );
      }}
    />
  );

};

export default CollectibleTable;


