export default class TrainingRecordSearchRequest {

  keywords?: string;
  key?: string;

  sort?: string;
  page?: string | number;
  size?: string | number;

}
