import React from 'react';
import FontAwesome from 'react-fontawesome';
import {ApiResponse, ApiSearchResponse, Button, Confirmable, Form, InputSelect, InputText, SearchResult, StatusButton, Table, TableHeaderCell} from 'startupbox-react';
import {Avatar} from '../components/Image';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Routes from '../Routes';
import UserDialog from './UserDialog';
import UserSearchRequest from "../models/UserSearchRequest";
import User from "../models/User";
import ApiIndex from "../models/ApiIndex";
import Address from "../models/Address";
import BankDetails from "../models/BankDetails";
import ExportDialog from "../components/ExportDialog";

let FIELDS = 'avatar';

export type UsersPageProps = PageProps & {};

export type UsersPageState = PageState & {
  userSearchRequest: UserSearchRequest;
  users?: SearchResult<User> | null;
};

export default class UsersPage extends Page<UsersPageProps, UsersPageState> {

  constructor(props: UsersPageProps) {
    super(props);
    this.setInitialState({
      userSearchRequest: new UserSearchRequest()
    });
  }

  onEnter() {
    this.setState({
      userSearchRequest: {
        name: this.query('name'),
        emailAddress: this.query('emailAddress'),
        status: this.query('status'),
        sort: this.query('sort'),
        page: parseInt(this.query('page') || '0'),
      },
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('name', this.state.userSearchRequest.name)
      .param('emailAddress', this.state.userSearchRequest.emailAddress)
      .param('status', this.state.userSearchRequest.status)
      .param('sort', this.state.userSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Users');
    this.state.index?.searchUsers(this.state.userSearchRequest, {
      next: this.setUsers,
      error: this.onApiError
    }, FIELDS);
  }

  onSave(response: ApiSearchResponse<User>) {
    let user = response.resource;
    this.navigate(Routes.admin.user(user.key));
  }

  onView(user: User) {
    this.navigate(Routes.admin.user(user.key!));
  }

  onNavigateToImportRuns() {
    this.navigate(Routes.admin.userImportRuns());
  }

  setUsers(response: ApiSearchResponse<User>) {
    this.setState({
      users: response.resource
    })
  }

  renderWithIndex(index: ApiIndex) {

    let users = this.state.users;
    let userExportHref = index.getExportUserHref(this.state.userSearchRequest);

    return (

      <div className="row">

        <div className="col-md-12">

          <PageHeader
            title="Users"
            lead="This area allows you to manage the users registered on the
            system. From here you may add a new user, edit the details of an
            existing user or disable their account."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="userSearchRequest.name"
              placeholder="Name"
              value={this.state.userSearchRequest.name}
              onChange={this.onFieldChange}
              className="wide"/>
            <InputText
              id="userSearchRequest.emailAddress"
              placeholder="Email Address"
              value={this.state.userSearchRequest.emailAddress}
              onChange={this.onFieldChange}/>
            <InputSelect
              id="userSearchRequest.status"
              blank="Status"
              value={this.state.userSearchRequest.status}
              onChange={this.onFieldChange}
              options={index.userStatuses}/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              {
                userExportHref &&
                <Confirmable
                  renderTrigger={(onShow) => {
                    return (
                      <Button
                        icon="download"
                        bsStyle="default"
                        onClick={onShow}/>
                    );
                  }}
                  renderConfirm={(onOk, onCancel) => {
                    return (
                      <ExportDialog
                        title="Export Users"
                        count={users!.page!.totalElements}
                        href={userExportHref!}
                        onClose={onCancel}/>
                    );
                  }}/>
              }
              <Confirmable
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="plus"
                      bsStyle="default"
                      className="pull-right"
                      onClick={onShow}>Add</Button>
                  );
                }}
                renderConfirm={(onOk, onCancel) => {
                  let newUser = new User();
                  newUser.address = new Address();
                  newUser.bankDetails = new BankDetails();
                  return (
                    <UserDialog
                      user={newUser}
                      fields={FIELDS}
                      onSave={(response: ApiResponse<User>) => {
                        this.onSave(response);
                        onOk();
                      }}
                      onCancel={onCancel}/>
                  );
                }}/>
              <Button
                icon="plus"
                bsStyle="default"
                className="pull-right"
                onClick={this.onNavigateToImportRuns}>Bulk Add</Button>
            </span>
          </Form>

          <br/>

          <Table
            items={users}
            sort={this.state.userSearchRequest.sort}
            onReload={this.onEnter}
            blankMessage="No users found"
            renderHeaderRow={() => {
              return (
                <tr>
                  <TableHeaderCell label="Name" sort="name"/>
                  <TableHeaderCell label="Email Address" sort="emailAddress" className="col-md-4"/>
                  <TableHeaderCell label="Type" sort="type" className="col-md-2"/>
                  <TableHeaderCell label="Status" sort="status" className="col-md-2 text-right"/>
                  <TableHeaderCell label="&nbsp;" className="col-chevron"/>
                </tr>
              );
            }}
            renderItemRow={(user: User, i: number) => {
              return (
                <tr key={i} className="clickable" onClick={this.fn(this.onView, user)}>
                  <td>
                    <Avatar src={user.avatar} transforms="w_300"/>
                    <strong>{user.name}</strong>
                  </td>
                  <td>{user.emailAddress}</td>
                  <td>{user.type?.name}</td>
                  <td className="text-right">
                    <StatusButton
                      id={'status-' + i}
                      value={user.status}/>
                  </td>
                  <td className="text-right">
                    <FontAwesome name="chevron-right"/>
                  </td>
                </tr>
              );
            }}
          />

        </div>

      </div>

    );

  }

}
