import React from 'react';
import {ApiResponse, Button, FileWithUpload, Form, FullScreenModal, GlobalMessage, ImageWithUpload, InputDatePicker, InputInlineEditor, InputText, VideoWithUpload} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import ApiIndex from "../models/ApiIndex";
import Pack, {PACK_STANDARD_FIELDS} from "../models/Pack";
import {acceptedFileMimeTypes, acceptedImageMimeTypes} from "../utils/acceptedFileMimeTypes";
import Binary from "../models/Binary";
import Partner from "../models/Partner";
import SectionHeader from "../components/SectionHeader";

export type PackDialogProps = DialogProps & {
  index?: ApiIndex;
  partner?: Partner;
  pack: Pack;
  onSave: (response: ApiResponse<Pack>) => void;
  onDelete: () => void;
  onCancel: () => void;
};

export type PackDialogState = DialogState & {
  pack: Pack;
};

export default class PackDialog extends Dialog<PackDialogProps, PackDialogState> {

  constructor(props: PackDialogProps) {
    super(props);
    let pack = this.props.pack;
    this.setInitialState({
      pack: pack
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let pack = this.state.pack;
      if (pack.key) {
        pack.save({
          next: this.onSave,
          error: this.onApiError
        }, PACK_STANDARD_FIELDS);
      }
      else if (this.props.partner) {
        let partner = this.props.partner;
        partner.addPack(pack, {
          next: this.onSave,
          error: this.onApiError
        }, PACK_STANDARD_FIELDS);
      }
    }
  }

  isModifyAllowed() {
    let pack = this.state.pack;
    return !pack.key || pack.isModifyAllowed();
  }

  onSave(response: ApiResponse<Pack>) {
    this.enable();
    this.props.onSave(response);
  }

  onDelete() {
    let pack = this.state.pack;
    pack.delete({
      next: this.props.onDelete,
      error: this.onApiError
    });
  }

  onCancel() {
    this.enable();
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let pack = this.state.pack;

    return (

      <div>

        <FullScreenModal title={!pack.key ? 'New Pack' : 'Edit Pack'} onHide={this.onCancel}>

          <p className="lead">Please enter the details of the pack below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled() || !this.isModifyAllowed()}>

            <Panel>

              <InputText
                id="pack.name"
                label="Pack Name"
                value={pack.name}
                error={this.state.errors?.['request.name']}
                onChange={this.onFieldChange}
                className="lg"
                required/>

              <InputText
                id="pack.badgeLabel"
                label="Badge Label"
                help="An optional promotional text for marketing purposes. eg Selling Fast, Exclusive to BTX etc."
                value={pack.badgeLabel}
                error={this.state.errors?.['request.badgeLabel']}
                onChange={this.onFieldChange}/>

              <div className="row">
                <div className="col-md-6">
                  <InputDatePicker
                    id="pack.startAt"
                    label="Start Date"
                    value={pack.startAt}
                    error={this.state.errors?.['request.startAt']}
                    dateFormat="DD/MM/YYYY"
                    onChange={this.onFieldChange}
                    required/>
                </div>
                <div className="col-md-6">
                  <InputDatePicker
                    id="pack.endAt"
                    label="End Date"
                    value={pack.endAt}
                    error={this.state.errors?.['request.endAt']}
                    dateFormat="DD/MM/YYYY"
                    onChange={this.onFieldChange}/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="pack.unitCount"
                    label="Number of Units"
                    value={pack.unitCount}
                    format="number"
                    error={this.state.errors?.['request.unitCount']}
                    onChange={this.onFieldChange}
                    className="lg"
                    required/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="pack.unitPrice"
                    label="Unit Price"
                    value={pack.unitPrice}
                    format="currency"
                    error={this.state.errors?.['request.unitPrice']}
                    onChange={this.onFieldChange}
                    className="lg"
                    required/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="pack.limitPerUser"
                    label="Limit per User"
                    help="Leave empty for no restriction."
                    value={pack.limitPerUser}
                    format="number"
                    error={this.state.errors?.['request.limitPerUser']}
                    onChange={this.onFieldChange}
                    className="lg"/>
                </div>
              </div>

              <SectionHeader title="Media" divider/>

              <ImageWithUpload
                id="pack.coverImage"
                label="Cover Image"
                files={pack.coverImage ? [pack.coverImage] : []}
                className="images-across-1"
                accept={acceptedImageMimeTypes}
                lightbox
                upload
                onChange={(e) => {
                  let images: Array<any> = e.target.value;
                  let coverImage = (images && images.length > 0) ? images[0] : undefined;
                  if (!coverImage || coverImage.externalReference) {
                    e.target.value = coverImage;
                    this.onFieldChange(e);
                  }
                }}/>

              <VideoWithUpload
                id="pack.promotionalVideos"
                label="Promotional Videos"
                files={pack.promotionalVideos || []}
                className="videos-across-3"
                accept="video/mp4"
                multiple
                upload
                onChange={(e: any) => {
                  let videos: Array<Binary> = e.target.value;
                  e.target.value = videos;
                  this.onFieldChange(e);
                }}/>

              <ImageWithUpload
                id="pack.promotionalImages"
                label="Promotional Images"
                files={pack.promotionalImages || []}
                className="images-across-3"
                accept={acceptedImageMimeTypes}
                lightbox
                multiple
                upload
                onChange={(e: any) => {
                  let images: Array<Binary> = e.target.value;
                  e.target.value = images;
                  this.onFieldChange(e);
                }}/>

              <SectionHeader title="Terms" divider/>

              <InputInlineEditor
                tools="traditional"
                id="pack.terms.body"
                elementId="dialog-terms"
                label="Terms"
                help="A freeform rich content of the terms of this pack comprising of text, images and file attachments."
                value={pack.terms?.body}
                error={this.state.errors?.['request.terms.body']}
                uploadFileAcceptedMimeTypes={acceptedFileMimeTypes}
                onChange={this.onFieldChange}/>

              <FileWithUpload
                id="pack.pdsFiles"
                label="Product Disclosure Statement (PDS)"
                files={pack.pdsFiles || []}
                multiple
                upload
                onChange={(e: any) => {
                  let files: Array<Binary> = e.target.value;
                  e.target.value = files;
                  this.onFieldChange(e);
                }}/>

            </Panel>

            <div className="actions">
              {
                this.isModifyAllowed() &&
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  type="submit">Save Changes</Button>
              }
              <Button
                onClick={this.onCancel}
                disabled={false}
                bsSize="large">{this.isModifyAllowed() ? 'Cancel' : 'Close'}</Button>
              {
                pack.isDeleteAllowed() &&
                <Button
                  onClick={this.onDelete}
                  bsSize="large"
                  bsStyle="default"
                  className="pull-right">Delete</Button>
              }
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
