import TransactionType from "./TransactionType";
import TransactionStatus from "./TransactionStatus";
import TransactionItem from "./TransactionItem";
import Model from "../Model";
import Subscription from "./Subscription";
import TransactionPayment from "./TransactionPayment";
import SubscribableType from "./SubscribableType";
import {ApiSubscribableCallbacks} from "startupbox-react";
import ApiError from "./ApiError";
import TransactionFixPaymentRequest from './TransactionFixPaymentRequest';

let Rel = {
//   self: 'self',
//   pay: 'pay',
  modifyBlockchainTransactionHash: 'modifyBlockchainTransactionHash',
  fixPayment: 'fixPayment'
};

export const TRANSACTION_LIST_FIELDS = 'items';
export const TRANSACTION_LIST_WITH_PAYMENTS_FIELDS = 'items,payments,payments.method';
export const TRANSACTION_STANDARD_FIELDS = 'subscription,subscription.subscribable,items';

export default class Transaction {

  key?: string;

  blockchainTransactionHash?: string;
  viewUrl?: string;

  date?: Date;
  unitCount?: number;
  totalAmount?: number;

  subscribableName?: string;
  subscribableType?: SubscribableType;
  subscribableBlockchainId?: number;
  subscribableBlockchainTransactionHash?: string;

  enclosingPackSubscriptionKey?: string;
  enclosingPackSubscribableName?: string;

  walletPayerName?: string;
  walletPublicKey?: string;

  type?: TransactionType;
  status?: TransactionStatus;

  subscriptionKey?: string;
  subscription?: Subscription;

  items?: Array<TransactionItem>;
  payments?: Array<TransactionPayment>;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  isModifyBlockchainTransactionHashAllowed() {
    return !!this.href(Rel.modifyBlockchainTransactionHash);
  }

  isFixPaymentAllowed() {
    return !!this.href(Rel.fixPayment);
  }

  modifyBlockchainTransactionHash(hash: string, callbacks: ApiSubscribableCallbacks<Transaction, ApiError>, fields: string | null) {
    this.href(Rel.modifyBlockchainTransactionHash, true)
      .field(fields)
      .patch({hash: hash})
      .subscribe(callbacks);
  }

  fixPayment(fixPaymentRequest: TransactionFixPaymentRequest, callbacks: ApiSubscribableCallbacks<Transaction, ApiError>, fields: string | null) {
    this.href(Rel.fixPayment, true)
      .field(fields)
      .patch(fixPaymentRequest)
      .subscribe(callbacks);
  }

};
