import React from 'react';
import {Environment} from 'startupbox-react';

const Footer = () => {

  return (

    <div id="footer-container">

      <div id="footer">
        <div id="footer-content">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="title">{Environment.getAppTitle()}</div>
                <div className="tagline">{Environment.getAppTagline()}</div>
              </div>
              <div className="col-md-3 col-md-offset-1">
                <div className="list-title">Discover</div>
                <ul>
                  <li><a href="/our-value">Our Value</a></li>
                  <li><a href="/our-vision">Our Vision</a></li>
                  <li><a href="/how-it-works">How It Works</a></li>
                  <li><a href="/faq">FAQs</a></li>
                </ul>
              </div>
              <div className="col-md-3">
                <div className="list-title">Company</div>
                <ul>
                  <li><a href="/about-us">About Us</a></li>
                  <li><a href="/contact-us">Contact Us</a></li>
                  <li><a href="/terms-and-conditions">Terms & Conditions</a></li>
                  <li><a href="/privacy-policy">Privacy Policy</a></li>
                </ul>
              </div>
              <div className="col-md-2">
                <div className="list-title">Existing Members</div>
                <ul>
                  <li><a href="/">Login</a></li>
                  <li><a href="/support">Support Centre</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="footer-links">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div id="footer-nav">
                  Black Type x Technology Pty Ltd. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  );

};

export default Footer;
