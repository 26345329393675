import React from 'react';
import {InputDatePicker, InputSelect, InputText, Str} from 'startupbox-react';
import './HorsePanel.css';
import HorseAttribute from "../models/HorseAttribute";
import moment from 'moment';
import NotSpecified from "../components/NotSpecified";

type HorseAttributeValueProps = {
  hideLabel?: boolean;
  attribute: HorseAttribute;
  onChange?: (stringValue: string) => void;
};

const HorseAttributeValue = (props: HorseAttributeValueProps) => {

  let attribute = props.attribute;
  let isEditing = !!props.onChange;
  let onChange = props.onChange;

  let fieldId = 'attribute-' + attribute.key;
  let fieldLabel = props.hideLabel ? undefined : 'Value';
  let fieldValue = attribute.stringValue;

  if (!attribute.type) {

    return (
      <NotSpecified/>
    );

  }
  else {

    if (!isEditing) {

      // viewing

      return (
        <>{fieldValue || <NotSpecified/>}</>
      );

    }
    else {

      // editing

      if (attribute.type.key === 'COLOR') {
        return (
          <InputSelect
            id={fieldId}
            label={fieldLabel}
            value={fieldValue}
            options={attribute.type.meta.options}
            onChange={(e) => {
              onChange?.(e.target.value);
            }}/>
        );
      }
      else if (attribute.type.key === 'INTEGER') {
        return (
          <InputText
            id={fieldId}
            label={fieldLabel}
            value={fieldValue}
            format="number"
            onChange={(e) => {
              onChange?.(e.target.value);
            }}/>
        );
      }
      else if (attribute.type.key === 'STRING_SINGLE_LINE') {
        return (
          <InputText
            id={fieldId}
            label={fieldLabel}
            value={fieldValue}
            onChange={(e) => {
              onChange?.(e.target.value);
            }}/>
        );
      }
      else if (attribute.type.key === 'DATE') {
        let dateFormat = 'DD/MM/YYYY';
        let date = undefined;
        if (fieldValue && fieldValue !== '') {
          date = moment(fieldValue, dateFormat);
        }
        return (
          <InputDatePicker
            id={fieldId}
            label={fieldLabel}
            value={date}
            placeholder={dateFormat}
            dateFormat={dateFormat}
            onChange={(e) => {
              onChange?.(e.target.value ? Str.formatDate(e.target.value, dateFormat) : '11/11/2020');
            }}/>
        );
      }
      if (attribute.type.key === 'SEX') {
        return (
          <InputSelect
            id={fieldId}
            label={fieldLabel}
            value={fieldValue}
            options={attribute.type.meta.options}
            onChange={(e) => {
              onChange?.(e.target.value);
            }}/>
        );
      }

    }

  }

  return <></>;

};

export default HorseAttributeValue;