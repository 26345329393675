import React from 'react';
import {Nav, NavItem} from 'react-bootstrap';
import Horse from "../models/Horse";

type HorseTabsProps = {
  horse: Horse;
  active: string;
  onSelect: (value?: any) => void;
};

const HorseTabs = (props: HorseTabsProps) => {

  return (
    <div id="horse-tabs" className="tabs">
      <Nav id="horse-tabs-nav" bsStyle="pills" justified activeKey={props.active} onSelect={props.onSelect}>
        <NavItem eventKey="details">Details</NavItem>
        <NavItem eventKey="ownerships">Ownerships</NavItem>
        <NavItem eventKey="collectibles">Collectibles</NavItem>
        <NavItem eventKey="memberships">Memberships</NavItem>
        <NavItem eventKey="posts">Posts</NavItem>
        <NavItem eventKey="medical-records">Medical</NavItem>
        <NavItem eventKey="training-records">Training</NavItem>
      </Nav>
    </div>
  );

}

export default HorseTabs;