import Model from "../Model";
import WalletType from "./WalletType";
import {ApiSubscribableCallbacks, SearchResult} from "startupbox-react";
import ApiError from "./ApiError";
import AppCreditSearchRequest from "./AppCreditSearchRequest";
import AppCredit from "./AppCredit";
import EntitlementSearchRequest from "./EntitlementSearchRequest";
import Entitlement from "./Entitlement";
import TransactionSearchRequest from "./TransactionSearchRequest";
import Transaction from "./Transaction";

let Rel = {
  self: 'self',
  appCredit_add: 'appCredit_add',
  appCredit_search: 'appCredit_search',
  entitlement_search: 'entitlement_search',
  transaction_search: 'transaction_search',
};

export const WALLET_ENTITLEMENT_FIELDS = 'payoutAmounts';

export default class Wallet {

  key?: string;

  name?: string;
  publicKey?: string;
  viewUrl?: string;
  type?: WalletType;

  privateKey?: string;
  appCreditPayoutBalanceAmount?: number;
  appCreditGiftCardBalanceAmount?: number;
  appCreditPromotionBalanceAmount?: number;
  appCreditBalanceAmount?: number;
  payoutEntitlementPendingAmount?: number;
  payoutEntitlementAvailableAmount?: number;
  payoutEntitlementConvertedAmount?: number;
  payoutEntitlementWithdrawnAmount?: number;
  payoutEntitlementWithdrawnAndDisbursedAmount?: number;


  href = Model.hrefFunction();

  // ................................................................................................................................................

  reload(callbacks: ApiSubscribableCallbacks<Wallet, ApiError>, fields: string | null) {
    this.href(Rel.self, true)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // app credit .....................................................................................................................................

  isAddAppCreditAllowed() {
    return !!this.href(Rel.appCredit_add);
  }

  addAppCredit(appCredit: AppCredit, callbacks: ApiSubscribableCallbacks<AppCredit, ApiError>, fields: string | null) {
    this.href(Rel.appCredit_add, true)
      .field(fields)
      .post(appCredit)
      .subscribe(callbacks);
  }

  searchAppCredits(request: AppCreditSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<AppCredit>, ApiError>, fields: string | null) {
    this.href(Rel.appCredit_search, true)!
      .param('keywords', request.keywords)
      .param('key', request.key)
      .param('type', request.type)
      .param('sort', request.sort || 'date,desc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // entitlement ....................................................................................................................................

  searchEntitlements(request: EntitlementSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Entitlement>, ApiError>, fields: string | null) {
    this.href(Rel.entitlement_search, true)!
      .param('type', request.type)
      .param('status', request.status)
      .param('disbursementAllowed', request.disbursementAllowed != null ? request.disbursementAllowed + '' : undefined)
      .param('sort', request.sort || 'date,desc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // transaction ................................................................................................................................

  searchTransactions(request: TransactionSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Transaction>, ApiError>, fields: string | null) {
    this.href(Rel.transaction_search, true)!
      .param('sort', request.sort || 'date,desc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

}
