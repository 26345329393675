import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, Confirm, Confirmable, Form, GlobalMessage, HelpTooltip, InputDatePicker, SearchResult, Str, Table, TableHeaderCell} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import EntitlementSearchRequest from "../models/EntitlementSearchRequest";
import Entitlement, {ENTITLEMENT_LIST_FIELDS} from "../models/Entitlement";
import ApiIndex from "../models/ApiIndex";
import Disbursement, {DISBURSEMENT_STANDARD_FIELDS} from "../models/Disbursement";
import FontAwesome from "react-fontawesome";

let DISBURSEMENT_FIELDS = DISBURSEMENT_STANDARD_FIELDS;
let ENTITLEMENT_FIELDS = ENTITLEMENT_LIST_FIELDS;

export type DisbursementPageProps = PageProps & {};

export type DisbursementPageState = PageState & {
  disbursement?: Disbursement;
  entitlementSearchRequest: EntitlementSearchRequest;
  entitlements?: SearchResult<Entitlement> | null;
};

export default class DisbursementPage extends Page<DisbursementPageProps, DisbursementPageState> {

  constructor(props: DisbursementPageProps) {
    super(props);
    this.setInitialState({
      entitlementSearchRequest: new EntitlementSearchRequest()
    });
  }

  onEnter() {
    this.setState({
      entitlementSearchRequest: {
        status: this.query('status'),
        disbursementAllowed: this.query('disbursementAllowed') ? this.query('disbursementAllowed') === 'yes' : undefined,
        sort: this.query('sort'),
        page: this.query('page'),
      },
    }, this.onLoad);
  }

  onSearchEntitlements() {
    let entitlementSearchRequest = this.state.entitlementSearchRequest;
    this.path()
      .param('status', entitlementSearchRequest.status)
      .param('sort', entitlementSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Disbursement');
    this.state.index?.findDisbursementByKey(this.props.params.key, {
      next: this.setDisbursement,
      error: this.onApiError
    }, DISBURSEMENT_FIELDS);
  }

  setDisbursement(response: ApiResponse<Disbursement>) {
    let disbursement = response.resource!;
    this.publish(Events.system.pageTitle, disbursement.title);
    this.setState({
      disbursement: disbursement
    }, () => {
      this.searchEntitlements();
    });
  }

  searchEntitlements() {
    let disbursement = this.state.disbursement!;
    disbursement.searchEntitlements(this.state.entitlementSearchRequest, {
      next: this.setEntitlements,
      error: this.onApiError
    }, ENTITLEMENT_FIELDS);
  }

  setEntitlements(response: ApiSearchResponse<Entitlement>) {
    this.setState({
      entitlements: response.resource,
    });
  }

  onExportABAFile() {
    let disbursement = this.state.disbursement;
    window.location.href = disbursement!.getABAFileHref();
  }

  onMarkAsProcessed(callback: () => void) {
    let disbursement = this.state.disbursement;
    disbursement?.markAsProcessed(disbursement!.processedAt, {
      next: response => {
        this.setDisbursement(response);
        callback();
      },
      error: response => {
        this.onApiError(response);
        callback();
      }
    }, DISBURSEMENT_FIELDS);
  }

  onRemoveFromDisbursement(entitlement: Entitlement) {
    entitlement.removeFromDisbursement({
      next: response => {
        this.onSearchEntitlements();
      },
      error: this.onApiError
    }, null);
  }

  renderWithIndex(index: ApiIndex) {

    let disbursement = this.state.disbursement;

    return (

      <div className="row">

        <div className="col-md-12">

          <PageHeader
            title={(disbursement ? disbursement.title : 'Disbursement')}
            lead="This area allows you to view the entitlements included in this disbursement, generate an ABA file for importing through
            your bank's internet banking facilities and marking this disbursement as processed."/>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Table
            items={this.state.entitlements}
            sort={this.state.entitlementSearchRequest.sort}
            onReload={this.onEnter}
            blankMessage="No entitlements found"
            title={
              <Form className="form-inline">
                <span className="actions">
                  {
                    disbursement?.isDownloadABAAllowed() &&
                    <Button
                      bsStyle="primary"
                      onClick={this.onExportABAFile}>Export ABA File</Button>
                  }
                  {
                    disbursement?.isMarkAsProcessedAllowed() &&
                    <Confirmable
                      renderConfirm={(onOk, onCancel) => {
                        return (
                          <Confirm
                            title="Mark As Processed"
                            message="You are about to mark this Disbursement as processed. Please specify the processing date or leave blank to use the current date."
                            onOk={() => {
                              this.onMarkAsProcessed(onOk);
                            }}
                            onCancel={onCancel}>
                            <div className="text-left" style={{display: 'inline-block'}}>
                              <InputDatePicker
                                id="disbursement.processedAt"
                                label="Processed Date"
                                value={disbursement?.processedAt}
                                placeholder="Now"
                                dateFormat="DD/MM/YYYY"
                                onChange={this.onFieldChange}/>
                            </div>
                          </Confirm>
                        );
                      }}
                      renderTrigger={(onShow) => {
                        return (
                          <Button
                            bsStyle="primary"
                            onClick={onShow}>Mark As Processed</Button>
                        );
                      }}/>
                  }
                  {
                    !disbursement?.isMarkAsProcessedAllowed() &&
                    <Button
                      icon="check"
                      bsStyle="default">{disbursement?.status?.name}</Button>
                  }
                </span>
              </Form>
            }
            renderHeaderRow={() => {
              return (
                <tr>
                  <TableHeaderCell label="Date" className="col-md-2"/>
                  <TableHeaderCell label="Description"/>
                  <TableHeaderCell label="Recipient" className="col-md-3"/>
                  <TableHeaderCell label="Amount" className="col-md-2 text-right paddingRight20"/>
                  <TableHeaderCell label="&nbsp;" className="col-chevron"/>
                  <TableHeaderCell label="&nbsp;" className="col-chevron"/>
                </tr>
              );
            }}
            renderItemRow={(entitlement: Entitlement, t: number) => {
              return (
                <tr key={t}>
                  <td>{Str.formatDate(entitlement.date, 'DD MMMM YYYY')}</td>
                  <td>{entitlement.subscribableName}</td>
                  <td>{entitlement.walletOwnerName}</td>
                  <td className="text-right paddingRight20">${Str.formatNumber(entitlement.totalAmount, 2)}</td>
                  <td className="text-right">
                    {
                      !entitlement.bankDetailsForAustraliaValid &&
                      <HelpTooltip id={'entitlement-help-' + t} message="Bank Account details are invalid and disbursement is not allowed. Either fix or remove this entitlement.">
                        <FontAwesome name="exclamation-triangle" className="error"/>
                      </HelpTooltip>
                    }
                  </td>
                  <td className="text-right">
                    {
                      entitlement.isRemoveFromDisbursementAllowed() &&
                      <FontAwesome name="trash" className="clickable text-muted" onClick={this.fn(this.onRemoveFromDisbursement, entitlement)}/>
                    }
                    {
                      disbursement?.isProcessed() &&
                      <FontAwesome name="check" className="text-muted"/>
                    }
                  </td>
                </tr>
              );
            }}
          />

        </div>

      </div>

    );

  }
}
