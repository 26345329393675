import React from "react";
import {Nav, NavItem} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import Routes from "../Routes";
import {navigate} from "startupbox-react";

type AccountNavProps = {
  active: string;
}

const AccountNav = (props: AccountNavProps) => {

  let onSelect = (tab: any) => {
    if (tab === 'Profile') {
      navigate(Routes.account.profile());
    }
    else if (tab === 'Avatar') {
      navigate(Routes.account.avatar());
    }
    else if (tab === 'Password') {
      navigate(Routes.account.password());
    }
    else if (tab === 'Logout') {
      navigate(Routes.account.logout());
    }
  }

  return (

    <div className="tabbed-pills-container">

      <Nav bsStyle="pills" justified activeKey={props.active} onSelect={onSelect}>
        <NavItem eventKey={'Profile'}><FontAwesome name="id-card-o"/><span className="lbl">Profile</span></NavItem>
        <NavItem eventKey={'Avatar'}><FontAwesome name="user-o"/><span className="lbl">Avatar</span></NavItem>
        <NavItem eventKey={'Password'}><FontAwesome name="lock"/><span className="lbl">Password</span></NavItem>
        <NavItem eventKey={'Logout'}><FontAwesome name="sign-out"/><span className="lbl">Logout</span></NavItem>
      </Nav>

    </div>

  );

};

export default AccountNav;