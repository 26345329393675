import {Environment} from "startupbox-react";

export type BinarySource = {
  key: string
  name: string
}

export default class Binary {

  url?: string;

  name?: string;
  externalReference?: string;
  mimeType?: string;
  sizeInBytes?: number;
  source?: BinarySource;

  getHref(transforms?: string | null | undefined) {
    if (this.url) {
      return this.url;
    }
    else if (this.source) {
      if (this.source.key === 'IMAGE_SERVICE') {
        return Environment.imageHref(this.externalReference!, transforms);
      }
      else if (this.source.key === 'FILE_SERVICE') {
        return Environment.fileHref(this.externalReference!, this.name || 'file');
      }
    }
    return null;
  }

};
