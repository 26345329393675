import PaymentMethod from "./PaymentMethod";
import PaymentMethodCreditCardBrand from "./PaymentMethodCreditCardBrand";

export default class PaymentMethodCreditCard extends PaymentMethod {

  name?: string | null;
  number?: string | null;
  brand?: PaymentMethodCreditCardBrand | null;
  expiry?: string | null;
  cvv?: string | null;

}