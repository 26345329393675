import Model from "../Model";
import {ApiSubscribableCallbacks} from "startupbox-react";
import ApiError from "./ApiError";
import PackStatus from "./PackStatus";
import Content from "./Content";
import Binary from "./Binary";
import BlockchainMintable from "./BlockchainMintable";
import BlockchainMintStatus from "./BlockchainMintStatus";
import Subscribable from "./Subscribable";

let Rel = {
  self: 'self',
  modify: 'modify',
  modifyStatus: 'modifyStatus',
  modifyMintStatus: 'modifyMintStatus',
  modifyName: 'modifyName',
  modifyBadge: 'modifyBadge',
  modifyMedia: 'modifyMedia',
  modifyFeatured: 'modifyFeatured',
  modifyRemainingCount: 'modifyRemainingCount',
  delete: 'delete',
  sendReviewRequestNotification: 'sendReviewRequestNotification',
  item_add: 'item_add',
  item_remove: 'removeItem',
};

export const PACK_LIST_FIELDS = 'coverImage,promotionalImages,promotionalVideos,ipfsMedia,pdsFiles,terms,terms.body,manageableMetadataAttributeGroup';
export const PACK_STANDARD_FIELDS = PACK_LIST_FIELDS + ',items';

export default class Pack extends BlockchainMintable {

  key?: string;
  partnerKey?: string;

  paymentAccountProductId?: string;
  name?: string;
  startAt?: Date;
  endAt?: Date;
  activatedAt?: Date;
  unitPrice?: number;
  unitCount?: number;
  remainingUnitCount?: number = 0;
  limitPerUser?: number;
  terms?: Content = new Content();
  status?: PackStatus;

  items?: Array<Subscribable>;

  coverImage?: Binary;
  promotionalImages?: Array<Binary>;
  promotionalVideos?: Array<Binary>;
  pdsFiles?: Array<Binary>;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  getCountText() {
    return this.remainingUnitCount + '/' + this.unitCount;
  }

  hasTerms() {
    return this.terms && this.terms.body && this.terms.body.blocks && this.terms.body.blocks.length > 0;
  }

  // ................................................................................................................................................

  reload(callbacks: ApiSubscribableCallbacks<Pack, ApiError>, fields: string | null) {
    this.href(Rel.self, true)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  isModifyAllowed() {
    return !!this.href(Rel.modify);
  }

  save(callbacks: ApiSubscribableCallbacks<Pack, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }

  isModifyStatusAllowed() {
    return !!this.href(Rel.modifyStatus);
  }

  modifyStatus(status: PackStatus | null, callbacks: ApiSubscribableCallbacks<Pack, ApiError>, fields: string | null) {
    this.href(Rel.modifyStatus, true)
      .field(fields)
      .patch({status: status})
      .subscribe(callbacks);
  }

  isModifyMintStatusAllowed() {
    return !!this.href(Rel.modifyMintStatus);
  }

  modifyMintStatus(mintStatus: BlockchainMintStatus | null, callbacks: ApiSubscribableCallbacks<Pack, ApiError>, fields: string | null) {
    this.href(Rel.modifyMintStatus, true)
      .field(fields)
      .patch({mintStatus: mintStatus})
      .subscribe(callbacks);
  }

  isModifyNameAllowed() {
    return !!this.href(Rel.modifyName);
  }

  modifyName(name: string | null, callbacks: ApiSubscribableCallbacks<Pack, ApiError>, fields: string | null) {
    this.href(Rel.modifyName, true)
      .field(fields)
      .patch({name: name})
      .subscribe(callbacks);
  }

  isModifyBadgeAllowed() {
    return !!this.href(Rel.modifyBadge);
  }

  modifyBadge(badgeLabel: string | null, callbacks: ApiSubscribableCallbacks<Pack, ApiError>, fields: string | null) {
    this.href(Rel.modifyBadge, true)
      .field(fields)
      .patch({badgeLabel: badgeLabel})
      .subscribe(callbacks);
  }

  isModifyMediaAllowed() {
    return !!this.href(Rel.modifyMedia);
  }

  modifyMedia(coverImage: Binary | undefined, promotionalImages: Array<Binary> | undefined, promotionalVideos: Array<Binary> | undefined, callbacks: ApiSubscribableCallbacks<Pack, ApiError>, fields: string | null) {
    this.href(Rel.modifyMedia, true)
      .field(fields)
      .patch({coverImage: coverImage, promotionalImages: promotionalImages, promotionalVideos: promotionalVideos})
      .subscribe(callbacks);
  }

  isModifyFeaturedAllowed() {
    return !!this.href(Rel.modifyFeatured);
  }

  modifyFeatured(featured: boolean | null, callbacks: ApiSubscribableCallbacks<Pack, ApiError>, fields: string | null) {
    this.href(Rel.modifyFeatured, true)
      .field(fields)
      .patch({featured: featured})
      .subscribe(callbacks);
  }

  isModifyRemainingCountAllowed() {
    return !!this.href(Rel.modifyRemainingCount);
  }

  modifyRemainingCount(callbacks: ApiSubscribableCallbacks<Pack, ApiError>, fields: string | null) {
    this.href(Rel.modifyRemainingCount, true)
      .field(fields)
      .patch({})
      .subscribe(callbacks);
  }

  isDeleteAllowed() {
    return !!this.href(Rel.delete);
  }

  delete(callbacks: ApiSubscribableCallbacks<null, ApiError>) {
    this.href(Rel.delete, true)
      .delete()
      .subscribe(callbacks);
  }

  isSendReviewRequestNotificationAllowed() {
    return !!this.href(Rel.sendReviewRequestNotification);
  }

  sendReviewRequestNotification(callbacks: ApiSubscribableCallbacks<Pack, ApiError>, fields: string | null) {
    this.href(Rel.sendReviewRequestNotification, true)
      .field(fields)
      .post({})
      .subscribe(callbacks);
  }

  // ................................................................................................................................................

  isAddItemAllowed() {
    return !!this.href(Rel.item_add);
  }

  addItem(subscribable: Subscribable, callbacks: ApiSubscribableCallbacks<Pack, ApiError>, fields: string | null) {
    this.href(Rel.item_add, true)
      .field(fields)
      .post({subscribable: subscribable})
      .subscribe(callbacks);
  }

  isRemoveItemAllowed() {
    return !!this.href(Rel.item_remove);
  }

  removeItem(subscribable: Subscribable, callbacks: ApiSubscribableCallbacks<Pack, ApiError>, fields: string | null) {
    this.href(Rel.item_remove, true)
      .field(fields)
      .post({subscribable: subscribable})
      .subscribe(callbacks);
  }

}
