import React from 'react';
import PartnerHorseAbstractPage, {PartnerHorseAbstractPageProps, PartnerHorseAbstractPageState} from './PartnerHorseAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Horse from "../models/Horse";
import Partner from "../models/Partner";
import Tranche, {TRANCHE_STANDARD_FIELDS} from "../models/Tranche";
import {ApiErrorResponse} from "../models/ApiError";
import {
  ApiResponse,
  Button,
  Confirm,
  Confirmable,
  FileWithUpload,
  GlobalMessage,
  ImageWithUpload,
  InputCheckbox,
  InputDatePicker,
  InputInlineEditor,
  InputText,
  InputWrap,
  StatusButton,
  VideoWithUpload
} from "startupbox-react";
import TrancheStatus from "../models/TrancheStatus";
import TrancheDialog from "../tranche/TrancheDialog";
import Routes from "../Routes";
import Panel from "../components/Panel";
import VisibilitiesPanel from "../components/VisibilitiesPanel";
import BlockchainMintable from "../models/BlockchainMintable";
import BlockchainMintablePanel from "../blockchain/BlockchainMintablePanel";
import BlockchainMintStatus from "../models/BlockchainMintStatus";
import SectionHeader from "../components/SectionHeader";
import TrancheBadgeDialog from "../tranche/TrancheBadgeDialog";
import PartnerHorseTrancheGiftToUserDialog from "./PartnerHorseTrancheGiftToUserDialog";
import TrancheNameDialog from "../tranche/TrancheNameDialog";
import TrancheMediaDialog from "../tranche/TrancheMediaDialog";

let FIELDS = TRANCHE_STANDARD_FIELDS;

export type PartnerHorseTranchePageProps = PartnerHorseAbstractPageProps & {};

export type PartnerHorseTranchePageState = PartnerHorseAbstractPageState & {
  tranche: Tranche | null | undefined;
}

export default class PartnerHorseTranchePage extends PartnerHorseAbstractPage<PartnerHorseTranchePageProps, PartnerHorseTranchePageState> {

  constructor(props: PartnerHorseTranchePageProps) {
    super(props);
    this.activeTab = 'ownerships';
  }

  onLoadHorse(horse: Horse | undefined) {
    this.loadTranche();
  }

  onReloadTranche(response: ApiResponse<BlockchainMintable>) {
    this.setTranche(response as ApiResponse<Tranche>);
  }

  loadTranche() {
    this.state.index?.findTrancheByKey(this.props.params.trancheKey, {
      next: this.setTranche,
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, FIELDS);
  }

  onSave(response: ApiResponse<Tranche>) {
    this.setTranche(response);
  }

  onDelete() {
    this.navigate(Routes.partner.horse_ownerships(this.getPartnerKey(), this.getHorseKey()))
  }

  onBack() {
    this.navigate(Routes.partner.horse_ownerships(this.getPartnerKey(), this.getHorseKey()))
  }

  onShowPayouts() {
    this.navigate(Routes.partner.horse_tranche_payouts(this.getPartnerKey(), this.getHorseKey(), this.props.params.trancheKey))
  }

  onChangeFeatured(option: any) {
    this.state.tranche?.modifyFeatured(option.key === 'true', {
      next: this.setTranche,
      error: this.onApiError
    }, FIELDS);
  }

  onChangeStatus(status: TrancheStatus) {
    this.state.tranche?.modifyStatus(status, {
      next: this.setTranche,
      error: this.onApiError
    }, FIELDS);
  }

  onChangeMintStatus(mintStatus: BlockchainMintStatus) {
    this.state.tranche?.modifyMintStatus(mintStatus, {
      next: this.setTranche,
      error: this.onApiError
    }, FIELDS);
  }

  setTranche(response: ApiResponse<Tranche>) {
    let tranche = response.resource;
    this.setState({
      tranche: tranche
    });
  }

  renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {

    let tranche = this.state.tranche;

    if (!tranche) {
      return <></>;
    }

    return <>

      <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity} fieldErrors={(this.state.errors as any)._}/>

      <SectionHeader
        title="Tranche"
        description={
          <>
            The details of this tranche is shown below. This tranche is currently <strong>{tranche!.status!.name}</strong> and <strong>{tranche!.mintStatus!.name}</strong>. Please note that
            users can only see tranches in the Marketplace if the tranche is both public and minted.
          </>
        }
        actions={
          <>
            <Button
              icon="chevron-left"
              iconPosition="left"
              bsStyle="default"
              bsSize="xs"
              onClick={this.onBack}>Back To Ownerships</Button>
            {
              tranche.isSendReviewRequestNotificationAllowed() &&
              <Confirmable
                renderConfirm={(onOk, onCancel) => {
                  return (
                    <Confirm
                      title="Send Review Request"
                      message="Would you like to send a review request to BTX for this tranche to be minted and published on the public blockchain?"
                      onOk={() => {
                        tranche!.sendReviewRequestNotification({
                          next: (response) => {
                          },
                          error: this.onApiError
                        }, FIELDS);
                        onOk();
                      }}
                      onCancel={onCancel}/>
                  );
                }}
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="external-link"
                      bsStyle="primary"
                      bsSize="xs"
                      onClick={onShow}>Send Review Request</Button>
                  );
                }}/>
            }
            {
              tranche.isGiftToUserAllowed() &&
              <Confirmable
                renderConfirm={(onOk, onCancel) => {
                  return (
                    <PartnerHorseTrancheGiftToUserDialog
                      index={index}
                      tranche={tranche!}
                      onSave={(response) => {
                        onOk();
                        this.setGlobalMessage("Successfully gifted!", "success")
                        this.onSave(response);
                      }}
                      onCancel={onCancel}/>
                  );
                }}
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="external-link"
                      bsStyle="primary"
                      bsSize="xs"
                      onClick={onShow}>Gift to User</Button>
                  );
                }}/>
            }
            {
              tranche.isSearchPayoutAllowed() &&
              <Button
                icon="chevron-right"
                bsStyle="default"
                bsSize="xs"
                onClick={this.onShowPayouts}>Show Payouts</Button>
            }
          </>
        }/>

      <DetailsPanel
        index={index}
        horse={horse}
        tranche={tranche}
        errors={this.state.errors}
        onSave={this.onSave}
        onDelete={this.onDelete}
        onChangeFeatured={this.onChangeFeatured}
        onChangeStatus={this.onChangeStatus}/>

      <VisibilitiesPanel
        index={index}
        visibilities={tranche.visibilities}
        labelField="meta.accessDescription"/>

      <BlockchainMintablePanel
        type="Tranche"
        entity={tranche}
        entityFields={FIELDS}
        statuses={index.mintChooseableStatuses}
        errors={this.state.errors}
        onReload={this.onReloadTranche}
        onChangeMintStatus={this.onChangeMintStatus}/>

    </>;

  }

  renderPageActions(index: ApiIndex, partner: Partner, horse: Horse): JSX.Element {

    return (

      <div className="actions">
        <Button
          icon="chevron-left"
          iconPosition="left"
          bsStyle="default"
          bsSize="large"
          onClick={() => {
            this.navigate(Routes.partner.horse_ownerships(this.getPartnerKey(), this.getHorseKey()));
          }}>Back to Ownerships</Button>
      </div>

    );

  }

};

// ................................................................................................................................................

const DetailsPanel = (props: {
  index: ApiIndex,
  horse: Horse,
  tranche: Tranche,
  errors?: { [key: string]: string };
  onSave: (response: ApiResponse<Tranche>) => void,
  onDelete: () => void
  onChangeFeatured: (option: any) => void
  onChangeStatus: (status: TrancheStatus) => void
}) => {

  let index = props.index;
  let horse = props.horse;
  let tranche = props.tranche;
  let isSalesChannelPack = tranche.isSalesChannelPack();
  let onSave = props.onSave;
  let onDelete = props.onDelete;
  let onChangeFeatured = props.onChangeFeatured;
  let onChangeStatus = props.onChangeStatus;

  return (

    <Panel>

      {
        tranche.isModifyAllowed() &&
        <Confirmable
          renderConfirm={(onOk, onCancel) => {
            return (
              <TrancheDialog
                horse={horse}
                tranche={tranche}
                onSave={(response) => {
                  onSave(response);
                  onOk();
                }}
                onDelete={() => {
                  onDelete();
                  onOk();
                }}
                onCancel={onCancel}/>
            );
          }}
          renderTrigger={(onShow) => {
            return (
              <Button
                icon="external-link"
                bsStyle="primary"
                bsSize="xs"
                className="pull-right"
                onClick={onShow}>Edit</Button>
            );
          }}/>
      }

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="tranche.name"
            label="Tranche Name"
            value={tranche.name}
            readOnly/>
        </div>
        <div className="col-md-6">
          {
            !tranche.isModifyAllowed() && tranche.isModifyBadgeAllowed() &&
            <Confirmable
              renderConfirm={(onOk, onCancel) => {
                return (
                  <TrancheNameDialog
                    tranche={tranche}
                    onSave={(response) => {
                      onSave(response);
                      onOk();
                    }}
                    onCancel={onCancel}/>
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <Button
                    icon="external-link"
                    bsStyle="primary"
                    bsSize="xs"
                    className="pull-right"
                    onClick={onShow}>Edit</Button>
                );
              }}/>
          }
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="tranche.badgeLabel"
            label="Badge"
            value={tranche.badgeLabel}
            readOnly/>
        </div>
        <div className="col-md-6">
          {
            !tranche.isModifyAllowed() && tranche.isModifyBadgeAllowed() &&
            <Confirmable
              renderConfirm={(onOk, onCancel) => {
                return (
                  <TrancheBadgeDialog
                    tranche={tranche}
                    onSave={(response) => {
                      onSave(response);
                      onOk();
                    }}
                    onCancel={onCancel}/>
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <Button
                    icon="external-link"
                    bsStyle="primary"
                    bsSize="xs"
                    className="pull-right"
                    onClick={onShow}>Edit</Button>
                );
              }}/>
          }
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputDatePicker
            id="tranche.startAt"
            label="Start Date"
            value={tranche.startAt}
            dateFormat="DD/MM/YYYY"
            readOnly/>
        </div>
        <div className="col-md-6 text-right">
          <InputDatePicker
            id="tranche.endAt"
            label="End Date"
            value={tranche.endAt}
            dateFormat="DD/MM/YYYY"
            readOnly/>
        </div>
      </div>

      {/*
      <div className="row">
        <div className="col-md-6">
          <InputText
            id="tranche.minSubscriberCount"
            label="Min Subscriber Count"
            value={tranche.minSubscriberCount}
            readOnly/>
        </div>
        <div className="col-md-6 text-right">
          <InputDatePicker
            id="tranche.minSubscriberCountAt"
            label="Min Subscriber Count At"
            value={tranche.minSubscriberCountAt}
            dateFormat="DD/MM/YYYY"
            readOnly/>
        </div>
      </div>
*/}

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="tranche.absoluteOwnershipPercent"
            label="Absolute Ownership Percentage"
            value={tranche.absoluteOwnershipPercent}
            format="percent"
            readOnly/>
        </div>
        <div className="col-md-6 text-right">
          <InputWrap id="tranche.salesChannel" label="Sales Channel">
            <div>
              {tranche.salesChannel?.name}
            </div>
          </InputWrap>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="tranche.unitCount"
            label="Number of Units"
            value={tranche.getCountText()}
            readOnly/>
        </div>
        <div className="col-md-6 text-right">
          <InputText
            id="tranche.unitPrice"
            label="Unit Price"
            value={!isSalesChannelPack ? tranche.unitPrice : 'N/A'}
            format={!isSalesChannelPack ? 'currency' : undefined}
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="tranche.limitPerUser"
            label="Limit per User"
            value={tranche.limitPerUser ? tranche.limitPerUser : 'Unlimited'}
            readOnly/>
        </div>
        <div className="col-md-6 text-right">
          <InputText
            id="tranche.paymentAccountProductId"
            label="Stripe Product Id"
            value={tranche.paymentAccountProductId ? tranche.paymentAccountProductId : 'Not Created Yet'}
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputText
              id="tranche.externalLink"
              label="External Link"
              value={tranche.externalLink}
              readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          {
            !isSalesChannelPack &&
            <InputWrap id="featured" label="Featured">
              <div>
                <StatusButton
                  id="featured"
                  value={tranche.getFeaturedOption()}
                  options={tranche.getFeaturedOptions()}
                  onEdit={tranche.isModifyFeaturedAllowed() ? onChangeFeatured : null}/>
              </div>
            </InputWrap>
          }
          {
            isSalesChannelPack &&
            <InputText
              id="tranche.featured"
              label="Featured"
              value="N/A"
              readOnly/>
          }
        </div>
        <div className="col-xs-6 text-right">
          {
            !isSalesChannelPack &&
            <InputWrap id="status" label="Status">
              <div>
                <StatusButton
                  id="status"
                  value={tranche.status}
                  options={index.trancheStatuses}
                  onEdit={tranche.isModifyStatusAllowed() ? onChangeStatus : null}/>
              </div>
            </InputWrap>
          }
          {
            isSalesChannelPack &&
            <InputText
              id="tranche.status"
              label="Status"
              value="N/A"
              readOnly/>
          }
        </div>
      </div>

      <SectionHeader
        title="Media"
        divider
        actions={
          <>
            {
              !tranche.isModifyAllowed() && tranche.isModifyMediaAllowed() &&
              <Confirmable
                renderConfirm={(onOk, onCancel) => {
                  return (
                    <TrancheMediaDialog
                      tranche={tranche}
                      onSave={(response) => {
                        onSave(response);
                        onOk();
                      }}
                      onCancel={onCancel}/>
                  );
                }}
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="external-link"
                      bsStyle="primary"
                      bsSize="xs"
                      className="pull-right"
                      onClick={onShow}>Edit</Button>
                  );
                }}/>
            }
          </>
        }/>

      <ImageWithUpload
        id="tranche.coverImage"
        label="Cover Image"
        files={tranche.coverImage ? [tranche.coverImage] : []}
        className="images-across-1"
        lightbox
        readOnly/>

      <VideoWithUpload
        id="tranche.promotionalVideos"
        label="Promotional Videos"
        multiple
        files={tranche.promotionalVideos || []}
        readOnly/>

      <ImageWithUpload
        id="tranche.promotionalImages"
        label="Promotional Images"
        multiple
        lightbox
        files={tranche.promotionalImages || []}
        readOnly/>

      <SectionHeader title="Terms & Product Disclosure Statement" divider/>

      {
        tranche.hasTerms() ?
          <InputInlineEditor
            id="tranche.terms.body"
            elementId="details-terms"
            label="Terms"
            value={tranche.terms?.body}
            readOnly/> :
          <InputText
            id="tranche.terms.body"
            label="Terms"
            value={null}
            readOnly/>
      }

      <FileWithUpload
        id="tranche.pdsFiles"
        label="Product Disclosure Statement (PDS)"
        multiple
        files={tranche.pdsFiles || []}
        readOnly/>

    </Panel>

  );

}
