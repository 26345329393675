import FontAwesome from "react-fontawesome";
import React from 'react';
import Context from '../Context';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Routes from "../Routes";
import User from "../models/User";
import {ApiResponse, ApiSearchResponse, SearchResult} from "startupbox-react";
import PartnerCollaboratorSearchRequest from "../models/PartnerCollaboratorSearchRequest";
import PartnerCollaborator, {PARTNER_COLLABORATOR_STANDARD_FIELDS} from "../models/PartnerCollaborator";
import PageHeader from "../components/PageHeader";
import DownloadPanel from "../components/DownloadPanel";

export type HomePageProps = PageProps & {};

export type HomePageState = PageState & {
  timestamp: number;
  collaborators?: SearchResult<PartnerCollaborator> | null;
};

export default class HomePage extends Page<HomePageProps, HomePageState> {

  constructor(props: HomePageProps) {
    super(props);
    this.setInitialState({
      timestamp: Date.now()
    });
  }

  init() {
    super.init();
    this.subscribe(Events.system.login, this.onLogin);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Home');
    if (Context.isAdmin()) {
      this.handleAuthenticatedAsAdmin();
    }
    else if (Context.isAuthenticated()) {
      this.handleAuthenticatedAsNonAdmin();
    }
    else {
      this.handleUnauthenticated();
    }
  }

  handleAuthenticatedAsAdmin() {
    this.navigate(Routes.admin.index());
  }

  handleAuthenticatedAsNonAdmin() {
    setTimeout(() => {
      this.state.index?.findMe({
        next: (response: ApiResponse<User>) => {
          let user = response.resource;
          let collaboratorSearchRequest = new PartnerCollaboratorSearchRequest();
          collaboratorSearchRequest.size = 100;
          user!.searchCollaborators(collaboratorSearchRequest, {
            next: this.setCollaborators,
            error: this.onApiError
          }, PARTNER_COLLABORATOR_STANDARD_FIELDS);
        },
        error: this.onApiError
      }, null);
    }, 200);
  }

  handleUnauthenticated() {
    Events.app.showLoginDialog.publish(true);
  }

  onLogin() {
    this.setState({
      timestamp: Date.now()
    }, () => {
      this.onLoad();
    });
  }

  setCollaborators(response: ApiSearchResponse<PartnerCollaborator>) {
    let collaborators = response.resource;
    if (collaborators.values.length === 1) {
      let firstCollaborator = collaborators.values[0];
      this.onNavigateToPartnerHome(firstCollaborator.partner.key);
    }
    else {
      this.setState({
        collaborators: collaborators
      });
    }
  }

  onNavigateToPartnerHome(key: string) {
    this.navigate(Routes.partner.index(key));
  }

  render() {

    let collaborators = this.state.collaborators;
    let isAuthenticated = Context.isAuthenticated();
    let isAuthenticatedWithoutCollaborations = isAuthenticated && collaborators && collaborators.values.length === 0;
    let isAuthenticatedWithCollaborations = isAuthenticated && collaborators && collaborators.values.length > 0;
    let isPasswordJustReset = this.query('passwordReset');

    return (

      <div>

        {
          isAuthenticatedWithoutCollaborations &&
          <div id="main-content" className="has-secondary-nav">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-md-offset-3">
                  {
                    !isPasswordJustReset &&
                    <DownloadPanel/>
                  }
                  {
                    isPasswordJustReset &&
                    <div style={{margin: '0 30px'}}>
                      <h1 className="title text-center">Password Reset</h1>
                      <p className="lead text-center">You have successfully reset your password. Please continue on the BTX Mobile App.</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        }

        {
          isAuthenticatedWithCollaborations &&
          <div id="main-content" className="has-secondary-nav">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-md-offset-3">
                  <PageHeader
                    title="Please select the partner to view"
                    lead="You have access to multiple partner dashboards. Please select from the list below to access a partner dashboard."/>
                  {
                    collaborators?.values.map((collaborator: PartnerCollaborator, c: number) => {
                      return (
                        <div key={c} className="form-section add clickable" onClick={this.fn(this.onNavigateToPartnerHome, collaborator.partner?.key)}>
                          <FontAwesome name="chevron-right pull-right"/>
                          {collaborator.partner?.name}
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        }

      </div>

    );

  }

}