import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, Confirmable, Form, InputText, SearchResult} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Collectible, {COLLECTIBLE_LIST_FIELDS} from "../models/Collectible";
import ApiIndex from "../models/ApiIndex";
import CollectibleSearchRequest from "../models/CollectibleSearchRequest";
import CollectibleTable from "./CollectibleTable";
import Routes from "../Routes";
import BlockchainAttributeGroup from "../models/BlockchainAttributeGroup";
import CollectibleDialog from "./CollectibleDialog";

let FIELDS = COLLECTIBLE_LIST_FIELDS;

export type CollectiblesPageProps = PageProps & {};

export type CollectiblesPageState = PageState & {
  collectibleSearchRequest: CollectibleSearchRequest;
  collectibles?: SearchResult<Collectible> | null;
};

export default class CollectiblesPage extends Page<CollectiblesPageProps, CollectiblesPageState> {

  constructor(props: CollectiblesPageProps) {
    super(props);
    this.setInitialState({
      collectibleSearchRequest: new CollectibleSearchRequest(),
      collectibles: null
    });
  }

  onEnter() {
    this.setState({
      collectibleSearchRequest: {
        keywords: this.query('keywords'),
        btx: true,
        sort: this.query('sort') || 'name,asc',
        page: parseInt(this.query('page') || '0'),
      }
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('keywords', this.state.collectibleSearchRequest.keywords)
      .param('sort', this.state.collectibleSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Collectibles');
    this.searchCollectibles(1);
  }

  searchCollectibles(page: number) {
    let collectibleSearchRequest = this.state.collectibleSearchRequest;
    collectibleSearchRequest.page = page - 1;
    this.state.index?.searchCollectibles(collectibleSearchRequest, {
      next: this.setCollectibles,
      error: this.onApiError
    }, FIELDS);
  }

  setCollectibles(response: ApiSearchResponse<Collectible>) {
    this.setState({
      collectibles: response.resource
    })
  }

  onSave(response: ApiResponse<Collectible>) {
    this.onView(response.resource!);
  }

  onView(collectible: Collectible) {
    this.navigate(Routes.admin.collectible(collectible.key!));
  }

  renderWithIndex(index: ApiIndex) {

    let collectibles = this.state.collectibles;

    return (

      <div className="row">

        <div className="col-md-12">

          <PageHeader
            title="Collectibles"
            lead="This area allows you to manage the collectibles offered
            for sale directly through BTX, not tied to a partner or horse. Users
            of the app may purchase these collectibles to view, own and trade."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="collectibleSearchRequest.keywords"
              className="wide"
              placeholder="Keywords"
              value={this.state.collectibleSearchRequest.keywords}
              onChange={this.onFieldChange}/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              {
                index.isCreateCollectibleAllowed() &&
                <Confirmable
                  renderTrigger={(onShow) => {
                    return (
                      <Button
                        icon="plus"
                        bsStyle="default"
                        className="pull-right"
                        onClick={onShow}>Add</Button>
                    );
                  }}
                  renderConfirm={(onOk, onCancel) => {
                    let collectible: Collectible = new Collectible();
                    collectible.manageableMetadataAttributeGroup = new BlockchainAttributeGroup();
                    return (
                      <CollectibleDialog
                        index={index}
                        collectible={collectible}
                        onSave={(response: ApiResponse<Collectible>) => {
                          this.onSave(response);
                          onOk();
                        }}
                        onDelete={() => {
                          this.searchCollectibles(1);
                          onOk();
                        }}
                        onCancel={onCancel}/>
                    );
                  }}/>
              }
            </span>
          </Form>

          <br/>

          <CollectibleTable
            collectibles={collectibles}
            sort={this.state.collectibleSearchRequest.sort}
            onReload={this.onEnter}
            onView={this.onView}/>

        </div>

      </div>

    );

  }

}
