import React from 'react';
import FontAwesome from 'react-fontawesome';
import {ApiSearchResponse, Button, Form, InputSelect, InputText, SearchResult, StatusButton, Str, Table, TableHeaderCell} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Routes from '../Routes';
import Membership from "../models/Membership";
import ApiIndex from "../models/ApiIndex";
import HubSpotAsyncMessageSearchRequest from "../models/HubSpotAsyncMessageSearchRequest";
import HubSpotAsyncMessage from "../models/HubSpotAsyncMessage";

let FIELDS = '';

export type HubSpotAsyncMessagesPageProps = PageProps & {};

export type HubSpotAsyncMessagesPageState = PageState & {
  hubSpotAsyncMessageSearchRequest: HubSpotAsyncMessageSearchRequest;
  hubSpotAsyncMessages?: SearchResult<HubSpotAsyncMessage> | null;
};

export default class HubSpotAsyncMessagesPage extends Page<HubSpotAsyncMessagesPageProps, HubSpotAsyncMessagesPageState> {

  constructor(props: HubSpotAsyncMessagesPageProps) {
    super(props);
    this.setInitialState({
      hubSpotAsyncMessageSearchRequest: new HubSpotAsyncMessageSearchRequest(),
      hubSpotAsyncMessages: null,
    });
  }

  onEnter() {
    this.setState({
      hubSpotAsyncMessageSearchRequest: {
        description: this.query('description'),
        type: this.query('type'),
        status: this.query('status'),
        sort: this.query('sort'),
        page: this.query('page'),
      }
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('description', this.state.hubSpotAsyncMessageSearchRequest.description)
      .param('type', this.state.hubSpotAsyncMessageSearchRequest.type)
      .param('status', this.state.hubSpotAsyncMessageSearchRequest.status)
      .param('sort', this.state.hubSpotAsyncMessageSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'HubSpot Async Messages');
    this.state.index?.searchHubSpotAsyncMessages(this.state.hubSpotAsyncMessageSearchRequest, {
      next: this.setHubSpotAsyncMessages,
      error: this.onApiError
    }, FIELDS);
  }

  onView(resource: HubSpotAsyncMessage) {
    this.navigate(Routes.tool.hubSpotAsyncMessage(resource.key!));
  }

  setHubSpotAsyncMessages(response: ApiSearchResponse<Membership>) {
    this.setState({
      hubSpotAsyncMessages: response.resource
    })
  }

  renderWithIndex(index: ApiIndex) {

    return (

      <div className="row">

        <div className="col-md-12">

          <PageHeader
            title="HubSpot Async Messages"
            lead="This area allows you to view the HubSpot async messages enqueued and sent to HubSpot from the platform."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="hubSpotAsyncMessageSearchRequest.description"
              placeholder="Description"
              value={this.state.hubSpotAsyncMessageSearchRequest.description}
              onChange={this.onFieldChange}
              className="wide"/>
            <InputSelect
              id="hubSpotAsyncMessageSearchRequest.type"
              blank="Type"
              value={this.state.hubSpotAsyncMessageSearchRequest.type}
              onChange={this.onFieldChange}
              options={index.hubSpotAsyncMessageTypes}/>
            <InputSelect
              id="hubSpotAsyncMessageSearchRequest.status"
              blank="Status"
              value={this.state.hubSpotAsyncMessageSearchRequest.status}
              onChange={this.onFieldChange}
              options={index.hubSpotAsyncMessageStatuses}/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
            </span>
          </Form>

          <br/>

          <Table
            items={this.state.hubSpotAsyncMessages}
            sort={this.state.hubSpotAsyncMessageSearchRequest.sort}
            onReload={this.onEnter}
            blankMessage="No messages found"
            renderHeaderRow={() => {
              return (
                <tr>
                  <TableHeaderCell label="Date" sort="date" className="col-md-3"/>
                  <TableHeaderCell label="Type" sort="type" className="col-md-2"/>
                  <TableHeaderCell label="Description" sort="description"/>
                  <TableHeaderCell label="Status" sort="status" className="col-md-2 text-right"/>
                  <TableHeaderCell label="&nbsp;" className="col-chevron"/>
                </tr>
              );
            }}
            renderItemRow={(hubSpotAsyncMessage: HubSpotAsyncMessage, i: number) => {
              return (
                <tr key={i} className="clickable" onClick={this.fn(this.onView, hubSpotAsyncMessage)}>
                  <td>{Str.formatDateTime(hubSpotAsyncMessage.createdAt)}</td>
                  <td>{hubSpotAsyncMessage.type?.name}</td>
                  <td>
                    <div>
                      {hubSpotAsyncMessage.description}
                      <span className="text-muted marginLeft10">{hubSpotAsyncMessage.entityKey}</span>
                    </div>
                    {hubSpotAsyncMessage.errorMessage && <div className="error">{hubSpotAsyncMessage.errorMessage}</div>}
                  </td>
                  <td className="text-right">
                    <StatusButton
                      id={'status-' + i}
                      value={hubSpotAsyncMessage.status}/>
                  </td>
                  <td className="text-right">
                    <FontAwesome name="chevron-right"/>
                  </td>
                </tr>
              );
            }}
            />

        </div>

      </div>

    );

  }

  }
