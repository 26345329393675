import React, {useState} from 'react';
import FontAwesome from 'react-fontawesome';
import {ApiResponse, ApiSearchResponse, Button, Confirm, Confirmable, Form, GlobalMessage, InputSelect, InputText, Model, SearchResult, StatusButton, Str, Table, TableHeaderCell} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Transaction, {TRANSACTION_LIST_WITH_PAYMENTS_FIELDS} from "../models/Transaction";
import ApiIndex from "../models/ApiIndex";
import TransactionSearchRequest from "../models/TransactionSearchRequest";
import ExportDialog from "../components/ExportDialog";
import TransactionFixPaymentRequest from '../models/TransactionFixPaymentRequest';

let FIELDS = TRANSACTION_LIST_WITH_PAYMENTS_FIELDS;

export type TransactionsPageProps = PageProps & {};

export type TransactionsPageState = PageState & {
  transactionSearchRequest: TransactionSearchRequest;
  transactions?: SearchResult<Transaction> | null;
  expandedTransactionKeys: { [key: string]: boolean };
};

export default class TransactionsPage extends Page<TransactionsPageProps, TransactionsPageState> {

  constructor(props: TransactionsPageProps) {
    super(props);
    this.setInitialState({
      transactionSearchRequest: new TransactionSearchRequest(),
      transactions: null,
      expandedTransactionKeys: {}
    });
  }

  onEnter() {
    this.setState({
      transactionSearchRequest: {
        subscribableName: this.query('subscribableName'),
        walletOwnerName: this.query('walletOwnerName'),
        type: this.query('type'),
        status: this.query('status'),
        sort: this.query('sort'),
        page: parseInt(this.query('page') || '0'),
      }
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('subscribableName', this.state.transactionSearchRequest.subscribableName)
      .param('walletOwnerName', this.state.transactionSearchRequest.walletOwnerName)
      .param('type', this.state.transactionSearchRequest.type)
      .param('status', this.state.transactionSearchRequest.status)
      .param('sort', this.state.transactionSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Transactions');
    this.state.index?.searchTransactions(this.state.transactionSearchRequest, {
      next: this.setTransactions,
      error: this.onApiError
    }, FIELDS);
  }

  setTransactions(response: ApiSearchResponse<Transaction>) {
    this.setState({
      transactions: response.resource
    })
  }

  onToggleTransactionFn(transaction: Transaction) {
    return (e: any) => {
      if (this.state.mode !== 'resolving-blockchain-failed') {
        if (e.target.tagName !== 'A') {
          e.preventDefault();
          let key = transaction.key!;
          if (!this.state.expandedTransactionKeys[key]) {
            this.state.expandedTransactionKeys![key] = true;
          } else {
            delete this.state.expandedTransactionKeys[key];
          }
          this.setState({});
        }
      }
    };
  }

  renderWithIndex(index: ApiIndex) {

    let transactions = this.state.transactions;
    let transactionExportHref = index.getExportTransactionHref(this.state.transactionSearchRequest);
    let expandedTransactionKeys = this.state.expandedTransactionKeys;

    return (

      <div className="row">

        <div className="col-md-12">

          <PageHeader
            title="Transactions"
            lead="This area allows you to view the transactions created on the
            system from sales. From here you may export the transactions for scheme reporting."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="transactionSearchRequest.subscribableName"
              className="wide"
              placeholder="Item Name"
              value={this.state.transactionSearchRequest.subscribableName}
              onChange={this.onFieldChange}/>
            <InputText
              id="transactionSearchRequest.walletOwnerName"
              className="wide"
              placeholder="Wallet Owner Name"
              value={this.state.transactionSearchRequest.walletOwnerName}
              onChange={this.onFieldChange}/>
            <InputSelect
              id="transactionSearchRequest.status"
              className="wide"
              blank="Status"
              value={this.state.transactionSearchRequest.status}
              onChange={this.onFieldChange}
              options={index.transactionStatuses}/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              {
                transactionExportHref &&
                <Confirmable
                  renderTrigger={(onShow) => {
                    return (
                      <Button
                        icon="download"
                        bsStyle="default"
                        onClick={onShow}/>
                    );
                  }}
                  renderConfirm={(onOk, onCancel) => {
                    return (
                      <ExportDialog
                        title="Export Transactions"
                        count={transactions!.page!.totalElements}
                        href={transactionExportHref!}
                        onClose={onCancel}/>
                    );
                  }}/>
              }
            </span>
          </Form>

          <br/>

          <Table
            items={transactions}
            sort={this.state.transactionSearchRequest.sort}
            onReload={this.onEnter}
            blankMessage="No transactions found"
            renderHeaderRow={() => {
              return (
                <tr>
                  <TableHeaderCell label="Date" className="col-md-2"/>
                  <TableHeaderCell label="Description"/>
                  <TableHeaderCell label="Details" className="col-md-2 text-center"/>
                  <TableHeaderCell label="Amount" className="text-right"/>
                  <TableHeaderCell label="Status" sort="status" className="col-md-2 text-right"/>
                  <TableHeaderCell label="&nbsp;" className="col-chevron"/>
                </tr>
              );
            }}
            renderItemRow={(transaction: Transaction, i: number) => {
              let isExpanded = expandedTransactionKeys[transaction.key!];
              return (
                <React.Fragment key={i}>
                  <tr className="clickable" onClick={this.onToggleTransactionFn(transaction)} data-key={transaction.key}>
                    <td>
                      <div>{Str.formatDate(transaction.date, "DD/MM/YYYY")}</div>
                      <div className="text-muted">{Str.formatDate(transaction.date, "h:mm:ss a")}</div>
                    </td>
                    <td>
                      <div>
                        {transaction.subscribableName}
                        <span className="text-muted marginLeft5">{transaction.subscribableType?.name}</span>
                      </div>
                      <div className="text-muted">{transaction.walletPayerName}</div>
                      <div className="text-muted">
                        Subscription {transaction.subscriptionKey}
                      </div>
                      {
                        transaction.enclosingPackSubscriptionKey &&
                        <div className="text-muted">
                          {transaction.enclosingPackSubscribableName} Subscription {transaction.enclosingPackSubscriptionKey}
                        </div>
                      }
                      {
                        transaction.blockchainTransactionHash &&
                        <div><a href={transaction.viewUrl} target="_blank" rel="noopener noreferrer">{transaction.blockchainTransactionHash?.substring(0, 30)} ...</a></div>
                      }
                    </td>
                    <td className="text-center">{transaction.unitCount + (transaction.unitCount! > 1 ? ' units' : ' unit')}</td>
                    <td className="text-right">{Str.formatNumber(transaction.totalAmount, 2, '.', ',')}</td>
                    <td className="text-right">
                      {
                        !transaction.isModifyBlockchainTransactionHashAllowed() && !transaction.isFixPaymentAllowed() &&
                        <StatusButton
                          id={'status-' + i}
                          value={transaction.status}/>
                      }
                      {
                        transaction.isModifyBlockchainTransactionHashAllowed() &&
                        <Confirmable
                          renderConfirm={(onOk, onCancel) => {
                            return (
                              <TransactionBlockchainTransactionHashDialog
                                transaction={transaction}
                                onSave={(response: ApiResponse<Transaction>) => {
                                  this.onSearch();
                                  this.setMode('');
                                  onOk();
                                }}
                                onCancel={() => {
                                  onCancel();
                                  this.setMode('');
                                }}/>
                            );
                          }}
                          renderTrigger={(onShow) => {
                            return (
                              <Button
                                bsStyle="danger"
                                bsSize="xs"
                                onClick={() => {
                                  this.setMode('resolving-blockchain-failed');
                                  onShow();
                                }}>Blockchain Failed</Button>
                            );
                          }}/>

                      }
                      {
                        transaction.isFixPaymentAllowed() &&
                        <Confirmable
                          renderConfirm={(onOk, onCancel) => {
                            return (
                              <TransactionFixPaymentDialog
                                transaction={transaction}
                                onSave={(response: ApiResponse<Transaction>) => {
                                  this.onSearch();
                                  this.setMode('');
                                  onOk();
                                }}
                                onCancel={() => {
                                  onCancel();
                                  this.setMode('');
                                }}/>
                            );
                          }}
                          renderTrigger={(onShow) => {
                            return (
                              <Button
                                bsStyle="danger"
                                bsSize="xs"
                                onClick={() => {
                                  this.setMode('fix-payment-failed');
                                  onShow();
                                }}>Fix Payment</Button>
                            );
                          }}/>

                      }
                    </td>
                    <td className="text-right">
                      <FontAwesome name={!isExpanded ? 'chevron-right' : 'chevron-down'}/>
                    </td>
                  </tr>
                  {
                    isExpanded && transaction.payments?.map((payment, p) => {
                      let key = i + '-' + p;
                      return (
                        <tr key={key}>
                          <td></td>
                          <td>
                            <div className="paddingLeft20">
                              <div>{payment.method?.type?.name}</div>
                              <div className="text-muted">{payment.intentId}</div>
                              {payment.exceptionMessage && <div className="text-muted">{payment.exceptionMessage}</div>}
                            </div>
                          </td>
                          <td className="text-center"></td>
                          <td className="text-right">{Str.formatNumber(payment.amount, 2, '.', ',')}</td>
                          <td className="text-right">
                            <StatusButton
                              id={'status-' + key}
                              value={payment.status}/>
                          </td>
                          <td className="text-right"></td>
                        </tr>
                      );
                    })
                  }
                </React.Fragment>
              );
            }}
          />

        </div>

      </div>

    );

  }

}

// ................................................................................................................................................


type TransactionBlockchainTransactionHashDialogProps = {
  transaction: Transaction;
  onSave: (response: ApiResponse<Transaction>) => void;
  onCancel: () => void;
};

const TransactionBlockchainTransactionHashDialog = (props: TransactionBlockchainTransactionHashDialogProps) => {

  let [hash, setHash] = useState('');
  let [globalMessage, setGlobalMessage] = useState<string | null | undefined>(null);
  let [globalMessageSeverity, setGlobalMessageSeverity] = useState<string | null | undefined>(null);
  let transaction = props.transaction;

  let onSave = () => {
    transaction.modifyBlockchainTransactionHash(hash, {
      next: response => {
        props.onSave(response);
      },
      error: response => {
        setGlobalMessage(response.resource?.message);
        setGlobalMessageSeverity(response.resource?.severity);
      }
    }, null);
  }


  return (

    <Confirm
      className="big"
      title="Enter Blockchain Transaction Hash"
      okButtonLabel="Confirm"
      onOk={onSave}
      onCancel={props.onCancel}
      isOkEnabled={!!hash && hash.trim() !== ''}>

      <div className="text-left">

        <GlobalMessage message={globalMessage} type={globalMessageSeverity}/>

        <div className="row">
          <div className="col-md-10">
            <InputText
              id="subscribableBlockchainTransactionHash"
              label="Item Blockchain Transaction Hash"
              value={transaction.subscribableBlockchainTransactionHash}
              disabled/>
          </div>
          <div className="col-md-2">
            <InputText
              id="subscribableBlockchainId"
              label="Id"
              value={transaction.subscribableBlockchainId}
              disabled/>
          </div>
        </div>

        <InputText
          id="walletPayerName"
          label="Wallet Payer"
          value={transaction.walletPayerName}
          disabled/>

        <InputText
          id="walletAddress"
          label="Wallet Address"
          value={transaction.walletPublicKey}
          disabled/>

        <InputText
          id="hash"
          label="Blockchain Transaction Hash"
          value={hash}
          onChange={e => {
            setHash(e.target.value);
          }}
          required/>

      </div>

    </Confirm>

  );

}

// ................................................................................................................................................


type TransactionFixPaymentDialogProps = {
  transaction: Transaction;
  onSave: (response: ApiResponse<Transaction>) => void;
  onCancel: () => void;
};

const TransactionFixPaymentDialog = (props: TransactionFixPaymentDialogProps) => {

  let [request, setRequest] = useState(new TransactionFixPaymentRequest());
  let [dirtyTimestamp, setDirtyTimestamp] = useState<number | null>(Date.now());
  let [globalMessage, setGlobalMessage] = useState<string | null | undefined>(null);
  let [globalMessageSeverity, setGlobalMessageSeverity] = useState<string | null | undefined>(null);
  let transaction = props.transaction;

  let onSave = () => {
    transaction.fixPayment(request, {
      next: response => {
        props.onSave(response);
      },
      error: response => {
        setGlobalMessage(response.resource?.message);
        setGlobalMessageSeverity(response.resource?.severity);
      }
    }, null);
  }

  let onChange: any = Model.update(request, setDirtyTimestamp);

  return (

    <Confirm
      className="big"
      title="Enter Fix Payment Details"
      okButtonLabel="Confirm"
      onOk={onSave}
      onCancel={props.onCancel}>

      <div className="text-left">

        <GlobalMessage message={globalMessage} type={globalMessageSeverity}/>

        <InputText
          id="request.creditCardPaymentMethodKey"
          label="Credit Card Payment Method Key"
          value={request.creditCardPaymentMethodKey}
          onChange={onChange}
          required/>

        <div className="row">
          <div className="col-md-6">
            <InputText
              id="request.creditCardIntentId"
              label="Credit Card Intent Id"
              value={request.creditCardIntentId}
              onChange={onChange}
              required/>
          </div>
          <div className="col-md-6">
            <InputText
              id="request.creditCardAmount"
              label="Credit Card Amount"
              format="currency"
              value={request.creditCardAmount}
              onChange={onChange}
              required/>
          </div>
        </div>

        <br/>
        <p>If the payment we are fixing had consumed app credits, enter the description and amount below. Otherwise, leave blank.</p>

        <div className="row">
          <div className="col-md-6">
            <InputText
              id="request.appCreditDescription"
              label="App Credit Description"
              value={request.appCreditDescription}
              onChange={onChange}/>
          </div>
          <div className="col-md-6">
            <InputText
              id="request.appCreditAmount"
              label="App Credit Amount"
              format="currency"
              value={request.appCreditAmount}
              onChange={onChange}/>
          </div>
        </div>

      </div>

    </Confirm>

  );

}
