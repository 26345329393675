import React from 'react';
import {ApiResponse, Button, Form, FullScreenModal, GlobalMessage, InputInlineEditor, InputText, InputTextArea} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import ApiIndex from "../models/ApiIndex";
import Divider from "../components/Divider";
import Partner from "../models/Partner";
import {acceptedFileMimeTypes} from "../utils/acceptedFileMimeTypes";
import PartnerType from "../models/PartnerType";
import NavigationPanel from "../components/NavigationPanel";
import PremiumContentConfigurationPanel from "../premium/PremiumContentConfigurationPanel";

export type PartnerDialogProps = DialogProps & {
  partner: Partner;
  fields: string | null;
  onSave: (response: ApiResponse<Partner>) => void;
  onCancel: () => void;
};

export type PartnerDialogState = DialogState & {
  partner: Partner;
};

export default class PartnerDialog extends Dialog<PartnerDialogProps, PartnerDialogState> {

  constructor(props: PartnerDialogProps) {
    super(props);
    this.setInitialState({
      partner: this.props.partner
    });
  }

  onSelectType(type: PartnerType) {
    let partner = this.state.partner;
    partner.type = type;
    this.setState({
      partner: partner
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let partner = this.state.partner;
      if (!partner.key) {
        this.state.index?.createPartner(partner, {
          next: this.onSave,
          error: this.onApiError
        }, this.props.fields);
      }
      else {
        partner.save({
          next: this.onSave,
          error: this.onApiError
        }, this.props.fields);
      }
    }
  }

  onSave(response: ApiResponse<Partner>) {
    this.enable();
    this.props.onSave(response);
  }

  onCancel() {
    this.enable();
    this.props.onCancel();
  }

  renderWithIndexStep1(index: ApiIndex, partner: Partner) {

    return (

      <div>

        <FullScreenModal title="New Partner" onHide={this.onCancel}>

          <p className="lead">To add a new partner to BTX, please select a type.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          {
            index.partnerTypes?.map((partnerType: PartnerType, p: number) => {
              return (
                <NavigationPanel
                  key={p}
                  title={partnerType.name!}
                  onClick={() => {
                    this.onSelectType(partnerType);
                  }}/>
              );
            })
          }

        </FullScreenModal>

      </div>

    );

  }

  renderWithIndexStep2(index: ApiIndex, partner: Partner) {

    return (

      <div>

        <FullScreenModal title={'Edit ' + partner.type?.name} onHide={this.onCancel}>

          <p className="lead">Please enter the details of this {partner.type?.name?.toLowerCase()} below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <InputText
                id="partner.name"
                label="Partner Name"
                help="The name of the partner."
                value={partner.name}
                error={this.state.errors?.['request.name']}
                onChange={this.onFieldChange}
                className="lg"
                required/>

              <Divider/>

              <InputText
                id="partner.tagline"
                label="Tagline"
                help="A short tagline to show in search results."
                value={partner.tagline}
                error={this.state.errors?.['request.tagline']}
                onChange={this.onFieldChange}
                required/>

              <InputTextArea
                id="partner.summaryDescription"
                label="Summary Description"
                help="A short one paragraph summary description without formatting to display alongside the profile of this partner."
                value={partner.summaryDescription}
                error={this.state.errors?.['request.summaryDescription']}
                onChange={this.onFieldChange}/>

              <InputInlineEditor
                tools="traditional"
                id="partner.profile.body"
                elementId="dialog-overview"
                label="Overview"
                help="A freeform rich content overview of this partner comprising of text, images and file attachments."
                value={partner.profile?.body}
                error={this.state.errors?.['request.overview.body']}
                uploadFileAcceptedMimeTypes={acceptedFileMimeTypes}
                onChange={this.onFieldChange}/>

              <Divider/>

              <div className="row">
                <div className="col-md-12">
                  <InputText
                    id="partner.address.street"
                    label="Street"
                    value={partner.address.street}
                    error={this.state.errors?.['request.address.street']}
                    onChange={this.onFieldChange}/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="partner.address.suburb"
                    label="Suburb"
                    value={partner.address.suburb}
                    error={this.state.errors?.['request.address.suburb']}
                    onChange={this.onFieldChange}/>
                </div>
                <div className="col-md-3">
                  <InputText
                    id="partner.address.state"
                    label="State"
                    value={partner.address.state}
                    error={this.state.errors?.['request.address.state']}
                    onChange={this.onFieldChange}/>
                </div>
                <div className="col-md-3">
                  <InputText
                    id="partner.address.postcode"
                    label="Postcode"
                    value={partner.address.postcode}
                    error={this.state.errors?.['request.address.postcode']}
                    onChange={this.onFieldChange}/>
                </div>
                <div className="col-md-12">
                  <InputText
                    id="partner.address.country"
                    label="Country"
                    value={partner.address!.country}
                    error={this.state.errors?.['request.address.country']}
                    onChange={this.onFieldChange}/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="partner.emailAddress"
                    label="Email Address"
                    value={partner.emailAddress}
                    error={this.state.errors?.['request.emailAddress']}
                    onChange={this.onFieldChange}/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="partner.phoneNumber"
                    label="Phone Number"
                    value={partner.phoneNumber}
                    error={this.state.errors?.['request.phoneNumber']}
                    onChange={this.onFieldChange}/>
                </div>
              </div>

            </Panel>

            <PremiumContentConfigurationPanel
              index={index}
              prefix="partner"
              entity={partner}
              title={null}
              description={null}
              applePremiumContentDefaultProductId={undefined}
              applePremiumContentDefaultTier={index.applePremiumContentDefaultTierForPartner}
              googlePremiumContentDefaultProductId={undefined}
              googlePremiumContentDefaultTier={index.googlePremiumContentDefaultTierForPartner}
              errors={this.state.errors}
              onFieldChange={this.onFieldChange}/>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Save Changes</Button>
              <Button
                onClick={this.onCancel}
                bsSize="large">Cancel</Button>
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

  renderWithIndex(index: ApiIndex) {

    let partner = this.state.partner;

    return <>

      {!partner.type && this.renderWithIndexStep1(index, partner)}
      {partner.type && this.renderWithIndexStep2(index, partner)}

    </>;

  }

}