import React from 'react';
import {ApiSearchResponse, Button, GlobalMessage, SearchResult} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import ApiIndex from "../models/ApiIndex";
import Collectible, {COLLECTIBLE_STANDARD_FIELDS} from "../models/Collectible";
import SectionHeader from "../components/SectionHeader";
import Routes from "../Routes";
import CollectibleSearchRequest from "../models/CollectibleSearchRequest";
import BlockchainMintableActualsTable from "../components/BlockchainMintableActualsTable";

let FIELDS = COLLECTIBLE_STANDARD_FIELDS;

export type CollectibleActualsPageProps = PageProps & {};

export type CollectibleActualsPageState = PageState & {
  collectible?: Collectible | null | undefined;
  actuals?: SearchResult<Collectible> | null;
};

export default class CollectibleActualsPage extends Page<CollectibleActualsPageProps, CollectibleActualsPageState> {

  constructor(props: CollectibleActualsPageProps) {
    super(props);
    this.setInitialState({
      collectible: null,
    });
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Collectible');
    this.loadCollectible();
  }

  loadCollectible() {
    this.state.index?.findCollectibleByKey(this.props.params.key, {
      next: response => {
        this.setCollectible(response, () => {
          this.searchActuals(1);
        });
      },
      error: this.onApiError
    }, FIELDS);
  }

  searchActuals(page: number) {
    let searchRequest = new CollectibleSearchRequest();
    searchRequest.page = page - 1;
    this.state.collectible?.searchActuals(searchRequest, {
      next: (response: ApiSearchResponse<Collectible>) => {
        let actuals = response.resource;
        this.setActuals(actuals);
      },
      error: this.onApiError
    }, null);
  }

  onBack() {
    this.navigate(Routes.admin.collectible(this.props.params.key));
  }

  setCollectible(response: ApiSearchResponse<Collectible>, callback?: () => void) {
    this.setState({
      collectible: response.resource
    }, callback);
  }

  setActuals(collectibles: SearchResult<Collectible>) {
    this.setState({
      actuals: collectibles
    });
  }

  renderWithIndex(index: ApiIndex) {

    let collectible = this.state.collectible;
    let actuals = this.state.actuals;

    if (!collectible) {
      return <></>;
    }

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity} fieldErrors={(this.state.errors as any)._}/>

          <PageHeader
            title={collectible.name}
            lead={
              <>
                The actual instances of this collectible are shown below. Actual instances are what users buy. The characteristics
                and metadata of an actual instance are the same as the template from which it is created from.
              </>
            }/>

          <SectionHeader
            title="Collectible Actuals"
            actions={
              <>
                <Button
                  icon="chevron-left"
                  iconPosition="left"
                  bsStyle="default"
                  bsSize="xs"
                  className="pull-right"
                  onClick={this.onBack}>Back To Template</Button>
              </>
            }/>

          <BlockchainMintableActualsTable
            actuals={actuals}
            onPaginate={this.searchActuals}/>

        </div>

      </div>

    );

  }
}
