import React from "react";
import {ApiSearchResponse, Component, InputCheckbox, InputSelect, InputText, SearchResult, Str, TableHeaderCell} from "startupbox-react";
import ApiIndex from "./ApiIndex";
import Model from "../Model";
import Membership from "./Membership";
import MembershipSearchRequest from "./MembershipSearchRequest";

export default class UserImportRunUser {

  firstName?: string;
  middleName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  australianResidencyDeclared?: boolean;
  membership?: Membership;

  // ................................................................................................................................................

  static buildNew() {
    return new UserImportRunUser();
  }

  getCols() {

    // top level attributes
    return [
      new UserImportRunUserCols(this, 'First Name', 'firstName', 'input-text'),
      new UserImportRunUserCols(this, 'Middle Name', 'middleName', 'input-text'),
      new UserImportRunUserCols(this, 'Last Name', 'lastName', 'input-text'),
      new UserImportRunUserCols(this, 'Email Address', 'emailAddress', 'input-text'),
      new UserImportRunUserCols(this, 'Phone Number', 'phoneNumber', 'input-text'),
      new UserImportRunUserCols(this, 'Australian Resident', 'australianResidencyDeclared', 'input-checkbox'),
      new UserImportRunUserCols(this, 'Membership', 'membership', 'input-membership'),
    ];

  }

}

// ................................................................................................................................................

export type UserImportRunUserColType = 'input-text' | 'input-checkbox' | 'input-membership';

export class UserImportRunUserCols {

  user: UserImportRunUser;
  label: string;
  fieldName: keyof UserImportRunUser | string;
  type: UserImportRunUserColType;

  constructor(user: UserImportRunUser, label: string, fieldName: keyof UserImportRunUser | string, type: UserImportRunUserColType) {
    this.user = user;
    this.label = label;
    this.fieldName = fieldName;
    this.type = type;
  }

  resolveClassName() {
    return 'col-' + this.type + ' col-' + Str.replaceAll(this.label, ' ', '-').toLowerCase();
  }

  renderHeaderCell(c: number) {
    return (
      <TableHeaderCell
        key={c}
        label={this.label}
        className={this.resolveClassName()}/>
    );
  }

  renderUserCell(index: ApiIndex, errors: { [key: string]: string } | undefined, memberships: SearchResult<Membership> | null, row: number, col: number, prefix: string, onFieldChange: ((e: Event, callback?: () => void) => void) | undefined) {

    let fieldName = this.fieldName;
    let errorKey = 'request.users[' + row + '].' + fieldName;
    let value: any = Model.getFieldValue(this.user, fieldName);
    let field: any = 'Type not implemented: ' + this.type;
    let isReadOnly = !onFieldChange;

    if (this.type === 'input-text') {
      field = <InputText
        id={prefix + fieldName}
        value={value}
        error={errors ? errors[errorKey] : null}
        onChange={onFieldChange}
        readOnly={isReadOnly}/>;
    }
    else if (this.type === 'input-checkbox') {
      field = <InputCheckbox
        id={prefix + fieldName}
        value={value}
        onChange={onFieldChange}
        readOnly={isReadOnly}/>;
    }
    else if (this.type === 'input-membership') {
      field = <MembershipSelect
        index={index}
        id={prefix + fieldName}
        value={value}
        memberships={memberships}
        onFieldChange={onFieldChange}
        isReadOnly={isReadOnly}/>;
    }

    return (
      <td key={prefix + fieldName} className={this.resolveClassName()}>
        {field}
      </td>
    );

  }

}

export type MembershipSelectProps = {
  index?: ApiIndex;
  id: string;
  value: Membership;
  memberships: SearchResult<Membership> | null;
  isReadOnly: boolean;
  onFieldChange: ((e: Event, callback?: () => void) => void) | undefined;
};

export type MembershipSelectState = {
  memberships: SearchResult<Membership> | null;
}

export class MembershipSelect extends Component<MembershipSelectProps, MembershipSelectState> {

  constructor(props: MembershipSelectProps) {
    super(props);
    this.setInitialState({
      memberships: props.value ? {values:[props.value]} : null
    })
  }

  loadMemberships(keywords: string) {
    let membershipSearchRequest = new MembershipSearchRequest();
    membershipSearchRequest.name = keywords;
    membershipSearchRequest.size = 10;
    this.props.index?.searchMemberships(membershipSearchRequest, {
      next: this.setMemberships,
      error: this.onApiError
    }, null);
  }

  setMemberships(response: ApiSearchResponse<Membership>) {
    this.setState({
      memberships: response.resource
    })
  }

  onSuggest(keywords: string) {
    this.loadMemberships(keywords);
  }

  render() {
    return (
      <InputSelect
        id={this.props.id}
        value={this.props.value}
        options={this.state.memberships ? this.state.memberships.values : this.props.memberships?.values}
        onChange={this.props.onFieldChange}
        enhanced={{
          clearable: true,
          onInputChange: this.onSuggest
        }}
        readOnly={this.props.isReadOnly}/>
    );
  }
}
