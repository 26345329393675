import BlockchainAttributeType from "./BlockchainAttributeType";

export default class BlockchainAttribute {

  key?: string;

  name?: string;
  stringValue?: string;
  type?: BlockchainAttributeType;

}
