import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, Confirmable, FileWithUpload, GlobalMessage, ImageWithUpload, InputDatePicker, InputInlineEditor, InputText, InputWrap, StatusButton, VideoWithUpload} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from './PartnerAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Partner from "../models/Partner";
import Pack, {PACK_STANDARD_FIELDS} from "../models/Pack";
import BlockchainMintStatus from "../models/BlockchainMintStatus";
import Routes from "../Routes";
import PackStatus from "../models/PackStatus";
import Panel from "../components/Panel";
import PackDialog from "../pack/PackDialog";
import PackBadgeDialog from "../pack/PackBadgeDialog";
import Divider from "../components/Divider";
import Subscribable from "../models/Subscribable";
import SectionHeader from "../components/SectionHeader";
import PackAddItemDialog from "../pack/PackAddItemDialog";
import BlockchainMintablePanel from "../blockchain/BlockchainMintablePanel";
import BlockchainMintable from "../models/BlockchainMintable";
import PackNameDialog from "../pack/PackNameDialog";
import PackMediaDialog from "../pack/PackMediaDialog";

let FIELDS = PACK_STANDARD_FIELDS;

export type PartnerPackPageProps = PartnerAbstractPageProps & {};

export type PartnerPackPageState = PartnerAbstractPageState & {
  pack?: Pack | null | undefined;
}

export default class PartnerPackPage extends PartnerAbstractPage<PartnerPackPageProps, PartnerPackPageState> {

  constructor(props: PartnerPackPageProps) {
    super(props);
    this.setInitialState({
      pack: null,
    });
  }

  onLoadPartner(partner: Partner) {
    this.loadPack();
  }

  loadPack() {
    let partner = this.state.partner;
    partner?.findPackByKey(this.props.params.packKey, {
      next: this.setPack,
      error: this.onApiError
    }, FIELDS);
  }

  onReloadPack(response: ApiResponse<BlockchainMintable>) {
    this.setPack(response as ApiResponse<Pack>);
  }

  onSave(response: ApiResponse<Pack>) {
    this.setPack(response);
  }

  onDelete() {
    this.navigate(Routes.partner.packs(this.state.partner?.key!))
  }

  onChangeFeatured(option: any) {
    this.state.pack?.modifyFeatured(option.key === 'true', {
      next: this.setPack,
      error: this.onApiError
    }, FIELDS);
  }

  onChangeStatus(status: PackStatus) {
    this.state.pack?.modifyStatus(status, {
      next: this.setPack,
      error: this.onApiError
    }, FIELDS);
  }

  onChangeMintStatus(mintStatus: BlockchainMintStatus) {
    this.state.pack?.modifyMintStatus(mintStatus, {
      next: this.setPack,
      error: this.onApiError
    }, FIELDS);
  }

  setPack(response: ApiSearchResponse<Pack>) {
    this.setState({
      pack: response.resource
    })
  }

  onRemoveItem(subscribable: Subscribable) {
    this.state.pack?.removeItem(subscribable, {
      next: this.setPack,
      error: this.onApiError
    }, FIELDS);
  }

  renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

    let pack = this.state.pack;

    if (!pack) {
      return <></>;
    }

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title={pack.name}
            lead={
              <>
                The details of this pack is shown below. This pack is currently <strong>{pack!.status!.name}</strong> and <strong>{pack!.mintStatus!.name}</strong>. Please note that
                users can only see packs in the Marketplace if the pack is both public and minted.
              </>
            }/>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity} fieldErrors={(this.state.errors as any)._}/>

          <DetailsPanel
            index={index}
            partner={partner}
            pack={pack}
            onSave={this.onSave}
            onDelete={this.onDelete}
            onChangeFeatured={this.onChangeFeatured}
            onChangeStatus={this.onChangeStatus}
            onReloadAfterMinting={this.onReloadPack}
            onChangeMintStatus={this.onChangeMintStatus}/>

          <SectionHeader
            title="Items"
            description="The items in this pack are shown below. If the pack is not yet minted, you may add or remove items from it."/>

          {
            pack.items?.map((item, i) => {
              return (
                <ItemPanel
                  key={i}
                  index={index} item={item} pack={pack!}
                  onDelete={pack!.isRemoveItemAllowed() ? this.onRemoveItem : null}/>
              );
            })
          }

          {
            pack.isAddItemAllowed() &&
            <div>
              <Confirmable
                renderConfirm={(onOk, onCancel) => {
                  return (
                    <PackAddItemDialog
                      index={index}
                      pack={pack!}
                      onSave={(response: ApiResponse<Pack>) => {
                        this.setState({
                          pack: response.resource
                        });
                        onOk();
                      }}
                      onCancel={onCancel}/>
                  );
                }}
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="plus"
                      bsStyle="primary"
                      bsSize="xs"
                      onClick={onShow}>Add Item</Button>
                  );
                }}/>
            </div>
          }

        </div>

      </div>

    );

  }

};

// ................................................................................................................................................

const DetailsPanel = (props: {
  index: ApiIndex,
  partner: Partner,
  pack: Pack,
  onSave: (response: ApiResponse<Pack>) => void,
  onDelete: () => void,
  onChangeFeatured: (option: any) => void,
  onChangeStatus: (status: PackStatus) => void,
  onReloadAfterMinting: (response: ApiResponse<BlockchainMintable>) => void,
  onChangeMintStatus: (status: { key: string }) => void
}) => {

  let index = props.index;
  let partner = props.partner;
  let pack = props.pack;
  let onSave = props.onSave;
  let onDelete = props.onDelete;
  let onChangeFeatured = props.onChangeFeatured;
  let onChangeStatus = props.onChangeStatus;

  let recomputeRemainingCount = (e: any) => {
    e.preventDefault();
    pack.modifyRemainingCount({
      next: onSave,
      error: null
    }, FIELDS);
  };

  return (

    <Panel>

      {
        pack.isModifyAllowed() &&
        <Confirmable
          renderConfirm={(onOk, onCancel) => {
            return (
              <PackDialog
                partner={partner}
                pack={pack}
                onSave={(response) => {
                  onSave(response);
                  onOk();
                }}
                onDelete={() => {
                  onDelete();
                  onOk();
                }}
                onCancel={onCancel}/>
            );
          }}
          renderTrigger={(onShow) => {
            return (
              <Button
                icon="external-link"
                bsStyle="primary"
                bsSize="xs"
                className="pull-right"
                onClick={onShow}>Edit</Button>
            );
          }}/>
      }

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="pack.name"
            label="Pack Name"
            value={pack.name}
            readOnly/>
        </div>
        <div className="col-md-6">
          {
            !pack.isModifyAllowed() && pack.isModifyNameAllowed() &&
            <Confirmable
              renderConfirm={(onOk, onCancel) => {
                return (
                  <PackNameDialog
                    pack={pack}
                    onSave={(response) => {
                      onSave(response);
                      onOk();
                    }}
                    onCancel={onCancel}/>
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <Button
                    icon="external-link"
                    bsStyle="primary"
                    bsSize="xs"
                    className="pull-right"
                    onClick={onShow}>Edit</Button>
                );
              }}/>
          }
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="pack.badgeLabel"
            label="Badge"
            value={pack.badgeLabel}
            readOnly/>
        </div>
        <div className="col-md-6">
          {
            !pack.isModifyAllowed() && pack.isModifyBadgeAllowed() &&
            <Confirmable
              renderConfirm={(onOk, onCancel) => {
                return (
                  <PackBadgeDialog
                    pack={pack}
                    onSave={(response) => {
                      onSave(response);
                      onOk();
                    }}
                    onCancel={onCancel}/>
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <Button
                    icon="external-link"
                    bsStyle="primary"
                    bsSize="xs"
                    className="pull-right"
                    onClick={onShow}>Edit</Button>
                );
              }}/>
          }
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputDatePicker
            id="pack.startAt"
            label="Start Date"
            value={pack.startAt}
            dateFormat="DD/MM/YYYY"
            readOnly/>
        </div>
        <div className="col-xs-6 text-right">
          <InputDatePicker
            id="pack.endAt"
            label="End Date"
            renderReadOnlyFn={() => 'No End Date'}
            dateFormat="DD/MM/YYYY"
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputWrap id="numberOfUnits" label="Number Of Units">
            <div>
              {pack.getCountText()} {pack.isModifyRemainingCountAllowed() && <a href="/" className="marginLeft5" onClick={recomputeRemainingCount}>Recompute</a>}
            </div>
          </InputWrap>
        </div>
        <div className="col-xs-6 text-right">
          <InputText
            id="pack.unitPrice"
            label="Unit Price"
            value={pack.unitPrice}
            format="currency"
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="pack.limitPerUser"
            label="Limit per User"
            value={pack.limitPerUser ? pack.limitPerUser : 'Unlimited'}
            readOnly/>
        </div>
        <div className="col-md-6 text-right">
          <InputText
            id="pack.paymentAccountProductId"
            label="Stripe Product Id"
            value={pack.paymentAccountProductId ? pack.paymentAccountProductId : 'Not Created Yet'}
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <InputWrap id="featured" label="Featured">
            <div>
              <StatusButton
                id="featured"
                value={pack.getFeaturedOption()}
                options={pack.getFeaturedOptions()}
                onEdit={pack.isModifyFeaturedAllowed() ? onChangeFeatured : null}/>
            </div>
          </InputWrap>
        </div>
        <div className="col-xs-6 text-right">
          <InputWrap id="status" label="Status">
            <div>
              <StatusButton
                id="status"
                value={pack.status}
                options={index.packStatuses}
                onEdit={pack.isModifyStatusAllowed() ? onChangeStatus : null}/>
            </div>
          </InputWrap>
        </div>
      </div>

      <SectionHeader
        title="Media"
        divider
        actions={
          <>
            {
              !pack.isModifyAllowed() && pack.isModifyMediaAllowed() &&
              <Confirmable
                renderConfirm={(onOk, onCancel) => {
                  return (
                    <PackMediaDialog
                      pack={pack}
                      onSave={(response) => {
                        onSave(response);
                        onOk();
                      }}
                      onCancel={onCancel}/>
                  );
                }}
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="external-link"
                      bsStyle="primary"
                      bsSize="xs"
                      className="pull-right"
                      onClick={onShow}>Edit</Button>
                  );
                }}/>
            }
          </>
        }/>

      <ImageWithUpload
        id="pack.coverImage"
        label="Cover Image"
        files={pack.coverImage ? [pack.coverImage] : []}
        className="images-across-1"
        lightbox
        readOnly/>

      <VideoWithUpload
        id="pack.promotionalVideos"
        label="Promotional Videos"
        multiple
        files={pack.promotionalVideos || []}
        readOnly/>

      <ImageWithUpload
        id="pack.promotionalImages"
        label="Promotional Images"
        multiple
        lightbox
        files={pack.promotionalImages || []}
        readOnly/>

      <SectionHeader title="Terms" divider/>

      {
        pack.hasTerms() ?
          <InputInlineEditor
            id="pack.terms.body"
            elementId="details-terms"
            label="Terms"
            value={pack.terms?.body}
            readOnly/> :
          <InputText
            id="pack.terms.body"
            label="Terms"
            value={null}
            readOnly/>
      }

      <FileWithUpload
        id="pack.pdsFiles"
        label="Product Disclosure Statement (PDS)"
        multiple
        files={pack.pdsFiles || []}
        readOnly/>

      <Divider/>

      <BlockchainMintablePanel
        type="Pack"
        entity={pack}
        entityFields={FIELDS}
        statuses={index.mintChooseableStatuses}
        errors={undefined}
        onReload={props.onReloadAfterMinting}
        onChangeMintStatus={props.onChangeMintStatus}/>

    </Panel>

  );

}

// ................................................................................................................................................

const ItemPanel = (props: {
  index: ApiIndex,
  item: Subscribable,
  pack: Pack,
  onDelete: ((subscribable: Subscribable) => void) | null,
}) => {

  let item = props.item;

  let onDelete = () => {
    props.onDelete?.(item);
  };

  return (

    <Panel>

      {
        props.onDelete &&
        <Button
          bsStyle="primary"
          bsSize="xs"
          className="pull-right"
          onClick={onDelete}>Remove</Button>
      }

      <InputWrap
        id="item.name"
        label={item.type?.name!}>
        <div className="read-only">
          <div>{item.name}</div>
          <div className="text-muted">{item.shortDescription}</div>
        </div>
      </InputWrap>

    </Panel>

  );

};