import {SalesChannelKey} from "./SalesChannel";

export default class SubscribableSearchRequest {

  keywords?: string;
  key?: string;
  targetEntityKey?: string;
  type?: string;
  partnerKey?: string;
  salesChannel?: SalesChannelKey;
  inPack?: boolean;

  sort?: string;
  page?: string | number;
  size?: string | number;

  unitPriceMin?: number;
  unitPriceMax?: number;
  sex?: string;
}
