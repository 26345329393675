import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, Confirm, Confirmable, GlobalMessage} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from './PartnerAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Partner from "../models/Partner";
import BlockchainMintStatus from "../models/BlockchainMintStatus";
import Routes from "../Routes";
import BlockchainMintable from "../models/BlockchainMintable";
import BlockchainMintablePanel from "../blockchain/BlockchainMintablePanel";
import SectionHeader from "../components/SectionHeader";
import Membership, {MEMBERSHIP_STANDARD_FIELDS} from "../models/Membership";
import MembershipStatus from "../models/MembershipStatus";
import PartnerMembershipGiftToUserDialog from "./PartnerMembershipGiftToUserDialog";
import MembershipDetailsPanel from "../membership/MembershipDetailsPanel";

let FIELDS = MEMBERSHIP_STANDARD_FIELDS;

export type PartnerMembershipPageProps = PartnerAbstractPageProps & {};

export type PartnerMembershipPageState = PartnerAbstractPageState & {
    membership?: Membership | null | undefined;
}

export default class PartnerMembershipPage extends PartnerAbstractPage<PartnerMembershipPageProps, PartnerMembershipPageState> {

    constructor(props: PartnerMembershipPageProps) {
        super(props);
        this.setInitialState({
            membership: null,
        });
    }

    onLoadPartner(partner: Partner) {
        this.loadMembership();
    }

    loadMembership() {
        let partner = this.state.partner;
        partner?.findMembershipByKey(this.props.params.membershipKey, {
            next: this.setMembership,
            error: this.onApiError
        }, FIELDS);
    }

    onReloadMembership(response: ApiResponse<BlockchainMintable>) {
        this.setMembership(response as ApiResponse<Membership>);
    }

    onSave(response: ApiResponse<Membership>) {
        this.setMembership(response);
    }

    onDelete() {
        this.navigate(Routes.partner.collectibles(this.state.partner?.key!))
    }

    onChangeFeatured(option: any) {
        this.state.membership?.modifyFeatured(option.key === 'true', {
            next: this.setMembership,
            error: this.onApiError
        }, FIELDS);
    }

    onChangeStatus(status: MembershipStatus) {
        this.state.membership?.modifyStatus(status, {
            next: this.setMembership,
            error: this.onApiError
        }, FIELDS);
    }

    onChangeMintStatus(mintStatus: BlockchainMintStatus) {
        this.state.membership?.modifyMintStatus(mintStatus, {
            next: this.setMembership,
            error: this.onApiError
        }, FIELDS);
    }

    setMembership(response: ApiSearchResponse<Membership>) {
        this.setState({
            membership: response.resource
        });
    }

    onShowActuals() {
        this.navigate(Routes.partner.membership_actuals(this.state.partner!.key!, this.props.params.membershipKey))
    }

    renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

        let membership = this.state.membership;

        if (!membership) {
            return <></>;
        }

        return (

            <div className="row">

                <div className="col-md-8 col-md-offset-2">

                    <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity} fieldErrors={(this.state.errors as any)._}/>

                    <PageHeader
                        title={membership.name}
                        lead={
                            <>
                                The details of this membership is shown below. This membership is currently <strong>{membership!.status!.name}</strong> and <strong>{membership!.mintStatus!.name}</strong>. Please note that
                                users can only see membership in the Marketplace if the membership is both public and minted.
                            </>
                        }/>

                    <SectionHeader
                        title="Membership"
                        actions={
                            <>
                                {
                                    membership.isSendReviewRequestNotificationAllowed() &&
                                    <Confirmable
                                        renderConfirm={(onOk, onCancel) => {
                                            return (
                                                <Confirm
                                                    title="Send Review Request"
                                                    message="Would you like to send a review request to BTX for this membership to be minted and published on the public blockchain?"
                                                    onOk={() => {
                                                        membership!.sendReviewRequestNotification({
                                                            next: (response) => {
                                                            },
                                                            error: this.onApiError
                                                        }, FIELDS);
                                                        onOk();
                                                    }}
                                                    onCancel={onCancel}/>
                                            );
                                        }}
                                        renderTrigger={(onShow) => {
                                            return (
                                                <Button
                                                    icon="external-link"
                                                    bsStyle="primary"
                                                    bsSize="xs"
                                                    onClick={onShow}>Send Review Request</Button>
                                            );
                                        }}/>
                                }
                                {
                                    membership.isGiftToUserAllowed() &&
                                    <Confirmable
                                        renderConfirm={(onOk, onCancel) => {
                                            return (
                                                <PartnerMembershipGiftToUserDialog
                                                    index={index}
                                                    membership={membership!}
                                                    onSave={(response) => {
                                                        onOk();
                                                        this.setGlobalMessage("Successfully gifted!", "success")
                                                        this.loadMembership();
                                                    }}
                                                    onCancel={onCancel}/>
                                            );
                                        }}
                                        renderTrigger={(onShow) => {
                                            return (
                                                <Button
                                                    icon="external-link"
                                                    bsStyle="primary"
                                                    bsSize="xs"
                                                    onClick={onShow}>Gift to User</Button>
                                            );
                                        }}/>
                                }
                                {
                                    membership.isMinted() &&
                                    <Button
                                        icon="chevron-right"
                                        bsStyle="primary"
                                        bsSize="xs"
                                        onClick={this.onShowActuals}>Show Actuals</Button>
                                }
                            </>
                        }/>

                    <MembershipDetailsPanel
                        index={index}
                        membership={membership}
                        partner={partner}
                        onSave={this.onSave}
                        onDelete={this.onDelete}
                        onChangeStatus={this.onChangeStatus}/>

                    <BlockchainMintablePanel
                        type="Membership"
                        entity={membership}
                        entityFields={FIELDS}
                        statuses={index.mintChooseableStatuses}
                        errors={this.state.errors}
                        onReload={this.onReloadMembership}
                        onChangeMintStatus={this.onChangeMintStatus}/>

                </div>

            </div>

        );

    }

};
