import React from 'react';
import {Alert, Api, Component, DeveloperConsole, Environment, Href, NetworkBar} from 'startupbox-react';
import Context from './app/Context';
import Events from './app/Events';
import Routes from './app/Routes';
import ForgottenPasswordDialog from './app/security/ForgottenPasswordDialog';
import LoginDialog from './app/security/LoginDialog';
import ResetPasswordDialog from './app/security/ResetPasswordDialog';

export default class App extends Component {

  networkRequestCount = 0;
  sectionTitle = '';
  pageTitle = '';

  constructor(props) {
    super(props);
    this.handleMockupMode();
    let resetPasswordToken = this.query('resetPasswordToken');
    this.setInitialState({
      networkRequestCount: 0,
      systemAlertTitle: null,
      systemAlertMessage: null,
      loginDialogVisible: false,
      forgottenPasswordDialogVisible: false,
      resetPasswordDialogVisible: false,
      resetPasswordToken: resetPasswordToken,
      invalidResetPasswordToken: false,
      animationRequired: true
    });
    if (resetPasswordToken) {
      this.onShowResetPasswordDialog();
    }
  }

  componentDidMount() {
    Events.network.start.subscribe(this.onNetworkStart);
    Events.network.end.subscribe(this.onNetworkEnd);
    Events.network.unauthenticated.subscribe(this.onNetworkUnauthorized, false);
    Events.network.unauthorized.subscribe(this.onNetworkUnauthorized, false);
    Events.system.sectionTitle.subscribe(this.setSectionTitle);
    Events.system.pageTitle.subscribe(this.setPageTitle);
    Events.system.alertTitle.subscribe(this.setSystemAlertTitle);
    Events.system.alertMessage.subscribe(this.setSystemAlertMessage);
    Events.system.logout.subscribe(this.onLogout, false);
    Events.app.showLoginDialog.subscribe(this.onShowLoginDialog, false);
    this.load();
  }

  load(e) {
    this.preventDefault(e);
    let href = new Href('/api/1/index');
    Api.get(href)
      .subscribe({
        next: this.onLoad,
        error: this.onApiError
      });
  }

  onLoad(response) {
    Events.app.index.publish(response.resource);
  }

  onNetworkStart() {
    this.networkRequestCount++;
    this.setState({
      networkRequestCount: this.networkRequestCount
    });
  }

  onNetworkEnd() {
    this.networkRequestCount--;
    this.setState({
      networkRequestCount: this.networkRequestCount
    });
  }

  onNetworkUnauthorized(response) {
    if (response && response.url && response.url.indexOf('/login') === -1 && !this.state.loginDialogVisible) {
      this.navigateToDefaultPage();
    }
  }

  isNetworkInProgress() {
    return this.state.networkRequestCount > 0;
  }

  setSectionTitle(sectionTitle) {
    this.sectionTitle = sectionTitle;
    this.setState({
      sectionTitle: sectionTitle
    });
    this.updateWindowTitle(sectionTitle, this.pageTitle);
  }

  setPageTitle(pageTitle) {
    this.pageTitle = pageTitle;
    this.setState({
      pageTitle: pageTitle
    });
    this.updateWindowTitle(this.sectionTitle, pageTitle);
  }

  updateWindowTitle(sectionTitle, pageTitle) {
    let appTitle = Environment.getAppTitle();
    window.document.title = appTitle + (sectionTitle ? ' - ' + sectionTitle : '') + (pageTitle ? ' - ' + pageTitle : '');
  }

  setSystemAlertTitle(value) {
    this.setState({
      systemAlertTitle: value
    });
  }

  setSystemAlertMessage(value) {
    this.setState({
      systemAlertMessage: value
    });
  }

  onDismissSystemAlertMessage() {
    this.setState({
      systemAlertTitle: null,
      systemAlertMessage: null
    });
  }

  onShowLoginDialog(e) {
    if (!this.state.resetPasswordDialogVisible) {
      this.onForceShowLoginDialog(e);
    }
  }

  onForceShowLoginDialog(e) {
    this.preventDefault(e);
    this.setState({
      loginDialogVisible: true,
      forgottenPasswordDialogVisible: false,
      resetPasswordDialogVisible: false,
      invalidResetPasswordToken: false
    });
    this.setAnimationRequired(false);
  }

  onShowForgottenPasswordDialog(e) {
    this.preventDefault(e);
    this.setState({
      loginDialogVisible: false,
      forgottenPasswordDialogVisible: true,
      resetPasswordDialogVisible: false
    });
    this.setAnimationRequired(false);
  }

  onShowResetPasswordDialog(e) {
    this.preventDefault(e);
    setTimeout(() => {
      this.setState({
        loginDialogVisible: false,
        forgottenPasswordDialogVisible: false,
        resetPasswordDialogVisible: true
      });
    }, 0);
    this.setAnimationRequired(false);
  }

  onLogin(response, extraQueryString) {
    Context.setPrincipal(response.resource);
    this.setState({
      loginDialogVisible: false,
      forgottenPasswordDialogVisible: false,
      resetPasswordDialogVisible: false,
    });
    Events.system.login.publish(true);
    this.load();
    this.navigateToDefaultPage(extraQueryString);
  }

  onLogout() {
    Context.setPrincipal(null);
    this.load();
    this.navigate(Routes.main.logout());
  }

  onInvalidResetPasswordToken() {
    this.setState({
      loginDialogVisible: false,
      forgottenPasswordDialogVisible: true,
      resetPasswordDialogVisible: false,
      invalidResetPasswordToken: true
    });
  }

  onResetPassword(response) {
    this.onLogin(response, 'passwordReset=true');
  }

  onCancelResetPassword(e) {
    this.preventDefault(e);
    this.navigateToDefaultPage();
  }

  onCancel() {
    this.setState({
      loginDialogVisible: false,
      forgottenPasswordDialogVisible: false,
      resetPasswordDialogVisible: false
    });
    this.setAnimationRequired(true);
  }

  navigateToDefaultPage(extraQueryString) {
    this.setState({
      loginDialogVisible: false,
      forgottenPasswordDialogVisible: false,
      resetPasswordDialogVisible: false
    });
    this.navigate(Routes.main.home() + (extraQueryString ? '?' + extraQueryString : ''));
  }

  setAnimationRequired(value) {
    setTimeout(() => {
      this.setState({
        animationRequired: value
      });
    }, 300);
  }

  handleMockupMode() {
    let mode = this.query('mockup');
    if (mode) {
      if (mode !== 'none') {
        Context.setMockupMode(mode);
      }
      else {
        Context.setMockupMode(null);
      }
    }
    let mockupActualOrWireframe = App.getMockupMode() || Context.getMockupMode();
    let isMockupActual = mockupActualOrWireframe === 'actual';
    let isMockupWireframe = mockupActualOrWireframe === 'wireframe';
    if (isMockupActual || isMockupWireframe) {
      document.body.classList.add('mockup');
      document.body.classList.add(isMockupActual ? 'actual' : 'wireframe');
    }
  }

  static getMockupMode() {
    let path = window.location.pathname;
    if (path.startsWith('/mockups')) {
      return 'actual';
    }
    else if (path.startsWith('/wireframes')) {
      return 'wireframe';
    }
    else {
      return Context.getMockupMode();
    }
  }

  render() {

    let environment = Environment.current();

    return (

      <div>

        {this.isNetworkInProgress() && <NetworkBar/>}

        <div id="page">

          {
            Context.getMockupMode() &&
            <div className="mockup-title">{document.title}</div>
          }

          {this.props.children}

          {
            this.state.systemAlertMessage &&
            <Alert
              title={this.state.systemAlertTitle || 'Alert'}
              onOk={this.onDismissSystemAlertMessage}
              animate>
              <p>{this.state.systemAlertMessage}</p>
            </Alert>
          }

          {
            this.state.loginDialogVisible &&
            <LoginDialog
              onForgotPassword={this.onShowForgottenPasswordDialog}
              onLogin={this.onLogin}
              onCancel={this.onCancel}
              animate={this.state.animationRequired}/>
          }

          {
            this.state.forgottenPasswordDialogVisible &&
            <ForgottenPasswordDialog
              onRememberedPassword={this.onForceShowLoginDialog}
              onSendResetPasswordInstructions={null}
              onCancel={this.onCancel}
              invalidResetPasswordToken={this.state.invalidResetPasswordToken}
              animate={this.state.animationRequired}/>
          }

          {
            this.state.resetPasswordDialogVisible &&
            <ResetPasswordDialog
              token={this.state.resetPasswordToken}
              onRememberedPassword={this.onForceShowLoginDialog}
              onInvalidResetPasswordToken={this.onInvalidResetPasswordToken}
              onResetPassword={this.onResetPassword}
              onCancel={this.onCancelResetPassword}
              animate={this.state.animationRequired}/>
          }

          {
            environment.debug &&
            <DeveloperConsole
              maxMessageCount={100}/>
          }

        </div>

      </div>

    );

  }

};
