import React from 'react';
import {ApiResponse, Button, Form, FullScreenModal, GlobalMessage, InputText} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import ApiIndex from "../models/ApiIndex";
import Membership, {MEMBERSHIP_STANDARD_FIELDS} from "../models/Membership";

export type MembershipNameDialogProps = DialogProps & {
  membership: Membership;
  onSave: (response: ApiResponse<Membership>) => void;
  onCancel: () => void;
};

export type MembershipNameDialogState = DialogState & {
  membership: Membership;
  name: string;
};

export default class MembershipNameDialog extends Dialog<MembershipNameDialogProps, MembershipNameDialogState> {

  constructor(props: MembershipNameDialogProps) {
    super(props);
    let membership = this.props.membership;
    this.setInitialState({
      name: membership.name
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let membership = this.props.membership;
      membership.modifyName(this.state.name, {
        next: this.onSave,
        error: this.onApiError
      }, MEMBERSHIP_STANDARD_FIELDS);
    }
  }

  isModifyAllowed() {
    let membership = this.props.membership;
    return membership.isModifyNameAllowed();
  }

  onSave(response: ApiResponse<Membership>) {
    this.enable();
    this.props.onSave(response);
  }

  onCancel() {
    this.enable();
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let name = this.state.name;

    return (

      <div>

        <FullScreenModal title="Edit Membership Name" onHide={this.onCancel}>

          <p className="lead">Please enter the name below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled() || !this.isModifyAllowed()}>

            <Panel>

              <InputText
                id="name"
                label="Name"
                value={name}
                error={this.state.errors?.['request.name']}
                onChange={this.onFieldChange}/>

            </Panel>

            <div className="actions">
              {
                this.isModifyAllowed() &&
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  type="submit">Save Changes</Button>
              }
              <Button
                onClick={this.onCancel}
                disabled={false}
                bsSize="large">{this.isModifyAllowed() ? 'Cancel' : 'Close'}</Button>
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
