import Device from "../models/Device";
import Panel from "../components/Panel";
import {Button, InputText} from "startupbox-react";
import React from "react";

type DevicePanelProps = {
  device: Device;
  onView?: (() => void) | null | undefined;
};

const DevicePanel = (props: DevicePanelProps) => {

  let device = props.device;
  let onView = props.onView;

  return (

    <Panel>

      {
        onView &&
        <Button
          icon="chevron-right"
          bsStyle="primary"
          bsSize="xs"
          className="pull-right"
          onClick={onView}>View</Button>
      }

      <div className="row">
        <div className="col-xs-6">
          <InputText
            id="device.brandAndModelName"
            label="Brand and Model"
            value={device?.getBrandAndModelName()}
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <InputText
            id="device.physicalIdentifier"
            label="Physical Identifier"
            value={device?.physicalIdentifier}
            readOnly/>
        </div>
        <div className="col-xs-6 text-right">
          <InputText
            id="device.sessionIdentifier"
            label="Session Identifier"
            value={device?.sessionIdentifier}
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <InputText
            id="device.clientVersion"
            label="Client Version"
            value={device?.clientVersion}
            readOnly/>
        </div>
        <div className="col-xs-6 text-right">
          <InputText
            id="device.platformType"
            label="PlatformType"
            value={device?.platformType?.name}
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <InputText
            id="device.osName"
            label="OS Name"
            value={device?.osName}
            readOnly/>
        </div>
        <div className="col-xs-6 text-right">
          <InputText
            id="device.osVersion"
            label="OS Version"
            value={device?.osVersion}
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <InputText
            id="device.userAgent"
            label="User Agent"
            value={device?.userAgent}
            readOnly/>
        </div>
      </div>

    </Panel>

  );

}

export default DevicePanel;