import React from 'react';
import {ApiResponse, Button, Confirmable, InputInlineEditor, InputText, InputTextArea, InputWrap, StatusButton} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Panel from '../components/Panel';
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from './PartnerAbstractPage';
import PartnerStatus from "../models/PartnerStatus";
import ApiIndex from "../models/ApiIndex";
import Partner from "../models/Partner";
import Divider from "../components/Divider";
import PartnerDialog from "./PartnerDialog";
import Binary from "../models/Binary";

export type PartnerHomePageProps = PartnerAbstractPageProps & {};

export type PartnerHomePageState = PartnerAbstractPageState & {}

export default class PartnerHomePage extends PartnerAbstractPage<PartnerHomePageProps, PartnerHomePageState> {

  constructor(props: PartnerHomePageProps) {
    super(props);
    this.setInitialState({});
  }

  onReload(response: ApiResponse<Partner>) {
    this.setPartner(response);
  }

  onSave(response: ApiResponse<Partner>) {
    this.setPartner(response);
  }

  onChangeAvatar(avatar: Binary | undefined) {
    let partner = this.state.partner;
    if (partner) {
      partner.thumbnailImage = avatar;
      if (!partner.thumbnailImage || partner.thumbnailImage!.externalReference) {
        partner.save({
          next: this.setPartner,
          error: this.onApiError
        }, this.getFields());
      }
    }
  }

  onChangeStatus(status: PartnerStatus) {
    this.state.partner?.modifyStatus(status, {
      next: this.setPartner,
      error: this.onApiError
    }, this.getFields());
  }

  renderProfilePanel(index: ApiIndex, partner: Partner) {

    return (

      <Panel>

        <Confirmable
          renderConfirm={(onOk, onCancel) => {
            return (
              <PartnerDialog
                partner={partner}
                fields={this.getFields()}
                onSave={(response: ApiResponse<Partner>) => {
                  this.onSave(response);
                  onOk();
                }}
                onCancel={onCancel}/>
            );
          }}
          renderTrigger={(onShow) => {
            return (
              <Button
                icon="external-link"
                bsStyle="primary"
                bsSize="xs"
                className="pull-right"
                onClick={onShow}
                disabled={!partner.isModifyAllowed()}>Edit</Button>
            );
          }}/>

        <InputText
          id="partner.name"
          label="Name"
          value={partner.name}
          readOnly/>

        <Divider/>

        <InputText
          id="partner.tagline"
          label="Tagline"
          value={partner.tagline}
          readOnly/>

        {
          partner.summaryDescription &&
          <InputTextArea
            id="partner.summaryDescription"
            label="Summary Description"
            value={partner.summaryDescription}
            readOnly/>
        }

        {
          partner.profile && partner.profile.body &&
          <InputInlineEditor
            id="partner.profile.body"
            elementId="profile"
            label="Profile"
            value={partner.profile.body}
            readOnly/>
        }

        <InputText
          id="partner.address.singleLineAddress"
          label="Address"
          value={partner.address.singleLineAddress}
          readOnly/>

        <div className="row">
          <div className="col-xs-6">
            <InputText
              id="partner.emailAddress"
              label="Email Address"
              value={partner.emailAddress}
              readOnly/>
          </div>
          <div className="col-xs-6 text-right">
            <InputText
              id="partner.phoneNumber"
              label="Phone Number"
              value={partner.phoneNumber}
              className="text-right"
              readOnly/>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-8">
          </div>
          <div className="col-xs-4 text-right">
            <InputWrap id="status" label="Status">
              <div>
                <StatusButton
                  id="status"
                  value={partner.status}
                  options={index.partnerStatuses}
                  onEdit={partner.isModifyStatusAllowed() ? this.onChangeStatus : null}/>
              </div>
            </InputWrap>
          </div>
        </div>

      </Panel>

    );

  }

  renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title={partner.name}
            subTitle="Home"
            avatar={partner.thumbnailImage || null}
            onChangeAvatar={this.onChangeAvatar}
            lead={<>
              {partner.type?.meta?.horsesAllowed && <>Welcome to your {partner.type?.name?.toLowerCase()} home page. From here you can edit your profile, view your horses, post events and engage with your followers.</>}
              {!partner.type?.meta?.horsesAllowed && <>Welcome to your {partner.type?.name?.toLowerCase()} home page. From here you can edit your profile, post events and engage with your followers.</>}
            </>}/>

          {this.renderProfilePanel(index, partner)}

        </div>

      </div>

    );

  }

};
