import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, Confirmable, Form, InputText, SearchResult} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from './PartnerAbstractPage';
import CollectibleSearchRequest from "../models/CollectibleSearchRequest";
import Partner from "../models/Partner";
import ApiIndex from "../models/ApiIndex";
import Collectible, {COLLECTIBLE_LIST_FIELDS} from "../models/Collectible";
import Routes from "../Routes";
import CollectibleDialog from "../collectible/CollectibleDialog";
import BlockchainAttributeGroup from "../models/BlockchainAttributeGroup";
import CollectibleTable from "../collectible/CollectibleTable";

let FIELDS = COLLECTIBLE_LIST_FIELDS;

export type PartnerCollectiblesPageProps = PartnerAbstractPageProps & {};

export type PartnerCollectiblesPageState = PartnerAbstractPageState & {
  collectibleSearchRequest: CollectibleSearchRequest;
  collectibles: SearchResult<Collectible> | null;
}

export default class PartnerCollectiblesPage extends PartnerAbstractPage<PartnerCollectiblesPageProps, PartnerCollectiblesPageState> {

  constructor(props: PartnerCollectiblesPageProps) {
    super(props);
    this.setInitialState({
      collectibleSearchRequest: new CollectibleSearchRequest(),
      collectibles: null,
    });
  }

  onEnter() {
    this.setState({
      collectibleSearchRequest: {
        keywords: this.query('keywords'),
        sort: this.query('sort'),
        page: parseInt(this.query('page') || '0'),
      }
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('keywords', this.state.collectibleSearchRequest.keywords)
      .param('sort', this.state.collectibleSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoadPartner(partner: Partner) {
    this.searchCollectibles(this.state.collectibleSearchRequest.page + 1);
  }

  searchCollectibles(page: number) {
    let partner = this.state.partner;
    if (partner) {
      let collectibleSearchRequest = this.state.collectibleSearchRequest;
      collectibleSearchRequest.page = page - 1;
      partner.searchCollectibles(collectibleSearchRequest, {
        next: this.setCollectibles,
        error: this.onApiError
      }, FIELDS);
    }
  }

  onSave(response: ApiResponse<Collectible>) {
    this.onView(response.resource!);
  }

  onView(collectible: Collectible) {
    let partner = this.state.partner;
    if (partner) {
      this.navigate(Routes.partner.collectible(partner.key!, collectible.key!));
    }
  }

  setCollectibles(response: ApiSearchResponse<Collectible>) {
    this.setState({
      collectibles: response.resource
    })
  }

  renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title={partner.name}
            subTitle="Collectibles"
            lead="This area allows you to manage the collectibles offered for sale for this partner, not tied to a horse. Users of the app may purchase these collectibles to view, own and trade."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="collectibleSearchRequest.keywords"
              placeholder="Keywords"
              value={this.state.collectibleSearchRequest.keywords}
              onChange={this.onFieldChange}
              className="wide"/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              {
                partner.isAddCollectibleAllowed() &&
                <Confirmable
                  renderTrigger={(onShow) => {
                    return (
                      <Button
                        icon="plus"
                        bsStyle="default"
                        className="pull-right"
                        onClick={onShow}>Add</Button>
                    );
                  }}
                  renderConfirm={(onOk, onCancel) => {
                    let collectible: Collectible = new Collectible();
                    collectible.manageableMetadataAttributeGroup = new BlockchainAttributeGroup();
                    return (
                      <CollectibleDialog
                        index={index}
                        partner={partner}
                        collectible={collectible}
                        onSave={(response: ApiResponse<Collectible>) => {
                          this.onSave(response);
                          onOk();
                        }}
                        onDelete={() => {
                          this.searchCollectibles(1);
                          onOk();
                        }}
                        onCancel={onCancel}/>
                    );
                  }}/>
              }
            </span>
          </Form>

          <br/>

          <CollectibleTable
            collectibles={this.state.collectibles}
            sort={this.state.collectibleSearchRequest.sort}
            onReload={this.onEnter}
            onView={this.onView}/>

        </div>

      </div>

    );

  }

};