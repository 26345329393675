import React from 'react';
import User from "../models/User";
import ApiIndex from "../models/ApiIndex";
import Wallet, {WALLET_ENTITLEMENT_FIELDS} from "../models/Wallet";
import UserWalletAbstractPage, {UserWalletAbstractPageProps, UserWalletAbstractPageState} from "./UserWalletAbstractPage";
import {ApiSearchResponse, SearchResult, Str, Table, TableHeaderCell} from "startupbox-react";
import EntitlementSearchRequest from "../models/EntitlementSearchRequest";
import Entitlement, {ENTITLEMENT_STANDARD_FIELDS} from "../models/Entitlement";
import SectionHeader from "../components/SectionHeader";
import Panel from "../components/Panel";

export type UserWalletEntitlementsPageProps = UserWalletAbstractPageProps & {};

export type UserWalletEntitlementsPageState = UserWalletAbstractPageState & {
  entitlements: SearchResult<Entitlement> | null;
};

export default class UserWalletEntitlementsPage extends UserWalletAbstractPage<UserWalletEntitlementsPageProps, UserWalletEntitlementsPageState> {

  constructor(props: UserWalletEntitlementsPageProps) {
    super(props);
    this.activeTab = 'entitlements';
  }

  onLoadUserAndWallet(user: User, wallet: Wallet) {
    this.searchEntitlements(1);
  }

  searchEntitlements(page: number) {
    let entitlementSearchRequest = new EntitlementSearchRequest();
    entitlementSearchRequest.page = page - 1;
    entitlementSearchRequest.sort = 'paymentDate,desc';
    this.state.wallet?.searchEntitlements(entitlementSearchRequest, {
      next: (response: ApiSearchResponse<Entitlement>) => {
        let entitlements = response.resource;
        this.setEntitlements(entitlements);
      },
      error: this.onApiError
    }, ENTITLEMENT_STANDARD_FIELDS);
  }

  setEntitlements(entitlements: SearchResult<Entitlement>) {
    this.setState({
      entitlements: entitlements
    })
  }

  getWalletFields(): string {
    return WALLET_ENTITLEMENT_FIELDS;
  }

  renderWithIndexUserAndWallet(index: ApiIndex, user: User, wallet: Wallet) {

    let entitlements = this.state.entitlements;

    return <>

      <SectionHeader
        title="Entitlements"/>

      <div className="row">
        <div className="col-md-6 col-lg-3 text-center">
          <Panel unpadded>
            <p className="text-muted marginBottom0">Pending</p>
            <h4 className="marginTop5">${Str.formatNumber(wallet.payoutEntitlementPendingAmount, 2, '.', ',')}</h4>
          </Panel>
        </div>
        <div className="col-md-6 col-lg-3 text-center">
          <Panel unpadded>
            <p className="text-muted marginBottom0">Available</p>
            <h4 className="marginTop5">${Str.formatNumber(wallet.payoutEntitlementAvailableAmount, 2, '.', ',')}</h4>
          </Panel>
        </div>
        <div className="col-md-6 col-lg-3 text-center">
          <Panel unpadded>
            <p className="text-muted marginBottom0">Converted</p>
            <h4 className="marginTop5">${Str.formatNumber(wallet.payoutEntitlementConvertedAmount, 2, '.', ',')}</h4>
          </Panel>
        </div>
        <div className="col-md-6 col-lg-3 text-center">
          <Panel unpadded>
            <p className="text-muted marginBottom0">Withdrawn</p>
            <h4 className="marginTop5">${Str.formatNumber(wallet.payoutEntitlementWithdrawnAmount, 2, '.', ',')}</h4>
          </Panel>
        </div>
      </div>

      <Table
        items={entitlements}
        onPaginate={this.searchEntitlements}
        blankMessage="No entitlements found"
        renderHeaderRow={() => {
          return (
            <tr>
              <TableHeaderCell label="Date" className="col-md-2"/>
              <TableHeaderCell label="Description"/>
              <TableHeaderCell label="Amount" className="text-right"/>
            </tr>
          );
        }}
        renderItemRow={(entitlement: Entitlement, e: number) => {
          return (
            <tr key={e} className="clickable" data-key={entitlement.key}>
              <td>{Str.formatDate(entitlement.date, "DD/MM/YYYY")}</td>
              <td>
                <div>{entitlement.subscribableName}{entitlement.actionLabel && <span className="badge badge-primary marginLeft10">{entitlement.actionLabel}</span>}</div>
                <div className="text-muted">{entitlement.payoutDescription}</div>
                <div className="text-muted">{entitlement.type?.name}</div>
              </td>
              <td className="text-right">{Str.formatNumber(entitlement.totalAmount, 2, '.', ',')}</td>
            </tr>
          );
        }}
      />

    </>;

  }

};
