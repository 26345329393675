import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Partner, {PARTNER_STANDARD_FIELDS} from "../models/Partner";
import {ApiResponse} from "startupbox-react";
import ApiIndex from "../models/ApiIndex";
import React from 'react';
import {ApiErrorResponse} from "../models/ApiError";

export type PartnerAbstractPageProps = PageProps & {};

export type PartnerAbstractPageState = PageState & {
  partner?: Partner
};

export default class PartnerAbstractPage<P extends PartnerAbstractPageProps, S extends PartnerAbstractPageState> extends Page<P, S> {

  constructor(props: P) {
    super(props);
    let initialState = {
      partner: new Partner()
    } as S;
    this.setInitialState(initialState);
    this.setPartner = this.setPartner.bind(this);
  }

  getFields() {
    // override this in sub-classes if required
    return PARTNER_STANDARD_FIELDS;
  }

  onLoad() {
    let key = this.props.params.key;
    if (key !== 'add') {
      this.state.index?.findPartnerByKey(key, {
        next: (response: ApiResponse<Partner>) => {
          this.setPartner(response);
          this.onLoadPartner(response.resource!);
        },
        error: (response: ApiErrorResponse) => {
          this.onApiError(response);
          this.showNotFound(response.status === 404);
        }
      }, this.getFields());
    }
  }

  onLoadPartner(partner: Partner) {
    // override this in sub-classes if required
  }

  setPartner(response: ApiResponse<Partner>) {
    let partner = response.resource!;
    this.publish(Events.system.pageTitle, partner.name);
    this.setState({
      mode: '',
      partner: partner
    });
  }

  renderWithIndex(index: ApiIndex) {

    let partner = this.state.partner;

    if (partner && partner.key) {
      if (!partner.isModifyAllowed()) {
        return this.renderAccessDenied();
      }
      return this.renderWithIndexAndPartner(index, partner!);
    }

    return <></>;

  }

  renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

    return <></>;

  }

};
