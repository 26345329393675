import React from 'react';
import {ApiSubscribableCallbacks, Button, Form, InputMonthYearPicker, InputSelect, Str} from 'startupbox-react';
import ApiIndex from "../models/ApiIndex";
import StatisticLineChart from "../components/StatisticLineChart";
import Statistic from "../models/Statistic";
import ApiError from "../models/ApiError";
import User from "../models/User";
import AdminDashboardAbstractPage, {AdminDashboardAbstractPageProps, AdminDashboardAbstractPageState} from "./AdminDashboardAbstractPage";
import DeviceStatisticSearchRequest from "../models/DeviceStatisticSearchRequest";
import StatisticBarChart from "../components/StatisticBarChart";

export type AdminDashboardDevicesPageProps = AdminDashboardAbstractPageProps & {};

export type AdminDashboardDevicesPageState = AdminDashboardAbstractPageState & {
  searchRequest: DeviceStatisticSearchRequest;
};

export default class AdminDashboardUsersPage extends AdminDashboardAbstractPage<AdminDashboardDevicesPageProps, AdminDashboardDevicesPageState> {

  constructor(props: AdminDashboardDevicesPageProps) {
    super(props);
    this.activeTab = 'devices';
    this.subTitle = 'Devices';
    this.description = 'This area allows you monitor device related statistics useful to the business. Filter the matching data by using the fields below.';
    setTimeout(() => {
      this.setState({
        searchRequest: new DeviceStatisticSearchRequest(),
      })
    }, 0);
  }

  onSearch() {
    this.setState({
      timestamp: Date.now()
    });
  }

  renderWithIndexAndUser(index: ApiIndex, me: User, timestamp: number) {

    let searchRequest = this.state.searchRequest;

    if (!me) {
      return <></>;
    }

    return (

      <>

        <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
          <InputMonthYearPicker
            id="searchRequest.fromYYYYMM"
            placeholder="Date From"
            years={[2022]}
            value={searchRequest.fromYYYYMM}
            onChange={this.onFieldChange}
            getFormattedValueFn={value => Str.formatStartOfMonth(value, 'DD/MM/YYYY')}
            clearable/>
          {/*
              <InputMonthYearPicker
                id="searchRequest.toYYYYMM"
                placeholder="Date To"
                years={[2022]}
                value={searchRequest.toYYYYMM}
                onChange={this.onFieldChange}
                getFormattedValueFn={value => Str.formatEndOfMonth(value, 'DD/MM/YYYY')}
                clearable/>
          */}
          <InputSelect
            id="searchRequest.platformType"
            blank="Platform Type"
            value={searchRequest.platformType}
            onChange={this.onFieldChange}
            options={index.devicePlatformTypes}/>
          <span className="actions">
            <Button
              icon="search"
              bsStyle="primary"
              type="submit">Search</Button>
          </span>
        </Form>

        <StatisticLineChart
          chartContainerId="creation-day-chart-container"
          title="Devices By Day"
          what="device"
          timestamp={timestamp}
          refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
            searchRequest.statisticType = 'CREATION_DAY';
            me?.findDeviceStatistic(searchRequest, callbacks);
          }}/>

        <StatisticLineChart
          chartContainerId="creation-month-chart-container"
          title="Devices By Month"
          what="device"
          timestamp={timestamp}
          refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
            searchRequest.statisticType = 'CREATION_MONTH';
            me?.findDeviceStatistic(searchRequest, callbacks);
          }}/>

        <div className="row">
          <div className="col-md-6">
            <StatisticBarChart
              chartContainerId="platform-type-chart-container"
              title="Devices By Platform Type"
              what="user"
              timestamp={timestamp}
              refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
                searchRequest.statisticType = 'PLATFORM_TYPE';
                me?.findDeviceStatistic(searchRequest, callbacks);
              }}/>
          </div>
          <div className="col-md-6">
            <StatisticBarChart
              chartContainerId="brand-chart-container"
              title="Devices By Brand"
              what="user"
              timestamp={timestamp}
              refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
                searchRequest.statisticType = 'BRAND';
                me?.findDeviceStatistic(searchRequest, callbacks);
              }}/>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <StatisticBarChart
              chartContainerId="os-name-chart-container"
              title="Devices By OS Name"
              what="user"
              timestamp={timestamp}
              refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
                searchRequest.statisticType = 'OS_NAME';
                me?.findDeviceStatistic(searchRequest, callbacks);
              }}/>
          </div>
          <div className="col-md-6">
            <StatisticBarChart
              chartContainerId="os-version-chart-container"
              title="Devices By OS Version"
              what="user"
              timestamp={timestamp}
              refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
                searchRequest.statisticType = 'OS_VERSION';
                me?.findDeviceStatistic(searchRequest, callbacks);
              }}/>
          </div>
        </div>


      </>

    );

  }

}
