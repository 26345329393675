import React from 'react';
import PartnerHorseAbstractPage, {PartnerHorseAbstractPageProps, PartnerHorseAbstractPageState} from './PartnerHorseAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Horse from "../models/Horse";
import Partner from "../models/Partner";
import {ApiSearchResponse, Button, Confirmable, StatusButton, Str, Table, TableHeaderCell} from "startupbox-react";
import TrainingRecordSearchRequest from "../models/TrainingRecordSearchRequest";
import TrainingRecord, {TRAINING_RECORD_LIST_FIELDS} from "../models/TrainingRecord";
import SectionHeader from "../components/SectionHeader";
import FontAwesome from "react-fontawesome";
import TrainingRecordDialog from "../training_record/TrainingRecordDialog";

let FIELDS = TRAINING_RECORD_LIST_FIELDS;

export type PartnerHorseTrainingRecordsPageProps = PartnerHorseAbstractPageProps & {};

export type PartnerHorseTrainingRecordsPageState = PartnerHorseAbstractPageState & {
  trainingRecords: Array<TrainingRecord> | null;
};

export default class PartnerHorseTrainingRecordsPage extends PartnerHorseAbstractPage<PartnerHorseTrainingRecordsPageProps, PartnerHorseTrainingRecordsPageState> {

  constructor(props: PartnerHorseTrainingRecordsPageProps) {
    super(props);
    this.activeTab = 'training-records';
  }

  onLoadHorse(horse: Horse | undefined) {
    this.searchTrainingRecords(1);
  }

  searchTrainingRecords(page: number) {
    let trainingRecordSearchRequest = new TrainingRecordSearchRequest();
    trainingRecordSearchRequest.page = page - 1;
    this.state.horse?.searchTrainingRecords(trainingRecordSearchRequest, {
      next: (response: ApiSearchResponse<TrainingRecord>) => {
        let trainingRecords = response.resource;
        this.setTrainingRecords(trainingRecords);
      },
      error: this.onApiError
    }, FIELDS);
  }

  setTrainingRecords(trainingRecords: Array<TrainingRecord>) {
    this.setState({
      trainingRecords: trainingRecords
    })
  }

  renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {

    let trainingRecords = this.state.trainingRecords;

    return <>

      <SectionHeader
        title="Training Records"
        description="The training records of this horse are shown below. Users of the app may subscribe to
          memberships that allow viewing of these training records and the associated notes."
        actions={
          <>
            <Confirmable
              renderConfirm={(onOk) => {
                let trainingRecord: TrainingRecord = new TrainingRecord();
                return (
                  <TrainingRecordDialog
                    horse={horse}
                    trainingRecord={trainingRecord}
                    onClose={() => {
                      this.searchTrainingRecords(1);
                      onOk();
                    }}
                  />
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <Button
                    icon="plus"
                    bsStyle="primary"
                    bsSize="xs"
                    className="pull-right"
                    onClick={onShow}
                    disabled={!horse.isAddTrainingRecordAllowed()}>Add Training Record</Button>
                );
              }}/>
          </>
        }/>

      <Table
        items={trainingRecords}
        onPaginate={this.searchTrainingRecords}
        blankMessage="No training records found"
        renderHeaderRow={() => {
          return (
            <tr>
              <TableHeaderCell label="Date" className="col-md-2"/>
              <TableHeaderCell label="Title"/>
              <TableHeaderCell label="Status" className="col-md-2 text-right"/>
              <TableHeaderCell label="&nbsp;" className="col-chevron"/>
            </tr>
          );
        }}
        renderItemRow={(trainingRecord: TrainingRecord, tr: number) => {
          return (
            <Confirmable
              key={tr}
              renderConfirm={(onOk) => {
                return (
                  <TrainingRecordDialog
                    horse={horse}
                    trainingRecord={trainingRecord}
                    onClose={() => {
                      this.searchTrainingRecords(1);
                      onOk();
                    }}
                  />
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <tr className="clickable" onClick={onShow}>
                    <td>{Str.formatDate(trainingRecord.date, "DD/MM/YYYY")}</td>
                    <td>{trainingRecord.title}</td>
                    <td className="text-right">
                      <StatusButton
                        id={'status-' + tr}
                        value={trainingRecord.status}/>
                    </td>
                    <td className="text-right">
                      <FontAwesome name="chevron-right"/>
                    </td>
                  </tr>
                );
              }}/>
          );
        }}
      />

    </>;

  }

};