import React from 'react';
import PartnerHorseAbstractPage, {PartnerHorseAbstractPageProps, PartnerHorseAbstractPageState} from './PartnerHorseAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Horse from "../models/Horse";
import Partner from "../models/Partner";
import {ApiSearchResponse, Button, Confirmable, StatusButton, Str, Table, TableHeaderCell} from "startupbox-react";
import MedicalRecordSearchRequest from "../models/MedicalRecordSearchRequest";
import MedicalRecord, {MEDICAL_RECORD_LIST_FIELDS} from "../models/MedicalRecord";
import SectionHeader from "../components/SectionHeader";
import FontAwesome from "react-fontawesome";
import MedicalRecordDialog from "../medical_record/MedicalRecordDialog";

let FIELDS = MEDICAL_RECORD_LIST_FIELDS;

export type PartnerHorseMedicalRecordsPageProps = PartnerHorseAbstractPageProps & {};

export type PartnerHorseMedicalRecordsPageState = PartnerHorseAbstractPageState & {
  medicalRecords: Array<MedicalRecord> | null;
};

export default class PartnerHorseMedicalRecordsPage extends PartnerHorseAbstractPage<PartnerHorseMedicalRecordsPageProps, PartnerHorseMedicalRecordsPageState> {

  constructor(props: PartnerHorseMedicalRecordsPageProps) {
    super(props);
    this.activeTab = 'medical-records';
  }

  onLoadHorse(horse: Horse | undefined) {
    this.searchMedicalRecords(1);
  }

  searchMedicalRecords(page: number) {
    let medicalRecordSearchRequest = new MedicalRecordSearchRequest();
    medicalRecordSearchRequest.page = page - 1;
    this.state.horse?.searchMedicalRecords(medicalRecordSearchRequest, {
      next: (response: ApiSearchResponse<MedicalRecord>) => {
        let medicalRecords = response.resource;
        this.setMedicalRecords(medicalRecords);
      },
      error: this.onApiError
    }, FIELDS);
  }

  setMedicalRecords(medicalRecords: Array<MedicalRecord>) {
    this.setState({
      medicalRecords: medicalRecords
    })
  }

  renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {

    let medicalRecords = this.state.medicalRecords;

    return <>

      <SectionHeader
        title="Medical Records"
        description="The medical records of this horse are shown below. Users of the app may subscribe to
          memberships that allow viewing of these medical records and the associated notes."
        actions={
          <>
            <Confirmable
              renderConfirm={(onOk) => {
                let medicalRecord: MedicalRecord = new MedicalRecord();
                return (
                  <MedicalRecordDialog
                    horse={horse}
                    medicalRecord={medicalRecord}
                    onClose={() => {
                      this.searchMedicalRecords(1);
                      onOk();
                    }}
                  />
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <Button
                    icon="plus"
                    bsStyle="primary"
                    bsSize="xs"
                    className="pull-right"
                    onClick={onShow}
                    disabled={!horse.isAddMedicalRecordAllowed()}>Add Medical Record</Button>
                );
              }}/>
          </>
        }/>

      <Table
        items={medicalRecords}
        onPaginate={this.searchMedicalRecords}
        blankMessage="No medical records found"
        renderHeaderRow={() => {
          return (
            <tr>
              <TableHeaderCell label="Date" className="col-md-2"/>
              <TableHeaderCell label="Title"/>
              <TableHeaderCell label="Status" className="col-md-2 text-right"/>
              <TableHeaderCell label="&nbsp;" className="col-chevron"/>
            </tr>
          );
        }}
        renderItemRow={(medicalRecord: MedicalRecord, mr: number) => {
          return (
            <Confirmable
              key={mr}
              renderConfirm={(onOk) => {
                return (
                  <MedicalRecordDialog
                    horse={horse}
                    medicalRecord={medicalRecord}
                    onClose={() => {
                      this.searchMedicalRecords(1);
                      onOk();
                    }}
                  />
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <tr className="clickable" onClick={onShow}>
                    <td>{Str.formatDate(medicalRecord.date, "DD/MM/YYYY")}</td>
                    <td>{medicalRecord.title}</td>
                    <td className="text-right">
                      <StatusButton
                        id={'status-' + mr}
                        value={medicalRecord.status}/>
                    </td>
                    <td className="text-right">
                      <FontAwesome name="chevron-right"/>
                    </td>
                  </tr>
                );
              }}/>
          );
        }}
      />

    </>;

  }

};