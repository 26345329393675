import React from 'react';
import {ApiResponse, Button, Confirm, Confirmable, GlobalMessage, ImageWithUpload, InputInlineEditor, InputText, InputTextArea, InputWrap, StatusButton} from 'startupbox-react';
import HorseAttributeGroupPanel from '../horse/HorseAttributeGroupPanel';
import HorseDialog from '../horse/HorseDialog';
import PartnerHorseAbstractPage, {PartnerHorseAbstractPageProps, PartnerHorseAbstractPageState} from './PartnerHorseAbstractPage';
import Horse from "../models/Horse";
import HorseStatus from "../models/HorseStatus";
import HorseAttributeGroup from "../models/HorseAttributeGroup";
import ApiIndex from "../models/ApiIndex";
import Partner from "../models/Partner";
import Divider from "../components/Divider";
import Panel from "../components/Panel";
import BlockchainMintable from "../models/BlockchainMintable";
import BlockchainMintStatus from "../models/BlockchainMintStatus";
import BlockchainMintablePanel from "../blockchain/BlockchainMintablePanel";
import SectionHeader from "../components/SectionHeader";

export type PartnerHorseDetailsPageProps = PartnerHorseAbstractPageProps & {};

export type PartnerHorseDetailsPageState = PartnerHorseAbstractPageState & {}

export default class PartnerHorseDetailsPage extends PartnerHorseAbstractPage<PartnerHorseDetailsPageProps, PartnerHorseDetailsPageState> {

  constructor(props: PartnerHorseDetailsPageProps) {
    super(props);
    this.activeTab = 'details';
  }

  onReloadHorse(response: ApiResponse<BlockchainMintable>) {
    this.setHorse(response as ApiResponse<Horse>);
  }

  onSave(response: ApiResponse<Horse>) {
    this.setHorse(response);
  }

  onChangeFeatured(option: any) {
    this.state.horse?.modifyFeatured(option.key === 'true', {
      next: this.setHorse,
      error: this.onApiError
    }, this.getHorseFields());
  }

  onChangeStatus(status: HorseStatus) {
    this.state.horse?.modifyStatus(status, {
      next: this.setHorse,
      error: this.onApiError
    }, this.getHorseFields());
  }

  onChangeMintStatus(mintStatus: BlockchainMintStatus) {
    this.state.horse?.modifyMintStatus(mintStatus, {
      next: this.setHorse,
      error: this.onApiError
    }, this.getHorseFields());
  }

  onCancelMint() {
    this.setMode('');
  }

  onMint() {
    this.onChangeMintStatus({key: 'MINT'})
    this.setMode('');
  }

  renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {

    return <>

      <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity} fieldErrors={(this.state.errors as any)._}/>

      <SectionHeader
        title="Horse"
        description={
          <>
            The details of this horse is shown below. This horse is currently <strong>{horse!.status!.name}</strong> and <strong>{horse!.mintStatus!.name}</strong>. Please note that
            users can see horses in the Marketplace if the horse is public. It does not need to be minted.
          </>
        }
        actions={
          <>
            {
              horse.isSendReviewRequestNotificationAllowed() &&
              <Confirmable
                renderConfirm={(onOk, onCancel) => {
                  return (
                    <Confirm
                      title="Send Review Request"
                      message="Would you like to send a review request to BTX for this horse to be minted and published on the public blockchain?"
                      onOk={() => {
                        horse.sendReviewRequestNotification({
                          next: (response) => {
                          },
                          error: this.onApiError
                        }, this.getHorseFields());
                        onOk();
                      }}
                      onCancel={onCancel}/>
                  );
                }}
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="external-link"
                      bsStyle="primary"
                      bsSize="xs"
                      onClick={onShow}>Send Review Request</Button>
                  );
                }}/>
            }
          </>
        }/>

      <Panel>

        <Confirmable
          renderConfirm={(onOk, onCancel) => {
            return (
              <HorseDialog
                partner={partner}
                horse={horse}
                fields={this.getHorseFields()}
                onSave={(response: ApiResponse<Horse>) => {
                  this.onSave(response);
                  onOk();
                }}
                onCancel={onCancel}/>
            );
          }}
          renderTrigger={(onShow) => {
            return (
              <Button
                icon="external-link"
                bsStyle="primary"
                bsSize="xs"
                className="pull-right"
                onClick={onShow}
                disabled={!horse.isModifyAllowed()}>Edit</Button>
            );
          }}/>

        <InputText
          id="horse.name"
          label="Name"
          value={horse.name}
          readOnly/>

        <div className="row">
          <div className="col-xs-6">
            <InputText
              id="horse.microchipNumber"
              label="Microchip Number"
              value={horse.microchipNumber}
              readOnly/>
          </div>
          <div className="col-xs-6 text-right">
            <InputWrap id="status" label="Racing Status">
              <div>
                {horse.racingStatus?.name}
              </div>
            </InputWrap>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-6">
            <InputWrap id="featured" label="Featured">
              <div>
                <StatusButton
                  id="featured"
                  value={horse.getFeaturedOption()}
                  options={horse.getFeaturedOptions()}
                  onEdit={horse.isModifyFeaturedAllowed() ? this.onChangeFeatured : null}/>
              </div>
            </InputWrap>
          </div>
          <div className="col-xs-6 text-right">
            <InputWrap id="status" label="Status">
              <div>
                <StatusButton
                  id="status"
                  value={horse.status}
                  options={index.horseStatuses}
                  onEdit={horse.isModifyStatusAllowed() ? this.onChangeStatus : null}/>
              </div>
            </InputWrap>
          </div>
        </div>

        <Divider/>

        <ImageWithUpload
          id="horse.coverImage"
          label="Cover Image"
          files={horse.coverImage ? [horse.coverImage] : []}
          className="images-across-1"
          lightbox
          readOnly/>

        {
          horse.summaryDescription &&
          <InputTextArea
            id="horse.summaryDescription"
            label="Summary Description"
            value={horse.summaryDescription}
            readOnly/>
        }

        {
          horse.overview && horse.overview.body &&
          <InputInlineEditor
            id="horse.overview.body"
            elementId="details-overview"
            label="Overview"
            value={horse.overview?.body}
            readOnly/>
        }

        <ImageWithUpload
          id="horse.pedigreeImages"
          label="Pedigree"
          multiple
          lightbox
          files={horse.pedigreeImages || []}
          readOnly/>

      </Panel>

      {
        horse.manageableAttributeGroups.map((group: HorseAttributeGroup, g: number) => {
          return (
            <HorseAttributeGroupPanel
              key={g}
              horse={horse}
              group={group}
              horseFields={this.getHorseFields()}
              onReload={this.onReloadHorse}/>
          );
        })
      }

      <BlockchainMintablePanel
        type="Horse"
        entity={horse}
        entityFields={this.getHorseFields()}
        statuses={index.mintChooseableStatuses}
        errors={this.state.errors}
        onReload={this.onReloadHorse}
        onChangeMintStatus={this.onChangeMintStatus}/>

    </>
      ;

  }

};
