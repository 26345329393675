import React from 'react';
import {ApiResponse, Button, DialogPanel, Form, FullScreenModal, GlobalMessage, InputText} from 'startupbox-react';
import Context from '../Context';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Routes from '../Routes';
import AccountNav from './AccountNav';
import User from "../models/User";

export type AccountProfilePageProps = PageProps & {};

export type AccountProfilePageState = PageState & {
  user: User,
};

export default class AccountProfilePage extends Page<AccountProfilePageProps, AccountProfilePageState> {

  constructor(props: AccountProfilePageProps) {
    super(props);
    this.setInitialState({
      mode: 'edit',
      disabled: true,
      user: new User()
    });
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Profile');
    this.state.index?.findMe({
      next: this.setUser,
      error: this.onApiError
    }, null);
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let user = this.state.user;
      user.save({
        next: this.onSave,
        error: this.onApiError
      }, null);
    }
  }

  onSave(response: ApiResponse<User>) {
    this.enable();
    this.setUser(response);
    this.setErrors(null, 'Your changes have been saved successfully', 'info');
  }

  onCancel() {
    this.navigate(Routes.main.home());
  }

  setUser(response: ApiResponse<User>) {
    let user = response.resource!;
    this.setState({
      disabled: false,
      user: user
    });
    Context.setUserDisplayName(user.name);
  }

  render() {

    let user = this.state.user;

    if (!user) {
      return null;
    }

    return (

      <div>

        <FullScreenModal title="Edit Profile" className="centered-title" onHide={this.onCancel}>

          <DialogPanel>

            <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

              <AccountNav active="Profile"/>

              <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

              <br/>

              <p className="lead">Please enter your details below.</p>

              <InputText
                id="user.name"
                label="Name"
                help="Your full legal name as shown in official documents, as required by the Rules of Racing and Stud Book that BTX Technology is obligated to comply with."
                placeholder="eg Bob Smith"
                value={user.name}
                error={this.state.errors?.['request.name']}
                onChange={this.onFieldChange}
                required/>

              <InputText
                id="user.username"
                label="Username"
                help="An alphanumeric username to login with, which you can use instead of your email address (eg joebloggs)."
                placeholder=""
                value={user.username}
                error={this.state.errors?.['request.username']}
                onChange={this.onFieldChange}
                required/>

              <InputText
                id="user.emailAddress"
                label="Email Address"
                help="An email address we can use to contact you or send notifications, which can also be used to login."
                value={user.emailAddress}
                error={this.state.errors?.['request.emailAddress']}
                onChange={this.onFieldChange}
                required/>

              <InputText
                id="user.phoneNumber"
                label="Phone Number"
                help="A valid land or mobile number which we can use to contact you when necessary."
                value={user.phoneNumber}
                error={this.state.errors?.['request.phoneNumber']}
                onChange={this.onFieldChange}/>

              <div className="actions">
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  type="submit">Save Changes</Button>
              </div>

            </Form>

          </DialogPanel>

        </FullScreenModal>

      </div>

    );

  }

};