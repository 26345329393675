import React from 'react';
import Page, {PageProps, PageState} from '../Page';
import ApiIndex from "../models/ApiIndex";
import {DialogPanel, FullScreenModal} from 'startupbox-react';

// note this page is no longer used, we're sending users to the BTX Hubspot page directly (see FrankieOneApi)

export type FrankieOneFailurePageProps = PageProps & {};

export type FrankieOneFailurePageState = PageState & {};

export default class FrankieOneFailurePage extends Page<FrankieOneFailurePageProps, FrankieOneFailurePageState> {

  constructor(props: FrankieOneFailurePageProps) {
    super(props);
    this.setInitialState({});
  }

  onEnter() {
    this.setState({}, this.onLoad);
  }

  renderWithIndex(index: ApiIndex) {

    return (

      <FullScreenModal className="centered-title">

        <DialogPanel>

          <h2 className="marginTop50">BTX Identity Verification Failure</h2>
          <p>We have encountered an issue verifying your identity. You need to complete this step in order to make
            purchases on the BTX Platform. Please contact us if you need help.</p>

        </DialogPanel>

      </FullScreenModal>

    );

  }

}
