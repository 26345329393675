export default class Routes {

  static main = {

    home() {
      return '/';
    },

    login() {
      return '/login';
    },

    logout() {
      return '/logout';
    }

  };

  static account = {

    index() {
      return '/account/profile';
    },

    profile() {
      return '/account/profile';
    },

    avatar() {
      return '/account/avatar';
    },

    password() {
      return '/account/password';
    },

    logout() {
      return '/account/logout';
    }

  };

  static admin = {

    index() {
      return '/admin/dashboard/users';
    },

    banners() {
      return '/admin/banners';
    },

    banner(key: string) {
      return '/admin/banners/' + key;
    },

    collectibles() {
      return '/admin/collectibles';
    },

    collectible(key: string) {
      return '/admin/collectibles/' + key;
    },

    collectible_actuals(key: string) {
      return '/admin/collectibles/' + key + '/actuals';
    },

    dashboard(tab?: string) {
      return tab ? '/admin/dashboard/' + tab : Routes.admin.index();
    },

    disbursements() {
      return '/admin/disbursements';
    },

    disbursement(key: string) {
      return '/admin/disbursements/' + key;
    },

    entitlements() {
      return '/admin/entitlements';
    },

    legalDocuments() {
      return '/admin/legal-documents';
    },

    memberships() {
      return '/admin/memberships';
    },

    membership(key: string) {
      return '/admin/memberships/' + key;
    },

    membership_actuals(key: string) {
      return '/admin/memberships/' + key + '/actuals';
    },

    partners() {
      return '/admin/partners';
    },

    posts() {
      return '/admin/posts';
    },

    transactions() {
      return '/admin/transactions';
    },

    users() {
      return '/admin/users';
    },

    user(key: string | null | undefined) {
      return '/admin/users/' + (key ? key : 'add');
    },

    user_wallet(key: string, walletKey: string, tab: string) {
      return '/admin/users/' + key + '/wallets/' + walletKey + '/' + tab;
    },

    user_device(key: string, deviceKey: string, tab: string) {
      return '/admin/users/' + key + '/devices/' + deviceKey + '/' + tab;
    },

    userImportRuns() {
      return '/admin/users/import-runs';
    },

    userImportRun(userImportRunKey: string) {
      return '/admin/users/import-runs/' + userImportRunKey;
    },

  };

  static partner = {

    index(key: string) {
      return '/partners/' + key + '/home';
    },

    collectibles(key: string) {
      return '/partners/' + key + '/collectibles';
    },

    collectible(key: string, collectibleKey: string) {
      return '/partners/' + key + '/collectibles/' + collectibleKey;
    },

    collectible_actuals(key: string, collectibleKey: string) {
      return '/partners/' + key + '/collectibles/' + collectibleKey + '/actuals';
    },

    memberships(key: string) {
      return '/partners/' + key + '/memberships';
    },

    membership(key: string, membershipKey: string) {
      return '/partners/' + key + '/memberships/' + membershipKey;
    },

    membership_actuals(key: string, membershipKey: string) {
      return '/partners/' + key + '/memberships/' + membershipKey + '/actuals';
    },

    horses(key: string) {
      return '/partners/' + key + '/horses';
    },

    horse(key: string, horseKey: string, tab: string) {
      return '/partners/' + key + '/horses/' + horseKey + '/' + tab;
    },

    horse_details(key: string, horseKey: string) {
      return Routes.partner.horse(key, horseKey, 'details');
    },

    horse_collectibles(key: string, horseKey: string) {
      return Routes.partner.horse(key, horseKey, 'collectibles');
    },

    horse_collectible(key: string, horseKey: string, collectibleKey: string) {
      return '/partners/' + key + '/horses/' + horseKey + '/collectibles/' + collectibleKey;
    },

    horse_collectible_actuals(key: string, horseKey: string, collectibleKey: string) {
      return '/partners/' + key + '/horses/' + horseKey + '/collectibles/' + collectibleKey + '/actuals';
    },

    horse_memberships(key: string, horseKey: string) {
      return Routes.partner.horse(key, horseKey, 'memberships');
    },

    horse_membership(key: string, horseKey: string, membershipKey: string) {
      return '/partners/' + key + '/horses/' + horseKey + '/memberships/' + membershipKey;
    },

    horse_membership_actuals(key: string, horseKey: string, membershipKey: string) {
      return '/partners/' + key + '/horses/' + horseKey + '/memberships/' + membershipKey + '/actuals';
    },

    horse_ownerships(key: string, horseKey: string) {
      return Routes.partner.horse(key, horseKey, 'ownerships');
    },

    horse_tranche(key: string, horseKey: string, trancheKey: string) {
      return '/partners/' + key + '/horses/' + horseKey + '/tranches/' + trancheKey;
    },

    horse_tranche_payouts(key: string, horseKey: string, trancheKey: string) {
      return '/partners/' + key + '/horses/' + horseKey + '/tranches/' + trancheKey + '/payouts';
    },

    horse_tranche_payout(key: string, horseKey: string, trancheKey: string, payoutKey: string) {
      return '/partners/' + key + '/horses/' + horseKey + '/tranches/' + trancheKey + '/payouts/' + payoutKey;
    },

    horseImportRuns(key: string) {
      return '/partners/' + key + '/horses/import-runs';
    },

    horseImportRun(key: string, horseImportRunKey: string) {
      return '/partners/' + key + '/horses/import-runs/' + horseImportRunKey;
    },

    packs(key: string) {
      return '/partners/' + key + '/packs';
    },

    pack(key: string, packKey: string) {
      return '/partners/' + key + '/packs/' + packKey;
    },

    posts(key: string) {
      return '/partners/' + key + '/posts';
    },

    team(key: string) {
      return '/partners/' + key + '/team';
    },

  };

  static frankieOne = {

    success(frankieOneAccessKey: string) {
      return '/frankie-one/success?frankieOneAccessKey=' + frankieOneAccessKey;
    },

    failure(frankieOneAccessKey: string) {
      return '/frankie-one/failure?frankieOneAccessKey=' + frankieOneAccessKey;
    },

  };

  static tool = {

    index() {
      return '/tools/hubspot-async-messages';
    },

    hubSpotAsyncMessages() {
      return '/tools/hubspot-async-messages';
    },

    hubSpotAsyncMessage(key: string) {
      return '/tools/hubspot-async-messages/' + key;
    },

    settings() {
      return '/tools/settings';
    },

    setting(key: string | null | undefined) {
      return '/tools/settings/' + (key ? key : 'add');
    },

    legalOwner() {
      return '/tools/legal-owner';
    },

  };


}
