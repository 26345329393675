import React from 'react';
import {ApiResponse, Button, Confirmable, GlobalMessage} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import ApiIndex from "../models/ApiIndex";
import Collectible, {COLLECTIBLE_STANDARD_FIELDS} from "../models/Collectible";
import SectionHeader from "../components/SectionHeader";
import PartnerHorseCollectibleGiftToUserDialog from "../partner/PartnerHorseCollectibleGiftToUserDialog";
import Routes from "../Routes";
import CollectibleDetailsPanel from "./CollectibleDetailsPanel";
import BlockchainMintablePanel from "../blockchain/BlockchainMintablePanel";
import BlockchainMintable from "../models/BlockchainMintable";
import CollectibleStatus from "../models/CollectibleStatus";
import BlockchainMintStatus from "../models/BlockchainMintStatus";

let FIELDS = COLLECTIBLE_STANDARD_FIELDS;

export type CollectiblePageProps = PageProps & {};

export type CollectiblePageState = PageState & {
  collectible?: Collectible | null | undefined;
};

export default class CollectiblePage extends Page<CollectiblePageProps, CollectiblePageState> {

  constructor(props: CollectiblePageProps) {
    super(props);
    this.setInitialState({
      collectible: null
    });
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Collectible');
    this.loadCollectible();
  }

  loadCollectible() {
    this.state.index?.findCollectibleByKey(this.props.params.key, {
      next: this.setCollectible,
      error: this.onApiError
    }, FIELDS);
  }

  onReloadCollectible(response: ApiResponse<BlockchainMintable>) {
    this.setCollectible(response as ApiResponse<Collectible>);
  }

  onSave(response: ApiResponse<Collectible>) {
    this.setCollectible(response);
  }

  onDelete() {
    this.navigate(Routes.admin.collectibles())
  }

  onChangeFeatured(option: any) {
    this.state.collectible?.modifyFeatured(option.key === 'true', {
      next: this.setCollectible,
      error: this.onApiError
    }, FIELDS);
  }

  onChangeStatus(status: CollectibleStatus) {
    this.state.collectible?.modifyStatus(status, {
      next: this.setCollectible,
      error: this.onApiError
    }, FIELDS);
  }

  onChangeMintStatus(mintStatus: BlockchainMintStatus) {
    this.state.collectible?.modifyMintStatus(mintStatus, {
      next: this.setCollectible,
      error: this.onApiError
    }, FIELDS);
  }

  setCollectible(response: ApiResponse<Collectible>) {
    let collectible = response.resource!;
    this.publish(Events.system.pageTitle, collectible.name);
    this.setState({
      collectible: collectible
    });
  }

  onShowActuals() {
    this.navigate(Routes.admin.collectible_actuals(this.props.params.key))
  }

  renderWithIndex(index: ApiIndex) {

    let collectible = this.state.collectible;

    if (!collectible) {
      return <></>;
    }

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity} fieldErrors={(this.state.errors as any)._}/>

          <PageHeader
            title={collectible.name}
            lead={
              <>
                The details of this collectible is shown below. This collectible is currently <strong>{collectible!.status!.name}</strong> and <strong>{collectible!.mintStatus!.name}</strong>. Please note that
                users can only see collectibles in the Marketplace if the collectible is both public and minted.
              </>
            }/>

          <SectionHeader
            title="Collectible"
            actions={
              <>
                {
                  collectible.isGiftToUserAllowed() &&
                  <Confirmable
                    renderConfirm={(onOk, onCancel) => {
                      return (
                        <PartnerHorseCollectibleGiftToUserDialog
                          index={index}
                          collectible={collectible!}
                          onSave={(response) => {
                            onOk();
                            this.setGlobalMessage("Successfully gifted!", "success")
                            this.loadCollectible();
                          }}
                          onCancel={onCancel}/>
                      );
                    }}
                    renderTrigger={(onShow) => {
                      return (
                        <Button
                          icon="external-link"
                          bsStyle="primary"
                          bsSize="xs"
                          onClick={onShow}>Gift to User</Button>
                      );
                    }}/>
                }
                {
                  collectible.isMinted() &&
                  <Button
                    icon="chevron-right"
                    bsStyle="primary"
                    bsSize="xs"
                    onClick={this.onShowActuals}>Show Actuals</Button>
                }
              </>
            }/>

          <CollectibleDetailsPanel
            index={index}
            collectible={collectible}
            onSave={this.onSave}
            onDelete={this.onDelete}
            onChangeFeatured={this.onChangeFeatured}
            onChangeStatus={this.onChangeStatus}/>

          <BlockchainMintablePanel
            type="Collectible"
            entity={collectible}
            entityFields={FIELDS}
            statuses={index.mintChooseableStatuses}
            errors={this.state.errors}
            onReload={this.onReloadCollectible}
            onChangeMintStatus={this.onChangeMintStatus}/>


        </div>

      </div>

    );

  }
}
