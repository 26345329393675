import Model from "../Model";
import {ApiSubscribableCallbacks, Environment, SearchResult} from "startupbox-react";
import ApiError from "./ApiError";
import DisbursementStatus from "./DisbursementStatus";
import EntitlementSearchRequest from "./EntitlementSearchRequest";
import Entitlement from "./Entitlement";
import Context from "../Context";

let Rel = {
  self: 'self',
  processed: 'processed',
  downloadAba: 'downloadAba',
  markAsProcessed: 'markAsProcessed',
  entitlement_search: 'entitlement_search',
};

export const DISBURSEMENT_STANDARD_FIELDS = '';
export const DISBURSEMENT_LIST_FIELDS = '';

export default class Disbursement {

  key?: string;

  title?: string;
  createdAt?: Date;
  processedAt?: Date;
  status?: DisbursementStatus;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  isPending() {
    return this.status?.key === 'PENDING';
  }

  isProcessed() {
    return this.status?.key === 'PROCESSED';
  }

  isMarkAsProcessedAllowed() {
    return !!this.href(Rel.markAsProcessed);
  }

  isDownloadABAAllowed() {
    return !!this.href(Rel.downloadAba);
  }

  getABAFileHref() {
    let url = Environment.current().baseBackendUrl + this.href(Rel.downloadAba).toString();
    if (Environment.current().name === 'localhost') {
      url += '?authorization=' + Context.getAccessToken();
    }
    return url;
  }

  markAsProcessed(processedAt: Date | null | undefined, callbacks: ApiSubscribableCallbacks<Disbursement, ApiError>, fields: string | null) {
    this.href(Rel.markAsProcessed, true)
      .field(fields)
      .patch({processedAt: processedAt})
      .subscribe(callbacks);
  }

  // entitlement ....................................................................................................................................

  searchEntitlements(request: EntitlementSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Entitlement>, ApiError>, fields: string | null) {
    this.href(Rel.entitlement_search, true)!
      .param('keywords', request.keywords)
      .param('status', request.status)
      .param('sort', request.sort || 'date,asc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

}
