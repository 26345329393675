import React from 'react';
import {Button, Form, GlobalMessage, InputText} from 'startupbox-react';
import SecurityDialog from '../components/SecurityDialog';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import Credentials from "../models/Credentials";
import {ApiErrorResponse} from "../models/ApiError";

export type ForgottenPasswordDialogProps = DialogProps & {
  onRememberedPassword: () => void,
  onSendResetPasswordInstructions: () => void,
  onCancel?: () => void,
  invalidResetPasswordToken: boolean
};

export type ForgottenPasswordDialogState = DialogState & {
  credentials: Credentials,
  emailSent: boolean
};

export default class ForgottenPasswordDialog extends Dialog<ForgottenPasswordDialogProps, ForgottenPasswordDialogState> {

  constructor(props: ForgottenPasswordDialogProps) {
    super(props);
    this.setInitialState({
      credentials: new Credentials(),
      emailSent: false,
      globalMessage: this.props.invalidResetPasswordToken ? 'The token you have supplied is invalid or has expired. Please request for a new one.' : null
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let credentials = this.state.credentials;
      this.state.index?.requestResetPassword(credentials, {
        next: this.onSendResetPasswordInstructions,
        error: this.onApiError
      });
    }
  }

  onSendResetPasswordInstructions() {
    this.enable();
    if (this.props.onSendResetPasswordInstructions) {
      this.props.onSendResetPasswordInstructions();
    }
    this.setState({
      emailSent: true,
      globalMessage: null
    });
  }

  onCancel() {
    this.enable();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onApiError(response: ApiErrorResponse) {
    this.enable();
    super.onApiError(response);
    this.setState({
      globalMessage: 'There was a problem resetting your password. Try again or contact us.'
    });
  }

  render() {

    return (

      <div>

        <SecurityDialog>

          {
            !this.state.emailSent &&
            <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

              <h1 className="title">Forgotten Password</h1>
              <p className="lead">Please enter your email address below for instructions to reset your password.</p>

              <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

              <InputText
                id="credentials.emailAddress"
                label="Email Address"
                placeholder=""
                value={this.state.credentials.emailAddress}
                error={this.state.errors?.['request.emailAddress']}
                onChange={this.onFieldChange}
                required/>

              <div className="actions">
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  type="submit">Send Instructions</Button>
              </div>

              <p><a href="/" onClick={this.props.onRememberedPassword}>I have remembered my password</a></p>

            </Form>
          }

          {
            this.state.emailSent &&
            <div>
              <br/>
              <br/>
              <br/>
              <p className="lead">We have sent you an email with instructions to reset your password.</p>
              <p>You may safely close this browser tab.</p>
            </div>
          }

        </SecurityDialog>

      </div>

    );

  }

}