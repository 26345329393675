import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, Confirm, Confirmable, GlobalMessage} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import ApiIndex from "../models/ApiIndex";
import Partner from "../models/Partner";
import BlockchainMintStatus from "../models/BlockchainMintStatus";
import Routes from "../Routes";
import BlockchainMintable from "../models/BlockchainMintable";
import BlockchainMintablePanel from "../blockchain/BlockchainMintablePanel";
import SectionHeader from "../components/SectionHeader";
import Membership, {MEMBERSHIP_STANDARD_FIELDS} from "../models/Membership";
import MembershipStatus from "../models/MembershipStatus";
import PartnerMembershipGiftToUserDialog from "./PartnerMembershipGiftToUserDialog";
import MembershipDetailsPanel from "../membership/MembershipDetailsPanel";
import PartnerHorseAbstractPage, {
    PartnerHorseAbstractPageProps,
    PartnerHorseAbstractPageState
} from "./PartnerHorseAbstractPage";
import Horse from "../models/Horse";

let FIELDS = MEMBERSHIP_STANDARD_FIELDS;

export type PartnerHorseMembershipPageProps = PartnerHorseAbstractPageProps & {};

export type PartnerHorseMembershipPageState = PartnerHorseAbstractPageState & {
    membership?: Membership | null | undefined;
}

export default class PartnerHorseMembershipPage extends PartnerHorseAbstractPage<PartnerHorseMembershipPageProps, PartnerHorseMembershipPageState> {

    constructor(props: PartnerHorseMembershipPageProps) {
        super(props);
        this.setInitialState({
            membership: null,
        });
    }

    onLoadHorse(horse: Horse) {
        this.loadMembership()
    }

    loadMembership() {
        let horse = this.state.horse;
        horse?.findMembershipByKey(this.props.params.membershipKey, {
            next: this.setMembership,
            error: this.onApiError
        }, FIELDS);
    }

    onReloadMembership(response: ApiResponse<BlockchainMintable>) {
        this.setMembership(response as ApiResponse<Membership>);
    }

    onSave(response: ApiResponse<Membership>) {
        this.setMembership(response);
    }

    onDelete() {
        this.navigate(Routes.partner.horse_memberships(this.state.partner?.key!, this.state.horse?.key!))
    }

    onChangeFeatured(option: any) {
        this.state.membership?.modifyFeatured(option.key === 'true', {
            next: this.setMembership,
            error: this.onApiError
        }, FIELDS);
    }

    onChangeStatus(status: MembershipStatus) {
        this.state.membership?.modifyStatus(status, {
            next: this.setMembership,
            error: this.onApiError
        }, FIELDS);
    }

    onChangeMintStatus(mintStatus: BlockchainMintStatus) {
        this.state.membership?.modifyMintStatus(mintStatus, {
            next: this.setMembership,
            error: this.onApiError
        }, FIELDS);
    }

    setMembership(response: ApiSearchResponse<Membership>) {
        this.setState({
            membership: response.resource
        });
    }

    onShowActuals() {
        this.navigate(Routes.partner.horse_membership_actuals(this.state.partner!.key!, this.state.horse!.key!, this.props.params.membershipKey))
    }

    renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {

        let membership = this.state.membership;

        if (!membership) {
            return <></>;
        }

        return (<>
            <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity} fieldErrors={(this.state.errors as any)._}/>

                <PageHeader
                    title={membership.name}
                    lead={
                        <>
                            The details of this membership is shown below. This membership is currently <strong>{membership!.status!.name}</strong> and <strong>{membership!.mintStatus!.name}</strong>. Please note that
                            users can only see membership in the Marketplace if the membership is both public and minted.
                        </>
                    }/>

                <SectionHeader
                    title="Membership"
                    actions={
                        <>
                            {
                                membership.isSendReviewRequestNotificationAllowed() &&
                                <Confirmable
                                    renderConfirm={(onOk, onCancel) => {
                                        return (
                                            <Confirm
                                                title="Send Review Request"
                                                message="Would you like to send a review request to BTX for this membership to be minted and published on the public blockchain?"
                                                onOk={() => {
                                                    membership!.sendReviewRequestNotification({
                                                        next: (response) => {
                                                        },
                                                        error: this.onApiError
                                                    }, FIELDS);
                                                    onOk();
                                                }}
                                                onCancel={onCancel}/>
                                        );
                                    }}
                                    renderTrigger={(onShow) => {
                                        return (
                                            <Button
                                                icon="external-link"
                                                bsStyle="primary"
                                                bsSize="xs"
                                                onClick={onShow}>Send Review Request</Button>
                                        );
                                    }}/>
                            }
                            {
                                membership.isGiftToUserAllowed() &&
                                <Confirmable
                                    renderConfirm={(onOk, onCancel) => {
                                        return (
                                            <PartnerMembershipGiftToUserDialog
                                                index={index}
                                                membership={membership!}
                                                onSave={(response) => {
                                                    onOk();
                                                    this.setGlobalMessage("Successfully gifted!", "success")
                                                    this.loadMembership();
                                                }}
                                                onCancel={onCancel}/>
                                        );
                                    }}
                                    renderTrigger={(onShow) => {
                                        return (
                                            <Button
                                                icon="external-link"
                                                bsStyle="primary"
                                                bsSize="xs"
                                                onClick={onShow}>Gift to User</Button>
                                        );
                                    }}/>
                            }
                            {
                                membership.isMinted() &&
                                <Button
                                    icon="chevron-right"
                                    bsStyle="primary"
                                    bsSize="xs"
                                    onClick={this.onShowActuals}>Show Actuals</Button>
                            }
                        </>
                    }/>

                    <MembershipDetailsPanel
                        index={index}
                        membership={membership}
                        partner={partner}
                        onSave={this.onSave}
                        onDelete={this.onDelete}
                        onChangeStatus={this.onChangeStatus}/>

                    <BlockchainMintablePanel
                        type="Membership"
                        entity={membership}
                        entityFields={FIELDS}
                        statuses={index.mintChooseableStatuses}
                        errors={this.state.errors}
                        onReload={this.onReloadMembership}
                        onChangeMintStatus={this.onChangeMintStatus}/>

                </>

        );

    }

    renderPageActions(index: ApiIndex, partner: Partner, horse: Horse): JSX.Element {

        return (

            <div className="actions">
                <Button
                    icon="chevron-left"
                    iconPosition="left"
                    bsStyle="default"
                    bsSize="large"
                    onClick={() => {
                        this.navigate(Routes.partner.horse_memberships(this.getPartnerKey(), this.getHorseKey()));
                    }}>Back to Memberships</Button>
            </div>

        );

    }

};
