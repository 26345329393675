import React, {useState} from 'react';
import Banner from "../models/Banner";
import ApiIndex from "../models/ApiIndex";
import {InputSelect, InputText} from "startupbox-react";
import SectionHeader from "../components/SectionHeader";
import Tranche from "../models/Tranche";
import Horse from "../models/Horse";
import HorseSearchRequest from "../models/HorseSearchRequest";
import Collectible from "../models/Collectible";
import Partner from "../models/Partner";
import Membership from "../models/Membership";
import Pack from "../models/Pack";
import PartnerSearchRequest from "../models/PartnerSearchRequest";
import CollectibleSearchRequest from "../models/CollectibleSearchRequest";
import MembershipSearchRequest from "../models/MembershipSearchRequest";
import PackSearchRequest from "../models/PackSearchRequest";
import TrancheSearchRequest from "../models/TrancheSearchRequest";

type BannerTypePanelProps = {
  index: ApiIndex;
  banner: Banner;
  errors?: { [key: string]: string };
  onFieldChange: (e: Event, callback?: () => void) => void;
}

const BannerTypePanel = (props: BannerTypePanelProps) => {

  let [horses, setHorses] = useState<Array<Horse> | undefined>(undefined);
  let [partners, setPartners] = useState<Array<Partner> | undefined>(undefined);

  let [collectibles, setCollectibles] = useState<Array<Collectible> | undefined>(undefined);
  let [memberships, setMemberships] = useState<Array<Membership> | undefined>(undefined);
  let [packs, setPacks] = useState<Array<Pack> | undefined>(undefined);
  let [tranches, setTranches] = useState<Array<Tranche> | undefined>(undefined);

  let index = props.index;
  let banner = props.banner;
  let errors = props.errors;
  let onFieldChange = props.onFieldChange;

  let onSearchHorses = (inputValue: string) => {
    let horseSearchRequest = new HorseSearchRequest();
    horseSearchRequest.name = inputValue;
    index.searchHorses(horseSearchRequest, {
      next: response => {
        setHorses(response.resource?.values);
      },
      error: null
    }, null);
  }

  let onSearchPartners = (inputValue: string) => {
    let partnerSearchRequest = new PartnerSearchRequest();
    partnerSearchRequest.name = inputValue;
    index.searchPartners(partnerSearchRequest, {
      next: response => {
        setPartners(response.resource?.values);
      },
      error: null
    }, null);
  }

  let onSearchCollectibles = (inputValue: string) => {
    let collectibleSearchRequest = new CollectibleSearchRequest();
    collectibleSearchRequest.name = inputValue;
    index.searchCollectibles(collectibleSearchRequest, {
      next: response => {
        setCollectibles(response.resource?.values);
      },
      error: null
    }, null);
  }

  let onSearchMemberships = (inputValue: string) => {
    let membershipSearchRequest = new MembershipSearchRequest();
    membershipSearchRequest.name = inputValue;
    index.searchMemberships(membershipSearchRequest, {
      next: response => {
        setMemberships(response.resource?.values);
      },
      error: null
    }, null);
  }

  let onSearchPacks = (inputValue: string) => {
    let packSearchRequest = new PackSearchRequest();
    packSearchRequest.name = inputValue;
    index.searchPacks(packSearchRequest, {
      next: response => {
        setPacks(response.resource?.values);
      },
      error: null
    }, null);
  }

  let onSearchTranches = (inputValue: string) => {
    let trancheSearchRequest = new TrancheSearchRequest();
    trancheSearchRequest.name = inputValue;
    index.searchTranches(trancheSearchRequest, {
      next: response => {
        setTranches(response.resource?.values);
      },
      error: null
    }, null);
  }

  return <>

    <SectionHeader
      title="Deep Link Details"
      description="Please choose a deep link type and enter the relevant details to link to. The data required depends on the type selected."
      divider/>

    <InputSelect
      id="banner.type"
      label="Type"
      value={banner.type}
      options={index.bannerTypes}
      onChange={onFieldChange}
      error={errors?.['request.type']}
      required/>

    {
      banner.type?.key === 'HORSE' &&
      <InputSelect
        id="banner.horse"
        label="Horse"
        help="Start typing to auto suggest horses to deep link"
        value={banner.horse}
        onChange={onFieldChange}
        error={errors?.['request.horse']}
        options={horses ? horses : (banner.horse ? [banner.horse] : null)}
        enhanced={{onInputChange: onSearchHorses}}
        required/>
    }

    {
      banner.type?.key === 'PARTNER' &&
      <InputSelect
        id="banner.partner"
        label="Partner"
        help="Start typing to auto suggest partners to deep link"
        value={banner.partner}
        onChange={onFieldChange}
        error={errors?.['request.partner']}
        options={partners ? partners : (banner.partner ? [banner.partner] : null)}
        enhanced={{onInputChange: onSearchPartners}}
        required/>
    }

    {
      banner.type?.key === 'COLLECTIBLE' &&
      <InputSelect
        id="banner.collectible"
        label="Collectible"
        help="Start typing to auto suggest collectibles to deep link"
        value={banner.collectible}
        onChange={onFieldChange}
        error={errors?.['request.collectible']}
        options={collectibles ? collectibles : (banner.collectible ? [banner.collectible] : null)}
        enhanced={{onInputChange: onSearchCollectibles}}
        required/>
    }

    {
      banner.type?.key === 'MEMBERSHIP' &&
      <InputSelect
        id="banner.membership"
        label="Membership"
        help="Start typing to auto suggest memberships to deep link"
        value={banner.membership}
        onChange={onFieldChange}
        error={errors?.['request.membership']}
        options={memberships ? memberships : (banner.membership ? [banner.membership] : null)}
        enhanced={{onInputChange: onSearchMemberships}}
        required/>
    }

    {
      banner.type?.key === 'PACK' &&
      <InputSelect
        id="banner.pack"
        label="Pack"
        help="Start typing to auto suggest packs to deep link"
        value={banner.pack}
        onChange={onFieldChange}
        error={errors?.['request.pack']}
        options={packs ? packs : (banner.pack ? [banner.pack] : null)}
        enhanced={{onInputChange: onSearchPacks}}
        required/>
    }

    {
      banner.type?.key === 'TRANCHE' &&
      <InputSelect
        id="banner.tranche"
        label="Tranche"
        help="Start typing to auto suggest tranches to deep link"
        value={banner.tranche}
        onChange={onFieldChange}
        error={errors?.['request.tranche']}
        options={tranches ? tranches : (banner.tranche ? [banner.tranche] : null)}
        enhanced={{onInputChange: onSearchTranches}}
        required/>
    }

    {
      banner.type?.key === 'EXTERNAL_LINK' &&
      <InputText
        id="banner.externalUrl"
        label="External Url"
        help="Enter a valid url including the protocol (eg https://google.com)"
        value={banner.externalUrl}
        onChange={onFieldChange}
        error={errors?.['request.externalUrl']}
        required/>
    }

  </>;

};

export default BannerTypePanel;
