import React from 'react';
import Page, {PageProps, PageState} from '../Page';
import ApiIndex from "../models/ApiIndex";
import {DialogPanel, FullScreenModal} from "startupbox-react";

export type FrankieOneSuccessPageProps = PageProps & {};

export type FrankieOneSuccessPageState = PageState & {
};

export default class FrankieOneSuccessPage extends Page<FrankieOneSuccessPageProps, FrankieOneSuccessPageState> {

  constructor(props: FrankieOneSuccessPageProps) {
    super(props);
    this.setInitialState({
    });
  }

  renderWithIndex(index: ApiIndex) {

    return <>

      <FullScreenModal className="centered-title">

        <DialogPanel>

          <h2 className="marginTop50">BTX Identity Verified Successfully</h2>
          <p>Congratulations, we have successfully verified your identity. You may close this screen to continue.</p>

        </DialogPanel>

      </FullScreenModal>

    </>;

  }

}
