import React from 'react';
import {Nav, NavItem} from 'react-bootstrap';
import Wallet from "../models/Wallet";

type WalletTabsProps = {
  wallet: Wallet;
  active: string;
  onSelect: (value?: any) => void;
};

const WalletTabs = (props: WalletTabsProps) => {

  return (
    <div id="wallet-tabs" className="tabs">
      <Nav id="wallet-tabs-nav" bsStyle="pills" justified activeKey={props.active} onSelect={props.onSelect}>
        <NavItem eventKey="transactions">NFTs</NavItem>
        <NavItem eventKey="entitlements">Payouts</NavItem>
        <NavItem eventKey="app-credits">Credits</NavItem>
      </Nav>
    </div>
  );

}

export default WalletTabs;