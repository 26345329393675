import React from 'react';
import {ApiSearchResponse, Button, Confirmable, Form, InputText, SearchResult} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from './PartnerAbstractPage';
import PostSearchRequest from "../models/PostSearchRequest";
import Partner from "../models/Partner";
import ApiIndex from "../models/ApiIndex";
import Post, {POST_STANDARD_FIELDS} from "../models/Post";
import PostDialog from "../post/PostDialog";
import PostTable from "../post/PostTable";

let FIELDS = POST_STANDARD_FIELDS;

export type PartnerPostsPageProps = PartnerAbstractPageProps & {};

export type PartnerPostsPageState = PartnerAbstractPageState & {
  postSearchRequest: PostSearchRequest;
  posts: SearchResult<Post> | null;
}

export default class PartnerPostsPage extends PartnerAbstractPage<PartnerPostsPageProps, PartnerPostsPageState> {

  constructor(props: PartnerPostsPageProps) {
    super(props);
    this.setInitialState({
      postSearchRequest: new PostSearchRequest(),
      posts: null,
    });
  }

  onEnter() {
    this.setState({
      postSearchRequest: {
        keywords: this.query('keywords'),
        page: parseInt(this.query('page') || '0'),
      }
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('keywords', this.state.postSearchRequest.keywords)
      .navigate(this.onLoad);
  }

  onLoadPartner(partner: Partner) {
    this.searchPosts(this.state.postSearchRequest.page + 1);
  }

  searchPosts(page: number) {
    let postSearchRequest = this.state.postSearchRequest;
    postSearchRequest.page = page - 1;
    this.state.partner?.searchPosts(postSearchRequest, {
      next: (response: ApiSearchResponse<Post>) => {
        let posts = response.resource;
        this.setPosts(posts);
      },
      error: this.onApiError
    }, FIELDS);
  }

  setPosts(posts: SearchResult<Post>) {
    this.setState({
      posts: posts
    })
  }

  renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

    let posts = this.state.posts;

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title={partner.name}
            subTitle="Posts"
            lead="This area allows you to manage your posts offered for sale. Users of the app may view these posts if they follow you or have subscribed to premium content."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="postSearchRequest.keywords"
              placeholder="Keywords"
              value={this.state.postSearchRequest.keywords}
              onChange={this.onFieldChange}
              className="wide"/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              <Confirmable
                renderConfirm={(onOk) => {
                  let post: Post = new Post();
                  return (
                    <PostDialog
                      index={index}
                      partner={partner}
                      post={post}
                      visibilityLevels={partner.visibilityLevelsForPosts}
                      onClose={() => {
                        this.searchPosts(1);
                        onOk();
                      }}
                    />
                  );
                }}
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="plus"
                      className="pull-right"
                      onClick={onShow}
                      disabled={!partner.isAddPostAllowed()}>Add</Button>
                  );
                }}/>
            </span>
          </Form>

          <br/>

          <PostTable
            index={index}
            partner={partner}
            posts={posts}
            visibilityLevels={partner.visibilityLevelsForPosts}
            showVisibilityLevel
            onReload={this.onEnter}/>

        </div>

      </div>

    );

  }

};