import React from 'react';
import {Button, Form, FullScreenModal, GlobalMessage, InputSelect, InputText, ApiResponse} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import PartnerCollaborator from "../models/PartnerCollaborator";
import Partner from "../models/Partner";
import ApiIndex from "../models/ApiIndex";

export type PartnerCollaboratorDialogProps = DialogProps & {
  partner: Partner,
  collaborator: PartnerCollaborator,
  onSave: (response: ApiResponse<PartnerCollaborator>) => void,
  onCancel: () => void,
};

export type PartnerCollaboratorDialogState = DialogState & {
  collaborator: PartnerCollaborator,
};

export default class PartnerCollaboratorDialog extends Dialog<PartnerCollaboratorDialogProps, PartnerCollaboratorDialogState> {

  constructor(props: PartnerCollaboratorDialogProps) {
    super(props);
    this.setInitialState({
      collaborator: this.props.collaborator
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let partner = this.props.partner;
      let collaborator = this.state.collaborator;
      if (!collaborator.key) {
        partner.addCollaborator(collaborator, {
          next: this.onSave,
          error: this.onApiError
        }, null);
      }
      else {
        collaborator.save({
          next: this.onSave,
          error: this.onApiError
        }, null);
      }
    }
  }

  onSave(response: ApiResponse<PartnerCollaborator>) {
    this.enable();
    this.props.onSave(response);
  }

  onCancel() {
    this.enable();
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let collaborator = this.state.collaborator;

    return (

      <div>

        <FullScreenModal title={!collaborator.key ? 'New Team Member' : 'Edit Team Member'} onHide={this.onCancel}>

          <p className="lead">You can only add a team member if they already have an account. To add a team
            member, enter their email address and choose their access level below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <InputText
                id="collaborator.emailAddress"
                label="Email Address"
                value={collaborator.emailAddress}
                error={this.state.errors?.['request.emailAddress']}
                onChange={this.onFieldChange}
                className="lg"
                required/>

              <InputSelect
                id="collaborator.type"
                label="Type"
                value={collaborator.type}
                error={this.state.errors?.['request.type']}
                options={index.partnerCollaboratorTypes}
                onChange={this.onFieldChange}
                required/>

            </Panel>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Save Changes</Button>
              <Button
                onClick={this.onCancel}
                bsSize="large">Cancel</Button>
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}