import React, {ReactNode} from 'react';
import './SectionHeader.css';
import Divider from "./Divider";

type SectionHeaderProps = {
  level?: number | null | undefined;
  title: string | null | undefined;
  description?: ReactNode | string | null | undefined;
  divider?: boolean | null | undefined
  actions?: ReactNode | string | null | undefined;
};

const SectionHeader = (props: SectionHeaderProps) => {

  let level = props.level || 2;

  return <>

    {
      props.divider &&
      <Divider/>
    }

    <div className="section-header">
      {
        level === 2 &&
        <h2>
          {props.title}
          {props.actions && <span className="actions">{props.actions}</span>}
        </h2>
      }
      {
        level === 3 &&
        <h3>
          {props.title}
          {props.actions && <span className="actions">{props.actions}</span>}
        </h3>
      }
      {props.description && <p className="text-muted">{props.description}</p>}
    </div>

  </>;

}

export default SectionHeader;