import React, {ReactNode, useEffect, useState} from 'react';
import {Navbar} from 'react-bootstrap';
import {Link} from 'react-router';
import Context from '../Context';
import Events from '../Events';
import Routes from '../Routes';
import Footer from './Footer';
import PrimaryNav from './PrimaryNav';
import {RouteProps} from "../Page";
import AccessDeniedPanel from "../components/AccessDeniedPanel";
import ApiIndex from "../models/ApiIndex";
import {ApiResponse} from "startupbox-react";
import Partner from "../models/Partner";
import {ApiErrorResponse} from "../models/ApiError";
import NotFoundPanel from "../components/NotFoundPanel";

type PartnerPageLayoutProps = {
  params: { [key: string]: any };
  route: RouteProps;
  children: ReactNode;
}

type MODE = 'NOT_FOUND' | null;

const PartnerPageLayout = (props: PartnerPageLayoutProps) => {

  let [partner, setPartner] = useState<Partner | undefined>(undefined);
  let [mode, setMode] = useState<MODE>(null);

  let load = () => {
    let key = props.params.key;
    Events.app.index.subscribe((index: ApiIndex) => {
      index?.findPartnerByKey(key, {
        next: (response: ApiResponse<Partner>) => {
          onLoadPartner(response.resource!);
        },
        error: (response: ApiErrorResponse) => {
          if (response.status === 404) {
            setMode('NOT_FOUND');
          }
        }
      }, null);
    }, true);
  };

  let onLoadPartner = (partner: Partner) => {
    setPartner(partner);
    setMode(null);
  };

  let getLinkClassName = (value: string, excludes?: string[]) => {
    let path = window.location.pathname;
    if (path.indexOf(value) !== -1) {
      if (excludes) {
        for (let exclude of excludes) {
          if (path.indexOf(exclude) !== -1) {
            return undefined;
          }
        }
      }
      return 'active';
    }
    return undefined
  }

  useEffect(() => {
    Events.system.sectionTitle.publish('Partner');
    load();
  }, []);

  let isAllowed = Context.isAuthenticated(); // todo partner - admins and collaborators only
  let isNotFound = mode === 'NOT_FOUND';
  let key = props.params.key;

  return (

    <div>

      <PrimaryNav/>

      <Navbar id="secondary-nav">
        <div className="row">
          <div className="col-md-12">
            {
              isAllowed && partner &&
              <ul className="nav navbar-nav">
                <li>
                  <Link
                    to={Routes.partner.index(key)}
                    className={getLinkClassName('home')}>Partner Home</Link>
                </li>
                {
                  partner?.type?.meta?.horsesAllowed &&
                  <li>
                    <Link
                      to={Routes.partner.horses(key)}
                      className={getLinkClassName('horses')}>Horses</Link>
                  </li>
                }
                {
                  partner?.type?.meta?.collectiblesAllowed &&
                  <li>
                    <Link
                      to={Routes.partner.collectibles(key)}
                      className={getLinkClassName('collectibles', ['horses'])}>Collectibles</Link>
                  </li>
                }
                {
                    <li>
                      <Link
                          to={Routes.partner.memberships(key)}
                          className={getLinkClassName('memberships', ['horses'])}>Memberships</Link>
                    </li>
                }
                {
                  partner?.type?.meta?.packsAllowed &&
                  <li>
                    <Link
                      to={Routes.partner.packs(key)}
                      className={getLinkClassName('packs')}>Packs</Link>
                  </li>
                }
                <li>
                  <Link
                    to={Routes.partner.posts(key)}
                    className={getLinkClassName('posts', ['horses'])}>Posts</Link>
                </li>
                <li>
                  <Link
                    to={Routes.partner.team(key)}
                    className={getLinkClassName('team')}>Team</Link>
                </li>
                {
                  Context.isAdmin() &&
                  <li className="pull-right">
                    <Link
                      to={Routes.admin.partners}>Search Partners</Link>
                  </li>
                }
              </ul>
            }
          </div>
        </div>
      </Navbar>

      <div id="main-content" className="has-secondary-nav">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {isAllowed && !isNotFound && props.children}
              {!isAllowed && !isNotFound && <AccessDeniedPanel/>}
              {isNotFound && <NotFoundPanel/>}
            </div>
          </div>
        </div>
      </div>

      <Footer/>

    </div>

  );

};

export default PartnerPageLayout;