import React from 'react';
import {ApiResponse, Button, Form, FullScreenModal, GlobalMessage, ImageWithUpload, InputDatePicker, InputInlineEditor, InputText, VideoWithUpload} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import Horse from "../models/Horse";
import ApiIndex from "../models/ApiIndex";
import Collectible, {COLLECTIBLE_STANDARD_FIELDS} from "../models/Collectible";
import {acceptedFileMimeTypes, acceptedImageMimeTypes} from "../utils/acceptedFileMimeTypes";
import Binary from "../models/Binary";
import SectionHeader from "../components/SectionHeader";
import Partner from "../models/Partner";

export type CollectibleDialogProps = DialogProps & {
  index?: ApiIndex;
  horse?: Horse;
  partner?: Partner;
  collectible: Collectible;
  onSave: (response: ApiResponse<Collectible>) => void;
  onDelete: () => void;
  onCancel: () => void;
};

export type CollectibleDialogState = DialogState & {
  collectible: Collectible;
};

export default class CollectibleDialog extends Dialog<CollectibleDialogProps, CollectibleDialogState> {

  constructor(props: CollectibleDialogProps) {
    super(props);
    let collectible = this.props.collectible;
    this.setInitialState({
      collectible: collectible
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let collectible = this.state.collectible;
      if (collectible.key) {
        collectible.save({
          next: this.onSave,
          error: this.onApiError
        }, COLLECTIBLE_STANDARD_FIELDS);
      }
      else if (this.props.horse) {
        let horse = this.props.horse;
        horse.addCollectible(collectible, {
          next: this.onSave,
          error: this.onApiError
        }, COLLECTIBLE_STANDARD_FIELDS);
      }
      else if (this.props.partner) {
        let partner = this.props.partner;
        partner.addCollectible(collectible, {
          next: this.onSave,
          error: this.onApiError
        }, COLLECTIBLE_STANDARD_FIELDS);
      }
      else {
        let index = this.props.index;
        index?.createCollectible(collectible, {
          next: this.onSave,
          error: this.onApiError
        }, COLLECTIBLE_STANDARD_FIELDS);
      }
    }
  }

  isModifyAllowed() {
    let collectible = this.state.collectible;
    return !collectible.key || collectible.isModifyAllowed();
  }

  onSave(response: ApiResponse<Collectible>) {
    this.enable();
    this.props.onSave(response);
  }

  onDelete() {
    let collectible = this.state.collectible;
    collectible.delete({
      next: this.props.onDelete,
      error: this.onApiError
    });
  }

  onCancel() {
    this.enable();
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let collectible = this.state.collectible;

    return (

      <div>

        <FullScreenModal title={!collectible.key ? 'New Collectible' : 'Edit Collectible'} onHide={this.onCancel}>

          <p className="lead">Please enter the details of the collectible below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled() || !this.isModifyAllowed()}>

            <Panel>

              <InputText
                id="collectible.name"
                label="Collectible Name"
                value={collectible.name}
                error={this.state.errors?.['request.name']}
                onChange={this.onFieldChange}
                className="lg"
                required/>

              <InputText
                id="collectible.badgeLabel"
                label="Badge Label"
                help="An optional promotional text for marketing purposes. eg Selling Fast, Exclusive to BTX etc."
                value={collectible.badgeLabel}
                error={this.state.errors?.['request.badgeLabel']}
                onChange={this.onFieldChange}/>

              <div className="row">
                <div className="col-md-6">
                  <InputDatePicker
                    id="collectible.startAt"
                    label="Start Date"
                    value={collectible.startAt}
                    error={this.state.errors?.['request.startAt']}
                    dateFormat="DD/MM/YYYY"
                    onChange={this.onFieldChange}
                    required/>
                </div>
                <div className="col-md-6">
                  <InputDatePicker
                    id="collectible.endAt"
                    label="End Date"
                    placeholder="No End Date"
                    value={null}
                    error={this.state.errors?.['request.endAt']}
                    dateFormat="DD/MM/YYYY"
                    onChange={this.onFieldChange}
                    disabled/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="collectible.unitCount"
                    label="Number of Units"
                    value={collectible.unitCount}
                    format="number"
                    error={this.state.errors?.['request.unitCount']}
                    onChange={this.onFieldChange}
                    className="lg"/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="collectible.unitPrice"
                    label="Unit Price"
                    value={collectible.unitPrice}
                    format="currency"
                    error={this.state.errors?.['request.unitPrice']}
                    onChange={this.onFieldChange}
                    className="lg"
                    required/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="collectible.limitPerUser"
                    label="Limit per User"
                    help="Leave empty for no restriction."
                    value={collectible.limitPerUser}
                    format="number"
                    error={this.state.errors?.['request.limitPerUser']}
                    onChange={this.onFieldChange}
                    className="lg"/>
                </div>
              </div>

              <SectionHeader title="Media" divider/>

              <ImageWithUpload
                id="collectible.coverImage"
                label="Cover Image"
                files={collectible.coverImage ? [collectible.coverImage] : []}
                accept={acceptedImageMimeTypes}
                className="images-across-1"
                lightbox
                upload
                onChange={(e) => {
                  let images: Array<any> = e.target.value;
                  let coverImage = (images && images.length > 0) ? images[0] : undefined;
                  if (!coverImage || coverImage.externalReference) {
                    e.target.value = coverImage;
                    this.onFieldChange(e);
                  }
                }}/>

              <VideoWithUpload
                id="collectible.promotionalVideos"
                label="Promotional Videos"
                files={collectible.promotionalVideos || []}
                className="videos-across-3"
                accept="video/mp4"
                multiple
                upload
                onChange={(e: any) => {
                  let videos: Array<Binary> = e.target.value;
                  e.target.value = videos;
                  this.onFieldChange(e);
                }}/>

              <ImageWithUpload
                id="collectible.promotionalImages"
                label="Promotional Images"
                files={collectible.promotionalImages || []}
                accept={acceptedImageMimeTypes}
                className="images-across-3"
                lightbox
                multiple
                upload
                onChange={(e: any) => {
                  let images: Array<Binary> = e.target.value;
                  e.target.value = images;
                  this.onFieldChange(e);
                }}/>

              <SectionHeader title="Terms" divider/>

              <InputInlineEditor
                tools="traditional"
                id="collectible.terms.body"
                elementId="dialog-terms"
                label="Terms"
                help="A freeform rich content of the terms of this collectible comprising of text, images and file attachments."
                value={collectible.terms?.body}
                error={this.state.errors?.['request.terms.body']}
                uploadFileAcceptedMimeTypes={acceptedFileMimeTypes}
                onChange={this.onFieldChange}/>

            </Panel>

            <div className="actions">
              {
                this.isModifyAllowed() &&
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  type="submit">Save Changes</Button>
              }
              <Button
                onClick={this.onCancel}
                disabled={false}
                bsSize="large">{this.isModifyAllowed() ? 'Cancel' : 'Close'}</Button>
              {
                collectible.isDeleteAllowed() &&
                <Button
                  onClick={this.onDelete}
                  bsSize="large"
                  bsStyle="default"
                  className="pull-right">Delete</Button>
              }
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
