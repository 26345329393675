import React from 'react';
import PageHeader from '../components/PageHeader';
import HorseTabs from '../horse/HorseTabs';
import Routes from '../Routes';
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from './PartnerAbstractPage';
import Partner from "../models/Partner";
import Horse, {HORSE_STANDARD_FIELDS} from "../models/Horse";
import {ApiResponse} from "startupbox-react";
import ApiIndex from "../models/ApiIndex";
import Binary from "../models/Binary";

export type PartnerHorseAbstractPageProps = PartnerAbstractPageProps & {};

export type PartnerHorseAbstractPageState = PartnerAbstractPageState & {
  horse?: Horse
};

export default class PartnerHorseAbstractPage<P extends PartnerHorseAbstractPageProps, S extends PartnerHorseAbstractPageState> extends PartnerAbstractPage<P, S> {

  activeTab: string | null = null;

  constructor(props: P) {
    super(props);
    let initialState = {
      horse: new Horse(),
    } as S;
    this.setInitialState(initialState);
    this.getPartnerKey = this.getPartnerKey.bind(this);
    this.getHorseKey = this.getHorseKey.bind(this);
    this.setHorse = this.setHorse.bind(this);
    this.onSelectTab = this.onSelectTab.bind(this);
    this.onChangeAvatar = this.onChangeAvatar.bind(this);
  }

  getHorseFields() {
    // override this in sub-classes if required
    return HORSE_STANDARD_FIELDS;
  }

  getPartnerKey() {
    return this.props.params.key;
  }

  getHorseKey() {
    return this.props.params.horseKey;
  }

  onLoadPartner(partner: Partner) {
    let horseKey = this.getHorseKey();
    partner.findHorseByKey(horseKey, {
      next: (response: ApiResponse<Horse>) => {
        this.setHorse(response);
        this.onLoadHorse(response.resource!);
      },
      error: this.onApiError
    }, this.getHorseFields());
  }

  // noinspection JSUnusedLocalSymbols
  onLoadHorse(horse: Horse) {
    // override this in sub-classes if required
  }

  setHorse(response: ApiResponse<Horse>) {
    this.setState({
      horse: response.resource
    })
  }

  onSelectTab(tab: string) {
    let horse = this.state.horse;
    if (horse && horse.partnerKey && horse.key) {
      this.navigate(Routes.partner.horse(horse.partnerKey, horse.key, tab));
    }
  }

  onChangeAvatar(avatar: Binary | undefined) {
    let horse = this.state.horse;
    if (horse) {
      horse.thumbnailImage = avatar;
      if (!horse.thumbnailImage || horse.thumbnailImage!.externalReference) {
        horse.save({
          next: this.setHorse,
          error: this.onApiError
        }, this.getHorseFields());
      }
    }
  }

  renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

    let horse = this.state.horse;

    if (!horse || !horse.key) {
      return <></>;
    }

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title={horse.name}
            avatar={horse.thumbnailImage || null}
            onChangeAvatar={this.onChangeAvatar}
            lead={
              <>
                You are viewing your horse <strong>{horse.name}</strong>. You can manage its
                details, upload images, videos, training material and medical records here.
              </>
            }/>

          <HorseTabs
            horse={horse!}
            active={this.activeTab!}
            onSelect={this.onSelectTab}/>

          {this.renderWithIndexPartnerAndHorse(index!, partner!, horse!)}

          {this.renderPageActions(index!, partner!, horse!)}

        </div>

      </div>

    );

  }

  renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {
    return <></>;
  }

  renderPageActions(index: ApiIndex, partner: Partner, horse: Horse) {
    return <></>;
  }

};
