import React from 'react';
import {ApiResponse, Button, DialogPanel, Form, FullScreenModal, GlobalMessage, ImageWithUpload} from 'startupbox-react';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Routes from '../Routes';
import AccountNav from './AccountNav';
import User from "../models/User";

export type AccountAvatarPageProps = PageProps & {};

export type AccountAvatarPageState = PageState & {
  user: User;
  avatars: Array<any>;
};

export default class AccountAvatarPage extends Page<AccountAvatarPageProps, AccountAvatarPageState> {

  constructor(props: AccountAvatarPageProps) {
    super(props);
    this.setInitialState({
      mode: 'edit',
      disabled: true,
      user: new User(),
      avatars: []
    });
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Avatar');
    this.state.index?.findMe({
      next: this.setUser,
      error: this.onApiError
    }, 'avatar');
  }

  onAddAvatar(acceptedFiles: Array<any>) {
    for (let file of acceptedFiles) {
      this.setState({
        avatars: [file]
      });
    }
  }

  onRemoveAvatar() {
    this.setState({
      avatars: []
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      this.state.index?.uploadImages(this.state.avatars, {
        next: (files: Array<any>) => {
          let user = this.state.user;
          user.avatar = files.length > 0 ? files[0] : null;
          user.save({
            next: this.onSave,
            error: this.onApiError
          }, 'avatar');
        },
        error: this.onApiError
      });
    }
  }

  onSave(response: ApiResponse<User>) {
    this.enable();
    this.setUser(response);
    this.setErrors(null, 'Your changes have been saved successfully', 'info');
  }

  onCancel() {
    this.navigate(Routes.main.home());
  }

  setUser(response: ApiResponse<User>) {
    let user = response.resource!;
    let avatar = user.avatar;
    let avatars = avatar ? [avatar] : [];
    this.setState({
      disabled: false,
      user: user,
      avatars: avatars
    });
  }

  render() {

    let user = this.state.user;

    if (!user) {
      return null;
    }

    return (

      <div>

        <FullScreenModal title="Edit Image" className="centered-title" onHide={this.onCancel}>

          <DialogPanel>

            <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

              <AccountNav active="Avatar"/>

              <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

              <br/>

              <p className="lead">Please upload your avatar below.</p>

              <ImageWithUpload
                id="user.avatar"
                type="avatar"
                files={this.state.avatars}
                transforms="w_300"
                onAdd={this.onAddAvatar}
                onRemove={this.onRemoveAvatar}
                multiple={false}
                className="text-center"/>

              <div className="actions">
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  type="submit">Save Changes</Button>
              </div>

            </Form>

          </DialogPanel>

        </FullScreenModal>

      </div>

    );

  }

};