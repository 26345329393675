import HorseAttribute from "./HorseAttribute";

export default class HorseAttributeGroup {

  key?: string;

  name?: string;
  attributes: Array<HorseAttribute> = [];

  // ................................................................................................................................................

  constructor(name?: string) {
    this.name = name;
  }

}
