import BlockchainAttribute from "./BlockchainAttribute";

export default class BlockchainAttributeGroup {

  key?: string;

  name?: string;
  description?: string;
  attributes: Array<BlockchainAttribute> = [];

}
