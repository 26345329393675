import React from 'react';
import {Alert, ApiResponse} from 'startupbox-react';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import ApiIndex from "../models/ApiIndex";
import BlockchainMintable from "../models/BlockchainMintable";

export type BlockchainMintablePollingDialogProps = DialogProps & {
  entity: BlockchainMintable,
  entityFields: string,
  onFinish: (response: ApiResponse<BlockchainMintable>) => void;
};

export type BlockchainMintablePollingDialogState = DialogState & {
  entity: BlockchainMintable;
};

export default class BlockchainMintablePollingDialog extends Dialog<BlockchainMintablePollingDialogProps, BlockchainMintablePollingDialogState> {

  constructor(props: BlockchainMintablePollingDialogProps) {
    super(props);
    this.setInitialState({
      entity: props.entity
    });
  }

  onLoad() {
    this.addBackgroundRepeatingTask('BlockchainMintablePollingDialog', 5000, this.onReload);
  }

  onReload() {
    let entity = this.state.entity;
    entity.reload({
      next: (response: ApiResponse<BlockchainMintable>) => {
        let entity = response.resource!;
        if (entity.isMinting()) {
          this.setState({
            entity: entity
          });
        }
        else {
          this.props.onFinish(response);
        }
      },
      error: this.onApiError
    }, this.props.entityFields);
  }

  renderWithIndex(index: ApiIndex) {

    let entity = this.state.entity;

    return (

      <Alert title="Minting In Progress" okButtonLabel="Minting ..." isOkEnabled={false}>
        <p>This entity is being minted. You can wait and this dialog will auto-refresh or you can close this browser and check back later.</p>
        <p>{entity.getCountText()}</p>
      </Alert>

    );

  }

}
