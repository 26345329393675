import React from 'react';
import User from "../models/User";
import ApiIndex from "../models/ApiIndex";
import Wallet from "../models/Wallet";
import UserDeviceAbstractPage, {UserDeviceAbstractPageProps, UserDeviceAbstractPageState} from "./UserDeviceAbstractPage";
import SectionHeader from "../components/SectionHeader";
import Device from "../models/Device";
import DevicePanel from "../device/DevicePanel";

export type UserDeviceInsightsPageProps = UserDeviceAbstractPageProps & {};

export type UserDeviceInsightsPageState = UserDeviceAbstractPageState & {};

export default class UserDeviceDeviceInsightsPage extends UserDeviceAbstractPage<UserDeviceInsightsPageProps, UserDeviceInsightsPageState> {

  constructor(props: UserDeviceInsightsPageProps) {
    super(props);
    this.activeTab = 'insights';
  }

  onLoadUserAndWallet(user: User, wallet: Wallet) {
  }

  renderWithIndexUserAndDevice(index: ApiIndex, user: User, device: Device) {

    return <>

      <DevicePanel
        device={device}
        onView={null}/>

      <SectionHeader
        title="Insights"/>

      <p>Coming Soon</p>

    </>;

  }

};

