import React from 'react';
import FontAwesome from 'react-fontawesome';
import {ApiResponse, ApiSearchResponse, Button, Confirmable, Form, InputText, SearchResult, Table, TableHeaderCell} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import LegalDocument, {LEGAL_DOCUMENT_LIST_FIELDS} from "../models/LegalDocument";
import ApiIndex from "../models/ApiIndex";
import LegalDocumentSearchRequest from "../models/LegalDocumentSearchRequest";
import LegalDocumentDialog from "./LegalDocumentDialog";

let FIELDS = LEGAL_DOCUMENT_LIST_FIELDS;

export type LegalDocumentsPageProps = PageProps & {};

export type LegalDocumentsPageState = PageState & {
  legalDocumentSearchRequest: LegalDocumentSearchRequest;
  legalDocuments?: SearchResult<LegalDocument> | null;
  legalDocument?: LegalDocument | null;
};

export default class LegalDocumentsPage extends Page<LegalDocumentsPageProps, LegalDocumentsPageState> {

  constructor(props: LegalDocumentsPageProps) {
    super(props);
    this.setInitialState({
      legalDocumentSearchRequest: new LegalDocumentSearchRequest(),
    });
  }

  onEnter() {
    this.setState({
      legalDocumentSearchRequest: {
        title: this.query('title'),
        sort: this.query('sort'),
        page: this.query('page'),
      }
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('title', this.state.legalDocumentSearchRequest.title)
      .param('sort', this.state.legalDocumentSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Legal Documents');
    this.state.index?.searchLegalDocuments(this.state.legalDocumentSearchRequest, {
      next: this.setLegalDocuments,
      error: this.onApiError
    }, FIELDS);
  }

  onSave(response: ApiSearchResponse<LegalDocument>) {
    this.onLoad();
  }

  setLegalDocuments(response: ApiSearchResponse<LegalDocumentsPage>) {
    this.setState({
      legalDocuments: response.resource
    })
  }

  renderWithIndex(index: ApiIndex) {

    return (

      <div className="row">

        <div className="col-md-12">

          <PageHeader
            title="Legal Documents"
            lead="This area allows you to manage the global legal documents on the
            system. From here you may add a new document or edit the details of an existing
            document. Global legal documents are visible to users via the side menu of the app."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="legalDocumentSearchRequest.title"
              placeholder="Title"
              value={this.state.legalDocumentSearchRequest.title}
              onChange={this.onFieldChange}
              className="wide"/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              <Confirmable
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="plus"
                      bsStyle="default"
                      className="pull-right"
                      onClick={onShow}>Add</Button>
                  );
                }}
                renderConfirm={(onOk, onCancel) => {
                  let newLegalDocument = new LegalDocument();
                  newLegalDocument.files = [];
                  return (
                    <LegalDocumentDialog
                      index={index}
                      legalDocument={newLegalDocument}
                      onSave={(response: ApiResponse<LegalDocument>) => {
                        this.onSave(response);
                        onOk();
                      }}
                      onCancel={onCancel}/>
                  );
                }}/>
            </span>
          </Form>

          <br/>


          <Table
            items={this.state.legalDocuments}
            sort={this.state.legalDocumentSearchRequest.sort}
            onReload={this.onEnter}
            blankMessage="No legal documents found"
            renderHeaderRow={() => {
              return (
                <tr>
                  <TableHeaderCell label="Title" sort="title"/>
                  <TableHeaderCell label="Type" sort="title" className="col-md-4"/>
                  <TableHeaderCell label="&nbsp;" className="col-chevron"/>
                </tr>
              );
            }}
            renderItemRow={(legalDocument: LegalDocument, i: number) => {
              return (
                <Confirmable
                  key={i}
                  renderTrigger={(onShow) => {
                    return (
                      <tr className="clickable" onClick={onShow}>
                        <td>{legalDocument.title}</td>
                        <td>{legalDocument.type?.name}</td>
                        <td className="text-right">
                          <FontAwesome name="chevron-right"/>
                        </td>
                      </tr>
                    );
                  }}
                  renderConfirm={(onOk, onCancel) => {
                    return (
                      <tr>
                        <td colSpan={3}>
                          <LegalDocumentDialog
                            index={index}
                            legalDocument={legalDocument}
                            onSave={(response: ApiResponse<LegalDocument>) => {
                              this.onSave(response);
                              onOk();
                            }}
                            onCancel={() => {
                              this.onLoad();
                              onCancel();
                            }}
                            onDelete={() => {
                              this.onLoad();
                              onCancel();
                            }}/>
                        </td>
                      </tr>
                    );
                  }}/>
              );
            }}
          />

        </div>

      </div>

    );

  }

}
