import {fn, SearchResult, StatusButton, Str, Table, TableHeaderCell} from "startupbox-react";
import FontAwesome from "react-fontawesome";
import React from "react";
import Membership from "../models/Membership";

type MembershipTableProps = {
    memberships: SearchResult<Membership> | null | undefined;
    sort?: string;
    onReload?: () => void;
    onPaginate?: (page: number) => void;
    onView: (membership: Membership) => void;
};

const MembershipTable = (props: MembershipTableProps) => {

    return (
        <Table
            items={props.memberships}
            sort={props.sort}
            onReload={props.onReload}
            blankMessage="No memberships found"
            renderHeaderRow={() => {
                return (
                    <tr>
                        <TableHeaderCell label="Name" sort="name"/>
                        <TableHeaderCell label="Status" sort="status" className="col-md-2 text-right"/>
                        <TableHeaderCell label="&nbsp;" className="col-chevron"/>
                    </tr>
                );
            }}
            renderItemRow={(membership: Membership, i: number) => {
                return (
                    <tr key={i} className="clickable" onClick={fn(props.onView, membership)}>
                        <td>{membership.name} <span className="text-muted">({membership.getCountText()})</span></td>
                        <td className="text-right">
                            <StatusButton
                                id={'status-' + i}
                                value={membership.mintStatus}/>
                        </td>
                        <td className="text-right">
                            <FontAwesome name="chevron-right"/>
                        </td>
                    </tr>
                );
            }}
        />);

};

export default MembershipTable;