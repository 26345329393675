import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, Form, FullScreenModal, GlobalMessage, InputSelect, SearchResult} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import ApiIndex from "../models/ApiIndex";
import User from "../models/User";
import UserSearchRequest from "../models/UserSearchRequest";
import Membership from "../models/Membership";

export type MembershipGiftToUserDialogProps = DialogProps & {
  index: ApiIndex;
  membership: Membership;
  onSave: (response: ApiResponse<Membership>) => void;
  onCancel: () => void;
};

export type MembershipGiftToUserDialogState = DialogState & {
  users?: SearchResult<User> | null;
  user: User;
};

export default class MembershipGiftToUserDialog extends Dialog<MembershipGiftToUserDialogProps, MembershipGiftToUserDialogState> {

  constructor(props: MembershipGiftToUserDialogProps) {
    super(props);
    this.setInitialState({
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let membership = this.props.membership;
      let user = this.state.user;
      membership.giftToUser(user, {
          next: this.props.onSave,
          error: this.onApiError
      }, null);
    }
  }

  onSearchUsers(inputValue: string) {
    let userSearchRequest = new UserSearchRequest();
    userSearchRequest.name = inputValue;
    this.props.index?.searchUsers(userSearchRequest, {
      next: this.setUsers,
      error: this.onApiError
    }, null);
  }

  setUsers(response: ApiSearchResponse<User>) {
    this.setState({
      users: response.resource.values
    })
  }

  onCancel() {
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let user = this.state.user;

    return (

      <div>

        <FullScreenModal title="Gift Collectible to User" onHide={this.onCancel}>

          <p className="lead">Please select a user to gift this membership to.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <InputSelect
                id="user"
                label="User"
                value={user}
                error={this.state.errors?.['request.user']}
                onChange={this.onFieldChange}
                options={this.state.users}
                className="lg"
                enhanced={{onInputChange:this.onSearchUsers}}
                required/>

            </Panel>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Submit</Button>
              <Button
                onClick={this.onCancel}
                bsSize="large">Cancel</Button>
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
