import Model from '../Model';
import {ApiSubscribableCallbacks} from "startupbox-react";
import ApiError from "./ApiError";
import Binary from "./Binary";
import LegalDocumentType from "./LegalDocumentType";

let Rel = {
  self: 'self',
  modify: 'modify',
  delete: 'delete',
};

export const LEGAL_DOCUMENT_LIST_FIELDS = 'files';
export const LEGAL_DOCUMENT_STANDARD_FIELDS = 'files';

export default class LegalDocument {

  key?: string;

  title?: string;
  type?: LegalDocumentType;
  files?: Array<Binary>;
  createdAt?: Date;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  isModifyAllowed() {
    return !!this.href(Rel.modify);
  }

  save(callbacks: ApiSubscribableCallbacks<LegalDocument, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }

  isDeleteAllowed() {
    return !!this.href(Rel.delete);
  }

  delete(callbacks: ApiSubscribableCallbacks<null, ApiError>) {
    this.href(Rel.delete, true)
      .delete()
      .subscribe(callbacks);
  }

}
