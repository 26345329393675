import React from 'react';
import {Button, Form, FullScreenModal, GlobalMessage, InputDatePicker, InputInlineEditor, InputSelect, InputText} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import Horse from "../models/Horse";
import ApiIndex from "../models/ApiIndex";
import TrainingRecord, {TRAINING_RECORD_STANDARD_FIELDS} from "../models/TrainingRecord";
import {acceptedFileMimeTypes} from "../utils/acceptedFileMimeTypes";
import PushNotificationPreparation from "../models/PushNotificationPreparation";
import SectionHeader from "../components/SectionHeader";

export type TrainingRecordDialogProps = DialogProps & {
  horse: Horse;
  trainingRecord: TrainingRecord;
  onClose: () => void;
};

export type TrainingRecordDialogState = DialogState & {
  trainingRecord: TrainingRecord;
};

export default class TrainingRecordDialog extends Dialog<TrainingRecordDialogProps, TrainingRecordDialogState> {

  constructor(props: TrainingRecordDialogProps) {
    super(props);
    let trainingRecord = this.props.trainingRecord;
    if (!trainingRecord.pushNotificationPreparation) {
      trainingRecord.pushNotificationPreparation = new PushNotificationPreparation();
    }
    this.setInitialState({
      trainingRecord: trainingRecord
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let trainingRecord = this.state.trainingRecord;
      if (trainingRecord.key) {
        trainingRecord.save({
          next: this.onClose,
          error: this.onApiError
        }, TRAINING_RECORD_STANDARD_FIELDS);
      }
      else {
        let horse = this.props.horse;
        horse.addTrainingRecord(trainingRecord, {
          next: this.onClose,
          error: this.onApiError
        }, TRAINING_RECORD_STANDARD_FIELDS);
      }
    }
  }

  isDeleteAllowed() {
    let trainingRecord = this.state.trainingRecord;
    return trainingRecord.isDeleteAllowed();
  }

  onDelete() {
    let trainingRecord = this.state.trainingRecord;
    trainingRecord.delete({
      next: this.onClose,
      error: this.onApiError
    });
  }

  onClose() {
    this.props.onClose();
  }

  renderWithIndex(index: ApiIndex) {

    let trainingRecord = this.state.trainingRecord;

    return (

      <div>

        <FullScreenModal title={!trainingRecord.key ? 'New Training Record' : 'Edit Training Record'} onHide={this.onClose}>

          <p className="lead">Please enter the details of the training record below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <InputText
                id="trainingRecord.title"
                label="Training Record Title"
                value={trainingRecord.title}
                error={this.state.errors?.['request.title']}
                onChange={(e) => {
                  trainingRecord.title = e.target.value;
                  if (!trainingRecord.isPushNotificationStarted()) {
                    trainingRecord.pushNotificationPreparation!.title = trainingRecord.title;
                  }
                  this.setState({});
                }}
                className="lg"
                required/>

              <InputInlineEditor
                tools="traditional"
                id="trainingRecord.notes.body"
                elementId="dialog-notes"
                label="Notes"
                help="A freeform rich content notes of this training record comprising of text, images and file attachments."
                value={trainingRecord.notes?.body}
                error={this.state.errors?.['trainingRecord.notes.body']}
                uploadFileAcceptedMimeTypes={acceptedFileMimeTypes}
                onChange={this.onFieldChange}/>

              <div className="row">
                <div className="col-md-6">
                  <InputDatePicker
                    id="trainingRecord.date"
                    label="Date"
                    placeholder="Now"
                    value={trainingRecord.date}
                    error={this.state.errors?.['request.date']}
                    dateFormat="DD/MM/YYYY"
                    onChange={this.onFieldChange}
                    required/>
                </div>
                <div className="col-md-6">
                  <InputSelect
                    id="trainingRecord.status"
                    label="Status"
                    value={trainingRecord.status}
                    error={this.state.errors?.['request.status']}
                    options={index.trainingRecordStatuses}
                    onChange={this.onFieldChange}/>
                </div>
              </div>

            </Panel>

            <Panel>

              <SectionHeader title="Push Notification"/>

              <div className="row">
                <div className="col-md-12">
                  <InputSelect
                    id="trainingRecord.pushNotificationPreparation.timing"
                    label="Timing"
                    value={trainingRecord.pushNotificationPreparation?.timing}
                    error={this.state.errors?.['request.pushNotificationPreparation.timing']}
                    options={index.pushNotificationPreparationTimings}
                    onChange={this.onFieldChange}
                    readOnly={trainingRecord.isPushNotificationStarted()}/>
                </div>
              </div>

              {
                trainingRecord.isPushNotificationRequired() && <>
                  <div className="row">
                    <div className="col-md-12">
                      <InputText
                        id="trainingRecord.pushNotificationPreparation.title"
                        label="Title"
                        value={trainingRecord.pushNotificationPreparation?.title}
                        error={this.state.errors?.['request.pushNotificationPreparation.title']}
                        onChange={this.onFieldChange}
                        className="lg"
                        readOnly={trainingRecord.isPushNotificationStarted()}/>
                    </div>
                  </div>
                </>
              }

            </Panel>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Save Changes</Button>
              <Button
                onClick={this.onClose}
                bsSize="large">Cancel</Button>
              {
                this.isDeleteAllowed() &&
                <Button
                  onClick={this.onDelete}
                  bsSize="large"
                  bsStyle="default"
                  className="pull-right">Delete</Button>
              }
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
