import React from 'react';
import PartnerHorseAbstractPage, {PartnerHorseAbstractPageProps, PartnerHorseAbstractPageState} from './PartnerHorseAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Horse from "../models/Horse";
import Partner from "../models/Partner";
import SectionHeader from "../components/SectionHeader";
import {ApiSearchResponse, Button, Confirmable, SearchResult} from "startupbox-react";
import PostDialog from "../post/PostDialog";
import Post, {POST_STANDARD_FIELDS} from "../models/Post";
import PostSearchRequest from "../models/PostSearchRequest";
import PostTable from "../post/PostTable";

export type PartnerHorsePostsPageProps = PartnerHorseAbstractPageProps & {};

export type PartnerHorsePostsPageState = PartnerHorseAbstractPageState & {
  page: number;
  posts?: SearchResult<Post> | null;
}

export default class PartnerHorsePostsPage extends PartnerHorseAbstractPage<PartnerHorsePostsPageProps, PartnerHorsePostsPageState> {

  constructor(props: PartnerHorsePostsPageProps) {
    super(props);
    this.activeTab = 'posts';
  }

  onEnter() {
    this.setState({
      page: parseInt(this.query('page') || '0'),
    }, this.onLoad);
  }

  onLoadHorse(horse: Horse | undefined) {
    this.searchPosts(this.state.page + 1);
  }

  searchPosts(page: number) {
    let postSearchRequest = new PostSearchRequest();
    postSearchRequest.page = page - 1;
    this.state.horse?.searchPosts(postSearchRequest, {
      next: (response: ApiSearchResponse<Post>) => {
        let posts = response.resource;
        this.setPosts(posts);
      },
      error: this.onApiError
    }, POST_STANDARD_FIELDS);
  }

  setPosts(posts: SearchResult<Post>) {
    this.setState({
      posts: posts
    })
  }

  renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {

    let posts = this.state.posts;

    return <>

      <SectionHeader
        title="Posts"
        description="The posts of this horse are shown below. Users of the app may subscribe to
          memberships that allow viewing of these posts and the associated content."
        actions={
          <>
            <Confirmable
              renderConfirm={(onOk) => {
                let post: Post = new Post();
                return (
                  <PostDialog
                    index={index}
                    horse={horse}
                    post={post}
                    visibilityLevels={horse.visibilityLevelsForPosts}
                    visibilityLevelLabelField="meta.horsePostDescription"
                    onClose={() => {
                      this.searchPosts(1);
                      onOk();
                    }}
                  />
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <Button
                    icon="plus"
                    bsStyle="primary"
                    bsSize="xs"
                    className="pull-right"
                    onClick={onShow}
                    disabled={!horse.isAddPostAllowed()}>Add Post</Button>
                );
              }}/>
          </>
        }/>

      <PostTable
        index={index}
        horse={horse}
        posts={posts}
        visibilityLevels={horse.visibilityLevelsForPosts}
        visibilityLevelLabelField="meta.horsePostDescription"
        showVisibilityLevel
        onReload={this.onEnter}/>

    </>;

  }

};
