import React from 'react';
import {Button, Form, FullScreenModal, GlobalMessage, ImageWithUpload, InputCheckbox} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import ApiIndex from "../models/ApiIndex";
import Banner, {BANNER_STANDARD_FIELDS} from "../models/Banner";
import {acceptedImageMimeTypes} from "../utils/acceptedFileMimeTypes";
import BannerTypePanel from "./BannerTypePanel";

export type BannerDialogProps = DialogProps & {
  index: ApiIndex;
  banner: Banner;
  onSave: () => void;
  onCancel: () => void;
};

export type BannerDialogState = DialogState & {
  banner: Banner;
  coverImages: Array<any>;
};

export default class BannerDialog extends Dialog<BannerDialogProps, BannerDialogState> {

  constructor(props: BannerDialogProps) {

    super(props);

    let banner = this.props.banner;

    this.setInitialState({
      banner: banner,
      coverImages: banner.coverImage ? [banner.coverImage] : []
    });

  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      this.props.index?.uploadImages(this.state.coverImages, {
        next: (files: Array<any>) => {
          let banner = this.state.banner;
          banner.coverImage = files.length > 0 ? files[0] : null;
          this.insertOrUpdate();
        },
        error: this.onApiError
      });
    }
  }

  insertOrUpdate() {
    let banner = this.state.banner;
    if (banner.key) {
      banner.save({
        next: this.onSave,
        error: this.onApiError
      }, BANNER_STANDARD_FIELDS);
    }
    else {
      let index = this.props.index;
      index.createBanner(banner, {
        next: this.onSave,
        error: this.onApiError
      }, BANNER_STANDARD_FIELDS);
    }
  }

  onAddCoverImage(acceptedFiles: Array<any>) {
    for (let file of acceptedFiles) {
      this.setState({
        coverImages: [file]
      });
    }
  }

  onRemoveCoverImage() {
    this.setState({
      coverImages: []
    });
  }

  onSave() {
    this.props.onSave();
  }

  onCancel() {
    this.props.onCancel();
  }

  setBanner(banner: Banner) {
    this.setState({
      banner: banner
    })
  }

  renderWithIndex(index: ApiIndex) {

    let banner = this.state.banner;

    return (

      <FullScreenModal title={!banner.key ? 'New Banner' : 'Edit Banner'} onHide={this.onCancel}>

        <p className="lead">Please enter the details of the banner below.</p>

        <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

        <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

          <Panel>

            <ImageWithUpload
              id="banner.coverImage"
              label="Cover Image"
              files={this.state.coverImages}
              error={this.state.errors?.['request.coverImage']}
              transforms="w_600"
              accept={acceptedImageMimeTypes}
              onAdd={this.onAddCoverImage}
              onRemove={this.onRemoveCoverImage}
              multiple={false}
              className="images-across-1"/>

            <InputCheckbox
              id="banner.active"
              value={banner.active}
              error={this.state.errors?.['request.active']}
              onChange={this.onFieldChange}
              className="lg">Make this banner the active banner</InputCheckbox>

            <BannerTypePanel
              index={index}
              banner={banner}
              errors={this.state.errors}
              onFieldChange={this.onFieldChange}/>

          </Panel>

          <div className="actions">
            <Button
              bsStyle="primary"
              bsSize="large"
              type="submit">Save Changes</Button>
            <Button
              onClick={this.onCancel}
              bsSize="large">Cancel</Button>
          </div>

        </Form>

      </FullScreenModal>

    );

  }

}
