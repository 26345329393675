import {ApiSubscribableCallbacks} from "startupbox-react";
import ApiError from "./ApiError";
import Model from "../Model";
import Tranche from "./Tranche";
import Binary from "./Binary";
import Horse from "./Horse";

let Rel = {
  self: 'self',
  modify: 'modify',
  delete: 'delete',
  tranche_add: 'tranche_add',
};

export const LEGAL_OWNER_STANDARD_FIELDS = 'tranches,tranches.coverImage,tranches.ipfsMedia,tranches.terms,tranches.terms.body,tranches.visibilities,ownershipCertificates,horse';

export default class LegalOwner {

  key?: string;

  name?: string;
  percentage?: number;
  microOwnershipSupported?: boolean;
  horse?: Horse;



  ownershipCertificates?: Array<Binary>;

  tranches: Array<Tranche> = [];

  href = Model.hrefFunction();

  // ................................................................................................................................................

  isModifyAllowed() {
    return !!this.href(Rel.modify);
  }

  save(callbacks: ApiSubscribableCallbacks<LegalOwner, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }

  isDeleteAllowed() {
    return !!this.href(Rel.delete);
  }

  delete(callbacks: ApiSubscribableCallbacks<null, ApiError>) {
    this.href(Rel.delete, true)
      .delete()
      .subscribe(callbacks);
  }

  // tranche ....................................................................................................................................

  isAddTrancheAllowed() {
    return !!this.href(Rel.tranche_add);
  }

  addTranche(tranche: Tranche, callbacks: ApiSubscribableCallbacks<Tranche, ApiError>, fields: string | null) {
    this.href(Rel.tranche_add, true)
      .field(fields)
      .post(tranche)
      .subscribe(callbacks);
  }

}
