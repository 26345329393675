import {Context as SystemContext} from "startupbox-react";
import AuthenticationResponse from "./models/AuthenticationResponse";

export default class Context extends SystemContext {

  static setPrincipal(resource: AuthenticationResponse) {
    if (resource) {
      SystemContext.setAuthenticated(true);
      SystemContext.setAccessToken(resource.accessToken);
      SystemContext.setAdmin(resource.user!.admin);
      SystemContext.setSysadmin(resource.user!.sysadmin);
      SystemContext.setUserDisplayName(resource.user!.name);
    }
    else {
      document.cookie = 'authorization=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      SystemContext.setAuthenticated(false);
      SystemContext.setAccessToken(null);
      SystemContext.setAdmin(false);
      SystemContext.setSysadmin(false);
      SystemContext.setUserDisplayName(null);
    }
  }

}