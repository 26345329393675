import React from 'react';
import {ApiResponse, Button, Form, FullScreenModal, GlobalMessage, ImageWithUpload, VideoWithUpload} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import ApiIndex from "../models/ApiIndex";
import Collectible, {COLLECTIBLE_STANDARD_FIELDS} from "../models/Collectible";
import Binary from "../models/Binary";
import {acceptedImageMimeTypes} from "../utils/acceptedFileMimeTypes";

export type CollectibleMediaDialogProps = DialogProps & {
  collectible: Collectible;
  onSave: (response: ApiResponse<Collectible>) => void;
  onCancel: () => void;
};

export type CollectibleMediaDialogState = DialogState & {
  collectible: Collectible;
  coverImage?: Binary;
  promotionalImages?: Array<Binary>;
  promotionalVideos?: Array<Binary>;
};

export default class CollectibleMediaDialog extends Dialog<CollectibleMediaDialogProps, CollectibleMediaDialogState> {

  constructor(props: CollectibleMediaDialogProps) {
    super(props);
    let collectible = this.props.collectible;
    this.setInitialState({
      coverImage: collectible.coverImage,
      promotionalImages: collectible.promotionalImages,
      promotionalVideos: collectible.promotionalVideos,
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let collectible = this.props.collectible;
      collectible.modifyMedia(this.state.coverImage, this.state.promotionalImages, this.state.promotionalVideos, {
        next: this.onSave,
        error: this.onApiError
      }, COLLECTIBLE_STANDARD_FIELDS);
    }
  }

  isModifyAllowed() {
    let collectible = this.props.collectible;
    return collectible.isModifyMediaAllowed();
  }

  onSave(response: ApiResponse<Collectible>) {
    this.enable();
    this.props.onSave(response);
  }

  onCancel() {
    this.enable();
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let coverImage = this.state.coverImage;
    let promotionalImages = this.state.promotionalImages;
    let promotionalVideos = this.state.promotionalVideos;

    return (

      <div>

        <FullScreenModal title="Edit Collectible Media" onHide={this.onCancel}>

          <p className="lead">Please upload the media below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled() || !this.isModifyAllowed()}>

            <Panel>

              <ImageWithUpload
                id="coverImage"
                label="Cover Image"
                files={coverImage ? [coverImage] : []}
                className="images-across-1"
                accept={acceptedImageMimeTypes}
                lightbox
                upload
                onChange={(e) => {
                  let images: Array<any> = e.target.value;
                  let coverImage = (images && images.length > 0) ? images[0] : undefined;
                  if (!coverImage || coverImage.externalReference) {
                    e.target.value = coverImage;
                    this.onFieldChange(e);
                  }
                }}/>

              <VideoWithUpload
                id="promotionalVideos"
                label="Promotional Videos"
                files={promotionalVideos || []}
                className="videos-across-3"
                accept="video/mp4"
                multiple
                upload
                onChange={(e: any) => {
                  let videos: Array<Binary> = e.target.value;
                  e.target.value = videos;
                  this.onFieldChange(e);
                }}/>

              <ImageWithUpload
                id="promotionalImages"
                label="Promotional Images"
                files={promotionalImages || []}
                className="images-across-3"
                accept={acceptedImageMimeTypes}
                lightbox
                multiple
                upload
                onChange={(e: any) => {
                  let images: Array<Binary> = e.target.value;
                  e.target.value = images;
                  this.onFieldChange(e);
                }}/>

            </Panel>

            <div className="actions">
              {
                this.isModifyAllowed() &&
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  type="submit">Save Changes</Button>
              }
              <Button
                onClick={this.onCancel}
                disabled={false}
                bsSize="large">{this.isModifyAllowed() ? 'Cancel' : 'Close'}</Button>
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
