import ApiIndex from "../models/ApiIndex";
import User from "../models/User";
import BankDetails from "../models/BankDetails";
import {ApiResponse, Button, Confirmable, Environment, InputDatePicker, InputText, InputWrap, StatusButton} from "startupbox-react";
import UserStatus from "../models/UserStatus";
import Panel from "../components/Panel";
import UserDialog from "./UserDialog";
import Divider from "../components/Divider";
import React from "react";

export const UserProfilePanel = (props: {
  index: ApiIndex,
  user: User,
  bankDetails: BankDetails | undefined,
  onSave: (response: ApiResponse<User>) => void,
  onChangeStatus: (status: UserStatus) => void,
  fields: string
}) => {

  let index = props.index;
  let user = props.user;
  let onSave = props.onSave;
  let onChangeStatus = props.onChangeStatus;
  let fields = props.fields;

  if (!user.key) {
    return null;
  }

  return (

    <Panel>

      <Confirmable
        renderConfirm={(onOk, onCancel) => {
          return (
            <UserDialog
              user={user!}
              fields={fields}
              onSave={(response: ApiResponse<User>) => {
                onSave(response);
                onOk();
              }}
              onCancel={onCancel}/>
          );
        }}
        renderTrigger={(onShow) => {
          return (
            <Button
              icon="external-link"
              bsStyle="primary"
              bsSize="xs"
              className="pull-right"
              onClick={onShow}>Edit</Button>
          );
        }}/>

      <InputWrap
        id="user.name"
        label="Name">
        <div className="read-only">
          {user.name}
          {user.username && <span className="text-muted marginLeft5">({user.username})</span>}
        </div>
      </InputWrap>

      <Divider/>

      <div className="row">
        <div className="col-xs-6">
          <InputText
            id="user.emailAddress"
            label="Email Address"
            value={user.emailAddress}
            readOnly/>
        </div>
        <div className="col-xs-6 text-right">
          <InputText
            id="user.phoneNumber"
            label="Phone Number"
            value={user.phoneNumber}
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <InputText
            id="user.address.singleLineAddress"
            label="Address"
            value={user.address?.singleLineAddress}
            readOnly/>
        </div>
        <div className="col-xs-6 text-right">
          <InputDatePicker
            id="user.dob"
            label="Date of Birth"
            value={user.dob}
            dateFormat="DD/MM/YYYY"
            utcOffset={0}
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <InputWrap id="frankieOne" label="FrankieOne">
            <div>
              {user.frankieOneEntityId && <div>Entity: {user.frankieOneEntityId}</div>}
              {user.frankieOneEntityActionRecommended && <div>Status: {user.frankieOneEntityActionRecommended}</div>}
              {!user.frankieOneEntityId && !user.frankieOneEntityActionRecommended && <div className="text-blank">Not Verified</div>}
            </div>
          </InputWrap>
        </div>
        <div className="col-xs-6 text-right">
          <Button
            icon="external-link"
            bsStyle="primary"
            bsSize="xs"
            onClick={() => {
              window.location.href = Environment.current().baseBackendUrl + '/api/1/frankie-one?accessKey=' + user.myFrankieOneAccessKey;
            }}>Verify in FrankieOne</Button>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <InputWrap id="hubSpot" label="HubSpot">
            <div>
              {user.hubSpotId && <div>{user.hubSpotId}</div>}
            </div>
          </InputWrap>
        </div>
        <div className="col-xs-6 text-right">
          <InputWrap id="campaignAndReferredBy" label="Campaign and Referred By">
            <div>
              {user.campaign && <span>{user.campaign}</span>}
              {user.campaign && user.referredBy && <span> / </span>}
              {user.referredBy && <span>{user.referredBy}</span>}
              {!user.campaign && !user.referredBy && <span className="text-blank">None</span>}
            </div>
          </InputWrap>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <InputWrap id="status" label="Status">
            <div>
              <div>{user.identityVerified ? 'Identity is Verified' : 'Identity is Not Verified'}</div>
              <div>{user.ageVerified ? 'Age is 18+ years old' : (user.dob ? 'Age is less than 18 years old' : 'Age is Not Specified')}</div>
              <div>{user.australianResidencyDeclared ? 'Australian Residency Declared' : 'Australian Residency Not Yet Declared'}</div>
              <div>{user.noCriminalRecordDeclared ? 'No criminal Record Declared' : 'No criminal Record Not Yet Declared'}</div>
            </div>
          </InputWrap>
        </div>
        <div className="col-xs-6 text-right">
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <InputWrap id="premium-content" label="Premium Content">
            <div>
              <div>{user.applePremiumContentSingleAllowed ? 'Apple Single In-App Purchases is Allowed' : 'Apple Single In-App Purchases is Not Allowed'}</div>
              <div>{user.applePremiumContentRecurringAllowed ? 'Apple Recurring In-App Purchases is Allowed' : 'Apple Recurring In-App Purchases is Not Allowed'}</div>
              <div>{user.googlePremiumContentSingleAllowed ? 'Google Single In-App Purchases is Allowed' : 'Google Single In-App Purchases is Not Allowed'}</div>
              <div>{user.googlePremiumContentRecurringAllowed ? 'Google Recurring In-App Purchases is Allowed' : 'Google Recurring In-App Purchases is Not Allowed'}</div>
            </div>
          </InputWrap>
        </div>
        <div className="col-xs-6 text-right">
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <InputText
            id="user.compliance"
            label="Compliance"
            value={user.compliant ? 'Account is Compliant' : 'Account is Not Compliant'}
            readOnly/>
        </div>
        <div className="col-xs-6 text-right">
          <InputWrap id="status" label="Status">
            <div>
              <StatusButton
                id="status"
                value={user.status}
                options={index.userStatuses}
                onEdit={user.isModifyStatusAllowed() ? onChangeStatus : null}/>
            </div>
          </InputWrap>
        </div>
      </div>
      {user.status?.key === 'PENDING' && user.onboardingAccessKey &&
        <div className="row">
          <div className="col-xs-offset-6 col-xs-6 text-right">
            <InputWrap id="onboardingAccessKey" label="Onboarding access key">
              <div>
                <div>{user.onboardingAccessKey}</div>
              </div>
            </InputWrap>
          </div>
        </div>
      }

    </Panel>

  );

}