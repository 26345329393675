import React from 'react';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import DownloadPanel from "../components/DownloadPanel";

export type DownloadPageProps = PageProps & {};

export type DownloadPageState = PageState & {};

export default class DownloadPage extends Page<DownloadPageProps, DownloadPageState> {

  init() {
    super.init();
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Download');
  }

  render() {

    return (

      <div>

        <div id="main-content" className="has-secondary-nav">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-md-offset-3">
                <DownloadPanel/>
              </div>
            </div>
          </div>
        </div>

      </div>

    );

  }

}