import PremiumContentTier from "./PremiumContentTier";

export default class PremiumContentConfiguration {

  productId?: string | null;
  tier?: PremiumContentTier | null;

  constructor(productId?: string | null, tier?: PremiumContentTier | null) {
    this.productId = productId;
    this.tier = tier;
  }

}
