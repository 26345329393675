import Page, {PageProps, PageState} from '../Page';
import {ApiResponse, GlobalMessage, SearchResult} from "startupbox-react";
import ApiIndex from "../models/ApiIndex";
import React from 'react';
import {ApiErrorResponse} from "../models/ApiError";
import LegalOwner, {LEGAL_OWNER_STANDARD_FIELDS} from "../models/LegalOwner";
import LegalOwnerSearchRequest from "../models/LegalOwnerSearchRequest";
import LegalOwnerTable from "./LegalOwnerTable";
import Tranche from "../models/Tranche";
import Routes from "../Routes";
import PageHeader from "../components/PageHeader";

export type LegalOwnersPageProps = PageProps & {};

export type LegalOwnersPageState = PageState & {
    legalOwnerSearchRequest: LegalOwnerSearchRequest;
    legalOwners: SearchResult<LegalOwner> | null;
};

export default class LegalOwnersPage<P extends LegalOwnersPageProps, S extends LegalOwnersPageState> extends Page<P, S> {
    constructor(props: P) {
        super(props);
        const initialState = {
            legalOwners: null,
            legalOwnerSearchRequest: new LegalOwnerSearchRequest()
        } as S;
        this.setInitialState(initialState);
    }

    getFields() {
        // override this in sub-classes if required
        return LEGAL_OWNER_STANDARD_FIELDS;
    }

    onEnter() {
        this.setState({
            legalOwnerSearchRequest: {
                keywords: this.query('keywords'),
                sort: this.query('sort'),
                page: parseInt(this.query('page') || '0'),
            }
        }, this.onLoad);
    }

    onLoad() {
        this.searchLegalOwners(1);
    }

    searchLegalOwners(page: number) {

        this.state.index?.searchLegalOwners(this.state.legalOwnerSearchRequest, {
            next: (response: ApiResponse<SearchResult<LegalOwner>>) => {
                this.setLegalOwners(response);
                this.onLoadLegalOwners(response.resource?.values!);
            },
            error: (response: ApiErrorResponse) => {
                this.onApiError(response);
                this.showNotFound(response.status === 404);
            }
        }, this.getFields());
    }

    onLoadLegalOwners(owners: Array<LegalOwner>) {
    }

    setLegalOwners(response: ApiResponse<SearchResult<LegalOwner>>) {
        let legalOwners = response.resource!;

        this.setState({
            legalOwners,
        })
    }

    onViewTranche(tranche: Tranche) {
        this.navigate(Routes.partner.horse_tranche(tranche.partnerKey!, tranche.horseKey!, tranche.key!));
    }

    renderWithIndex(index: ApiIndex) {

        let legalOwners = this.state.legalOwners;
        if (legalOwners) {
            return (<div className="row">
                <div className="col-md-12">

                    <PageHeader
                        title="Legal Ownership and Tranches"
                        lead="A list of all tranches and legal owners are presented to the user as a way to conveniently manage tranche details. As more tranches are created they will appear here."/>

                    <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

                    <LegalOwnerTable legalOwners={legalOwners.values}
                     searchLegalOwners={this.searchLegalOwners}
                     index={index}
                     onViewTranche={this.onViewTranche}
                     onApiError={this.onApiError}/>
                </div>
            </div>)
        }

        return <></>;

    }
};
