import React from 'react';
import PartnerHorseAbstractPage, {PartnerHorseAbstractPageProps, PartnerHorseAbstractPageState} from './PartnerHorseAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Horse from "../models/Horse";
import Partner from "../models/Partner";
import Collectible, {COLLECTIBLE_STANDARD_FIELDS} from "../models/Collectible";
import {ApiErrorResponse} from "../models/ApiError";
import {ApiResponse, ApiSearchResponse, Button, GlobalMessage, SearchResult} from "startupbox-react";
import Routes from "../Routes";
import SectionHeader from "../components/SectionHeader";
import BlockchainMintableActualsTable from "../components/BlockchainMintableActualsTable";
import Membership from "../models/Membership";
import MembershipSearchRequest from "../models/MembershipSearchRequest";

let FIELDS = COLLECTIBLE_STANDARD_FIELDS;

export type PartnerHorseMembershipActualsPageProps = PartnerHorseAbstractPageProps & {};

export type PartnerHorseMembershipActualsPageState = PartnerHorseAbstractPageState & {
  membership: Membership | null | undefined;
  actuals?: SearchResult<Collectible> | null;
}

export default class PartnerHorseMembershipActualsPage extends PartnerHorseAbstractPage<PartnerHorseMembershipActualsPageProps, PartnerHorseMembershipActualsPageState> {

  constructor(props: PartnerHorseMembershipActualsPageProps) {
    super(props);
    this.activeTab = 'memberships';
  }

  onLoadHorse(horse: Horse | undefined) {
    this.loadMembership();
  }

  loadMembership() {
    this.state.horse?.findMembershipByKey(this.props.params.membershipKey, {
      next: (response => {
        this.setCollectible(response, () => {
          this.searchActuals(1);
        });
      }),
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, FIELDS);
  }

  searchActuals(page: number) {
    let searchRequest = new MembershipSearchRequest();
    searchRequest.page = page - 1;
    this.state.membership?.searchActuals(searchRequest, {
      next: (response: ApiSearchResponse<Membership>) => {
        let actuals = response.resource;
        this.setActuals(actuals);
      },
      error: this.onApiError
    }, null);
  }

  onBack() {
    this.navigate(Routes.partner.horse_membership(this.getPartnerKey(), this.getHorseKey(), this.props.params.membershipKey))
  }

  setCollectible(response: ApiResponse<Membership>, callback?: () => void) {
    let membership = response.resource;
    this.setState({
      membership: membership
    }, callback);
  }

  setActuals(memberships: SearchResult<Membership>) {
    this.setState({
      actuals: memberships
    });
  }

  renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {

    let membership = this.state.membership;
    let actuals = this.state.actuals;

    if (!membership) {
      return <></>;
    }

    return <>

      <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

      <SectionHeader
        title="Membership Actuals"
        description={
          <>
            The actual instances of this membership are shown below. Actual instances are what users buy. The characteristics
            and metadata of an actual instance are the same as the template from which it is created from.
          </>
        }
        actions={
          <>
            <Button
              icon="chevron-left"
              iconPosition="left"
              bsStyle="default"
              bsSize="xs"
              className="pull-right"
              onClick={this.onBack}>Back To Template</Button>
          </>
        }/>

      <BlockchainMintableActualsTable
        actuals={actuals}
        onPaginate={this.searchActuals}/>

    </>;

  }

};
