import Model from "../Model";
import DevicePlatformType from "./DevicePlatformType";
import {ApiSubscribableCallbacks, SearchResult} from "startupbox-react";
import ApiError from "./ApiError";
import DeviceEventSearchRequest from "./DeviceEventSearchRequest";
import DeviceEvent from "./DeviceEvent";

let Rel = {
  self: 'self',
  event_search: 'event_search',
};

export const DEVICE_STANDARD_FIELDS = '';

export default class Device {

  key?: string;

  physicalIdentifier?: string;
  sessionIdentifier?: string;
  clientVersion?: string;
  platformType?: DevicePlatformType;
  brand?: string;
  modelName?: string;
  osName?: string;
  osVersion?: string;
  userAgent?: string;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  getBrandAndModelName() {
    let value = this.brand || '';
    value += (this.brand && this.modelName ? ' ' : '');
    value += (this.modelName || '');
    return value;
  }

  reload(callbacks: ApiSubscribableCallbacks<Device, ApiError>, fields: string | null) {
    this.href(Rel.self, true)!
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // ................................................................................................................................................

  searchEvents(params: DeviceEventSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<DeviceEvent>, ApiError>, fields: string | null) {
    this.href(Rel.event_search, true)
      .param('keywords', params.keywords)
      .param('key', params.key)
      .param('type', params.type)
      .param('details', params.details)
      .param('sort', params.sort || 'user.name,asc')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

}
