import Model from "../Model";
import {ApiSubscribableCallbacks, SearchResult} from "startupbox-react";
import ApiError from "./ApiError";
import Content from "./Content";
import Binary from "./Binary";
import CollectibleStatus from "./CollectibleStatus";
import BlockchainMintable from "./BlockchainMintable";
import CollectibleSearchRequest from "./CollectibleSearchRequest";
import BlockchainMintStatus from "./BlockchainMintStatus";
import User from "./User";

let Rel = {
  self: 'self',
  modify: 'modify',
  modifyStatus: 'modifyStatus',
  modifyMintStatus: 'modifyMintStatus',
  modifyName: 'modifyName',
  modifyBadge: 'modifyBadge',
  modifyMedia: 'modifyMedia',
  modifyFeatured: 'modifyFeatured',
  delete: 'delete',
  sendReviewRequestNotification: 'sendReviewRequestNotification',
  giftToUser: 'giftToUser',
  collectible_search: 'collectible_search',
};

export const COLLECTIBLE_STANDARD_FIELDS = 'coverImage,promotionalImages,promotionalVideos,ipfsMedia,terms,terms.body,manageableMetadataAttributeGroup';
export const COLLECTIBLE_LIST_FIELDS = 'coverImage,promotionalImages,promotionalVideos,ipfsMedia,terms,terms.body,manageableMetadataAttributeGroup';

export default class Collectible extends BlockchainMintable {

  key?: string;

  paymentAccountProductId?: string;
  name?: string;
  startAt?: Date;
  activatedAt?: Date;
  unitPrice?: number;
  unitCount?: number = 1;
  remainingUnitCount?: number = 0;
  limitPerUser?: number;
  terms?: Content = new Content();
  status?: CollectibleStatus;

  coverImage?: Binary;
  promotionalImages?: Array<Binary>;
  promotionalVideos?: Array<Binary>;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  getCountText() {
    return this.remainingUnitCount + '/' + this.unitCount;
  }

  hasTerms() {
    return this.terms && this.terms.body && this.terms.body.blocks && this.terms.body.blocks.length > 0;
  }

  // ................................................................................................................................................

  reload(callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.self, true)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  isModifyAllowed() {
    return !!this.href(Rel.modify);
  }

  save(callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }

  isModifyStatusAllowed() {
    return !!this.href(Rel.modifyStatus);
  }

  modifyStatus(status: CollectibleStatus | null, callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.modifyStatus, true)
      .field(fields)
      .patch({status: status})
      .subscribe(callbacks);
  }

  isModifyMintStatusAllowed() {
    return !!this.href(Rel.modifyMintStatus);
  }

  modifyMintStatus(mintStatus: BlockchainMintStatus | null, callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.modifyMintStatus, true)
      .field(fields)
      .patch({mintStatus: mintStatus})
      .subscribe(callbacks);
  }

  isModifyNameAllowed() {
    return !!this.href(Rel.modifyName);
  }

  modifyName(name: string | null, callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.modifyName, true)
      .field(fields)
      .patch({name: name})
      .subscribe(callbacks);
  }

  isModifyBadgeAllowed() {
    return !!this.href(Rel.modifyBadge);
  }

  modifyBadge(badgeLabel: string | null, callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.modifyBadge, true)
      .field(fields)
      .patch({badgeLabel: badgeLabel})
      .subscribe(callbacks);
  }

  isModifyMediaAllowed() {
    return !!this.href(Rel.modifyMedia);
  }

  modifyMedia(coverImage: Binary | undefined, promotionalImages: Array<Binary> | undefined, promotionalVideos: Array<Binary> | undefined, callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.modifyMedia, true)
      .field(fields)
      .patch({coverImage: coverImage, promotionalImages: promotionalImages, promotionalVideos: promotionalVideos})
      .subscribe(callbacks);
  }

  isModifyFeaturedAllowed() {
    return !!this.href(Rel.modifyFeatured);
  }

  modifyFeatured(featured: boolean | null, callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.modifyFeatured, true)
      .field(fields)
      .patch({featured: featured})
      .subscribe(callbacks);
  }

  isDeleteAllowed() {
    return !!this.href(Rel.delete);
  }

  delete(callbacks: ApiSubscribableCallbacks<null, ApiError>) {
    this.href(Rel.delete, true)
      .delete()
      .subscribe(callbacks);
  }

  isSendReviewRequestNotificationAllowed() {
    return !!this.href(Rel.sendReviewRequestNotification);
  }

  sendReviewRequestNotification(callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.sendReviewRequestNotification, true)
      .field(fields)
      .post({})
      .subscribe(callbacks);
  }

  isGiftToUserAllowed() {
    return !!this.href(Rel.giftToUser);
  }

  giftToUser(user: User, callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.giftToUser, true)
      .field(fields)
      .post({user: user})
      .subscribe(callbacks);
  }

  // actuals ........................................................................................................................................

  searchActuals(request: CollectibleSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Collectible>, ApiError>, fields: string | null) {
    this.href(Rel.collectible_search, true)!
      .param('keywords', request.keywords)
      .param('key', request.key)
      .param('name', request.name)
      .param('sort', request.sort || 'id,asc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

}
