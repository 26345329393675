export default class SettingSearchRequest {

  name?: string;
  value?: string;

  sort?: string;
  page?: string | number;
  size?: string | number;

}
