export default class LegalOwnerSearchRequest {

  keywords?: string;
  key?: string;
  name?: string;

  sort?: string;
  page?: string | number;
  size?: string | number;

}
