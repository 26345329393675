import React from 'react';
import {ApiResponse, GlobalMessage} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Page, {PageProps, PageState} from '../Page';
import User from "../models/User";
import ApiIndex from "../models/ApiIndex";
import Events from "../Events";
import Routes from "../Routes";
import AdminDashboardTabs from "./AdminDashboardTabs";

export type AdminDashboardAbstractPageProps = PageProps & {};

export type AdminDashboardAbstractPageState = PageState & {
  me?: User;
  timestamp: number;
};

export default class AdminDashboardAbstractPage<P extends AdminDashboardAbstractPageProps, S extends AdminDashboardAbstractPageState> extends Page<P, S> {

  activeTab: string | null = null;
  subTitle: string | null = null;
  description: string | null = null;

  constructor(props: P) {
    super(props);
    let initialState = {
      timestamp: Date.now(),
    } as S;
    this.setInitialState(initialState);
    this.onSelectTab = this.onSelectTab.bind(this);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Dashboard');
    this.loadMe();
  }

  loadMe() {
    this.state.index?.findMe({
      next: (response: ApiResponse<User>) => {
        this.setState({
          me: response.resource
        })
      },
      error: this.onApiError
    }, null);
  }

  onSelectTab(tab: string) {
    this.navigate(Routes.admin.dashboard(tab));
  }

  renderWithIndex(index: ApiIndex) {

    let me = this.state.me;
    let timestamp = this.state.timestamp;

    if (!me) {
      return <></>;
    }

    return (

      <div className="row">

        <div className="col-md-12">

          <PageHeader
            title="Admin Dashboard"
            subTitle={this.subTitle}/>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <AdminDashboardTabs
            active={this.activeTab!}
            onSelect={this.onSelectTab}/>

          <p className="text-muted">{this.description}</p>

          {this.renderWithIndexAndUser(index, me!, timestamp)}

        </div>

      </div>

    );

  }

  renderWithIndexAndUser(index: ApiIndex, me: User, timestamp: number) {
    return <></>;
  }

};
