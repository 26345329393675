import React from 'react';
import {Button, Form, FullScreenModal, GlobalMessage, InputDatePicker, InputInlineEditor, InputSelect, InputText} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import Horse from "../models/Horse";
import ApiIndex from "../models/ApiIndex";
import MedicalRecord, {MEDICAL_RECORD_STANDARD_FIELDS} from "../models/MedicalRecord";
import {acceptedFileMimeTypes} from "../utils/acceptedFileMimeTypes";
import PushNotificationPreparation from "../models/PushNotificationPreparation";
import SectionHeader from "../components/SectionHeader";

export type MedicalRecordDialogProps = DialogProps & {
  horse: Horse;
  medicalRecord: MedicalRecord;
  onClose: () => void;
};

export type MedicalRecordDialogState = DialogState & {
  medicalRecord: MedicalRecord;
};

export default class MedicalRecordDialog extends Dialog<MedicalRecordDialogProps, MedicalRecordDialogState> {

  constructor(props: MedicalRecordDialogProps) {
    super(props);
    let medicalRecord = this.props.medicalRecord;
    if (!medicalRecord.pushNotificationPreparation) {
      medicalRecord.pushNotificationPreparation = new PushNotificationPreparation();
    }
    this.setInitialState({
      medicalRecord: medicalRecord
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let medicalRecord = this.state.medicalRecord;
      if (medicalRecord.key) {
        medicalRecord.save({
          next: this.onClose,
          error: this.onApiError
        }, MEDICAL_RECORD_STANDARD_FIELDS);
      }
      else {
        let horse = this.props.horse;
        horse.addMedicalRecord(medicalRecord, {
          next: this.onClose,
          error: this.onApiError
        }, MEDICAL_RECORD_STANDARD_FIELDS);
      }
    }
  }

  isDeleteAllowed() {
    let medicalRecord = this.state.medicalRecord;
    return medicalRecord.isDeleteAllowed();
  }

  onDelete() {
    let medicalRecord = this.state.medicalRecord;
    medicalRecord.delete({
      next: this.onClose,
      error: this.onApiError
    });
  }

  onClose() {
    this.props.onClose();
  }

  renderWithIndex(index: ApiIndex) {

    let medicalRecord = this.state.medicalRecord;

    return (

      <div>

        <FullScreenModal title={!medicalRecord.key ? 'New Medical Record' : 'Edit Medical Record'} onHide={this.onClose}>

          <p className="lead">Please enter the details of the medical record below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <InputText
                id="medicalRecord.title"
                label="Medical Record Title"
                value={medicalRecord.title}
                error={this.state.errors?.['request.title']}
                onChange={(e) => {
                  medicalRecord.title = e.target.value;
                  if (!medicalRecord.isPushNotificationStarted()) {
                    medicalRecord.pushNotificationPreparation!.title = medicalRecord.title;
                  }
                  this.setState({});
                }}
                className="lg"
                required/>

              <InputInlineEditor
                tools="traditional"
                id="medicalRecord.notes.body"
                elementId="dialog-notes"
                label="Notes"
                help="A freeform rich content notes of this medical record comprising of text, images and file attachments."
                value={medicalRecord.notes?.body}
                error={this.state.errors?.['medicalRecord.notes.body']}
                uploadFileAcceptedMimeTypes={acceptedFileMimeTypes}
                onChange={this.onFieldChange}/>

              <div className="row">
                <div className="col-md-6">
                  <InputDatePicker
                    id="medicalRecord.date"
                    label="Date"
                    placeholder="Now"
                    value={medicalRecord.date}
                    error={this.state.errors?.['request.date']}
                    dateFormat="DD/MM/YYYY"
                    onChange={this.onFieldChange}
                    required/>
                </div>
                <div className="col-md-6">
                  <InputSelect
                    id="medicalRecord.status"
                    label="Status"
                    value={medicalRecord.status}
                    error={this.state.errors?.['request.status']}
                    options={index.medicalRecordStatuses}
                    onChange={this.onFieldChange}/>
                </div>
              </div>

            </Panel>

            <Panel>

              <SectionHeader title="Push Notification"/>

              <div className="row">
                <div className="col-md-12">
                  <InputSelect
                    id="medicalRecord.pushNotificationPreparation.timing"
                    label="Timing"
                    value={medicalRecord.pushNotificationPreparation?.timing}
                    error={this.state.errors?.['request.pushNotificationPreparation.timing']}
                    options={index.pushNotificationPreparationTimings}
                    onChange={this.onFieldChange}
                    readOnly={medicalRecord.isPushNotificationStarted()}/>
                </div>
              </div>

              {
                medicalRecord.isPushNotificationRequired() && <>
                  <div className="row">
                    <div className="col-md-12">
                      <InputText
                        id="medicalRecord.pushNotificationPreparation.title"
                        label="Title"
                        value={medicalRecord.pushNotificationPreparation?.title}
                        error={this.state.errors?.['request.pushNotificationPreparation.title']}
                        onChange={this.onFieldChange}
                        className="lg"
                        readOnly={medicalRecord.isPushNotificationStarted()}/>
                    </div>
                  </div>
                </>
              }

            </Panel>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Save Changes</Button>
              <Button
                onClick={this.onClose}
                bsSize="large">Cancel</Button>
              {
                this.isDeleteAllowed() &&
                <Button
                  onClick={this.onDelete}
                  bsSize="large"
                  bsStyle="default"
                  className="pull-right">Delete</Button>
              }
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
