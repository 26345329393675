import React from 'react';
import User from "../models/User";
import ApiIndex from "../models/ApiIndex";
import Wallet from "../models/Wallet";
import UserWalletAbstractPage, {UserWalletAbstractPageProps, UserWalletAbstractPageState} from "./UserWalletAbstractPage";
import {ApiSearchResponse, SearchResult, Str, Table, TableHeaderCell} from "startupbox-react";
import Collectible from "../models/Collectible";
import SectionHeader from "../components/SectionHeader";
import TransactionSearchRequest from "../models/TransactionSearchRequest";
import Transaction, {TRANSACTION_LIST_FIELDS} from "../models/Transaction";

export type UserWalletTransactionsPageProps = UserWalletAbstractPageProps & {};

export type UserWalletTransactionsPageState = UserWalletAbstractPageState & {
  transactions: SearchResult<Transaction> | null;
};

export default class UserWalletTransactionsPage extends UserWalletAbstractPage<UserWalletTransactionsPageProps, UserWalletTransactionsPageState> {

  constructor(props: UserWalletTransactionsPageProps) {
    super(props);
    this.activeTab = 'transactions';
  }

  onLoadUserAndWallet(user: User, wallet: Wallet) {
    this.searchTransactions(1);
  }

  searchTransactions(page: number) {
    let transactionSearchRequest = new TransactionSearchRequest();
    transactionSearchRequest.page = page - 1;
    this.state.wallet?.searchTransactions(transactionSearchRequest, {
      next: (response: ApiSearchResponse<Collectible>) => {
        let transactions = response.resource;
        this.setTransactions(transactions);
      },
      error: this.onApiError
    }, TRANSACTION_LIST_FIELDS);
  }

  setTransactions(transactions: SearchResult<Transaction>) {
    this.setState({
      transactions: transactions
    })
  }

  renderWithIndexUserAndWallet(index: ApiIndex, user: User, wallet: Wallet) {

    let transactions = this.state.transactions;

    return <>

      <SectionHeader
        title="Transactions"/>

      <Table
        items={transactions}
        onPaginate={this.searchTransactions}
        blankMessage="No transactions found"
        renderHeaderRow={() => {
          return (
            <tr>
              <TableHeaderCell label="Date" className="col-md-2"/>
              <TableHeaderCell label="Description"/>
              <TableHeaderCell label="Details" className="col-md-2 text-center"/>
              <TableHeaderCell label="Amount" className="text-right"/>
            </tr>
          );
        }}
        renderItemRow={(transaction: Transaction, t: number) => {
          return (
            <tr key={t} className="clickable" data-key={transaction.key}>
              <td>
                <div>{Str.formatDate(transaction.date, "DD/MM/YYYY")}</div>
                <div className="text-muted">{Str.formatDate(transaction.date, "h:mm:ss a")}</div>
              </td>
              <td>
                <div>
                  {transaction.subscribableName}
                  <span className="text-muted marginLeft5">{transaction.subscribableType?.name}</span>
                </div>
                <div className="text-muted">
                  Subscription {transaction.subscriptionKey}
                </div>
                {
                  transaction.enclosingPackSubscriptionKey &&
                  <div className="text-muted">
                    {transaction.enclosingPackSubscribableName} Subscription {transaction.enclosingPackSubscriptionKey}
                  </div>
                }
                {
                  transaction.blockchainTransactionHash &&
                  <div><a href={transaction.viewUrl} target="_blank" rel="noopener noreferrer">{transaction.blockchainTransactionHash?.substring(0, 30)} ...</a></div>
                }
                <div className="text-muted">{transaction.status?.name}</div>
              </td>
              <td className="text-center">{transaction.unitCount + (transaction.unitCount! > 1 ? ' units' : ' unit')}</td>
              <td className="text-right">{Str.formatNumber(transaction.totalAmount, 2, '.', ',')}</td>
            </tr>
          );
        }}
      />

    </>;

  }

};

