import StatisticSearchRequest from "./StatisticSearchRequest";
import {DeviceStatisticTypeKey} from "./DeviceStatisticType";

export default class DeviceStatisticSearchRequest extends StatisticSearchRequest {

  statisticType?: DeviceStatisticTypeKey | null;
  platformType?: string | null;
  active?: boolean;

}
