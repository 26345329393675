import Model from '../Model';
import {ApiSubscribableCallbacks} from "startupbox-react";
import ApiError from "./ApiError";
import HorseImportRunStatus from "./HorseImportRunStatus";
import HorseImportRunHorse from "./HorseImportRunHorse";

let Rel = {
  self: 'self',
  modify: 'modify',
  modifyStatus: 'modifyStatus',
};

export const HORSE_IMPORT_RUN_STANDARD_FIELDS = 'horses';

export default class HorseImportRun {

  key?: string;

  createdAt?: Date;
  status?: HorseImportRunStatus;
  horses?: Array<HorseImportRunHorse>;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  isModifyAllowed() {
    return !!this.href(Rel.modify);
  }

  save(callbacks: ApiSubscribableCallbacks<HorseImportRun, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }

  isModifyStatusAllowed() {
    return !!this.href(Rel.modifyStatus);
  }

  modifyStatus(status: HorseImportRunStatus | null, callbacks: ApiSubscribableCallbacks<HorseImportRun, ApiError>, fields: string | null) {
    this.href(Rel.modifyStatus, true)
      .field(fields)
      .patch({status: status})
      .subscribe(callbacks);
  }

}
