import React from 'react';
import {ApiSearchResponse, Button, Confirmable, Form, InputText, SearchResult} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import ApiIndex from "../models/ApiIndex";
import PostSearchRequest from "../models/PostSearchRequest";
import Post, {POST_STANDARD_FIELDS} from "../models/Post";
import PostDialog from "./PostDialog";
import PostTable from "./PostTable";

export type PostsPageProps = PageProps & {};

export type PostsPageState = PageState & {
  postSearchRequest: PostSearchRequest;
  posts?: SearchResult<Post> | null;
};

export default class PostsPage extends Page<PostsPageProps, PostsPageState> {

  constructor(props: PostsPageProps) {
    super(props);
    this.setInitialState({
      postSearchRequest: new PostSearchRequest()
    });
  }

  onEnter() {
    this.setState({
      postSearchRequest: {
        keywords: this.query('keywords'),
        page: parseInt(this.query('page') || '0'),
      },
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('keywords', this.state.postSearchRequest.keywords)
      .param('sort', this.state.postSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'News');
    this.searchNews(this.state.postSearchRequest.page + 1);
  }

  searchNews(page: number) {
    let postSearchRequest = this.state.postSearchRequest;
    postSearchRequest.page = page - 1;
    this.state.index?.searchNews(postSearchRequest, {
      next: this.setPosts,
      error: this.onApiError
    }, POST_STANDARD_FIELDS);
  }

  setPosts(response: ApiSearchResponse<Post>) {
    this.setState({
      posts: response.resource
    })
  }

  renderWithIndex(index: ApiIndex) {

    let posts = this.state.posts;

    return (

      <div className="row">

        <div className="col-md-12">

          <PageHeader
            title="News"
            lead="This area allows you to manage the BTX News which is visible to all users on
            the platform. From here you may add a new post or edit the details of an existing post."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="postSearchRequest.keywords"
              placeholder="Keywords"
              value={this.state.postSearchRequest.keywords}
              onChange={this.onFieldChange}
              className="wide"/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              <Confirmable
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="plus"
                      bsStyle="default"
                      className="pull-right"
                      onClick={onShow}>Add</Button>
                  );
                }}
                renderConfirm={(onOk) => {
                  return (
                    <PostDialog
                      index={index}
                      post={new Post()}
                      visibilityLevels={index.visibilityLevelsForNews}
                      visibilityLevelLabelField="meta.membershipPostDescription"
                      onClose={() => {
                        this.searchNews(1);
                        onOk();
                      }}/>
                  );
                }}/>
            </span>
          </Form>

          <br/>

          <PostTable
            index={index}
            posts={posts}
            onReload={this.onEnter}
            visibilityLevels={index.visibilityLevelsForNews}
            visibilityLevelLabelField="meta.membershipPostDescription"
            showVisibilityLevel/>

        </div>

      </div>

    );

  }

}
