import React from 'react';
import {ApiResponse, Button, Form, FullScreenModal, GlobalMessage, InputSelect, InputText} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import BlockchainAttribute from "../models/BlockchainAttribute";
import BlockchainAttributeValue from "./BlockchainAttributeValue";
import ApiIndex from "../models/ApiIndex";
import BlockchainAttributeGroup from "../models/BlockchainAttributeGroup";
import Divider from "../components/Divider";
import ApiError from "../models/ApiError";
import BlockchainMintable from "../models/BlockchainMintable";

export type BlockchainMintableAttributeDialogProps = DialogProps & {
  entity: BlockchainMintable;
  group: BlockchainAttributeGroup;
  attribute: BlockchainAttribute;
  i?: number;
  entityFields: string | null;
  onClose: (response: ApiResponse<BlockchainMintable>) => void;
};

export type BlockchainMintableAttributeDialogState = DialogState & {
  attribute: BlockchainAttribute;
};

export default class BlockchainMintableAttributeDialog extends Dialog<BlockchainMintableAttributeDialogProps, BlockchainMintableAttributeDialogState> {

  constructor(props: BlockchainMintableAttributeDialogProps) {
    super(props);
    this.setInitialState({
      attribute: this.props.attribute
    });
  }

  onValueChange(stringValue: string) {
    let attribute = this.state.attribute;
    attribute.stringValue = stringValue;
    this.setState({
      attribute: attribute
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let entity = this.props.entity;
      let group = this.props.group;
      let isNew = this.isNewAttribute();
      if (isNew) {
        group.attributes.push(this.state.attribute);
      }
      entity.save({
        next: this.onCloseWithReloadedEntity,
        error: (response: ApiResponse<ApiError>) => {
          if (isNew) {
            group.attributes.splice(group.attributes.length - 1, 1);
          }
          this.onApiError(response);
        }
      }, this.props.entityFields);
    }
  }

  onCancel() {
    if (this.isEnabled()) {
      this.disable();
      let entity = this.props.entity;
      entity.reload({
        next: this.onCloseWithReloadedEntity,
        error: this.onApiError
      }, this.props.entityFields);
    }
  }

  onCloseWithReloadedEntity(response: ApiResponse<BlockchainMintable>) {
    this.enable();
    this.props.onClose(response);
  }

  isNewAttribute() {
    return this.props.i === undefined;
  }

  onDelete() {
    let i = this.props.i;
    if (i !== null && i !== undefined) {
      let group = this.props.group;
      group.attributes.splice(i, 1);
      this.onSubmit();
    }
  }

  renderWithIndex(index: ApiIndex) {

    let attribute = this.state.attribute;

    return (

      <div>

        <FullScreenModal title={!attribute.key ? 'New Attribute' : 'Edit Attribute'} onHide={this.onCancel}>

          <p className="lead">Please enter the details of this attribute below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <InputText
                id="attribute.name"
                label="Attribute Name"
                value={attribute.name}
                error={this.state.errors?.['request.name']}
                onChange={this.onFieldChange}
                className="lg"
                required/>

              <InputSelect
                id="attribute.type"
                label="Type"
                value={attribute.type}
                options={index.blockchainAttributeTypes}
                onChange={(e) => {
                  this.onFieldChange(e, () => {
                    attribute.stringValue = undefined;
                    this.setState({
                      attribute: attribute
                    });
                  });
                }}/>

              {
                attribute.type &&
                <>

                  <BlockchainAttributeValue
                    attribute={attribute}
                    onChange={this.onValueChange}/>

                  <Divider/>

                </>
              }

            </Panel>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Save Changes</Button>
              <Button
                onClick={this.onCancel}
                bsSize="large">Cancel</Button>
              {
                !this.isNewAttribute() &&
                <Button
                  onClick={this.onDelete}
                  bsSize="large"
                  bsStyle="default"
                  className="pull-right">Delete</Button>
              }
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
