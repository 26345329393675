import {ApiSubscribableCallbacks} from "startupbox-react";
import Model from "../Model";
import ApiError from "./ApiError";

let Rel = {
  self: 'self',
  modify: 'modify',
};

export default class Setting {

  key?: string;

  name?: string;
  value?: string;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  save(callbacks: ApiSubscribableCallbacks<Setting, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }


}
