import React from 'react';
import {ApiResponse, Button, Form, FullScreenModal, GlobalMessage, InputText} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import ApiIndex from "../models/ApiIndex";
import Tranche, {TRANCHE_STANDARD_FIELDS} from "../models/Tranche";

export type TrancheNameDialogProps = DialogProps & {
  tranche: Tranche;
  onSave: (response: ApiResponse<Tranche>) => void;
  onCancel: () => void;
};

export type TrancheNameDialogState = DialogState & {
  tranche: Tranche;
  name: string;
};

export default class TrancheNameDialog extends Dialog<TrancheNameDialogProps, TrancheNameDialogState> {

  constructor(props: TrancheNameDialogProps) {
    super(props);
    let tranche = this.props.tranche;
    this.setInitialState({
      name: tranche.name
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let tranche = this.props.tranche;
      tranche.modifyName(this.state.name, {
        next: this.onSave,
        error: this.onApiError
      }, TRANCHE_STANDARD_FIELDS);
    }
  }

  isModifyAllowed() {
    let tranche = this.props.tranche;
    return tranche.isModifyNameAllowed();
  }

  onSave(response: ApiResponse<Tranche>) {
    this.enable();
    this.props.onSave(response);
  }

  onCancel() {
    this.enable();
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let name = this.state.name;

    return (

      <div>

        <FullScreenModal title="Edit Tranche Name" onHide={this.onCancel}>

          <p className="lead">Please enter the name below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled() || !this.isModifyAllowed()}>

            <Panel>

              <InputText
                id="name"
                label="Name"
                value={name}
                error={this.state.errors?.['request.name']}
                onChange={this.onFieldChange}/>

            </Panel>

            <div className="actions">
              {
                this.isModifyAllowed() &&
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  type="submit">Save Changes</Button>
              }
              <Button
                onClick={this.onCancel}
                disabled={false}
                bsSize="large">{this.isModifyAllowed() ? 'Cancel' : 'Close'}</Button>
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
