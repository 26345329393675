import React from 'react';
import PartnerHorseAbstractPage, {PartnerHorseAbstractPageProps, PartnerHorseAbstractPageState} from './PartnerHorseAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Horse from "../models/Horse";
import Partner from "../models/Partner";
import Payout, {PAYOUT_STANDARD_FIELDS} from "../models/Payout";
import {ApiErrorResponse} from "../models/ApiError";
import {ApiResponse, ApiSearchResponse, Button, GlobalMessage, InputDatePicker, InputText, InputWrap, SearchResult, StatusButton, Str, Table, TableHeaderCell} from "startupbox-react";
import Routes from "../Routes";
import Panel from "../components/Panel";
import SectionHeader from "../components/SectionHeader";
import EntitlementSearchRequest from "../models/EntitlementSearchRequest";
import Entitlement, {ENTITLEMENT_STANDARD_FIELDS} from "../models/Entitlement";

export type PartnerHorseTrancheRacePayoutProps = PartnerHorseAbstractPageProps & {};

export type PartnerHorseTrancheRacePayoutState = PartnerHorseAbstractPageState & {
  payout: Payout | null | undefined;
  entitlements?: SearchResult<Entitlement> | null;
}

export default class PartnerHorseTranchePayoutPage extends PartnerHorseAbstractPage<PartnerHorseTrancheRacePayoutProps, PartnerHorseTrancheRacePayoutState> {

  constructor(props: PartnerHorseTrancheRacePayoutProps) {
    super(props);
    this.activeTab = 'ownerships';
  }

  onLoadHorse(horse: Horse | undefined) {
    this.loadPayout();
  }

  onReloadPayout(response: ApiResponse<Payout>) {
    this.setPayout(response as ApiResponse<Payout>);
  }

  loadPayout() {
    this.state.index?.findPayoutByKey(this.props.params.payoutKey, {
      next: this.setPayout,
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, PAYOUT_STANDARD_FIELDS);
  }

  searchEntitlements(page: number) {
    let searchRequest = new EntitlementSearchRequest();
    searchRequest.page = page - 1;
    this.state.payout?.searchEntitlements(searchRequest, {
      next: (response: ApiSearchResponse<Payout>) => {
        let entitlements = response.resource;
        this.setEntitlements(entitlements);
      },
      error: this.onApiError
    }, ENTITLEMENT_STANDARD_FIELDS);
  }

  onBack() {
    this.navigate(Routes.partner.horse_tranche_payouts(this.getPartnerKey(), this.getHorseKey(), this.props.params.trancheKey))
  }

  setPayout(response: ApiResponse<Payout>) {
    let payout = response.resource;
    this.setState({
      payout: payout
    }, () => {
      this.searchEntitlements(1);
    });
  }

  setEntitlements(response: SearchResult<Entitlement>) {
    this.setState({
      entitlements: response
    });
  }

  renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {

    let payout = this.state.payout;
    let entitlements = this.state.entitlements;

    if (!payout) {
      return <></>;
    }

    return <>

      <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

      <SectionHeader
        title="Payout"
        description={
          <>
            The details of this payout is shown below, including the entitlements allocated to token holders.
          </>
        }
        actions={
          <span className="pull-right">
            <Button
              icon="chevron-left"
              iconPosition="left"
              bsStyle="default"
              bsSize="xs"
              onClick={this.onBack}>Back To Payouts</Button>
          </span>
        }/>

      <DetailsPanel
        index={index}
        horse={horse}
        payout={payout}/>

      <SectionHeader
        title="Entitlements"
        description={
          <>
            The entitlements to token holders for this payout are shown below.
          </>
        }/>

      <Table
        items={entitlements}
        onPaginate={this.searchEntitlements}
        blankMessage="No entitlements found"
        renderHeaderRow={() => {
          return (
            <tr>
              <TableHeaderCell label="Date" className="col-md-2"/>
              <TableHeaderCell label="Details"/>
              <TableHeaderCell label="Total Amount" className="text-right"/>
              <TableHeaderCell label="Status" className="col-md-2 text-right"/>
            </tr>
          );
        }}
        renderItemRow={(entitlement: Entitlement, e: number) => {
          return (
            <tr key={e}>
              <td>{Str.formatDate(entitlement.date, "DD/MM/YYYY")}</td>
              <td>{entitlement.walletOwnerName}</td>
              <td className="text-right">${Str.formatNumber(entitlement.totalAmount, 2, '.', ',')}</td>
              <td className="text-right">
                <StatusButton
                  id={'status-' + e}
                  value={entitlement.isScheduled() ? {key: 'Scheduled'} : {key: 'Approved'}}/>
              </td>
            </tr>
          );
        }}
      />

    </>;

  }

  renderPageActions(index: ApiIndex, partner: Partner, horse: Horse): JSX.Element {

    return (

      <div className="actions">
        <Button
          icon="chevron-left"
          iconPosition="left"
          bsStyle="default"
          bsSize="large"
          onClick={() => {
            this.navigate(Routes.partner.horse_tranche_payouts(this.getPartnerKey(), this.getHorseKey(), this.props.params.trancheKey));
          }}>Back to Payouts</Button>
      </div>

    );

  }

};

// ................................................................................................................................................

const DetailsPanel = (props: {
  index: ApiIndex,
  horse: Horse,
  payout: Payout,
}) => {

  let payout = props.payout;

  return (

    <Panel>

      <div className="row">
        <div className="col-md-6">
          <InputDatePicker
            id="payout.date"
            label="Event Date"
            value={payout.date}
            dateFormat="DD/MM/YYYY"
            readOnly/>
        </div>
        <div className="col-xs-6 text-right">
          <InputDatePicker
            id="payout.paymentDate"
            label="Scheduled Payment Date"
            value={payout.paymentDate}
            dateFormat="DD/MM/YYYY"
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="payout.totalAmount"
            label="Total Amount"
            value={payout.totalAmount}
            format="currency"
            readOnly/>
        </div>
        <div className="col-xs-6 text-right">
          <InputText
            id="payout.remainingAmount"
            label="BTX Amount"
            value={payout.remainingAmount}
            format="currency"
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <InputText
            id="payout.description"
            label="Description"
            value={payout.description}
            readOnly/>
        </div>
        <div className="col-xs-6 text-right">
          <InputWrap id="status" label="Status">
            <div>
              <StatusButton
                id="status"
                value={payout.status}/>
            </div>
          </InputWrap>
        </div>
      </div>

    </Panel>

  );

}
