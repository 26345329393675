import React from 'react';
import {Nav, NavItem} from 'react-bootstrap';
import Device from "../models/Device";

type DeviceTabsProps = {
  device: Device;
  active: string;
  onSelect: (value?: any) => void;
};

const DeviceTabs = (props: DeviceTabsProps) => {

  return (
    <div id="device-tabs" className="tabs">
      <Nav id="device-tabs-nav" bsStyle="pills" justified activeKey={props.active} onSelect={props.onSelect}>
        <NavItem eventKey="events">Events</NavItem>
        <NavItem eventKey="insights">Insights</NavItem>
      </Nav>
    </div>
  );

}

export default DeviceTabs;