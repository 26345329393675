import {Event, Events as SystemEvents} from "startupbox-react";

export default class Events extends SystemEvents {

  static app = {

    index: new Event('event.app.index'),
    showLoginDialog: new Event('event.app.showLoginDialog'),

  };

}