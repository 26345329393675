import Model from '../Model';
import {ApiSubscribableCallbacks} from "startupbox-react";
import PartnerCollaboratorStatus from "./PartnerCollaboratorStatus";
import PartnerCollaboratorType from "./PartnerCollaboratorType";
import ApiError from "./ApiError";
import Partner from "./Partner";

let Rel = {
  self: 'self',
  modify: 'modify',
  modifyStatus: 'modifyStatus',
};

export let PARTNER_COLLABORATOR_STANDARD_FIELDS = 'partner';

export default class PartnerCollaborator {

  key?: string;

  emailAddress?: string;
  userName?: string;
  userAvatar?: any;
  type?: PartnerCollaboratorType;
  status?: PartnerCollaboratorStatus;

  partner?: Partner;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  save(callbacks: ApiSubscribableCallbacks<PartnerCollaborator, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }

  isModifyStatusAllowed() {
    return !!this.href(Rel.modifyStatus);
  }

  modifyStatus(status: PartnerCollaboratorStatus, callbacks: ApiSubscribableCallbacks<PartnerCollaborator, ApiError>, fields: string | null) {
    this.href(Rel.modifyStatus, true)
      .field(fields)
      .patch({status: status})
      .subscribe(callbacks);
  }

}
