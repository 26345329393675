import Model from '../Model';
import Content from "./Content";
import {ApiSubscribableCallbacks} from "startupbox-react";
import ApiError from "./ApiError";
import PostStatus from "./PostStatus";
import VisibilityLevel from "./VisibilityLevel";
import PushNotificationPreparation from "./PushNotificationPreparation";
import PremiumContentConfiguration from "./PremiumContentConfiguration";

let Rel = {
  self: 'self',
  modify: 'modify',
  delete: 'delete',
};

export const POST_STANDARD_FIELDS = 'content,content.body,pushNotificationPreparation,applePremiumContentConfiguration,googlePremiumContentConfiguration';

export default class Post {

  key?: string;

  title?: string;
  teaser?: string;
  date?: Date;
  content?: Content = new Content();
  visibilityLevel?: VisibilityLevel;
  published = false;
  status?: PostStatus;
  pushNotificationPreparation?: PushNotificationPreparation;

  applePremiumContentConfiguration?: PremiumContentConfiguration;
  googlePremiumContentConfiguration?: PremiumContentConfiguration;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  isPremium() {
    return this.visibilityLevel?.meta.premium;
  }

  // ................................................................................................................................................

  isPushNotificationRequired() {
    let pushNotificationPreparation = this.pushNotificationPreparation;
    return pushNotificationPreparation && pushNotificationPreparation.timing && pushNotificationPreparation.timing.key !== 'NOT_REQUIRED';
  }

  isPushNotificationStarted() {
    let pushNotificationPreparation = this.pushNotificationPreparation;
    return pushNotificationPreparation && !!pushNotificationPreparation.status && pushNotificationPreparation.status?.key !== 'NOT_STARTED';
  }

  // ................................................................................................................................................

  save(callbacks: ApiSubscribableCallbacks<Post, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }

  isDeleteAllowed() {
    return !!this.href(Rel.delete);
  }

  delete(callbacks: ApiSubscribableCallbacks<null, ApiError>) {
    this.href(Rel.delete, true)
      .delete()
      .subscribe(callbacks);
  }

}
