import React from 'react';
import {Button, FileWithUpload, Form, FullScreenModal, GlobalMessage, InputCheckbox, InputText} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import Horse from "../models/Horse";
import ApiIndex from "../models/ApiIndex";
import LegalOwner from "../models/LegalOwner";
import Divider from "../components/Divider";

export type LegalOwnerDialogProps = DialogProps & {
  horse?: Horse;
  legalOwner: LegalOwner;
  onClose: () => void;
};

export type LegalOwnerDialogState = DialogState & {
  legalOwner: LegalOwner;
};

export default class LegalOwnerDialog extends Dialog<LegalOwnerDialogProps, LegalOwnerDialogState> {

  constructor(props: LegalOwnerDialogProps) {
    super(props);
    this.setInitialState({
      legalOwner: this.props.legalOwner
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let legalOwner = this.state.legalOwner;
      if (legalOwner.key) {
        legalOwner.save({
          next: this.onClose,
          error: this.onApiError
        }, null);
      }
      else {
        let horse = this.props.horse;
        horse?.addLegalOwner(legalOwner, {
          next: this.onClose,
          error: this.onApiError
        }, null);
      }
    }
  }

  isDeleteAllowed() {
    let legalOwner = this.state.legalOwner;
    return legalOwner.isDeleteAllowed();
  }

  onDelete() {
    let legalOwner = this.state.legalOwner;
    legalOwner.delete({
      next: this.onClose,
      error: this.onApiError
    });
  }

  onClose() {
    this.props.onClose();
  }

  renderWithIndex(index: ApiIndex) {

    let legalOwner = this.state.legalOwner;

    return (

      <div>

        <FullScreenModal title={!legalOwner.key ? 'New Legal Owner' : 'Edit Legal Owner'} onHide={this.onClose}>

          <p className="lead">Please enter the details of the legal owner below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <InputText
                id="legalOwner.name"
                label="Legal Owner Name"
                value={legalOwner.name}
                error={this.state.errors?.['request.name']}
                onChange={this.onFieldChange}
                className="lg"
                required/>

              <InputText
                id="legalOwner.percentage"
                label="Percentage"
                value={legalOwner.percentage}
                format="percent"
                error={this.state.errors?.['request.percentage']}
                onChange={this.onFieldChange}
                className="lg"
                required/>

              <InputCheckbox
                id="legalOwner.microOwnershipSupported"
                value={legalOwner.microOwnershipSupported}
                error={this.state.errors?.['request.microOwnershipSupported']}
                onChange={this.onFieldChange}
                disabled={legalOwner.tranches && legalOwner.tranches.length > 0}
                className="lg">Micro Ownership Supported?</InputCheckbox>

              <Divider/>

              <FileWithUpload
                id="legalOwner.ownershipCertificates"
                label="Ownership Certificate"
                multiple
                upload
                files={legalOwner.ownershipCertificates || []}
                error={this.state.errors?.['request.ownershipCertificates']}
                onChange={this.onFieldChange}/>

            </Panel>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Save Changes</Button>
              <Button
                onClick={this.onClose}
                bsSize="large">Cancel</Button>
              {
                this.isDeleteAllowed() &&
                <Button
                  onClick={this.onDelete}
                  bsSize="large"
                  bsStyle="default"
                  className="pull-right">Delete</Button>
              }
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
