import User from "../models/User";
import BankDetails from "../models/BankDetails";
import {ApiResponse, Button, Confirmable, InputText} from "startupbox-react";
import Panel from "../components/Panel";
import UserBankDetailsDialog from "../user/UserBankDetailsDialog";
import React from "react";

type BankDetailsPanelProps = {
  user: User,
  bankDetails?: BankDetails,
  onSaveBankDetails: (response: ApiResponse<BankDetails>) => void,
  fields: string;
};

export const BankDetailsPanel = (props: BankDetailsPanelProps) => {

  let user = props.user;
  let bankDetails = props.bankDetails;
  let onSaveBankDetails = props.onSaveBankDetails;
  let fields = props.fields;

  return (

    <Panel>

      {
        bankDetails &&
        <Confirmable
          renderConfirm={(onOk, onCancel) => {
            return (
              <UserBankDetailsDialog
                user={user}
                bankDetails={bankDetails}
                fields={fields}
                onSave={(response: ApiResponse<BankDetails>) => {
                  onSaveBankDetails(response);
                  onOk();
                }}
                onCancel={onCancel}/>
            );
          }}
          renderTrigger={(onShow) => {
            return (
              <Button
                icon="external-link"
                bsStyle="primary"
                bsSize="xs"
                className="pull-right"
                onClick={onShow}>Edit</Button>
            );
          }}/>
      }

      <InputText
        id="bankDetails.name"
        label="Bank Name"
        value={bankDetails?.name + (bankDetails?.identifier ? ' (' + bankDetails?.identifier + ')' : '')}
        readOnly/>

      <div className="row">
        <div className="col-xs-6">
          <InputText
            id="bankDetails.accountName"
            label="Account Name"
            value={bankDetails?.accountName}
            readOnly/>
        </div>
        <div className="col-xs-6 text-right">
          <InputText
            id="bankDetails.accountNumber"
            label="Account Number"
            value={bankDetails?.accountNumber}
            readOnly/>
        </div>
      </div>

    </Panel>

  );

}