import React, {ReactNode, useEffect} from 'react';
import {Navbar} from 'react-bootstrap';
import {Link} from 'react-router';
import Context from '../Context';
import Events from '../Events';
import {RouteProps} from '../Page';
import Routes from '../Routes';
import Footer from './Footer';
import PrimaryNav from './PrimaryNav';
import AccessDeniedPanel from "../components/AccessDeniedPanel";

type AdminPageLayoutProps = {
  params: { [key: string]: any };
  route: RouteProps;
  children: ReactNode;
}

const AdminPageLayout = (props: AdminPageLayoutProps) => {

  useEffect(() => {
    Events.system.sectionTitle.publish('Admin');
  }, []);

  let getLinkClassName = (value: string, excludes?: string[]) => {
    let path = window.location.pathname;
    if (path.indexOf(value) !== -1) {
      if (excludes) {
        for (let exclude of excludes) {
          if (path.indexOf(exclude) !== -1) {
            return undefined;
          }
        }
      }
      return 'active';
    }
    return undefined
  }

  let isAllowed = Context.isAdmin();

  return (

    <div>

      <PrimaryNav/>

      <Navbar id="secondary-nav">
        <div className="row">
          <div className="col-md-12">
            {
              isAllowed &&
              <ul className="nav navbar-nav">
                <li>
                  <Link
                    to={Routes.admin.dashboard()}
                    className={getLinkClassName('dashboard')}>Dashboard</Link>
                </li>
                <li>
                  <Link
                    to={Routes.admin.posts()}
                    className={getLinkClassName('posts')}>News</Link>
                </li>
                <li>
                  <Link
                    to={Routes.admin.banners()}
                    className={getLinkClassName('banners')}>Banners</Link>
                </li>
                <li>
                  <Link
                    to={Routes.admin.partners()}
                    className={getLinkClassName('partners')}>Partners</Link>
                </li>
                <li>
                  <Link
                    to={Routes.admin.collectibles()}
                    className={getLinkClassName('collectibles')}>Collectibles</Link>
                </li>
                <li>
                  <Link
                    to={Routes.admin.transactions()}
                    className={getLinkClassName('transactions', ['dashboard', 'users'])}>Transactions</Link>
                </li>
                <li>
                  <Link
                    to={Routes.admin.entitlements()}
                    className={getLinkClassName('entitlements', ['users'])}>Entitlements</Link>
                </li>
                <li>
                  <Link
                    to={Routes.admin.disbursements()}
                    className={getLinkClassName('disbursements')}>Disbursements</Link>
                </li>
                <li>
                  <Link
                    to={Routes.admin.users()}
                    className={getLinkClassName('users', ['dashboard'])}>Users</Link>
                </li>
                <li className="pull-right">
                  <Link
                    to={Routes.admin.legalDocuments()}
                    className={getLinkClassName('legal-documents')}>Legal Documents</Link>
                </li>
                <li className="pull-right">
                  <Link
                    to={Routes.admin.memberships()}
                    className={getLinkClassName('memberships')}>Memberships</Link>
                </li>
              </ul>
            }
          </div>
        </div>
      </Navbar>

      <div id="main-content" className="has-secondary-nav">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {isAllowed && props.children}
              {!isAllowed && <AccessDeniedPanel/>}
            </div>
          </div>
        </div>
      </div>

      <Footer/>

    </div>

  );

};

export default AdminPageLayout;
