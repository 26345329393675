export default class HorseImportRunSearchRequest {

  key?: string;
  status?: string;

  sort?: string;
  page?: string | number;
  size?: string | number;

}
