import React from 'react';
import {ApiSearchResponse, Button, Confirmable, Form, InputText, SearchResult, StatusButton, Str, Table, TableHeaderCell} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Entitlement, {ENTITLEMENT_LIST_FIELDS} from "../models/Entitlement";
import ApiIndex from "../models/ApiIndex";
import EntitlementSearchRequest from "../models/EntitlementSearchRequest";
import ExportDialog from "../components/ExportDialog";

let FIELDS = ENTITLEMENT_LIST_FIELDS;

export type EntitlementsPageProps = PageProps & {};

export type EntitlementsPageState = PageState & {
  entitlementSearchRequest: EntitlementSearchRequest;
  entitlements?: SearchResult<Entitlement> | null;
};

export default class EntitlementsPage extends Page<EntitlementsPageProps, EntitlementsPageState> {

  constructor(props: EntitlementsPageProps) {
    super(props);
    this.setInitialState({
      entitlementSearchRequest: new EntitlementSearchRequest(),
      entitlements: null
    });
  }

  onEnter() {
    this.setState({
      entitlementSearchRequest: {
        keywords: this.query('keywords'),
        type: this.query('type'),
        sort: this.query('sort') || 'paymentDate,desc',
        page: parseInt(this.query('page') || '0'),
      }
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('keywords', this.state.entitlementSearchRequest.keywords)
      .param('type', this.state.entitlementSearchRequest.type)
      .param('sort', this.state.entitlementSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Entitlements');
    this.state.index?.searchEntitlements(this.state.entitlementSearchRequest, {
      next: this.setEntitlements,
      error: this.onApiError
    }, FIELDS);
  }

  setEntitlements(response: ApiSearchResponse<Entitlement>) {
    this.setState({
      entitlements: response.resource
    })
  }

  renderWithIndex(index: ApiIndex) {

    let entitlements = this.state.entitlements;
    let entitlementExportHref = index.getExportEntitlementHref(this.state.entitlementSearchRequest);

    return (

      <div className="row">

        <div className="col-md-12">

          <PageHeader
            title="Entitlements"
            lead="This area allows you to view the entitlements created on the
            system from payouts. From here you may export the entitlements for scheme reporting."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="entitlementSearchRequest.keywords"
              className="wide"
              placeholder="Keywords"
              value={this.state.entitlementSearchRequest.keywords}
              onChange={this.onFieldChange}/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              {
                entitlementExportHref &&
                <Confirmable
                  renderTrigger={(onShow) => {
                    return (
                      <Button
                        icon="download"
                        bsStyle="default"
                        onClick={onShow}/>
                    );
                  }}
                  renderConfirm={(onOk, onCancel) => {
                    return (
                      <ExportDialog
                        title="Export Entitlements"
                        count={entitlements!.page!.totalElements}
                        href={entitlementExportHref!}
                        onClose={onCancel}/>
                    );
                  }}/>
              }
            </span>
          </Form>

          <br/>

          <Table
            items={entitlements}
            sort={this.state.entitlementSearchRequest.sort}
            onReload={this.onEnter}
            blankMessage="No entitlements found"
            renderHeaderRow={() => {
              return (
                <tr>
                  <TableHeaderCell label="Payment Date" sort="paymentDate" className="col-md-2"/>
                  <TableHeaderCell label="Details"/>
                  <TableHeaderCell label="Total Amount" className="text-right"/>
                  <TableHeaderCell label="Type" sort="type" className="col-md-2 text-right"/>
                  <TableHeaderCell label="Status" className="col-md-2 text-right"/>
                </tr>
              );
            }}
            renderItemRow={(entitlement: Entitlement, e: number) => {
              return (
                <tr key={e}>
                  <td>
                    <div>{Str.formatDate(entitlement.paymentDate, "DD/MM/YYYY")}</div>
                  </td>
                  <td>
                    <div>{entitlement.walletOwnerName}</div>
                    <div className="text-muted">{entitlement.subscribableName}</div>
                    <div className="text-muted">{entitlement.payoutDescription}</div>
                    <div className="text-muted">{entitlement.disbursementTitle}</div>
                  </td>
                  <td className="text-right">${Str.formatNumber(entitlement.totalAmount, 2, '.', ',')}</td>
                  <td className="text-right">{entitlement.type?.meta?.shortDescription}</td>
                  <td className="text-right">
                    <StatusButton
                      id={'status-' + e}
                      value={entitlement.isScheduled() ? {key: 'Scheduled'} : {key: 'Approved'}}/>
                  </td>
                </tr>
              );
            }}
          />

        </div>

      </div>

    );

  }

}
