import React from 'react';
import {StatusButton} from 'startupbox-react';
import {Image} from '../components/Image';
import './BannerPanel.css';
import Banner from "../models/Banner";

type BannerPanelProps = {
  banner: Banner;
  onClick?: () => void | null
}

const BannerPanel = (props: BannerPanelProps) => {

  let banner = props.banner;
  let onClick = props.onClick;

  let className = 'banner-panel' +
    (onClick ? ' clickable' : '') +
    (banner.active ? ' active' : '');

  return (

    <div className={className} onClick={onClick}>
      <Image src={banner.coverImage} className="cover" transforms="w_600"/>
      {
        banner.active &&
        <div className="meta">
          <StatusButton value={{key: 'Active', name: 'Active'}}/>
        </div>
      }
    </div>

  );

};

export default BannerPanel;