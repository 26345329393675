import Model from '../Model';
import Content from "./Content";
import {ApiSubscribableCallbacks} from "startupbox-react";
import ApiError from "./ApiError";
import PushNotificationPreparation from "./PushNotificationPreparation";
import TrainingRecordStatus from "./TrainingRecordStatus";

let Rel = {
  self: 'self',
  modify: 'modify',
  delete: 'delete',
};

export const TRAINING_RECORD_STANDARD_FIELDS = 'notes,notes.body,pushNotificationPreparation';
export const TRAINING_RECORD_LIST_FIELDS = 'notes,notes.body,pushNotificationPreparation';

export default class TrainingRecord {

  key?: string;

  title?: string;
  date?: Date;
  notes?: Content = new Content();
  published = false;
  status?: TrainingRecordStatus;
  pushNotificationPreparation?: PushNotificationPreparation;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  isPushNotificationRequired() {
    let pushNotificationPreparation = this.pushNotificationPreparation;
    return pushNotificationPreparation && pushNotificationPreparation.timing && pushNotificationPreparation.timing.key !== 'NOT_REQUIRED';
  }

  isPushNotificationStarted() {
    let pushNotificationPreparation = this.pushNotificationPreparation;
    return pushNotificationPreparation && !!pushNotificationPreparation.status && pushNotificationPreparation.status?.key !== 'NOT_STARTED';
  }

  // ................................................................................................................................................

  save(callbacks: ApiSubscribableCallbacks<TrainingRecord, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }

  isDeleteAllowed() {
    return !!this.href(Rel.delete);
  }

  delete(callbacks: ApiSubscribableCallbacks<null, ApiError>) {
    this.href(Rel.delete, true)
      .delete()
      .subscribe(callbacks);
  }

}
