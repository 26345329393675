import React, {ReactNode} from 'react';

type PanelProps = {
  unpadded?: boolean;
  children?: ReactNode;
};

const Panel = (props: PanelProps) => (
  <div className={'panel panel-default shadowed-normal' + (!props.unpadded ? ' padded' : '')}>
    <div className="panel-body">
      {props.children}
    </div>
  </div>
);

export default Panel;