import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, Confirmable, Form, InputText, Pagination, SearchResult} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import HorsePanel from '../horse/HorsePanel';
import Routes from '../Routes';
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from './PartnerAbstractPage';
import PartnerStatus from "../models/PartnerStatus";
import Horse from "../models/Horse";
import ApiIndex from "../models/ApiIndex";
import Partner from "../models/Partner";
import Binary from "../models/Binary";
import HorseDialog from "../horse/HorseDialog";
import Content from "../models/Content";
import BlockchainAttributeGroup from "../models/BlockchainAttributeGroup";
import HorseSearchRequest from "../models/HorseSearchRequest";
import Context from "../Context";

let HORSE_FIELDS = 'thumbnailImage,coverImage';

export type PartnerHorsesPageProps = PartnerAbstractPageProps & {};

export type PartnerHorsesPageState = PartnerAbstractPageState & {
  horseSearchRequest: HorseSearchRequest,
  horses?: SearchResult<Horse> | null;
}

export default class PartnerHorsesPage extends PartnerAbstractPage<PartnerHorsesPageProps, PartnerHorsesPageState> {

  constructor(props: PartnerHorsesPageProps) {
    super(props);
    this.setInitialState({
      horseSearchRequest: new HorseSearchRequest(),
      horses: null,
    });
  }

  onEnter() {
    this.setState({
      horseSearchRequest: {
        keywords: this.query('keywords'),
        sort: this.query('sort'),
        page: parseInt(this.query('page') || '0'),
        size: 9,
      }
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('keywords', this.state.horseSearchRequest.keywords)
      .param('sort', this.state.horseSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoadPartner(partner: Partner) {
    partner.searchHorses(this.state.horseSearchRequest, {
      next: this.setHorses,
      error: this.onApiError
    }, HORSE_FIELDS);
  }

  onReload(response: ApiResponse<Partner>) {
    this.setPartner(response);
  }

  onSave(response: ApiResponse<Partner>) {
    this.setPartner(response);
  }

  onChangeAvatar(avatar: Binary | undefined) {
    let partner = this.state.partner;
    if (partner) {
      partner.thumbnailImage = avatar;
      if (!partner.thumbnailImage || partner.thumbnailImage!.externalReference) {
        partner.save({
          next: this.setPartner,
          error: this.onApiError
        }, this.getFields());
      }
    }
  }

  onChangeStatus(status: PartnerStatus) {
    this.state.partner?.modifyStatus(status, {
      next: this.setPartner,
      error: this.onApiError
    }, this.getFields());
  }

  onViewHorse(horse: Horse) {
    let partner = this.state.partner;
    if (partner) {
      this.navigate(Routes.partner.horse_details(partner.key!, horse.key!));
    }
  }

  onNavigateToImportRuns() {
    let partner = this.state.partner!;
    this.navigate(Routes.partner.horseImportRuns(partner.key!));
  }

  setHorses(response: ApiSearchResponse<Horse>) {
    this.setState({
      horses: response.resource
    })
  }

  renderHorses(partner: Partner, horses: SearchResult<Horse>) {

    return <>

      <div className="row">
        {
          horses.values.map((horse: Horse, h: number) => {
            return (
              <div key={h} className="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-0">
                <HorsePanel
                  horse={horse}
                  onClick={this.fn(this.onViewHorse, horse)}/>
              </div>
            );
          })
        }
      </div>

      {
        horses &&
        <Pagination
          pageable={horses as any}
          load={this.onEnter}/>
      }

    </>;

  }

  renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

    let horses = this.state.horses;

    return (

      <div className="row">

        <div className="col-md-12">

          <PageHeader
            title={partner.name}
            subTitle="Horses"
            lead="The horses you have in your stable are shown below. Simply click on
          a horse to view and manage its details on the platform including images, videos,
          training material and medical records."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="horseSearchRequest.keywords"
              placeholder="Keywords"
              value={this.state.horseSearchRequest.keywords}
              onChange={this.onFieldChange}
              className="wide"/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              {
                partner.isAddHorseAllowed() &&
                <Confirmable
                  renderTrigger={(onShow) => {
                    return (
                      <Button
                        icon="plus"
                        bsStyle="default"
                        className="pull-right"
                        onClick={onShow}>Add</Button>
                    );
                  }}
                  renderConfirm={(onOk, onCancel) => {
                    let newHorse = new Horse();
                    newHorse.overview = new Content();
                    newHorse.manageableMetadataAttributeGroup = new BlockchainAttributeGroup();
                    return (
                      <HorseDialog
                        partner={partner}
                        horse={newHorse}
                        fields={''}
                        onSave={(response: ApiResponse<Horse>) => {
                          this.onViewHorse(response.resource!);
                          onOk();
                        }}
                        onCancel={onCancel}/>
                    );
                  }}/>
              }
              {
                Context.isAdmin() && partner.isAddHorseAllowed() &&
                <Button
                  icon="plus"
                  bsStyle="default"
                  className="pull-right"
                  onClick={this.onNavigateToImportRuns}>Bulk Add</Button>
              }
            </span>
          </Form>

          <br/>

          {horses && this.renderHorses(partner, horses)}

        </div>

      </div>

    );

  }

};