import React, {ReactNode, useEffect} from "react";
import {Col, Grid, Row} from "react-bootstrap";
import Events from "../Events";

type AccountPageLayoutProps = {
  children: ReactNode;
}

const AccountPageLayout = (props: AccountPageLayoutProps) => {

  useEffect(() => {
    Events.system.sectionTitle.publish('Account');
  }, []);

  return (

    <div>

      <div id="main-content">
        <Grid>
          <Row>
            <Col md={12}>
              {props.children}
            </Col>
          </Row>
        </Grid>
      </div>

    </div>

  );

};

export default AccountPageLayout