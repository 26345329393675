import HorseAttributeType from "./HorseAttributeType";
import VisibilityLevel from "./VisibilityLevel";

export default class HorseAttribute {

  key?: string;

  name?: string;
  stringValue?: string;
  type?: HorseAttributeType;
  visibilityLevel?: VisibilityLevel;

  // ................................................................................................................................................

  constructor(name?: string, type?: HorseAttributeType, visibilityLevel?: VisibilityLevel) {
    this.name = name;
    this.type = type;
    this.visibilityLevel = visibilityLevel;
  }

}
