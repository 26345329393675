import React from 'react';
import {ApiResponse, Button, Form, FullScreenModal, GlobalMessage, InputText, VideoWithUpload} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import ApiIndex from "../models/ApiIndex";
import SectionHeader from "../components/SectionHeader";
import Binary from "../models/Binary";
import BlockchainMintable from "../models/BlockchainMintable";

export type BlockchainMintableDialogProps = DialogProps & {
  index?: ApiIndex;
  entity: BlockchainMintable,
  entityFields: string,
  onSave: (response: ApiResponse<BlockchainMintable>) => void;
  onCancel: () => void;
};

export type BlockchainMintableDialogState = DialogState & {
  entity: BlockchainMintable;
};

export default class BlockchainMintableDialog extends Dialog<BlockchainMintableDialogProps, BlockchainMintableDialogState> {

  constructor(props: BlockchainMintableDialogProps) {
    super(props);
    let entity = this.props.entity;
    this.setInitialState({
      entity: entity
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let entity = this.state.entity;
      entity.save({
        next: this.onSave,
        error: this.onApiError
      }, this.props.entityFields);
    }
  }

  isModifyAllowed() {
    let entity = this.state.entity;
    return entity.isMetadataModifyAllowed();
  }

  onSave(response: ApiResponse<BlockchainMintable>) {
    this.enable();
    this.props.onSave(response);
  }

  onCancel() {
    this.enable();
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let entity = this.state.entity;

    return (

      <div>

        <FullScreenModal title="Edit Blockchain Data" onHide={this.onCancel}>

          <p className="lead">Please enter the details of this entity's blockchain below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled() || !this.isModifyAllowed()}>

            <Panel>

              <SectionHeader title="Blockchain"/>

              {
                entity.manageableMetadataAttributeGroup &&
                <InputText
                  id="entity.manageableMetadataAttributeGroup.description"
                  label="Metadata Description"
                  value={entity.manageableMetadataAttributeGroup.description}
                  placeholderReadOnly="No description"
                  onChange={this.onFieldChange}/>
              }

              <VideoWithUpload
                id="entity.ipfsMedia"
                label="IPFS Media"
                files={entity.ipfsMedia ? [entity.ipfsMedia] : []}
                className="videos-across-1"
                accept="video/mp4"
                upload
                onChange={(e: any) => {
                  let videos: Array<Binary> = e.target.value;
                  let ipfsMedia = (videos && videos.length > 0) ? videos[0] : undefined;
                  if (!ipfsMedia || ipfsMedia.externalReference) {
                    e.target.value = ipfsMedia;
                    this.onFieldChange(e);
                  }
                }}/>

            </Panel>

            <div className="actions">
              {
                this.isModifyAllowed() &&
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  type="submit">Save Changes</Button>
              }
              <Button
                onClick={this.onCancel}
                disabled={false}
                bsSize="large">{this.isModifyAllowed() ? 'Cancel' : 'Close'}</Button>
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
