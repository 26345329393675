import React from 'react';
import FontAwesome from 'react-fontawesome';
import {ApiResponse, Button, Confirmable, Table, TableHeaderCell} from 'startupbox-react';
import SectionHeader from '../components/SectionHeader';
import BlockchainAttribute from "../models/BlockchainAttribute";
import BlockchainMintableAttributeGroup from "../models/BlockchainAttributeGroup";
import BlockchainMintableAttributeDialog from "./BlockchainMintableAttributeDialog";
import BlockchainMintableAttributeValue from "./BlockchainAttributeValue";
import NotSpecified from "../components/NotSpecified";
import BlockchainMintable from "../models/BlockchainMintable";

type BlockchainMintableAttributeGroupPanelProps = {
  entity: BlockchainMintable,
  group: BlockchainMintableAttributeGroup,
  entityFields: string | null,
  disabled?: boolean;
  onReload?: (response: ApiResponse<BlockchainMintable>) => void,
};

const BlockchainMintableAttributeGroupPanel = (props: BlockchainMintableAttributeGroupPanelProps) => {

  let entity = props.entity;
  let group = props.group;
  let entityFields = props.entityFields;
  let attributes = group.attributes;
  let isSaveAttributeAllowed = !!props.onReload && !props.disabled;
  let onReload = props.onReload;

  return (

    <>

      <SectionHeader
        title="Blockchain Metadata"
        description="These metadata attributes are published to IPFS and are immutable after minting. They are permanently on the public record as part of this token."
        actions={
          <>
            {
              isSaveAttributeAllowed &&
              <Confirmable
                renderConfirm={(onOk, onCancel) => {
                  let blockchainAttribute = new BlockchainAttribute();
                  return (
                    <BlockchainMintableAttributeDialog
                      entity={entity}
                      group={group}
                      attribute={blockchainAttribute}
                      entityFields={entityFields}
                      onClose={(response: ApiResponse<BlockchainMintable>) => {
                        onReload?.(response);
                        onOk();
                      }}/>
                  );
                }}
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="plus"
                      bsStyle="primary"
                      bsSize="xs"
                      onClick={onShow}>Add Attribute</Button>
                  );
                }}/>
            }
          </>
        }/>

      <Table
        items={attributes}
        blankMessage="No attributes found"
        renderHeaderRow={() => {
          return (
            <tr>
              <TableHeaderCell label="Name" className="col-md-4"/>
              <TableHeaderCell label="Value"/>
              <TableHeaderCell label="&nbsp;" className="col-chevron"/>
            </tr>
          );
        }}
        renderItemRow={(attribute: BlockchainAttribute, i: number) => {
          return (
            <Confirmable
              key={i}
              renderConfirm={(onOk, onCancel) => {
                return (
                  <tr className="clickable">
                    <td>
                      <BlockchainMintableAttributeDialog
                        entity={entity}
                        group={group}
                        attribute={attribute}
                        i={i}
                        entityFields={entityFields}
                        onClose={(response: ApiResponse<BlockchainMintable>) => {
                          onReload?.(response);
                          onOk();
                        }}/>
                    </td>
                  </tr>
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <tr className="clickable" onClick={isSaveAttributeAllowed ? onShow : undefined}>
                    <td><strong>{attribute.name || <NotSpecified/>}</strong></td>
                    <td>
                      <BlockchainMintableAttributeValue
                        attribute={attribute}/>
                    </td>
                    <td className="text-right text-muted">
                      {isSaveAttributeAllowed && <FontAwesome name="external-link"/>}
                    </td>
                  </tr>
                );
              }}/>
          );
        }}
      />

    </>

  );

};

export default BlockchainMintableAttributeGroupPanel;
