import React from 'react';
import ApiIndex from "../models/ApiIndex";
import Visibilities from "../models/Visibilities";
import Panel from "./Panel";
import SectionHeader from "./SectionHeader";
import {InputText} from "startupbox-react";
import VisibilityLevel from "../models/VisibilityLevel";

type VisibilitiesPanelProps = {
  index: ApiIndex,
  visibilities: Visibilities,
  labelField?: string;
};

const VisibilitiesPanel = (props: VisibilitiesPanelProps) => {

  let index = props.index;
  let visibilities = props.visibilities;
  let labelField = props.labelField || 'name';
  let visibilityLevels = index.visibilityLevels;

  let getLevelLabel = (levelValue: number | null | undefined) => {
    if (levelValue) {
      let level = VisibilityLevel.getLevel(levelValue, visibilityLevels);
      return VisibilityLevel.getLabel(level, labelField);
    }
    return undefined;
  }

  return (

    <Panel>

      <SectionHeader title="Visibilities"/>

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="visibilities.attributeLevel"
            label="Attribute Visibility"
            value={getLevelLabel(visibilities.attributeLevel)}
            readOnly/>
        </div>
        <div className="col-md-6 text-right">
          <InputText
            id="visibilities.postLevel"
            label="Post Visibility"
            value={getLevelLabel(visibilities.postLevel)}
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="visibilities.medicalRecordLevel"
            label="Medical Record Visibility"
            value={getLevelLabel(visibilities.medicalRecordLevel)}
            readOnly/>
        </div>
        <div className="col-md-6 text-right">
          <InputText
            id="visibilities.trainingRecordLevel"
            label="Training Record Visibility"
            value={getLevelLabel(visibilities.trainingRecordLevel)}
            readOnly/>
        </div>
      </div>

    </Panel>

  );

}


export default VisibilitiesPanel;