import React from 'react';
import {ApiResponse, Button, Confirm, Confirmable, InputText, InputWrap, StatusButton, VideoWithUpload} from 'startupbox-react';
import SectionHeader from '../components/SectionHeader';
import BlockchainMintable from "../models/BlockchainMintable";
import Panel from "../components/Panel";
import BlockchainMintableAttributeGroupPanel from "./BlockchainMintableAttributeGroupPanel";
import BlockchainMintStatus from "../models/BlockchainMintStatus";
import Divider from "../components/Divider";
import BlockchainMintableDialog from "./BlockchainMintableDialog";
import BlockchainMintablePollingDialog from "./BlockchainMintablePollingDialog";
import Context from "../Context";
import Pack from "../models/Pack";

type BlockchainMintablePanelProps = {
  type: string,
  entity: BlockchainMintable,
  entityFields: string,
  statuses: Array<BlockchainMintStatus>,
  errors: { [key: string]: string } | undefined,
  onReload: (response: ApiResponse<BlockchainMintable>) => void,
  onChangeMintStatus: (status: { key: string }) => void
};

const BlockchainMintablePanel = (props: BlockchainMintablePanelProps) => {

  let type = props.type;
  let entity = props.entity;
  let entityFields = props.entityFields;
  let statuses = props.statuses;
  let onReload = props.onReload;
  let onChangeMintStatus = props.onChangeMintStatus;

  let onMint = () => {
    onChangeMintStatus({key: 'MINT'})
  }

  if (!Context.isAdmin()) {
    return <></>;
  }

  let mintStatusButton = (
    <InputWrap id="status" label="Status">
      <div>
        <Confirmable
          renderConfirm={(onOk, onCancel) => {
            return (
              <Confirm
                title="Confirm Mint"
                message={'Are you sure you\'d like to Mint this ' + type + '. This will trigger blockchain minting actions and cannot be undone.'}
                okButtonLabel="Mint"
                onOk={() => {
                  onMint();
                  onOk();
                }}
                onCancel={onCancel}/>
            );
          }}
          renderTrigger={(onShow) => {
            return (
              <StatusButton
                id="status"
                value={entity.mintStatus}
                options={statuses}
                onEdit={entity.isModifyMintStatusAllowed() ? onShow : null}/>
            );
          }}/>
      </div>
    </InputWrap>
  );

  let mintingPollingDialog = entity.isMinting() ? (
    <BlockchainMintablePollingDialog
      entity={entity}
      entityFields={entityFields}
      onFinish={(response: ApiResponse<BlockchainMintable>) => {
        onReload(response);
      }}/>
  ) : <></>;

  if (entity instanceof Pack) {

    return <>

      {mintingPollingDialog}

      <div className="row">
        <div className="col-xs-8">
        </div>
        <div className="col-xs-4 text-right">
          {mintStatusButton}
        </div>
      </div>

    </>

  }
  else {

    return <>

      <Panel>

        {mintingPollingDialog}

        <SectionHeader
          title="Blockchain Details"
          actions={
            <>
              {
                entity.isMetadataModifyAllowed() &&
                <Confirmable
                  renderConfirm={(onOk, onCancel) => {
                    return (
                      <BlockchainMintableDialog
                        entity={entity}
                        entityFields={entityFields}
                        onSave={(response: ApiResponse<BlockchainMintable>) => {
                          onReload?.(response);
                          onOk();
                        }}
                        onCancel={onCancel}/>
                    );
                  }}
                  renderTrigger={(onShow) => {
                    return (
                      <Button
                        icon="external-link"
                        bsStyle="primary"
                        bsSize="xs"
                        onClick={onShow}>Edit</Button>
                    );
                  }}/>
              }
            </>
          }/>

        {
          entity.manageableMetadataAttributeGroup &&
          <InputText
            id="entity.manageableMetadataAttributeGroup.description"
            label="Metadata Description"
            value={entity.manageableMetadataAttributeGroup.description}
            placeholderReadOnly="No description"
            readOnly/>
        }

        <VideoWithUpload
          id="entity.ipfsMedia"
          label="IPFS Media"
          files={entity.ipfsMedia ? [entity.ipfsMedia] : []}
          className="videos-across-1"
          error={props.errors?.['ipfsMedia']}
          upload
          readOnly/>

        {
          entity.manageableMetadataAttributeGroup &&
          <>
            <Divider className="marginTop50"/>
            <BlockchainMintableAttributeGroupPanel
              entity={entity}
              group={entity.manageableMetadataAttributeGroup}
              entityFields={entityFields}
              disabled={!entity.isMetadataModifyAllowed()}
              onReload={onReload}/>
          </>
        }

        <Divider/>

        <SectionHeader title="Blockchain Minting"/>

        <div className="row">
          <div className="col-xs-9">
            <InputWrap id="ipfsUri" label="Blockchain Transaction Hash">
              <div>
                {
                  entity.viewUrl &&
                  <a href={entity.viewUrl} target="_blank" rel="noopener noreferrer">{entity.blockchainTransactionHash}</a>
                }
                {
                  !entity.viewUrl &&
                  <span className="text-secondary">Not Minted Yet</span>
                }
              </div>
            </InputWrap>
          </div>
          <div className="col-xs-3 text-right">
            <InputText
              id="entity.blockchainId"
              label="Blockchain ID"
              value={entity.blockchainId}
              placeholderReadOnly="Not Minted Yet"
              readOnly/>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-8">
            <InputWrap id="ipfsUri" label="IPFS URI">
              <div>
                {
                  entity.ipfsUri &&
                  <a href={entity.ipfsUri} target="_blank" rel="noopener noreferrer">{entity.ipfsUri}</a>
                }
                {
                  !entity.ipfsUri &&
                  <span className="text-secondary">Not Minted Yet</span>
                }
              </div>
            </InputWrap>
          </div>
          <div className="col-xs-4 text-right">
            {mintStatusButton}
          </div>
        </div>

      </Panel>

    </>;

  }

};

export default BlockchainMintablePanel;
