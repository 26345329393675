import {ApiSubscribableCallbacks} from "startupbox-react";
import ApiError from "./ApiError";
import Binary from "./Binary";
import Model from "../Model";
import BannerType from "./BannerType";
import Partner from "./Partner";
import Horse from "./Horse";
import Collectible from "./Collectible";
import Membership from "./Membership";
import Tranche from "./Tranche";
import Pack from "./Pack";

let Rel = {
  self: 'self',
  modify: 'modify',
  delete: 'delete',
};

export const BANNER_STANDARD_FIELDS = 'coverImage,horse,partner,collectible,membership,pack,tranche';

export default class Banner {

  key?: string;

  coverImage?: Binary;
  active = false;

  type?: BannerType;
  targetIdentifier?: string;

  horse?: Horse;
  partner?: Partner;

  collectible?: Collectible;
  membership?: Membership;
  pack?: Pack;
  tranche?: Tranche;

  externalUrl?: string;

  href = Model.hrefFunction();

  // ................................................................................................................................................


  // ................................................................................................................................................

  save(callbacks: ApiSubscribableCallbacks<Banner, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }

}
