import React from 'react';
import {ApiResponse, Button, DialogPanel, Form, FullScreenModal, GlobalMessage, InputText} from 'startupbox-react';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Routes from '../Routes';
import AccountNav from './AccountNav';
import Credentials from "../models/Credentials";
import User from "../models/User";

export type AccountPasswordPageProps = PageProps & {};

export type AccountPasswordPageState = PageState & {
  credentials: Credentials,
  user: User,
};

export default class AccountPasswordPage extends Page<AccountPasswordPageProps, AccountPasswordPageState> {

  constructor(props: AccountPasswordPageProps) {
    super(props);
    this.setInitialState({
      mode: 'edit',
      disabled: true,
      credentials: new Credentials(),
      user: new User()
    });
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Password');
    this.state.index?.findMe({
      next: this.setUser,
      error: this.onApiError
    }, null);
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let credentials = this.state.credentials;
      this.state.index?.modifyMyPassword(credentials, {
        next: this.onSave,
        error: this.onApiError
      }, null);
    }
  }

  onSave() {
    this.enable();
    this.setErrors(null, 'Your changes have been saved successfully', 'info');
  }

  onCancel() {
    this.navigate(Routes.main.home());
  }

  setUser(response: ApiResponse<User>) {
    this.setState({
      disabled: false,
      user: response.resource!
    });
  }

  render() {

    let user = this.state.user;

    if (!user) {
      return null;
    }

    return (

      <div>

        <FullScreenModal title="Change Password" className="centered-title" onHide={this.onCancel}>

          <DialogPanel>

            <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

              <AccountNav active="Password"/>

              <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

              <br/>

              <p className="lead">Please enter your new password below.</p>

              <InputText
                id="credentials.newPassword"
                label="New Password"
                placeholder=""
                type="password"
                value={this.state.credentials.newPassword}
                error={this.state.errors?.['request.newPassword']}
                onChange={this.onFieldChange}
                required/>

              <InputText
                id="credentials.confirmNewPassword"
                label="Confirm New Password"
                placeholder=""
                type="password"
                value={this.state.credentials.confirmNewPassword}
                error={this.state.errors?.['request.confirmNewPassword']}
                onChange={this.onFieldChange}
                required/>

              <div className="actions">
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  type="submit">Save Changes</Button>
              </div>

            </Form>

          </DialogPanel>

        </FullScreenModal>

      </div>

    );

  }

};
