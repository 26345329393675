import {browserHistory} from 'react-router';
import {Api, ApiCustomHeadersResolver, Environment} from 'startupbox-react';
import Context from './Context';
import Model from './Model';

let appName = 'btx';
let appTitle = 'BTX Technology';
let appTagline = 'A Technology Company Focused on the Global Equine Industry.';

export default class Initialiser {

  static init() {

    // initialise the environment
    Environment.init({

      name: appName,
      title: appTitle,
      tagline: appTagline,
      browserHistory: browserHistory,

      environments: {

        'localhost': {
          debug: true,
          mockupsAllowed: true,
          name: 'localhost',
          appName: appName + '-localhost',
          baseFrontendUrl: 'http://localhost:3000',
          baseBackendUrl: 'http://localhost:5000',
          getStartupBoxHref: function (link) {
            return this.baseBackendUrl + link;
          }
        },

        'staging': {
          debug: false,
          mockupsAllowed: false,
          name: 'staging',
          appName: appName + '-staging',
          baseFrontendUrl: '',
          baseBackendUrl: '',
          getStartupBoxHref: function (link) {
            return this.baseBackendUrl + link;
          }
        },

        'production': {
          debug: false,
          mockupsAllowed: false,
          name: 'production',
          appName: appName + '-production',
          baseFrontendUrl: '',
          baseBackendUrl: '',
          getStartupBoxHref: function (link) {
            return this.baseBackendUrl + link;
          }
        }

      }

    });

    // initialise the model factory
    Api.modelFactory = Model;

    // initialise customer headers
    // todo device - perhaps track when we expose a web interface to end users
    Api.setCustomHeadersResolver(new ApiCustomHeadersResolver(() => {
      return {
        'Device-Platform': 'WEB',
        'Client-Version': '99999999'
      };
    }));

    // initialise the context
    Context.init();

    // access token cookie required for cross-domain support in Safari
    Context.setAccessTokenCreatesCookie(true);

    // not using startup box, so set to some non-expiring key so redirected apis work
    Context.setStartupBoxClientKey(appName);
    Context.setStartupBoxExpiryTimestamp(null);

  }

}
