import React from 'react';
import {ApiResponse, Button, Confirmable, GlobalMessage} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Routes from '../Routes';
import Membership, {MEMBERSHIP_STANDARD_FIELDS} from "../models/Membership";
import ApiIndex from "../models/ApiIndex";
import {ApiErrorResponse} from "../models/ApiError";
import UserStatus from "../models/UserStatus";
import BlockchainMintable from "../models/BlockchainMintable";
import BlockchainMintablePanel from "../blockchain/BlockchainMintablePanel";
import BlockchainMintStatus from "../models/BlockchainMintStatus";
import SectionHeader from "../components/SectionHeader";
import MembershipGiftToUserDialog from "./MembershipGiftToUserDialog";
import MembershipDetailsPanel from "./MembershipDetailsPanel";

let FIELDS = MEMBERSHIP_STANDARD_FIELDS;

export type MembershipPageProps = PageProps & {};

export type AdminMembershipPageState = PageState & {
  membership?: Membership | null | undefined;
};

export default class MembershipPage extends Page<MembershipPageProps, AdminMembershipPageState> {

  constructor(props: MembershipPageProps) {
    super(props);
    this.setInitialState({});
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Membership');
    this.state.index?.findMembershipByKey(this.props.params.key, {
      next: this.setMembership,
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, FIELDS);
  }

  onReloadMembership(response: ApiResponse<BlockchainMintable>) {
    this.setMembership(response as ApiResponse<Membership>);
  }

  onSave(response: ApiResponse<Membership>) {
    this.setMembership(response);
  }

  onChangeStatus(status: UserStatus) {
    this.state.membership?.modifyStatus(status, {
      next: this.setMembership,
      error: this.onApiError
    }, FIELDS);
  }

  onChangeMintStatus(mintStatus: BlockchainMintStatus) {
    this.state.membership?.modifyMintStatus(mintStatus, {
      next: this.setMembership,
      error: this.onApiError
    }, FIELDS);
  }

  onDelete() {
    this.onNavigateToMemberships(null);
  }

  setMembership(response: ApiResponse<Membership>) {
    this.setState({
      membership: response.resource
    })
  }

  onNavigateToMemberships(e: any) {
    this.preventDefault(e);
    this.navigate(Routes.admin.memberships());
  }

  onShowActuals() {
    this.navigate(Routes.admin.membership_actuals(this.props.params.key))
  }

  renderWithIndex(index: ApiIndex) {

    let membership = this.state.membership;

    if (!membership) {
      return <></>;
    }

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity} fieldErrors={(this.state.errors as any)._}/>

          <PageHeader
            title="Memberships"
            subTitle={membership.name}
            lead={
              <>
                You are editing an existing membership
                called <strong>{membership.name}</strong> on the system. <a href="/" className="back" onClick={this.onNavigateToMemberships}>Pick another membership.</a> Please note that
                users can only see memberships in the Marketplace if the membership is both public and minted.
              </>
            }/>

          <SectionHeader
            title="Membership"
            actions={
              <span className="pull-right">
                <Button
                  icon="chevron-left"
                  iconPosition="left"
                  bsStyle="default"
                  bsSize="xs"
                  onClick={this.onNavigateToMemberships}>Back To Memberships</Button>
                {
                  membership.isGiftToUserAllowed() &&
                  <Confirmable
                    renderConfirm={(onOk, onCancel) => {
                      return (
                        <MembershipGiftToUserDialog
                          index={index}
                          membership={membership!}
                          onSave={(response) => {
                            onOk();
                            this.setGlobalMessage("Successfully gifted!", "success")
                            this.onSave(response);
                          }}
                          onCancel={onCancel}/>
                      );
                    }}
                    renderTrigger={(onShow) => {
                      return (
                        <Button
                          icon="external-link"
                          bsStyle="primary"
                          bsSize="xs"
                          onClick={onShow}>Gift to User</Button>
                      );
                    }}/>
                }
                <Button
                  icon="chevron-right"
                  bsStyle="primary"
                  bsSize="xs"
                  onClick={this.onShowActuals}>Show Actuals</Button>
              </span>
            }/>

          <MembershipDetailsPanel
            index={index}
            membership={membership}
            onSave={this.onSave}
            onDelete={this.onDelete}
            onChangeStatus={this.onChangeStatus}/>

          <BlockchainMintablePanel
            type="Membership"
            entity={membership}
            entityFields={FIELDS}
            statuses={index.mintChooseableStatuses}
            errors={this.state.errors}
            onReload={this.onReloadMembership}
            onChangeMintStatus={this.onChangeMintStatus}/>

        </div>

      </div>

    );

  }

}

// ................................................................................................................................................
