import React from 'react';
import {ApiSearchResponse, Button, Confirm, Confirmable, Form, InputSelect, SearchResult, StatusButton, Str, Table, TableHeaderCell} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import DisbursementSearchRequest from "../models/DisbursementSearchRequest";
import Transaction from "../models/Transaction";
import ApiIndex from "../models/ApiIndex";
import Disbursement, {DISBURSEMENT_LIST_FIELDS} from "../models/Disbursement";
import FontAwesome from "react-fontawesome";
import Routes from "../Routes";
import EntitlementWithdrawalPendingDisbursementCount from "../models/EntitlementWithdrawalPendingDisbursementCount";

let FIELDS = DISBURSEMENT_LIST_FIELDS;

export type DisbursementsPageProps = PageProps & {};

export type DisbursementsPageState = PageState & {
  pendingCount?: EntitlementWithdrawalPendingDisbursementCount;
  disbursementSearchRequest: DisbursementSearchRequest;
  disbursements?: SearchResult<Transaction> | null;
};

export default class DisbursementsPage extends Page<DisbursementsPageProps, DisbursementsPageState> {

  constructor(props: DisbursementsPageProps) {
    super(props);
    this.setInitialState({
      disbursementSearchRequest: new DisbursementSearchRequest()
    });
  }

  onEnter() {
    this.setState({
      disbursementSearchRequest: {
        status: this.query('status'),
        sort: this.query('sort'),
        page: this.query('page'),
      },
    }, this.onLoad);
  }

  onSearch() {
    let disbursementSearchRequest = this.state.disbursementSearchRequest;
    this.path()
      .param('status', disbursementSearchRequest.status)
      .param('sort', disbursementSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Disbursements');
    this.loadPendingCount();
    this.loadDisbursements();
  }

  loadPendingCount() {
    this.state.index?.findEntitlementWithdrawalPendingDisbursementCount({
      next: this.setPendingCount,
      error: this.onApiError
    }, null);
  }

  loadDisbursements() {
    this.state.index?.searchDisbursements(this.state.disbursementSearchRequest, {
      next: this.setDisbursements,
      error: this.onApiError
    }, FIELDS);
  }

  onView(disbursement: Disbursement) {
    this.navigate(Routes.admin.disbursement(disbursement.key!));
  }

  setPendingCount(response: ApiSearchResponse<EntitlementWithdrawalPendingDisbursementCount>) {
    this.setState({
      pendingCount: response.resource
    });
  }

  setDisbursements(response: ApiSearchResponse<Transaction>) {
    this.setState({
      disbursements: response.resource
    });
  }

  isAddAllowed() {
    let pendingCount = this.state.pendingCount;
    return pendingCount && pendingCount!.value > 0;
  }

  onAdd() {
    let index = this.getIndex();
    index?.createDisbursement(new Disbursement(), {
      next: response => {
        let disbursement = response.resource!;
        this.navigate(Routes.admin.disbursement(disbursement.key!));
      },
      error: this.onApiError
    }, null);
  }

  renderWithIndex(index: ApiIndex) {

    let pendingCount = this.state.pendingCount;

    return (

      <div className="row">

        <div className="col-md-12">

          <PageHeader
            title="Disbursements"
            lead="This area allows you to manage the disbursements of withdrawal entitlements on the
            system. From here you may generate an ABA file for importing through your bank's
            internet banking facilities."/>

          {
            this.isAddAllowed() &&
            <div className="alert alert-warning">
              {pendingCount!.value === 1 && <>There is {pendingCount!.value} entitlement to be disbursed. Please click <code>Add</code> below to create a disbursement and generate an ABA.</>}
              {pendingCount!.value > 1 && <>There are {pendingCount!.value} entitlements to be disbursed. Please click <code>Add</code> below to create a disbursement and generate an ABA.</>}
            </div>
          }

          <Table
            items={this.state.disbursements}
            sort={this.state.disbursementSearchRequest.sort}
            onReload={this.onEnter}
            blankMessage="No disbursements found"
            title={
              <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
                <InputSelect
                  id="disbursementSearchRequest.status"
                  blank="Status"
                  value={this.state.disbursementSearchRequest.status}
                  onChange={this.onFieldChange}
                  options={index.disbursementStatuses}/>
                <span className="actions">
                  <Button
                    icon="search"
                    bsStyle="primary"
                    type="submit">Search</Button>
                  {
                    this.isAddAllowed() &&
                    <Confirmable
                      renderTrigger={(onShow) => {
                        return (
                          <Button
                            icon="plus"
                            bsStyle="default"
                            className="pull-right"
                            onClick={onShow}>Add</Button>
                        );
                      }}
                      renderConfirm={(onOk, onCancel) => {
                        return (
                          <Confirm
                            title="Add Disbursement"
                            message="You are about to create a disbursement for all pending withdrawal entitlements. This will generate an ABA file for importing through your bank's internet banking facilities."
                            onOk={this.onAdd}
                            onCancel={onCancel}/>
                        );
                      }}/>
                  }
                </span>
              </Form>
            }
            renderHeaderRow={() => {
              return (
                <tr>
                  <TableHeaderCell label="Date" className="col-md-2"/>
                  <TableHeaderCell label="Description"/>
                  <TableHeaderCell label="Processed Date" className="col-md-2"/>
                  <TableHeaderCell label="Status" className="col-md-2 text-right"/>
                  <TableHeaderCell label="&nbsp;" className="col-chevron"/>
                </tr>
              );
            }}
            renderItemRow={(disbursement: Disbursement, r: number) => {
              return (
                <tr key={r} className="clickable" onClick={this.fn(this.onView, disbursement)}>
                  <td>{disbursement.processedAt ? Str.formatDate(disbursement.processedAt, 'DD MMMM YYYY') : Str.formatDate(disbursement.createdAt, 'DD MMMM YYYY')}</td>
                  <td>{disbursement.title}</td>
                  <td>{Str.formatDate(disbursement.processedAt, 'DD MMMM YYYY')}</td>
                  <td className="text-right">
                    <StatusButton
                      id={'status-' + r}
                      value={disbursement.status}/>
                  </td>
                  <td className="text-right">
                    <FontAwesome name="chevron-right"/>
                  </td>
                </tr>
              );
            }}
          />

        </div>

      </div>

    );

  }
}
