import React from 'react';
import ApiIndex from "../models/ApiIndex";
import Partner from "../models/Partner";
import HorseImportRun, {HORSE_IMPORT_RUN_STANDARD_FIELDS} from "../models/HorseImportRun";
import {ApiErrorResponse} from "../models/ApiError";
import {ApiResponse, Button, Form, FullScreenModal, GlobalMessage, Str, Table} from "startupbox-react";
import HorseImportRunStatus from "../models/HorseImportRunStatus";
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from "./PartnerAbstractPage";
import HorseImportRunHorse from "../models/HorseImportRunHorse";
import PageHeader from "../components/PageHeader";
import "./PartnerHorseImportRunPage.css";
import Routes from "../Routes";
import FontAwesome from "react-fontawesome";

export type PartnerHorseImportRunPageProps = PartnerAbstractPageProps & {};

export type PartnerHorseImportRunPageState = PartnerAbstractPageState & {
  horseImportRun: HorseImportRun | null | undefined;
}

export default class PartnerHorseImportRunPage extends PartnerAbstractPage<PartnerHorseImportRunPageProps, PartnerHorseImportRunPageState> {

  // constructor(props: PartnerHorseImportRunPageProps) {
  //   super(props);
  // }

  onLoadPartner(partner: Partner) {
    this.loadHorseImportRun();
  }

  loadHorseImportRun() {
    this.state.index?.findHorseImportRunByKey(this.props.params.horseImportRunKey, {
      next: this.setHorseImportRun,
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, HORSE_IMPORT_RUN_STANDARD_FIELDS);
  }

  // -------------------------------------------------------------------------------------------------------------------

  onSubmit() {
    this.setMode('save');
  }

  onHideSaveDialog() {
    this.setMode('');
  }

  onSaveOnly() {
    this.performSave()
  }

  onSaveAndSubmit() {
    this.performSave(response => this.onChangeStatus({key: 'SUBMITTED'}))
  }

  performSave(callback?: (response: ApiResponse<HorseImportRun>) => void) {
    if (this.isEnabled()) {
      this.disable();
      let horseImportRun = this.state.horseImportRun;
      horseImportRun?.save({
        next: (callback ? callback : this.onSave),
        error: response => {
          this.onApiError(response);
          this.setMode('');
        }
      }, HORSE_IMPORT_RUN_STANDARD_FIELDS);
    }
  }

  onChangeStatus(status: HorseImportRunStatus) {
    this.state.horseImportRun?.modifyStatus(status, {
      next: this.onSave,
      error: this.onApiError
    }, HORSE_IMPORT_RUN_STANDARD_FIELDS);
  }

  onSave(response: ApiResponse<HorseImportRun>) {
    this.onNavigateToRuns();
  }

  // -------------------------------------------------------------------------------------------------------------------

  onCancel() {
    this.onNavigateToRuns();
  }

  onDelete(h: number) {
    let horseImportRun = this.state.horseImportRun;
    horseImportRun?.horses?.splice(h, 1);
    this.setState({
      horseImportRun: horseImportRun
    });
  }

  onAppend() {
    let horseImportRun = this.state.horseImportRun;
    horseImportRun?.horses?.push(HorseImportRunHorse.buildNew(this.getIndex()!));
    this.setState({
      horseImportRun: horseImportRun
    });
  }

  isModifyAllowed() {
    let horseImportRun = this.state.horseImportRun;
    return horseImportRun && horseImportRun.isModifyAllowed();
  }

  onNavigateToRuns() {
    this.navigate(Routes.partner.horseImportRuns(this.state.partner!.key!));
  }

  setHorseImportRun(response: ApiResponse<HorseImportRun>) {
    let horseImportRun = response.resource;
    this.setState({
      horseImportRun: horseImportRun
    })
  }

  renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

    let horseImportRun = this.state.horseImportRun;
    let isModifyAllowed = this.isModifyAllowed();
    let mode = this.state.mode;

    if (!horseImportRun) {
      return <></>;
    }

    return <>

      <div id="partner-horse-import-run-page">

        <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity} fieldErrors={(this.state.errors as any)._}/>

        {
          mode === 'save' &&
          <FullScreenModal title="Save Import Run" onHide={this.onHideSaveDialog}>

            <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity} fieldErrors={(this.state.errors as any)._}/>

            <Form disabled={this.isDisabled()}>

              <p className="lead">Would you like to Submit and create the Horses?</p>

              <div className="actions">
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  onClick={this.onSaveOnly}>Save Draft</Button>
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  onClick={this.onSaveAndSubmit}>Save and Submit</Button>
                <Button
                  onClick={this.onHideSaveDialog}
                  bsSize="large">Cancel</Button>
              </div>

            </Form>

          </FullScreenModal>
        }

        <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

          <PageHeader
            title="Horse Import Run"
            subTitle={Str.formatDate(horseImportRun.createdAt)}
            lead="This area allows you to view and edit an import run for this partner."/>

          <DetailsPanel
            index={index}
            errors={this.state.errors}
            partner={partner}
            horseImportRun={horseImportRun}
            onFieldChange={isModifyAllowed ? this.onFieldChange : undefined}
            onDelete={isModifyAllowed ? this.onDelete : undefined}/>

          {
            isModifyAllowed &&
            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Save Changes</Button>
              <Button
                onClick={this.onCancel}
                bsSize="large">Cancel</Button>
              <Button
                bsStyle="primary"
                bsSize="large"
                className="pull-right"
                onClick={this.onAppend}>Append Row</Button>
            </div>
          }
          {
            !isModifyAllowed &&
            <div className="actions">
              <Button
                bsStyle="primary"
                onClick={this.onCancel}
                bsSize="large">Close</Button>
            </div>
          }

        </Form>

      </div>

    </>;

  }

};

// ................................................................................................................................................

const DetailsPanel = (props: {
  index: ApiIndex,
  errors?: { [key: string]: string },
  partner: Partner,
  horseImportRun: HorseImportRun,
  onFieldChange: ((e: Event, callback?: () => void) => void) | undefined,
  onDelete: ((h: number) => void) | undefined,
}) => {

  let index = props.index;
  let errors = props.errors;
  // let partner = props.partner;
  let horseImportRun = props.horseImportRun;
  let onFieldChange = props.onFieldChange;
  let onDelete = props.onDelete;
  let isDeleteAllowed = !!onDelete && horseImportRun.horses!.length > 1;

  return (

    <>

      <Table
        items={horseImportRun.horses}
        blankMessage="No horses found"
        renderHeaderRow={() => {
          if (horseImportRun) {
            let firstHorse = horseImportRun.horses![0];
            let cols = firstHorse.getCols();
            return (
              <tr>
                <th className="col-delete">&nbsp;</th>
                {
                  cols.map((col, c) => {
                    return (
                      col.renderHeaderCell(c)
                    );
                  })
                }
              </tr>
            );
          }
          return null;
        }}
        renderItemRow={(horse: HorseImportRunHorse, row: number) => {
          let prefix = 'horseImportRun.horses[' + row + '].';
          let cols = horse.getCols();
          return (
            <tr key={row} className={horse.status?.key || ''}>
              <td className="col-delete">
                {
                  isDeleteAllowed &&
                  <FontAwesome name="times" onClick={() => {
                    onDelete!(row);
                  }}/>
                }
              </td>
              {
                cols.map((col, h) => {
                  return (
                    col.renderHorseCell(index, errors, row, h, prefix, onFieldChange)
                  );
                })
              }
            </tr>
          );
        }}
      />

    </>

  );

}
