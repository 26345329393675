import PaymentMethodType from "./PaymentMethodType";
import PaymentMethodStatus from "./PaymentMethodStatus";

export default class PaymentMethod {

  key?: string;
  type?: PaymentMethodType;
  status?: PaymentMethodStatus;
  externalReference?: string;
  externalReferenceTrust?: string;

}
