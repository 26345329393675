import React, {useEffect, useState} from "react";
import {ApiResponse, ApiSubscribableCallbacks, Str} from "startupbox-react";
import Statistic from "../models/Statistic";
import ApiError from "../models/ApiError";
import Panel from "./Panel";
import {Bar} from "react-chartjs-3";

const StatisticBarChart = (props: {
  chartContainerId: string,
  title: string,
  what: string,
  timestamp: number,
  refresh: (callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => void
}) => {

  let [statistics, setStatistics] = useState<Statistic | undefined>(undefined);

  let refresh = props.refresh;

  useEffect(() => {
    refresh({
      next: (response: ApiResponse<Statistic>) => {
        setStatistics(response.resource);
      },
      error: null
    });
  }, [refresh, props.timestamp]);

  let maxThresholds = [10, 20, 30, 40, 50, 100, 200, 500, 1000, 5000];

  let getPageData = () => {
    return statistics;
  }

  let data = getPageData();

  if (!data) {
    return <></>;
  }

  // console.log('data', data);
  let xLabels = data.items!.map((item) => item.name);
  let points = data.items!.map((item) => item.value);

  // let isSinglePointOnly = data.isSinglePointOnly();

  // let pointBackgroundColor = 'transparent';
  // let pointBorderColor = 'transparent';
  // let pointHoverBackgroundColor = '#C8FF00';
  // let pointHoverBorderColor = 'rgba(36, 73, 34, 0.2)';

  let chartData = {
    labels: xLabels,
    datasets: [
      {
        label: props.title,
        data: points,
        fill: false,
        backgroundColor: '#C8FF00',
        borderWidth: 0,
        barPercentage: 0.65,
        categoryPercentage: 0.6
      }
    ]
  };

  let chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: '#fff',
          fontSize: 10,
        }
      }],
      yAxes: [{
        gridLines: {
          color: 'rgba(228, 228, 228, 0.1)',
          zeroLineColor: 'rgba(228, 228, 228, 0.1)',
          zeroLineWidth: 1,
        },
        ticks: {
          max: data.getMaxValue(maxThresholds),
          beginAtZero: true,
          fontColor: '#fff',
          fontSize: 10,
          stepSize: data.getStepSize(maxThresholds),
          callback: (value: number) => {
            return value;
          }
        },
      }],
    },
    tooltips: {
      backgroundColor: '#000',
      titleFontColor: '#fff',
      titleFontSize: 12,
      titleAlign: 'center',
      bodyFontColor: '#fff',
      bodyFontSize: 12,
      bodyFontStyle: 'normal',
      bodyAlign: 'center',
      displayColors: false,
      position: 'average',
      mode: 'index',
      intersect: false,
      xPadding: 20,
      yPadding: 10,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowBlur: 10,
      shadowColor: 'rgba(218, 218, 218, 0.5)',
      callbacks: {
        title: (tooltipItem: any) => {
          let item = data!.items![tooltipItem[0].index];
          return item.name;
        },
        label: (tooltipItem: any) => {
          let value = tooltipItem.yLabel;
          return Str.formatNumber(value, 0) + (value === 1 ? ' ' + props.what : ' ' + props.what + 's');
        }
      }
    },
    hover: {
      mode: 'index',
      intersect: false
    }
  }

  return (

    <Panel>
      <h4>{props.title}</h4>
      <div id={props.chartContainerId} className="chart-container" style={{height: '300px'}}>
        <Bar
          data={chartData}
          options={chartOptions as any}/>
      </div>
    </Panel>

  );

}

export default StatisticBarChart;
