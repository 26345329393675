import Wallet from "../models/Wallet";
import {Button, InputWrap} from "startupbox-react";
import Panel from "../components/Panel";
import React, {ReactNode} from "react";

type WalletPanelProps = {
  userKey: string;
  wallet: Wallet;
  onView: (() => void) | null | undefined;
  children?: ReactNode;
};

const WalletPanel = (props: WalletPanelProps) => {

  let wallet = props.wallet;
  let onView = props.onView;

  return (

    <Panel>

      {
        onView &&
        <Button
          icon="chevron-right"
          bsStyle="primary"
          bsSize="xs"
          className="pull-right"
          onClick={onView}>View</Button>
      }

      <InputWrap
        id="wallet.name"
        label="Wallet">
        <div className="read-only">
          <div>{wallet.name}</div>
          {wallet.viewUrl && <div><a href={wallet.viewUrl!} target="_blank" rel="noopener noreferrer">{wallet.viewUrl!}</a></div>}
          <div className="text-muted">{wallet.publicKey}</div>
        </div>
      </InputWrap>

      {props.children}

    </Panel>

  );

}

export default WalletPanel;