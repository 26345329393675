import React from 'react';
import {ApiSearchResponse, Button, SearchResult} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from './PartnerAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Partner from "../models/Partner";
import Collectible, {COLLECTIBLE_STANDARD_FIELDS} from "../models/Collectible";
import Routes from "../Routes";
import SectionHeader from "../components/SectionHeader";
import CollectibleSearchRequest from "../models/CollectibleSearchRequest";
import BlockchainMintableActualsTable from "../components/BlockchainMintableActualsTable";

let FIELDS = COLLECTIBLE_STANDARD_FIELDS;

export type PartnerCollectibleActualsPageProps = PartnerAbstractPageProps & {};

export type PartnerCollectibleActualsPageState = PartnerAbstractPageState & {
  collectible?: Collectible | null | undefined;
  actuals?: SearchResult<Collectible> | null;
}

export default class PartnerCollectibleActualsPage extends PartnerAbstractPage<PartnerCollectibleActualsPageProps, PartnerCollectibleActualsPageState> {

  constructor(props: PartnerCollectibleActualsPageProps) {
    super(props);
    this.setInitialState({
      collectible: null,
    });
  }

  onLoadPartner(partner: Partner) {
    this.loadCollectible();
  }

  loadCollectible() {
    let partner = this.state.partner;
    partner?.findCollectibleByKey(this.props.params.collectibleKey, {
      next: response => {
        this.setCollectible(response, () => {
          this.searchActuals(1);
        });
      },
      error: this.onApiError
    }, FIELDS);
  }

  searchActuals(page: number) {
    let searchRequest = new CollectibleSearchRequest();
    searchRequest.page = page - 1;
    this.state.collectible?.searchActuals(searchRequest, {
      next: (response: ApiSearchResponse<Collectible>) => {
        let actuals = response.resource;
        this.setActuals(actuals);
      },
      error: this.onApiError
    }, null);
  }

  onBack() {
    this.navigate(Routes.partner.collectible(this.props.params.key, this.props.params.collectibleKey));
  }

  setCollectible(response: ApiSearchResponse<Collectible>, callback?: () => void) {
    this.setState({
      collectible: response.resource
    }, callback);
  }

  setActuals(collectibles: SearchResult<Collectible>) {
    this.setState({
      actuals: collectibles
    });
  }

  renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

    let collectible = this.state.collectible;
    let actuals = this.state.actuals;

    if (!collectible) {
      return <></>;
    }

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title={collectible.name}
            lead={
              <>
                The actual instances of this collectible are shown below. Actual instances are what users buy. The characteristics
                and metadata of an actual instance are the same as the template from which it is created from.
              </>
            }/>

          <SectionHeader
            title="Collectible Actuals"
            actions={
              <>
                <Button
                  icon="chevron-left"
                  iconPosition="left"
                  bsStyle="default"
                  bsSize="xs"
                  className="pull-right"
                  onClick={this.onBack}>Back To Template</Button>
              </>
            }/>

          <BlockchainMintableActualsTable
            actuals={actuals}
            onPaginate={this.searchActuals}/>

        </div>

      </div>

    );

  }

};
