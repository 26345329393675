export default class PushNotificationSearchRequest {

  key?: string;
  title?: string;
  body?: string;
  deviceOwnerIdentifier?: string;
  status?: string;

  sort?: string;
  page?: string | number;
  size?: string | number;

}
