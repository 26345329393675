import {Component} from 'startupbox-react';
import Events from './Events';
import ApiIndex from "./models/ApiIndex";
import React from 'react';

export type DialogProps = {};

export type DialogState = {
  index?: ApiIndex;
  mode?: string | null;
  globalMessage?: string | null | undefined;
  globalMessageSeverity?: string | null | undefined;
  errors?: { [key: string]: string };
};

// noinspection DuplicatedCode
export default class Dialog<P extends DialogProps, S extends DialogState> extends Component<P, S> {

  constructor(props: P) {
    super(props);
    this.init = this.init.bind(this);
    this.onLoadIndex = this.onLoadIndex.bind(this);
    this.onEnter = this.onEnter.bind(this);
    this.onLoad = this.onLoad.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  init() {
    super.init();
    this.subscribe(Events.app.index, this.onLoadIndex);
  }

  onLoadIndex(index: ApiIndex) {
    if (index && !!index.href) {
      this.setState({index: index}, () => {
        this.onEnter();
      });
    }
    else {
      ApiIndex.load({
        next: response => {
          this.setState({index: response.resource}, () => {
            this.onEnter();
          });
        },
        error: this.onApiError
      });
    }
  }

  onEnter() {
    this.onLoad();
  }

  onLoad() {
  }

  getIndex() {
    return this.state.index;
  }

  render() {
    let index = this.state.index;
    if (index) {
      return this.renderWithIndex(index!);
    }
    return <></>;
  }

  renderWithIndex(index: ApiIndex) {
    return <></>;
  }

};