import React from 'react';
import {ApiSearchResponse, Button, Confirmable} from 'startupbox-react';
import SectionHeader from '../components/SectionHeader';
import PartnerHorseAbstractPage, {PartnerHorseAbstractPageProps, PartnerHorseAbstractPageState} from './PartnerHorseAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Horse from "../models/Horse";
import LegalOwner, {LEGAL_OWNER_STANDARD_FIELDS} from "../models/LegalOwner";
import Partner from "../models/Partner";
import Tranche from "../models/Tranche";
import LegalOwnerDialog from "../legal_owner/LegalOwnerDialog";
import LegalOwnerSearchRequest from "../models/LegalOwnerSearchRequest";
import Routes from "../Routes";
import LegalOwnerTable from "../tranche/LegalOwnerTable";

export type PartnerHorseOwnershipsPageProps = PartnerHorseAbstractPageProps & {};

export type PartnerHorseOwnershipsPageState = PartnerHorseAbstractPageState & {
  legalOwners: Array<LegalOwner> | null;
}

export default class PartnerHorseOwnershipsPage extends PartnerHorseAbstractPage<PartnerHorseOwnershipsPageProps, PartnerHorseOwnershipsPageState> {

  constructor(props: PartnerHorseOwnershipsPageProps) {
    super(props);
    this.activeTab = 'ownerships';
  }

  onLoadHorse(horse: Horse | undefined) {
    this.searchLegalOwners(1);
  }

  searchLegalOwners(page: number) {
    let legalOwnerSearchRequest = new LegalOwnerSearchRequest();
    legalOwnerSearchRequest.page = page - 1;
    this.state.horse?.searchLegalOwners(legalOwnerSearchRequest, {
      next: (response: ApiSearchResponse<LegalOwner>) => {
        let legalOwners = response.resource;
        this.setLegalOwners(legalOwners);
      },
      error: this.onApiError
    }, LEGAL_OWNER_STANDARD_FIELDS);
  }

  setLegalOwners(legalOwners: Array<LegalOwner>) {
    this.setState({
      legalOwners: legalOwners
    })
  }

  onViewTranche(tranche: Tranche) {
    this.navigate(Routes.partner.horse_tranche(this.getPartnerKey(), this.getHorseKey(), tranche.key!));
  }

  renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {

    let legalOwners = this.state.legalOwners;

    return <>

      <SectionHeader
        title="Legal Owners"
        description="The legal owners of this horse are shown below. Users of the app may subscribe to
          blockchain-backed tranches for ownerships that support it. Simply click on these to view
          tranche details."
        actions={
          <>
            <Confirmable
              renderConfirm={(onOk) => {
                let legalOwner: LegalOwner = new LegalOwner();
                return (
                  <LegalOwnerDialog
                    horse={horse}
                    legalOwner={legalOwner}
                    onClose={() => {
                      this.searchLegalOwners(1);
                      onOk();
                    }}
                  />
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <Button
                    icon="plus"
                    bsStyle="primary"
                    bsSize="xs"
                    className="pull-right"
                    onClick={onShow}
                    disabled={!horse.isAddLegalOwnerAllowed()}>Add Legal Owner</Button>
                );
              }}/>
          </>
        }/>

      <LegalOwnerTable legalOwners={legalOwners}
         searchLegalOwners={this.searchLegalOwners}
         index={index}
         onViewTranche={this.onViewTranche}
         onApiError={this.onApiError}
      />

    </>;

  }
};

