import React from 'react';
import {Component, log} from 'startupbox-react';
import Context from './Context';
import Events from './Events';
import Routes from './Routes';
import ApiIndex from "./models/ApiIndex";
import NotFoundPanel from "./components/NotFoundPanel";
import AccessDeniedPanel from "./components/AccessDeniedPanel";

let NOT_FOUND_MODE = 'not-found';
let ACCESS_DENIED_MODE = 'access-denied';

export type RouteProps = {
  full: boolean;
  hideSecondaryNav: boolean;
  requiresAuthentication: boolean;
  requiresAdmin: boolean;
  requiresSysadmin: boolean;
};

export type PageProps = {
  params: { [key: string]: any };
  route: RouteProps;
  location: { pathname: string };
};

export type PageState = {
  index?: ApiIndex;
  mode?: string | null;
  globalMessage?: string | null | undefined;
  globalMessageSeverity?: string | null | undefined;
  errors?: { [key: string]: string };
  disabled?: boolean;
};

// noinspection JSUnusedGlobalSymbols,DuplicatedCode
export default class Page<P extends PageProps, S extends PageState> extends Component<P, S> {

  constructor(props: P) {
    super(props);
    this.init = this.init.bind(this);
    this.onLoadIndex = this.onLoadIndex.bind(this);
    this.onEnter = this.onEnter.bind(this);
    this.onLoad = this.onLoad.bind(this);
  }

  setInitialState(initialState: S) {
    if (this.props.route) {
      if (this.props.route.requiresAuthentication && !Context.isAuthenticated()) {
        this.navigate(Routes.main.home());
      }
      else if (this.props.route.requiresAdmin && !Context.isAdmin()) {
        this.navigate(Routes.main.home());
      }
      else if (this.props.route.requiresSysadmin && !Context.isSysadmin()) {
        this.navigate(Routes.main.home());
      }
    }
    super.setInitialState(initialState);
  }

  componentDidMount() {
    this.publish(Events.route.enter);
    this.publish(Events.system.pageTitle, '');
    log.heading('Initialised ' + this.constructor.name);
    super.componentDidMount();
  }

  init() {
    super.init();
    this.subscribe(Events.app.index, this.onLoadIndex);
  }

  onLoadIndex(index: ApiIndex) {
    if (index && !!index.href) {
      this.setState({index: index}, () => {
        this.onEnter();
      });
    }
  }

  onEnter() {
    this.onLoad();
  }

  onLoad() {
  }

  getIndex() {
    return this.state.index;
  }

  getKeyFromPath() {
    return this.props.params.key;
  }

  isAdminPortal() {
    return this.props.location.pathname.startsWith('/admin');
  }

  showNotFound(value: boolean | null) {
    this.setState({
      mode: value ? NOT_FOUND_MODE : null
    });
  }

  showAccessDenied(value: boolean | null) {
    this.setState({
      mode: value ? ACCESS_DENIED_MODE : null
    });
  }

  // ................................................................................................................................................

  render() {

    let mode = this.state.mode;
    let index = this.state.index;

    if (mode === NOT_FOUND_MODE) {
      return this.renderNotFound();
    }
    else if (mode === ACCESS_DENIED_MODE) {
      return this.renderAccessDenied();
    }
    else {
      if (index) {
        return this.renderWithIndex(index!);
      }
      return null;
    }

  }

  renderWithIndex(index: ApiIndex) {
    return <></>;
  }

  renderNotFound() {
    return (
      <NotFoundPanel/>
    );
  }

  renderAccessDenied() {
    return (
      <AccessDeniedPanel/>
    );
  }

};