import React from 'react';
import User from "../models/User";
import ApiIndex from "../models/ApiIndex";
import Wallet from "../models/Wallet";
import UserDeviceAbstractPage, {UserDeviceAbstractPageProps, UserDeviceAbstractPageState} from "./UserDeviceAbstractPage";
import {ApiSearchResponse, SearchResult, Str, Table, TableHeaderCell} from "startupbox-react";
import Collectible from "../models/Collectible";
import SectionHeader from "../components/SectionHeader";
import TransactionSearchRequest from "../models/TransactionSearchRequest";
import Device from "../models/Device";
import DeviceEvent, {DEVICE_EVENT_LIST_FIELDS} from "../models/DeviceEvent";

export type UserDeviceEventsPageProps = UserDeviceAbstractPageProps & {};

export type UserDeviceEventsPageState = UserDeviceAbstractPageState & {
  deviceEvents: SearchResult<DeviceEvent> | null;
};

export default class UserDeviceDeviceEventsPage extends UserDeviceAbstractPage<UserDeviceEventsPageProps, UserDeviceEventsPageState> {

  constructor(props: UserDeviceEventsPageProps) {
    super(props);
    this.activeTab = 'events';
  }

  onLoadUserAndWallet(user: User, wallet: Wallet) {
    this.searchDeviceEvents(1);
  }

  searchDeviceEvents(page: number) {
    let deviceEventSearchRequest = new TransactionSearchRequest();
    deviceEventSearchRequest.page = page - 1;
    this.state.device?.searchEvents(deviceEventSearchRequest, {
      next: (response: ApiSearchResponse<Collectible>) => {
        let transactions = response.resource;
        this.setDeviceEvents(transactions);
      },
      error: this.onApiError
    }, DEVICE_EVENT_LIST_FIELDS);
  }

  setDeviceEvents(deviceEvents: SearchResult<DeviceEvent>) {
    this.setState({
      deviceEvents: deviceEvents
    })
  }

  renderWithIndexUserAndDevice(index: ApiIndex, user: User, device: Device) {

    let deviceEvents = this.state.deviceEvents;

    return <>

      <SectionHeader
        title="Events"/>

      <Table
        items={deviceEvents}
        onPaginate={this.searchDeviceEvents}
        blankMessage="No device events found"
        renderHeaderRow={() => {
          return (
            <tr>
              <TableHeaderCell label="Date" className="col-md-2"/>
              <TableHeaderCell label="Type" className="col-md-2"/>
              <TableHeaderCell label="Details"/>
            </tr>
          );
        }}
        renderItemRow={(deviceEvent: DeviceEvent, de: number) => {
          return (
            <tr key={de} className="clickable">
              <td>{Str.formatDate(deviceEvent.createdAt, "DD/MM/YYYY")}</td>
              <td>
                <div>{deviceEvent.type?.name}</div>
              </td>
              <td className="text-center">{deviceEvent.details}</td>
            </tr>
          );
        }}
      />

    </>;

  }

};

