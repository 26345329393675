import React from 'react';
import FontAwesome from 'react-fontawesome';
import {ApiResponse, ApiSearchResponse, Button, Confirmable, Form, InputText, SearchResult, StatusButton, Table, TableHeaderCell} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Routes from '../Routes';
import Membership from "../models/Membership";
import ApiIndex from "../models/ApiIndex";
import MembershipSearchRequest from "../models/MembershipSearchRequest";
import Content from "../models/Content";
import Binary from "../models/Binary";
import BlockchainAttributeGroup from "../models/BlockchainAttributeGroup";
import MembershipDialog from "./MembershipDialog";
import MembershipTable from "./MembershipTable";

let FIELDS = '';

export type MembershipsPageProps = PageProps & {};

export type MembershipsPageState = PageState & {
  membershipSearchRequest: MembershipSearchRequest;
  memberships?: SearchResult<Membership> | null;
};

export default class MembershipsPage extends Page<MembershipsPageProps, MembershipsPageState> {

  constructor(props: MembershipsPageProps) {
    super(props);
    this.setInitialState({
      membershipSearchRequest: new MembershipSearchRequest(),
      memberships: null,
    });
  }

  onEnter() {
    this.setState({
      membershipSearchRequest: {
        name: this.query('name'),
        // status: this.query('status'),
        sort: this.query('sort'),
        page: +this.query('page'),
      }
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('name', this.state.membershipSearchRequest.name)
      // .param('status', this.state.membershipSearchRequest.status)
      .param('sort', this.state.membershipSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Memberships');
    this.state.index?.searchMemberships(this.state.membershipSearchRequest, {
      next: this.setMemberships,
      error: this.onApiError
    }, FIELDS);
  }

  onSave(response: ApiSearchResponse<Membership>) {
    let membership = response.resource;
    this.navigate(Routes.admin.membership(membership.key));
  }

  onView(resource: Membership) {
    this.navigate(Routes.admin.membership(resource.key!));
  }

  setMemberships(response: ApiSearchResponse<Membership>) {
    this.setState({
      memberships: response.resource
    })
  }

  renderWithIndex(index: ApiIndex) {

    return (

      <div className="row">

        <div className="col-md-12">

          <PageHeader
            title="Memberships"
            lead="This area allows you to manage the memberships on the
            system. From here you may add a new membership or edit the details of an existing
            membership."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="membershipSearchRequest.name"
              placeholder="Name"
              value={this.state.membershipSearchRequest.name}
              onChange={this.onFieldChange}
              className="wide"/>
{/*
            <InputSelect
              id="membershipSearchRequest.status"
              blank="Status"
              value={this.state.membershipSearchRequest.status}
              onChange={this.onFieldChange}
              options={index.membershipStatuses}/>
*/}
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              <Confirmable
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="plus"
                      bsStyle="default"
                      className="pull-right"
                      onClick={onShow}>Add</Button>
                  );
                }}
                renderConfirm={(onOk, onCancel) => {
                  let newMembership = new Membership();
                  newMembership.terms = new Content();
                  newMembership.promotionalImages = new Array<Binary>();
                  newMembership.manageableMetadataAttributeGroup = new BlockchainAttributeGroup();
                  return (
                    <MembershipDialog
                      index={index}
                      membership={newMembership}
                      onSave={(response: ApiResponse<Membership>) => {
                        this.onSave(response);
                        onOk();
                      }}
                      onCancel={onCancel}/>
                  );
                }}/>
            </span>
          </Form>

          <br/>

          <MembershipTable
              memberships={this.state.memberships}
              sort={this.state.membershipSearchRequest.sort}
              onReload={this.onEnter}
              onView={this.onView}
          />


        </div>

      </div>

    );

  }

}
