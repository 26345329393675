import React from 'react';
import {ApiSearchResponse, Button, SearchResult} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from './PartnerAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Partner from "../models/Partner";
import Routes from "../Routes";
import SectionHeader from "../components/SectionHeader";
import CollectibleSearchRequest from "../models/CollectibleSearchRequest";
import BlockchainMintableActualsTable from "../components/BlockchainMintableActualsTable";
import Membership, {MEMBERSHIP_STANDARD_FIELDS} from "../models/Membership";

let FIELDS = MEMBERSHIP_STANDARD_FIELDS;

export type PartnerMembershipActualsPageProps = PartnerAbstractPageProps & {};

export type PartnerMembershipActualsPageState = PartnerAbstractPageState & {
    membership?: Membership | null | undefined;
    actuals?: SearchResult<Membership> | null;
}

export default class PartnerMembershipActualsPage extends PartnerAbstractPage<PartnerMembershipActualsPageProps, PartnerMembershipActualsPageState> {

    constructor(props: PartnerMembershipActualsPageProps) {
        super(props);
        this.setInitialState({
            membership: null,
        });
    }

    onLoadPartner(partner: Partner) {
        this.loadMembership();
    }

    loadMembership() {
        let partner = this.state.partner;
        partner?.findMembershipByKey(this.props.params.membershipKey, {
            next: response => {
                this.setMembership(response, () => {
                    this.searchActuals(1);
                });
            },
            error: this.onApiError
        }, FIELDS);
    }

    searchActuals(page: number) {
        let searchRequest = new CollectibleSearchRequest();
        searchRequest.page = page - 1;
        this.state.membership?.searchActuals(searchRequest, {
            next: (response: ApiSearchResponse<Membership>) => {
                let actuals = response.resource;
                this.setActuals(actuals);
            },
            error: this.onApiError
        }, null);
    }

    onBack() {
        this.navigate(Routes.partner.membership(this.props.params.key, this.props.params.membershipKey));
    }

    setMembership(response: ApiSearchResponse<Membership>, callback?: () => void) {
        this.setState({
            membership: response.resource
        }, callback);
    }

    setActuals(memberships: SearchResult<Membership>) {
        this.setState({
            actuals: memberships
        });
    }

    renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

        let membership = this.state.membership;
        let actuals = this.state.actuals;

        if (!membership) {
            return <></>;
        }

        return (

            <div className="row">

                <div className="col-md-8 col-md-offset-2">

                    <PageHeader
                        title={membership.name}
                        lead={
                            <>
                                The actual instances of this membership are shown below. Actual instances are what users buy. The characteristics
                                and metadata of an actual instance are the same as the template from which it is created from.
                            </>
                        }/>

                    <SectionHeader
                        title="Membership Actuals"
                        actions={
                            <>
                                <Button
                                    icon="chevron-left"
                                    iconPosition="left"
                                    bsStyle="default"
                                    bsSize="xs"
                                    className="pull-right"
                                    onClick={this.onBack}>Back To Template</Button>
                            </>
                        }/>

                    <BlockchainMintableActualsTable
                        actuals={actuals}
                        onPaginate={this.searchActuals}/>

                </div>

            </div>

        );

    }

};
