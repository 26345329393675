import Model from "../Model";

export default class TransactionFixPaymentRequest {

  creditCardPaymentMethodKey?: string;
  creditCardIntentId?: string;
  creditCardAmount?: number;

  appCreditDescription?: string;
  appCreditAmount?: number;

  href = Model.hrefFunction();


};
