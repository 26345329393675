import React from 'react';
import {ApiResponse, Button, Form, FullScreenModal, GlobalMessage, InputText} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import User from "../models/User";
import ApiIndex from "../models/ApiIndex";
import BankDetails from "../models/BankDetails";

export type UserBankDetailsDialogProps = DialogProps & {
  user: User;
  bankDetails?: BankDetails;
  fields: string | null;
  onSave: (response: ApiResponse<BankDetails>) => void;
  onCancel: () => void;
};

export type UserBankDetailsDialogState = DialogState & {
  user: User;
  bankDetails: BankDetails;
};

export default class UserBankDetailsDialog extends Dialog<UserBankDetailsDialogProps, UserBankDetailsDialogState> {

  constructor(props: UserBankDetailsDialogProps) {
    super(props);
    this.setInitialState({
      user: this.props.user,
      bankDetails: this.props.bankDetails
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let user = this.state.user;
      let bankDetails = this.state.bankDetails;
      user.modifyBankDetails(bankDetails, {
        next: this.onSave,
        error: this.onApiError
      }, this.props.fields);
    }
  }

  onSave(response: ApiResponse<BankDetails>) {
    this.enable();
    this.props.onSave(response);
  }

  onCancel() {
    this.enable();
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let bankDetails = this.state.bankDetails;

    return (

      <div>

        <FullScreenModal title={'Edit Bank Details'} onHide={this.onCancel}>

          <p className="lead">Please enter the details of this user's bank details below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="bankDetails.name"
                    label="Bank Name"
                    value={bankDetails!.name}
                    error={this.state.errors?.['request.name']}
                    onChange={this.onFieldChange}/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="bankDetails.identifier"
                    label="BSB"
                    value={bankDetails!.identifier}
                    error={this.state.errors?.['request.identifier']}
                    onChange={this.onFieldChange}/>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="bankDetails.accountName"
                    label="Account Name"
                    value={bankDetails!.accountName}
                    error={this.state.errors?.['request.accountName']}
                    onChange={this.onFieldChange}/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="bankDetails.accountNumber"
                    label="Account Number"
                    value={bankDetails!.accountNumber}
                    error={this.state.errors?.['request.accountNumber']}
                    onChange={this.onFieldChange}/>
                </div>
              </div>

            </Panel>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Save Changes</Button>
              <Button
                onClick={this.onCancel}
                bsSize="large">Cancel</Button>
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
