export type SalesChannelKey = 'MARKETPLACE' | 'PACK' | null;

export default class SalesChannel {

  key?: SalesChannelKey;
  name?: string | null;

  // ................................................................................................................................................

  constructor(key?: SalesChannelKey) {
    this.key = key;
  }

}

export let SalesChannelMarketplace = new SalesChannel('MARKETPLACE');
export let SalesChannelPack = new SalesChannel('PACK');

