export class ContentBlock {

  type?: string;
  data: any = {};

  constructor(type?: string) {
    this.type = type;
  }

  setDatum(key: string, value: any) {
    this.data[key] = value;
    return this;
  }

}

export class ContentBody {

  type?: string;
  blocks: Array<ContentBlock> = [];

  isEmpty() {
    return !this.blocks || this.blocks.length === 0;
  }

  addHeader(level: number, text?: string, condition?: boolean) {
    if (condition === undefined || condition) {
      if (text) {
        this.blocks.push(new ContentBlock('header')
          .setDatum('level', level)
          .setDatum('text', text)
        );
      }
    }
    return this;
  }

  addParagraph(text?: string, condition?: boolean) {
    if (condition === undefined || condition) {
      if (text) {
        this.blocks.push(new ContentBlock('paragraph')
          .setDatum('text', text)
        );
      }
    }
    return this;
  }

  addOrderedList(items?: string[], condition?: boolean) {
    if (condition === undefined || condition) {
      if (items && items.length > 0) {
        this.blocks.push(new ContentBlock('list')
          .setDatum('style', 'ordered')
          .setDatum('items', items)
        );
      }
    }
    return this;
  }

  addUnorderedList(items?: string[], condition?: boolean) {
    if (condition === undefined || condition) {
      if (items && items.length > 0) {
        this.blocks.push(new ContentBlock('list')
          .setDatum('style', 'unordered')
          .setDatum('items', items)
        );
      }
    }
    return this;
  }

  addImage(url: string, condition?: boolean) {
    if (condition === undefined || condition) {
      this.blocks.push(new ContentBlock('images')
        .setDatum('type', 'images-across-1')
        .setDatum('multiple', false)
        .setDatum('files', [{
          url: url
        }])
      );
    }
    return this;
  }

  addImages(urls: string[], number?: number, condition?: boolean) {
    if (condition === undefined || condition) {
      let images = urls.map((url, u) => {
        return {url: url}
      });
      this.blocks.push(new ContentBlock('images')
        .setDatum('type', 'images-across-' + (number || urls.length))
        .setDatum('multiple', false)
        .setDatum('files', images)
      );
    }
    return this;
  }

  addLargePrimaryButton(text: string, url: string, condition?: boolean) {
    if (condition === undefined || condition) {
      if (text) {
        this.blocks.push(new ContentBlock('buttons')
          .setDatum('items', [{
            label: text,
            style: 'primary',
            size: 'lg',
            url: url
          }])
        );
      }
    }
    return this;
  }

  addSpacer(height: number, condition?: boolean) {
    if (condition === undefined || condition) {
      if (height > 0) {
        this.blocks.push(new ContentBlock('spacer')
          .setDatum('height', height)
        );
      }
    }
    return this;
  }

}

export default class Content {

  body: ContentBody;

  constructor(body?: ContentBody) {
    this.body = body ? body : new ContentBody();
  }

  isEmpty() {
    return !this.body || (this.body.isEmpty && this.body.isEmpty()) || !this.body.blocks || this.body.blocks.length === 0;
  }

}
