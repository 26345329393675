import React from 'react';
import {StatusButton} from 'startupbox-react';
import {Image} from '../components/Image';
import './HorsePanel.css';
import Horse from "../models/Horse";

type HorsePanelProps = {
  horse: Horse;
  onClick?: () => void | null
}

const HorsePanel = (props: HorsePanelProps) => {

  let horse = props.horse;
  let onClick = props.onClick;

  return (

    <div className={'horse-panel' + (onClick ? ' clickable' : '')} onClick={onClick}>
      <Image src={horse.coverImage} className="cover" transforms="w_700"/>
      <div className="meta">
        <StatusButton value={horse.racingStatus}/>
      </div>
      <div className="content">
        <div className="name">{horse.name}</div>
        <div className="short-description">{horse.shortDescription || 'No description'}</div>
        {horse.partnerName && <div className="partner-name">{horse.partnerName}</div>}
      </div>
    </div>

  );

};

export default HorsePanel;