export default class AppCreditSearchRequest {

  keywords?: string;
  key?: string;
  type?: string;

  sort?: string;
  page?: string | number;
  size?: string | number;

}
