import React from 'react';
import {ApiResponse, Button, FileWithUpload, Form, FullScreenModal, GlobalMessage, ImageWithUpload, InputDatePicker, InputInlineEditor, InputSelect, InputText, InputCheckbox, VideoWithUpload} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import ApiIndex from "../models/ApiIndex";
import Tranche, {TRANCHE_STANDARD_FIELDS} from "../models/Tranche";
import LegalOwner from "../models/LegalOwner";
import VisibilityLevel from "../models/VisibilityLevel";
import Model from "../Model";
import {acceptedFileMimeTypes, acceptedImageMimeTypes} from "../utils/acceptedFileMimeTypes";
import SectionHeader from "../components/SectionHeader";
import Binary from "../models/Binary";
import Horse from "../models/Horse";

export type TrancheDialogProps = DialogProps & {
  horse: Horse;
  legalOwner?: LegalOwner;
  tranche: Tranche;
  onSave: (response: ApiResponse<Tranche>) => void;
  onDelete: () => void;
  onCancel: () => void;
};

export type TrancheDialogState = DialogState & {
  tranche: Tranche;
};

export default class TrancheDialog extends Dialog<TrancheDialogProps, TrancheDialogState> {

  constructor(props: TrancheDialogProps) {
    super(props);
    this.setInitialState({
      tranche: this.props.tranche,
      disabled: !!this.props.tranche.key && !this.props.tranche.isModifyAllowed()
    });
  }

  onSubmit(onSave?: (tranche: Tranche) => void) {
    if (this.isEnabled()) {
      this.disable();
      let tranche = this.state.tranche;
      if (tranche.key) {
        tranche.save({
          next: (response: ApiResponse<Tranche>) => {
            if (onSave) {
              onSave(response.resource!);
            }
            else {
              this.onSave(response);
            }
          },
          error: this.onApiError
        }, TRANCHE_STANDARD_FIELDS);
      }
      else if (this.props.legalOwner) {
        let legalOwner = this.props.legalOwner;
        legalOwner?.addTranche(tranche, {
          next: this.onSave,
          error: this.onApiError
        }, TRANCHE_STANDARD_FIELDS);
      }
      else {
        alert('Legal Owner is required for insert!')
      }
    }
  }

  isActive() {
    let tranche = this.state.tranche;
    return tranche.mintStatus && tranche.mintStatus.key === 'ACTIVE'
  }

  isModifyAllowed() {
    let tranche = this.state.tranche;
    return !tranche.key || tranche.isModifyAllowed();
  }

  onSave(response: ApiResponse<Tranche>) {
    this.enable();
    this.props.onSave(response);
  }

  onDelete() {
    let tranche = this.state.tranche;
    tranche.delete({
      next: this.props.onDelete,
      error: this.onApiError
    });
  }

  onCancel() {
    this.enable();
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let horse = this.props.horse;
    let tranche = this.state.tranche;
    let isSalesChannelPack = tranche.isSalesChannelPack();

    return (

      <div>

        <FullScreenModal title={!tranche.key ? 'New Tranche' : this.isActive() ? 'Active Tranche' : 'Edit Tranche'} onHide={this.onCancel}>

          {!this.isActive() &&
            <p className="lead">Please enter the details of the tranche below.</p>
          }

          {this.isActive() &&
            <p className="lead">This Tranche is active and can no longer be modified.</p>
          }

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <InputText
                id="tranche.name"
                label="Tranche Name"
                value={tranche.name}
                error={this.state.errors?.['request.name']}
                onChange={this.onFieldChange}
                className="lg"
                required/>

              <InputText
                id="tranche.badgeLabel"
                label="Badge Label"
                help="An optional promotional text for marketing purposes. eg Selling Fast, Exclusive to BTX etc"
                value={tranche.badgeLabel}
                error={this.state.errors?.['request.badgeLabel']}
                onChange={this.onFieldChange}/>

              <div className="row">
                <div className="col-md-6">
                  <InputDatePicker
                    id="tranche.startAt"
                    label="Start Date"
                    value={tranche.startAt}
                    error={this.state.errors?.['request.startAt']}
                    dateFormat="DD/MM/YYYY"
                    onChange={this.onFieldChange}
                    required/>
                </div>
                <div className="col-md-6">
                  <InputDatePicker
                    id="tranche.endAt"
                    label="End Date"
                    value={tranche.endAt}
                    error={this.state.errors?.['request.endAt']}
                    dateFormat="DD/MM/YYYY"
                    onChange={this.onFieldChange}
                    required/>
                </div>
              </div>

              {/*
              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="tranche.minSubscriberCount"
                    label="Min Subscriber Count"
                    value={tranche.minSubscriberCount}
                    error={this.state.errors?.['request.minSubscriberCount']}
                    onChange={this.onFieldChange}/>
                </div>
                <div className="col-md-6">
                  <InputDatePicker
                    id="tranche.minSubscriberCountAt"
                    label="Min Subscriber Count At"
                    value={tranche.minSubscriberCountAt}
                    error={this.state.errors?.['request.minSubscriberCountAt']}
                    dateFormat="DD/MM/YYYY"
                    onChange={this.onFieldChange}/>
                </div>
              </div>
              */}

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="tranche.absoluteOwnershipPercent"
                    label="Absolute Ownership Percentage"
                    value={tranche.absoluteOwnershipPercent}
                    format="percent"
                    error={this.state.errors?.['request.absoluteOwnershipPercent']}
                    onChange={this.onFieldChange}
                    required/>
                </div>
                <div className="col-md-6">
                  <InputSelect
                    id="tranche.salesChannel"
                    label="Sales Channel"
                    value={tranche.salesChannel}
                    error={this.state.errors?.['request.salesChannel']}
                    options={index.salesChannels}
                    onChange={this.onFieldChange}
                    disabled={this.isDisabled() || tranche.isInPack()}
                    required/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="tranche.unitCount"
                    label="Number of Units"
                    value={!isSalesChannelPack ? tranche.unitCount : 'N/A - refer to pack'}
                    format={!isSalesChannelPack ? 'number' : undefined}
                    error={this.state.errors?.['request.unitCount']}
                    onChange={this.onFieldChange}
                    className="lg"
                    disabled={this.isDisabled() || isSalesChannelPack}
                    required={!isSalesChannelPack}/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="tranche.unitPrice"
                    label="Unit Price"
                    value={!isSalesChannelPack ? tranche.unitPrice : 'N/A - refer to pack'}
                    format={!isSalesChannelPack ? 'currency' : undefined}
                    error={this.state.errors?.['request.unitPrice']}
                    onChange={this.onFieldChange}
                    className="lg"
                    disabled={this.isDisabled() || isSalesChannelPack}
                    required={!isSalesChannelPack}/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                      id="tranche.externalLink"
                      help="External link associated with this tranche"
                      label="External Link"
                      value={tranche.externalLink}
                      error={this.state.errors?.['request.externalLink']}
                      className="lg"
                      onChange={this.onFieldChange}
                      disabled={this.isDisabled()}/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 text-left">
                  <InputText
                    id="tranche.limitPerUser"
                    help="Leave empty for no restriction"
                    label="Limit per User"
                    value={!isSalesChannelPack ? tranche.limitPerUser : 'N/A - refer to pack'}
                    format={!isSalesChannelPack ? 'number' : undefined}
                    error={this.state.errors?.['request.limitPerUser']}
                    className="lg"
                    onChange={this.onFieldChange}
                    disabled={this.isDisabled() || isSalesChannelPack}/>
                </div>
              </div>

              <SectionHeader title="Media" divider/>

              <ImageWithUpload
                id="tranche.coverImage"
                label="Cover Image"
                files={tranche.coverImage ? [tranche.coverImage] : []}
                className="images-across-1"
                accept={acceptedImageMimeTypes}
                lightbox
                upload
                onChange={(e) => {
                  let images: Array<any> = e.target.value;
                  let coverImage = (images && images.length > 0) ? images[0] : undefined;
                  if (!coverImage || coverImage.externalReference) {
                    e.target.value = coverImage;
                    this.onFieldChange(e);
                  }
                }}/>

              <VideoWithUpload
                id="tranche.promotionalVideos"
                label="Promotional Videos"
                files={tranche.promotionalVideos || []}
                className="videos-across-3"
                accept="video/mp4"
                multiple
                upload
                onChange={(e: any) => {
                  let videos: Array<Binary> = e.target.value;
                  e.target.value = videos;
                  this.onFieldChange(e);
                }}/>

              <ImageWithUpload
                id="tranche.promotionalImages"
                label="Promotional Images"
                files={tranche.promotionalImages || []}
                className="images-across-3"
                accept={acceptedImageMimeTypes}
                lightbox
                multiple
                upload
                onChange={(e: any) => {
                  let images: Array<Binary> = e.target.value;
                  e.target.value = images;
                  this.onFieldChange(e);
                }}/>

              <SectionHeader title="Terms & Product Disclosure Statement" divider/>

              <InputInlineEditor
                tools="traditional"
                id="tranche.terms.body"
                elementId="dialog-terms"
                label="Terms"
                help="A freeform rich content of the terms of this tranche comprising of text, images and file attachments."
                value={tranche.terms?.body}
                error={this.state.errors?.['request.terms.body']}
                uploadFileAcceptedMimeTypes={acceptedFileMimeTypes}
                onChange={this.onFieldChange}/>

              {
                !isSalesChannelPack &&
                <FileWithUpload
                  id="tranche.pdsFiles"
                  label="Product Disclosure Statement (PDS)"
                  files={tranche.pdsFiles || []}
                  multiple
                  upload
                  onChange={(e: any) => {
                    let files: Array<Binary> = e.target.value;
                    e.target.value = files;
                    this.onFieldChange(e);
                  }}/>
              }

              {
                isSalesChannelPack &&
                <InputText
                  id="tranche.pdsFiles"
                  label="Product Disclosure Statement (PDS)"
                  value="N/A - refer to pack"
                  className="lg"
                  disabled/>
              }

            </Panel>

            <Panel>

              <SectionHeader title="Visibilities"/>

              <div className="row">
                <div className="col-md-6">
                  <InputSelect
                    id="tranche.visibilities.attributeLevel"
                    label="Attribute Visibility"
                    labelField="meta.accessDescription"
                    value={VisibilityLevel.getLevel(tranche.visibilities.attributeLevel, index.visibilityLevels)}
                    error={this.state.errors?.['request.visibilities.attributeLevel']}
                    options={horse.visibilityLevelsForTrancheOwners}
                    onChange={(e) => {
                      Model.onFieldChange(this, e.target.id, e.target.value?.meta.value);
                    }}
                    required/>
                </div>
                <div className="col-md-6">
                  <InputSelect
                    id="tranche.visibilities.postLevel"
                    label="Post Visibility"
                    labelField="meta.accessDescription"
                    value={VisibilityLevel.getLevel(tranche.visibilities.postLevel, index.visibilityLevels)}
                    error={this.state.errors?.['request.visibilities.postLevel']}
                    options={horse.visibilityLevelsForTrancheOwners}
                    onChange={(e) => {
                      Model.onFieldChange(this, e.target.id, e.target.value?.meta.value);
                    }}
                    required/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputSelect
                    id="tranche.visibilities.medicalRecordLevel"
                    label="Medical Record Visibility"
                    labelField="meta.accessDescription"
                    value={VisibilityLevel.getLevel(tranche.visibilities.medicalRecordLevel, index.visibilityLevels)}
                    error={this.state.errors?.['request.visibilities.medicalRecordLevel']}
                    options={horse.visibilityLevelsForTrancheOwners}
                    onChange={(e) => {
                      Model.onFieldChange(this, e.target.id, e.target.value?.meta.value);
                    }}
                    required/>
                </div>
                <div className="col-md-6">
                  <InputSelect
                    id="tranche.visibilities.trainingRecordLevel"
                    label="Training Record Visibility"
                    labelField="meta.accessDescription"
                    value={VisibilityLevel.getLevel(tranche.visibilities.trainingRecordLevel, index.visibilityLevels)}
                    error={this.state.errors?.['request.visibilities.trainingRecordLevel']}
                    options={horse.visibilityLevelsForTrancheOwners}
                    onChange={(e) => {
                      Model.onFieldChange(this, e.target.id, e.target.value?.meta.value);
                    }}
                    required/>
                </div>
              </div>

            </Panel>

            <div className="actions">
              {
                this.isModifyAllowed() &&
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  type="submit">Save Changes</Button>
              }
              <Button
                onClick={this.onCancel}
                disabled={false}
                bsSize="large">{this.isModifyAllowed() ? 'Cancel' : 'Close'}</Button>
              {
                tranche.isDeleteAllowed() &&
                <Button
                  onClick={this.onDelete}
                  bsSize="large"
                  bsStyle="default"
                  className="pull-right">Delete</Button>
              }
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
