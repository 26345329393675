import React from 'react';
import {ApiResponse, Button, Form, GlobalMessage, InputText} from 'startupbox-react';
import SecurityDialog from '../components/SecurityDialog';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import Credentials from "../models/Credentials";
import {ApiErrorResponse} from "../models/ApiError";
import AuthenticationResponse from "../models/AuthenticationResponse";
import ApiIndex from "../models/ApiIndex";

export type ResetPasswordDialogProps = DialogProps & {
  token: string,
  onRememberedPassword?: () => void
  onInvalidResetPasswordToken: () => void,
  onResetPassword: (response: ApiResponse<AuthenticationResponse>) => void,
  onCancel?: () => void,
};

export type ResetPasswordDialogState = DialogState & {
  credentials: Credentials,
  emailSent: boolean
};

export default class ResetPasswordDialog extends Dialog<ResetPasswordDialogProps, ResetPasswordDialogState> {

  constructor(props: ResetPasswordDialogProps) {
    super(props);
    let credentials = new Credentials();
    credentials.token = this.props.token;
    this.setInitialState({
      credentials: credentials
    });
  }

  onLoad() {
    this.state.index?.confirmPasswordResetToken(this.props.token, {
      next: null,
      error: () => {
        if (this.props.onInvalidResetPasswordToken) {
          this.props.onInvalidResetPasswordToken();
        }
      }
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let credentials = this.state.credentials;
      this.state.index?.resetPassword(credentials, {
        next: this.onResetPassword,
        error: this.onApiError
      }, 'user');
    }
  }

  onResetPassword(response: ApiResponse<AuthenticationResponse>) {
    this.enable();
    if (this.props.onResetPassword) {
      this.props.onResetPassword(response);
    }
  }

  onCancel() {
    this.enable();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onApiError(response: ApiErrorResponse) {
    this.enable();
    super.onApiError(response);
    this.setState({
      globalMessage: 'There was a problem resetting your password. Try again or contact us.'
    });
  }

  renderWithIndex(index: ApiIndex) {

    return (

      <div>

        <SecurityDialog>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <h1 className="title">Reset Password</h1>
            <p className="lead">Please enter your new credentials below.</p>

            <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

            <InputText
              id="credentials.newPassword"
              label="New Password"
              placeholder=""
              type="password"
              value={this.state.credentials.newPassword}
              error={this.state.errors?.['request.newPassword']}
              onChange={this.onFieldChange}
              required/>

            <InputText
              id="credentials.confirmNewPassword"
              label="Confirm New Password"
              placeholder=""
              type="password"
              value={this.state.credentials.confirmNewPassword}
              error={this.state.errors?.['request.confirmNewPassword']}
              onChange={this.onFieldChange}
              required/>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Reset Password</Button>
            </div>

            <p><a href="/" onClick={this.props.onRememberedPassword}>I have remembered my password</a></p>

          </Form>

        </SecurityDialog>

      </div>

    );

  }

}