import {Model as SystemModel} from 'startupbox-react';
import ApiIndex from './models/ApiIndex';
import AppCredit from "./models/AppCredit";
import Banner from "./models/Banner";
import Binary from './models/Binary';
import Horse from './models/Horse';
import LegalOwner from './models/LegalOwner';
import Setting from './models/Setting';
import Partner from './models/Partner';
import PartnerCollaborator from './models/PartnerCollaborator';
import Tranche from './models/Tranche';
import User from './models/User';
import MedicalRecord from "./models/MedicalRecord";
import TrainingRecord from "./models/TrainingRecord";
import Post from "./models/Post";
import PaymentMethod from "./models/PaymentMethod";
import PaymentMethodCreditCard from "./models/PaymentMethodCreditCard";
import Collectible from "./models/Collectible";
import HorseImportRun from "./models/HorseImportRun";
import HorseImportRunHorse from "./models/HorseImportRunHorse";
import Membership from "./models/Membership";
import UserImportRun from "./models/UserImportRun";
import UserImportRunUser from "./models/UserImportRunUser";
import LegalDocument from "./models/LegalDocument";
import Payout from "./models/Payout";
import Entitlement from "./models/Entitlement";
import Wallet from "./models/Wallet";
import Pack from "./models/Pack";
import Device from "./models/Device";
import DeviceEvent from "./models/DeviceEvent";
import Statistic from "./models/Statistic";
import Transaction from "./models/Transaction";
import HubSpotAsyncMessage from "./models/HubSpotAsyncMessage";
import Disbursement from "./models/Disbursement";

export default class Model extends SystemModel {

  static create(type: string) {
    let fn = Model.map[type];
    if (fn) {
      return fn();
    }
    return null;
  }

  static augment(model: any) {
    model.href = Model.hrefFunction();
    return model;
  }

  // registration ...................................................................................................................................

  static map: { [key: string]: any } = {
    'Index': Model.blankIndex,
    'AppCredit': Model.blankAppCredit,
    'Banner': Model.blankBanner,
    'Binary': Model.blankBinary,
    'Collectible': Model.blankCollectible,
    'Device': Model.blankDevice,
    'DeviceEvent': Model.blankDeviceEvent,
    'Disbursement': Model.blankDisbursement,
    'Entitlement': Model.blankEntitlement,
    'Horse': Model.blankHorse,
    'HorseImportRun': Model.blankHorseImportRun,
    'HorseImportRunHorse': Model.blankHorseImportRunHorse,
    'HubSpotAsyncMessage': Model.blankHubSpotAsyncMessage,
    'LegalDocument': Model.blankLegalDocument,
    'LegalOwner': Model.blankLegalOwner,
    'MedicalRecord': Model.blankMedicalRecord,
    'Membership': Model.blankMembership,
    'Pack': Model.blankPack,
    'Partner': Model.blankPartner,
    'PartnerCollaborator': Model.blankPartnerCollaborator,
    'PaymentMethod': Model.blankPaymentMethod,
    'PaymentMethodCreditCard': Model.blankPaymentMethodCreditCard,
    'Payout': Model.blankPayout,
    'Post': Model.blankPost,
    'Setting': Model.blankSetting,
    'Statistic': Model.blankStatistic,
    'TrainingRecord': Model.blankTrainingRecord,
    'Tranche': Model.blankTranche,
    'Transaction': Model.blankTransaction,
    'User': Model.blankUser,
    'UserImportRun': Model.blankUserImportRun,
    'UserImportRunUser': Model.blankUserImportRunUser,
    'Wallet': Model.blankWallet,
  };

  // builders .......................................................................................................................................

  static blankIndex() {
    return Model.augment(new ApiIndex());
  }

  static blankAppCredit() {
    return Model.augment(new AppCredit());
  }

  static blankBanner() {
    return Model.augment(new Banner());
  }

  static blankBinary() {
    return Model.augment(new Binary());
  }

  static blankCollectible() {
    return Model.augment(new Collectible());
  }

  static blankDevice() {
    return Model.augment(new Device());
  }

  static blankDeviceEvent() {
    return Model.augment(new DeviceEvent());
  }

  static blankDisbursement() {
    return Model.augment(new Disbursement());
  }

  static blankEntitlement() {
    return Model.augment(new Entitlement());
  }

  static blankHorse() {
    return Model.augment(new Horse());
  }

  static blankHorseImportRun() {
    return Model.augment(new HorseImportRun());
  }

  static blankHorseImportRunHorse() {
    return Model.augment(new HorseImportRunHorse());
  }

  static blankHubSpotAsyncMessage() {
    return Model.augment(new HubSpotAsyncMessage());
  }

  static blankLegalDocument() {
    return Model.augment(new LegalDocument());
  }

  static blankLegalOwner() {
    return Model.augment(new LegalOwner());
  }

  static blankMedicalRecord() {
    return Model.augment(new MedicalRecord());
  }

  static blankMembership() {
    return Model.augment(new Membership());
  }

  static blankPack() {
    return Model.augment(new Pack());
  }

  static blankPaymentMethod() {
    return Model.augment(new PaymentMethod());
  }

  static blankPaymentMethodCreditCard() {
    return Model.augment(new PaymentMethodCreditCard());
  }

  static blankPost() {
    return Model.augment(new Post());
  }

  static blankPayout() {
    return Model.augment(new Payout());
  }

  static blankSetting() {
    return Model.augment(new Setting());
  }

  static blankStatistic() {
    return Model.augment(new Statistic());
  }

  static blankPartner() {
    return Model.augment(new Partner());
  }

  static blankPartnerCollaborator() {
    return Model.augment(new PartnerCollaborator());
  }

  static blankTrainingRecord() {
    return Model.augment(new TrainingRecord());
  }

  static blankTranche() {
    return Model.augment(new Tranche());
  }

  static blankTransaction() {
    return Model.augment(new Transaction());
  }

  static blankUser() {
    return Model.augment(new User());
  }

  static blankUserImportRun() {
    return Model.augment(new UserImportRun());
  }

  static blankUserImportRunUser() {
    return Model.augment(new UserImportRunUser());
  }

  static blankWallet() {
    return Model.augment(new Wallet());
  }

}
