import React from 'react';
import PageHeader from '../components/PageHeader';
import Panel from '../components/Panel';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import {ApiResponse, Button, Form, GlobalMessage, InputText} from 'startupbox-react';
import Setting from "../models/Setting";
import ApiIndex from "../models/ApiIndex";
import {ApiErrorResponse} from "../models/ApiError";
import Routes from '../Routes';

export type SettingPageProps = PageProps & {};

export type SettingPageState = PageState & {
  setting: Setting;
};

// noinspection UnnecessaryLocalVariableJS
export default class SettingPage extends Page<SettingPageProps, SettingPageState> {

  constructor(props: SettingPageProps) {
    super(props);
    this.setInitialState({
      setting: new Setting()
    });
  }

  onLoad() {
    let key = this.props.params.key;
    if (!this.isAdding()) {
      this.state.index?.findSettingByKey(key, {
        next: this.setSetting,
        error: (response: ApiErrorResponse) => {
          this.onApiError(response);
          this.showNotFound(response.status === 404);
        }
      }, null);
    }
  }

  onSave() {
    this.navigate(Routes.tool.settings());
  }

  onCancelEdit() {
    this.navigate(Routes.tool.settings());
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let setting = this.state.setting;
      if (!setting.key) {
        this.state.index?.createSetting(setting, {
          next: this.onSave,
          error: this.onApiError
        }, null);
      }
      else {
        setting.save({
          next: this.onSave,
          error: this.onApiError
        }, null);
      }
    }
  }

  setSetting(response: ApiResponse<Setting>) {
    let setting = response.resource!;
    this.publish(Events.system.pageTitle, setting.name);
    this.setState({
      mode: '',
      setting: setting
    });
  }

  isAdding() {
    let key = this.props.params.key;
    return !key || key === 'add';
  }

  renderWithIndex(index: ApiIndex) {

    let setting = this.state.setting;

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title={this.isAdding() ? 'Add Setting' : 'Edit Setting'}
            lead="Please enter the details of this setting below."/>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <InputText
                id="setting.name"
                label="Name"
                help="The name of the setting."
                placeholder="Name"
                value={setting.name}
                error={this.state.errors?.['name']}
                onChange={this.onFieldChange}
                className="lg"
                required/>

              <InputText
                id="setting.value"
                label="Value"
                help="The value of the setting."
                placeholder="Value"
                value={setting.value}
                error={this.state.errors?.['value']}
                onChange={this.onFieldChange}/>

            </Panel>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Save Changes</Button>
              <Button
                onClick={this.onCancelEdit}
                bsSize="large">Cancel</Button>
            </div>

          </Form>

        </div>

      </div>

    );

  }

};