import React from 'react';
import FontAwesome from "react-fontawesome";
import {navigate} from "startupbox-react";

type NavigationPanelProps = {
  title?: string;
  path?: string;
  onClick?: () => void;
};

const NavigationPanel = (props: NavigationPanelProps) => {

  let onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
    else if (props.path) {
      navigate(props.path);
    }
  };

  return (
    <div className="form-section clickable" onClick={onClick}>
      <h5>{props.title} <FontAwesome name="chevron-right" className="pull-right"/></h5>
    </div>
  );

};

export default NavigationPanel;