import React from 'react';
import {ApiSubscribableCallbacks, Button, Form, InputMonthYearPicker, InputSelect, Str} from 'startupbox-react';
import ApiIndex from "../models/ApiIndex";
import UserStatisticSearchRequest from "../models/UserStatisticSearchRequest";
import StatisticLineChart from "../components/StatisticLineChart";
import Statistic from "../models/Statistic";
import ApiError from "../models/ApiError";
import User from "../models/User";
import StatisticBarChart from "../components/StatisticBarChart";
import AdminDashboardAbstractPage, {AdminDashboardAbstractPageProps, AdminDashboardAbstractPageState} from "./AdminDashboardAbstractPage";

export type AdminDashboardUsersPageProps = AdminDashboardAbstractPageProps & {};

export type AdminDashboardUsersPageState = AdminDashboardAbstractPageState & {
  searchRequest: UserStatisticSearchRequest;
};

export default class AdminDashboardUsersPage extends AdminDashboardAbstractPage<AdminDashboardUsersPageProps, AdminDashboardUsersPageState> {

  constructor(props: AdminDashboardUsersPageProps) {
    super(props);
    this.activeTab = 'users';
    this.subTitle = 'Users';
    this.description = 'This area allows you monitor user related statistics useful to the business. Filter the matching data by using the fields below.';
    setTimeout(() => {
      this.setState({
        searchRequest: new UserStatisticSearchRequest(),
      })
    }, 0);
  }

  onSearch() {
    this.setState({
      timestamp: Date.now()
    });
  }

  renderWithIndexAndUser(index: ApiIndex, me: User, timestamp: number) {

    let searchRequest = this.state.searchRequest;

    if (!me) {
      return <></>;
    }

    return (

      <>

        <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
          <InputMonthYearPicker
            id="searchRequest.fromYYYYMM"
            placeholder="Date From"
            years={[2022]}
            value={searchRequest.fromYYYYMM}
            onChange={this.onFieldChange}
            getFormattedValueFn={value => Str.formatStartOfMonth(value, 'DD/MM/YYYY')}
            clearable/>
          {/*
              <InputMonthYearPicker
                id="searchRequest.toYYYYMM"
                placeholder="Date To"
                years={[2022]}
                value={searchRequest.toYYYYMM}
                onChange={this.onFieldChange}
                getFormattedValueFn={value => Str.formatEndOfMonth(value, 'DD/MM/YYYY')}
                clearable/>
          */}
          <InputSelect
            id="searchRequest.status"
            blank="Status"
            value={searchRequest.status}
            onChange={this.onFieldChange}
            options={index.userStatuses}/>
          <span className="actions">
            <Button
              icon="search"
              bsStyle="primary"
              type="submit">Search</Button>
          </span>
        </Form>

        <StatisticLineChart
          chartContainerId="creation-day-chart-container"
          title="Users Created By Day"
          what="user"
          timestamp={timestamp}
          refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
            searchRequest.statisticType = 'CREATION_DAY';
            me?.findUserStatistic(searchRequest, callbacks);
          }}/>

        <StatisticLineChart
          chartContainerId="creation-month-chart-container"
          title="Users Created By Month"
          what="user"
          timestamp={timestamp}
          refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
            searchRequest.statisticType = 'CREATION_MONTH';
            me?.findUserStatistic(searchRequest, callbacks);
          }}/>

        <div className="row">
          <div className="col-md-6">
            <StatisticBarChart
              chartContainerId="compliance-chart-container"
              title="Users By Compliance"
              what="user"
              timestamp={timestamp}
              refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
                searchRequest.statisticType = 'COMPLIANCE';
                me?.findUserStatistic(searchRequest, callbacks);
              }}/>
          </div>
          <div className="col-md-6">
            <StatisticBarChart
              chartContainerId="identity-verification-chart-container"
              title="Users By Identity Verification"
              what="user"
              timestamp={timestamp}
              refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
                searchRequest.statisticType = 'IDENTITY_VERIFICATION';
                me?.findUserStatistic(searchRequest, callbacks);
              }}/>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <StatisticBarChart
              chartContainerId="age-verification-chart-container"
              title="Users By Age Verification"
              what="user"
              timestamp={timestamp}
              refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
                searchRequest.statisticType = 'AGE_VERIFICATION';
                me?.findUserStatistic(searchRequest, callbacks);
              }}/>
          </div>
          <div className="col-md-6">
            <StatisticBarChart
              chartContainerId="bank-details-given-chart-container"
              title="Users By Bank Details Given"
              what="user"
              timestamp={timestamp}
              refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
                searchRequest.statisticType = 'BANK_DETAILS_GIVEN';
                me?.findUserStatistic(searchRequest, callbacks);
              }}/>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <StatisticBarChart
              chartContainerId="residency-declaration-chart-container"
              title="Users By Residency Declaration"
              what="user"
              timestamp={timestamp}
              refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
                searchRequest.statisticType = 'RESIDENCY_DECLARATION';
                me?.findUserStatistic(searchRequest, callbacks);
              }}/>
          </div>
          <div className="col-md-6">
            <StatisticBarChart
              chartContainerId="criminal-declaration-chart-container"
              title="Users By Criminal Declaration"
              what="user"
              timestamp={timestamp}
              refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
                searchRequest.statisticType = 'CRIMINAL_DECLARATION';
                me?.findUserStatistic(searchRequest, callbacks);
              }}/>
          </div>
        </div>

      </>

    );

  }

}
