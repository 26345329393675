import Model from "../Model";
import PayoutStatus from "./PayoutStatus";
import {ApiSubscribableCallbacks, SearchResult} from "startupbox-react";
import ApiError from "./ApiError";
import EntitlementSearchRequest from "./EntitlementSearchRequest";
import Entitlement from "./Entitlement";

let Rel = {
  self: 'self',
  entitlement_search: 'entitlement_search',
};

export const PAYOUT_STANDARD_FIELDS = '';

export default class Payout {

  key?: string;

  description?: string;
  date?: Date;
  paymentDate?: Date;
  totalAmount?: number;
  remainingAmount?: number;
  status?: PayoutStatus;

  href = Model.hrefFunction();

  // ................................................................................................................................................


  // entitlement ....................................................................................................................................

  searchEntitlements(request: EntitlementSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Entitlement>, ApiError>, fields: string | null) {
    this.href(Rel.entitlement_search, true)!
      .param('type', request.type)
      .param('status', request.status)
      .param('disbursementAllowed', request.disbursementAllowed != null ? request.disbursementAllowed + '' : undefined)
      .param('sort', request.sort || 'id,desc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

}
