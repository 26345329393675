// noinspection DuplicatedCode

import React from 'react';
import {Environment} from 'startupbox-react';
import './Image.css';

const resolveHref = (props: ImageProps) => {
  let src = props.src;
  if (src) {
    if (typeof src === 'object') {
      src = src.externalReference || src.url;
    }
    if (src) {
      if (src.indexOf('.') === -1 && src.indexOf('/') === -1) {
        src = Environment.imageHref(src, getImageTransforms(props));
      }
    }
  }
  else {
    src = resolveDefaultHref(props);
  }
  return src;
}

const resolveDefaultHref = (props: ImageProps) => {
  let defaultSrc = props.defaultSrc;
  if (defaultSrc) {
    if (typeof defaultSrc === 'object') {
      defaultSrc = defaultSrc.externalReference || defaultSrc.url;
    }
    if (defaultSrc) {
      if (defaultSrc.indexOf('.') === -1 && defaultSrc.indexOf('/') === -1) {
        defaultSrc = Environment.imageHref(defaultSrc, getImageTransforms(props));
      }
    }
  }
  return defaultSrc;
}


const getImageTransforms = (props: ImageProps) => {
  let transforms = props.transforms;
  if (!transforms) {
    transforms = '-';
  }
  return transforms;
}

const getClassName = (props: ImageProps, prefixClassName?: string | null) => {
  return ((prefixClassName ? prefixClassName + ' ' : ' ') + (props.className || '')).trim();
}

const render = (props: ImageProps, defaultClassName?: string | null) => {

  let src = resolveHref(props);
  let className = getClassName(props, defaultClassName);
  let alt = props.alt;

  if (src) {
    return (
      <img
        src={src}
        className={className}
        alt={alt}/>
    );
  }
  else {
    return (
      <div className={(className ? className + ' ' : '') + 'blank'}/>
    );
  }

};

type ImageProps = {
  src: string | any | null | undefined;
  defaultSrc?: string | any | null | undefined;
  transforms?: string | null | undefined;
  className?: string;
  alt?: string | undefined;
};

// ................................................................................................................................................

const Image = (props: ImageProps) => {
  return render(props, 'image');
};

export {Image};

// ................................................................................................................................................

const Avatar = (props: ImageProps) => {
  return render(props, 'avatar');
};

export {Avatar};