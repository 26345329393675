import Model from '../Model';
import {ApiSubscribableCallbacks, SearchResult} from "startupbox-react";
import PartnerCollaborator from "./PartnerCollaborator";
import PartnerCollaboratorSearchRequest from "./PartnerCollaboratorSearchRequest";
import HorseSearchRequest from "./HorseSearchRequest";
import PartnerStatus from "./PartnerStatus";
import Content from "./Content";
import Address from "./Address";
import ApiError from "./ApiError";
import Horse from "./Horse";
import Binary from "./Binary";
import HorseImportRunSearchRequest from "./HorseImportRunSearchRequest";
import HorseImportRun from "./HorseImportRun";
import Pack from "./Pack";
import PackSearchRequest from "./PackSearchRequest";
import PartnerType, {PartnerTypeKey} from "./PartnerType";
import Post from "./Post";
import PostSearchRequest from "./PostSearchRequest";
import VisibilityLevel from "./VisibilityLevel";
import PremiumContentConfiguration from "./PremiumContentConfiguration";
import PremiumContentTier from "./PremiumContentTier";
import Collectible from "./Collectible";
import CollectibleSearchRequest from "./CollectibleSearchRequest";
import MembershipSearchRequest from "./MembershipSearchRequest";
import Membership from "./Membership";

let Rel = {
  self: 'self',
  modify: 'modify',
  modifyStatus: 'modifyStatus',
  collaborator_add: 'collaborator_add',
  collaborator_search: 'collaborator_search',
  collectible_add: 'collectible_add',
  collectible_find: 'collectible_find',
  collectible_search: 'collectible_search',
  horse_add: 'horse_add',
  horse_find: 'horse_find',
  horse_search: 'horse_search',
  horseImportRun_add: 'horseImportRun_add',
  horseImportRun_search: 'horseImportRun_search',
  horseImportRun_upload: 'horseImportRun_upload',
  pack_add: 'pack_add',
  pack_find: 'pack_find',
  pack_search: 'pack_search',
  post_add: 'post_add',
  post_search: 'post_search',
  membership_find: 'membership_find',
  membership_add: 'membership_add',
  membership_search: 'membership_search',
  sendReviewRequestNotification: 'sendReviewRequestNotification',
};

export const PARTNER_STANDARD_FIELDS =
  'address,profile,profile.body,thumbnailImage,' +
  'applePremiumContentConfiguration,googlePremiumContentConfiguration,' +
  'applePremiumContentDefaultTierForSinglePost,googlePremiumContentDefaultTierForSinglePost,' +
  'applePremiumContentDefaultTierForHorse,googlePremiumContentDefaultTierForHorse';

export default class Partner {

  key?: string;

  name?: string;
  tagline?: string;
  summaryDescription?: string;
  profile: Content = new Content();
  emailAddress?: string;
  phoneNumber?: string;
  address: Address = new Address();
  type?: PartnerType;
  status?: PartnerStatus;

  thumbnailImage?: Binary;

  applePremiumContentConfiguration?: PremiumContentConfiguration;
  googlePremiumContentConfiguration?: PremiumContentConfiguration;

  applePremiumContentDefaultProductIdForSinglePost?: string;
  applePremiumContentDefaultTierForSinglePost?: PremiumContentTier;

  googlePremiumContentDefaultProductIdForSinglePost?: string;
  googlePremiumContentDefaultTierForSinglePost?: PremiumContentTier;

  applePremiumContentDefaultTierForHorse?: PremiumContentTier;
  googlePremiumContentDefaultTierForHorse?: PremiumContentTier;

  visibilityLevelsForPosts: Array<VisibilityLevel> = [];

  href = Model.hrefFunction();

  // ................................................................................................................................................

  constructor(typeKey?: PartnerTypeKey) {
    this.type = typeKey ? {key: typeKey} : undefined;
  }

  // ................................................................................................................................................

  reload(callbacks: ApiSubscribableCallbacks<Partner, ApiError>, fields: string | null) {
    this.href(Rel.self, true)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  isModifyAllowed() {
    return !!this.href(Rel.modify);
  }

  save(callbacks: ApiSubscribableCallbacks<Partner, ApiError>, fields: string | null) {
    this.href(Rel.modify, true)
      .field(fields)
      .put(this)
      .subscribe(callbacks);
  }

  isModifyStatusAllowed() {
    return !!this.href(Rel.modifyStatus);
  }

  modifyStatus(status: PartnerStatus | null, callbacks: ApiSubscribableCallbacks<Partner, ApiError>, fields: string | null) {
    this.href(Rel.modifyStatus, true)
      .field(fields)
      .patch({status: status})
      .subscribe(callbacks);
  }

  // collaborator ...................................................................................................................................

  isAddCollaboratorAllowed() {
    return !!this.href(Rel.collaborator_add);
  }

  addCollaborator(collaborator: PartnerCollaborator, callbacks: ApiSubscribableCallbacks<PartnerCollaborator, ApiError>, fields: string | null) {
    this.href(Rel.collaborator_add, true)
      .field(fields)
      .post(collaborator)
      .subscribe(callbacks);
  }

  searchCollaborators(params: PartnerCollaboratorSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<PartnerCollaborator>, ApiError>, fields: string | null) {
    this.href(Rel.collaborator_search, true)
      .param('name', params.name)
      .param('type', params.type)
      .param('status', params.status)
      .param('sort', params.sort || 'user.name,asc')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // collectible ....................................................................................................................................

  isAddCollectibleAllowed() {
    return !!this.href(Rel.collectible_add);
  }

  addCollectible(collectible: Collectible, callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.collectible_add, true)
      .field(fields)
      .post(collectible)
      .subscribe(callbacks);
  }
  findMembershipByKey(key: string, callbacks: ApiSubscribableCallbacks<Membership, ApiError>, fields: string | null) {
    this.href(Rel.membership_find, true)
        .replace('aggregateKey', key)
        .field(fields)
        .get()
        .subscribe(callbacks);
  }

  findCollectibleByKey(key: string, callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.collectible_find, true)
      .replace('aggregateKey', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  searchCollectibles(request: CollectibleSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Collectible>, ApiError>, fields: string | null) {
    this.href(Rel.collectible_search, true)!
      .param('keywords', request.keywords)
      .param('key', request.key)
      .param('name', request.name)
      .param('sort', request.sort || 'name,asc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // horse ..........................................................................................................................................

  isAddHorseAllowed() {
    return !!this.href(Rel.horse_add);
  }

  addHorse(horse: Horse, callbacks: ApiSubscribableCallbacks<Horse, ApiError>, fields: string | null) {
    this.href(Rel.horse_add, true)
      .field(fields)
      .post(horse)
      .subscribe(callbacks);
  }

  findHorseByKey(key: string, callbacks: ApiSubscribableCallbacks<Horse, ApiError>, fields: string | null) {
    this.href(Rel.horse_find, true)
      .replace('aggregateKey', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  searchHorses(params: HorseSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Horse>, ApiError>, fields: string | null) {
    this.href(Rel.horse_search, true)
      .param('keywords', params.keywords)
      .param('key', params.key)
      .param('name', params.name)
      .param('microchipNumber', params.microchipNumber)
      .param('racingStatus', params.racingStatus)
      .param('status', params.status)
      .param('sort', params.sort || 'name')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // horse import run ...............................................................................................................................

  isAddHorseImportRunAllowed() {
    return !!this.href(Rel.horseImportRun_add);
  }

  addHorseImportRun(horse: HorseImportRun, callbacks: ApiSubscribableCallbacks<HorseImportRun, ApiError>, fields: string | null) {
    this.href(Rel.horseImportRun_add, true)
      .field(fields)
      .post(horse)
      .subscribe(callbacks);
  }

  searchHorseImportRuns(params: HorseImportRunSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<HorseImportRun>, ApiError>, fields: string | null) {
    this.href(Rel.horseImportRun_search, true)
      .param('key', params.key)
      .param('status', params.status)
      .param('sort', params.sort || 'createdAt,desc')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  isUploadHorseImportRunAllowed() {
    return !!this.href(Rel.horseImportRun_upload);
  }

  uploadHorseImportRun(file: any, callbacks: ApiSubscribableCallbacks<HorseImportRun, ApiError>, fields: string | null) {
    let href = this.href(Rel.horseImportRun_upload, true);
    href
      .field(fields)
      .uploadLocalFile(href.toString(), file)
      .subscribe(callbacks);
  }

  // pack ..........................................................................................................................................

  isAddPackAllowed() {
    return !!this.href(Rel.horse_add);
  }

  addPack(pack: Pack, callbacks: ApiSubscribableCallbacks<Pack, ApiError>, fields: string | null) {
    this.href(Rel.pack_add, true)
      .field(fields)
      .post(pack)
      .subscribe(callbacks);
  }

  findPackByKey(key: string, callbacks: ApiSubscribableCallbacks<Pack, ApiError>, fields: string | null) {
    this.href(Rel.pack_find, true)
      .replace('aggregateKey', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  searchPacks(params: PackSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Pack>, ApiError>, fields: string | null) {
    this.href(Rel.pack_search, true)
      .param('keywords', params.keywords)
      .param('key', params.key)
      .param('name', params.name)
      .param('status', params.status)
      .param('sort', params.sort || 'name')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // post ...........................................................................................................................................

  isAddPostAllowed() {
    return !!this.href(Rel.post_add);
  }

  addPost(post: Post, callbacks: ApiSubscribableCallbacks<Post, ApiError>, fields: string | null) {
    this.href(Rel.post_add, true)
      .field(fields)
      .post(post)
      .subscribe(callbacks);
  }

  searchPosts(request: PostSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Post>, ApiError>, fields: string | null) {
    this.href(Rel.post_search, true)!
      .param('keywords', request.keywords)
      .param('key', request.key)
      .param('sort', request.sort || 'date,desc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  addMembership(membership: Membership, callbacks: ApiSubscribableCallbacks<Membership, ApiError>, fields: string | null) {
    this.href(Rel.membership_add, true)
        .field(fields)
        .post(membership)
        .subscribe(callbacks);
  }

  searchMemberships(request: MembershipSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Membership>, ApiError>, fields: string | null) {
    this.href(Rel.membership_search, true)!
        .param('keywords', request.keywords)
        .param('key', request.key)
        .param('sort', request.sort || 'startAt,desc')
        .param('page', request.page)
        .param('size', request.size)
        .field(fields)
        .get()
        .subscribe(callbacks);
  }
}
