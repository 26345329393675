import React from 'react';
import {Button, DialogPanel, FullScreenModal, GlobalMessage} from 'startupbox-react';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Routes from '../Routes';
import AccountNav from './AccountNav';

export type AccountLogoutPageProps = PageProps & {};

export type AccountLogoutPageState = PageState & {};

export default class AccountLogoutPage extends Page<AccountLogoutPageProps, AccountLogoutPageState> {

  constructor(props: AccountLogoutPageProps) {
    super(props);
    this.setInitialState({
      mode: 'edit'
    });
  }

  load() {
    this.publish(Events.system.pageTitle, 'Logout');
  }

  onLogout(e: any) {
    this.preventDefault(e);
    this.state.index?.logout({
      next: () => {
        this.publish(Events.system.logout, true);
      },
      error: this.onApiError
    });
  }

  onCancel() {
    this.navigate(Routes.main.home());
  }

  render() {

    return (

      <div>

        <FullScreenModal title="Confirm Logout" className="centered-title" onHide={this.onCancel}>

          <DialogPanel>

            <AccountNav active="Logout"/>

            <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

            <br/>

            <p className="lead">You are about to logout.</p>

            <p>You are about to logout from BTX. Please confirm by tapping the button below.</p>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                onClick={this.onLogout}>Logout</Button>
            </div>

          </DialogPanel>

        </FullScreenModal>

      </div>

    );

  }

};