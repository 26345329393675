import React from 'react';
import User from "../models/User";
import ApiIndex from "../models/ApiIndex";
import Wallet from "../models/Wallet";
import UserWalletAbstractPage, {UserWalletAbstractPageProps, UserWalletAbstractPageState} from "./UserWalletAbstractPage";
import SectionHeader from "../components/SectionHeader";
import {ApiSearchResponse, Button, Confirmable, SearchResult, Str, Table, TableHeaderCell} from "startupbox-react";
import AppCredit from "../models/AppCredit";
import {COLLECTIBLE_STANDARD_FIELDS} from "../models/Collectible";
import AppCreditSearchRequest from "../models/AppCreditSearchRequest";
import WalletAppCreditDialog from "../wallet/WalletAppCreditDialog";
import Panel from "../components/Panel";

export type UserWalletAppCreditsPageProps = UserWalletAbstractPageProps & {};

export type UserWalletAppCreditsPageState = UserWalletAbstractPageState & {
  appCredits: SearchResult<AppCredit> | null;
};

export default class UserWalletAppCreditsPage extends UserWalletAbstractPage<UserWalletAppCreditsPageProps, UserWalletAppCreditsPageState> {

  constructor(props: UserWalletAppCreditsPageProps) {
    super(props);
    this.activeTab = 'app-credits';
  }

  onLoadUserAndWallet(user: User, wallet: Wallet) {
    this.searchAppCredits(1);
  }

  searchAppCredits(page: number) {
    let appCreditSearchRequest = new AppCreditSearchRequest();
    appCreditSearchRequest.page = page - 1;
    this.state.wallet?.searchAppCredits(appCreditSearchRequest, {
      next: (response: ApiSearchResponse<AppCredit>) => {
        let appCredits = response.resource;
        this.setAppCredits(appCredits);
      },
      error: this.onApiError
    }, COLLECTIBLE_STANDARD_FIELDS);
  }

  setAppCredits(appCredits: SearchResult<AppCredit>) {
    this.setState({
      appCredits: appCredits
    })
  }

  getWalletFields(): string {
    return 'appCreditBalanceAmount';
  }

  renderWithIndexUserAndWallet(index: ApiIndex, user: User, wallet: Wallet) {

    let appCredits = this.state.appCredits;

    return <>

      <SectionHeader
        title="App Credits"
        actions={
          <>
            <Confirmable
              renderConfirm={(onOk, onCancel) => {
                let appCredit: AppCredit = new AppCredit();
                return (
                  <WalletAppCreditDialog
                    wallet={wallet}
                    appCredit={appCredit}
                    onSave={(response) => {
                      onOk();
                      this.reload();
                    }}
                    onCancel={onCancel}/>
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <Button
                    icon="plus"
                    bsStyle="primary"
                    bsSize="xs"
                    className="pull-right"
                    onClick={onShow}
                    disabled={!wallet.isAddAppCreditAllowed()}>Add App Credit</Button>
                );
              }}/>
          </>
        }/>

      <div className="row">
        <div className="col-md-6 col-lg-3 text-center">
          <Panel unpadded>
            <p className="text-muted marginBottom0">Gift Card</p>
            <h4 className="marginTop5">${Str.formatNumber(wallet.appCreditGiftCardBalanceAmount, 2, '.', ',')}</h4>
          </Panel>
        </div>
        <div className="col-md-6 col-lg-3 text-center">
          <Panel unpadded>
            <p className="text-muted marginBottom0">Payout</p>
            <h4 className="marginTop5">${Str.formatNumber(wallet.appCreditPayoutBalanceAmount, 2, '.', ',')}</h4>
          </Panel>
        </div>
        <div className="col-md-6 col-lg-3 text-center">
          <Panel unpadded>
            <p className="text-muted marginBottom0">Promotion</p>
            <h4 className="marginTop5">${Str.formatNumber(wallet.appCreditPromotionBalanceAmount, 2, '.', ',')}</h4>
          </Panel>
        </div>
        <div className="col-md-6 col-lg-3 text-center">
          <Panel unpadded>
            <p className="text-muted marginBottom0">Total</p>
            <h4 className="marginTop5">${Str.formatNumber(wallet.appCreditBalanceAmount, 2, '.', ',')}</h4>
          </Panel>
        </div>
      </div>


      <Table
        items={appCredits}
        onPaginate={this.searchAppCredits}
        blankMessage="No app credits found"
        renderHeaderRow={() => {
          return (
            <tr>
              <TableHeaderCell label="Date" className="col-md-2"/>
              <TableHeaderCell label="Description"/>
              <TableHeaderCell label="Type" className="col-md-2"/>
              <TableHeaderCell label="Amount" className="text-right"/>
            </tr>
          );
        }}
        renderItemRow={(appCredit: AppCredit, a: number) => {
          return (
            <tr key={a} className="clickable" data-key={appCredit.key}>
              <td>{Str.formatDate(appCredit.date, "DD/MM/YYYY")}</td>
              <td>{appCredit.description}</td>
              <td>{appCredit.type?.name}</td>
              <td className="text-right">{Str.formatNumber(appCredit.amount, 2, '.', ',')}</td>
            </tr>
          );
        }}
      />

    </>;

  }

};
