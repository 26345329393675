import {Confirmable, SearchResult, StatusButton, Str, Table, TableHeaderCell} from "startupbox-react";
import React from "react";
import Post from "../models/Post";
import PostDialog from "./PostDialog";
import FontAwesome from "react-fontawesome";
import ApiIndex from "../models/ApiIndex";
import Horse from "../models/Horse";
import Partner from "../models/Partner";
import VisibilityLevel from "../models/VisibilityLevel";

type PostTableProps = {
  index: ApiIndex;
  horse?: Horse;
  partner?: Partner;
  posts: SearchResult<Post> | null | undefined;
  visibilityLevels: VisibilityLevel[];
  visibilityLevelLabelField?: string;
  showVisibilityLevel?: boolean;
  onReload: () => void;
};

const PostTable = (props: PostTableProps) => {

  let index = props.index;
  let horse = props.horse;
  let partner = props.partner;
  let posts = props.posts;
  let visibilityLevels = props.visibilityLevels;
  let visibilityLevelLabelField = props.visibilityLevelLabelField;
  let showVisibilityLevel = props.showVisibilityLevel;
  let onReload = props.onReload;

  if (!posts) {
    return <></>;
  }
  return (

    <Table
      items={posts}
      onReload={onReload}
      blankMessage="No posts found"
      renderHeaderRow={() => {
        return (
          <tr>
            <TableHeaderCell label="Date" className="col-md-2"/>
            <TableHeaderCell label="Title"/>
            {
              showVisibilityLevel &&
              <TableHeaderCell label="Visibility Level" style={{width: '130px'}}/>
            }
            <TableHeaderCell label="Status" className="col-md-2 text-right"/>
            <TableHeaderCell label="&nbsp;" className="col-chevron"/>
          </tr>
        );
      }}
      renderItemRow={(post: Post, p: number) => {
        return (
          <Confirmable
            key={p}
            renderConfirm={(onOk) => {
              return (
                <PostDialog
                  index={index}
                  horse={horse}
                  partner={partner}
                  post={post}
                  visibilityLevels={visibilityLevels}
                  visibilityLevelLabelField={visibilityLevelLabelField}
                  onClose={() => {
                    onReload();
                    onOk();
                  }}
                />
              );
            }}
            renderTrigger={(onShow) => {
              return (
                <tr className="clickable" onClick={onShow}>
                  <td>{Str.formatDate(post.date, "DD/MM/YYYY")}</td>
                  <td>{post.title}</td>
                  {
                    showVisibilityLevel &&
                    <td className="text-muted">{VisibilityLevel.getLabel(post.visibilityLevel, visibilityLevelLabelField)}</td>
                  }
                  <td className="text-right">
                    <StatusButton
                      id={'status-' + p}
                      value={post.status}/>
                  </td>
                  <td className="text-right">
                    <FontAwesome name="chevron-right"/>
                  </td>
                </tr>
              );
            }}/>
        );
      }}
    />

  );

};

export default PostTable;