import React from 'react';
import PartnerHorseAbstractPage, {PartnerHorseAbstractPageProps, PartnerHorseAbstractPageState} from './PartnerHorseAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Horse from "../models/Horse";
import Partner from "../models/Partner";
import SectionHeader from "../components/SectionHeader";
import {ApiResponse, ApiSearchResponse, Button, Confirmable, SearchResult} from "startupbox-react";
import Routes from "../Routes";
import BlockchainAttributeGroup from "../models/BlockchainAttributeGroup";
import Membership, {MEMBERSHIP_STANDARD_FIELDS} from "../models/Membership";
import MembershipSearchRequest from "../models/MembershipSearchRequest";
import MembershipDialog from "../membership/MembershipDialog";
import MembershipTable from "../membership/MembershipTable";

let FIELDS = MEMBERSHIP_STANDARD_FIELDS;

export type PartnerHorseMembershipsPageProps = PartnerHorseAbstractPageProps & {};

export type PartnerHorseMembershipsPageState = PartnerHorseAbstractPageState & {
    membershipSearchRequest: MembershipSearchRequest;
    memberships: SearchResult<Membership> | null;
}

export default class PartnerHorseMembershipsPage extends PartnerHorseAbstractPage<PartnerHorseMembershipsPageProps, PartnerHorseMembershipsPageState> {

    constructor(props: PartnerHorseMembershipsPageProps) {
        super(props);
        this.setInitialState({
            membershipSearchRequest: new MembershipSearchRequest(),
            memberships: null,
        });
        this.activeTab = 'memberships';
    }

    onLoadHorse(horse: Horse | undefined) {
        this.searchMemberships(1);
    }

    onSave(response: ApiResponse<Membership>) {
        this.onView(response.resource!);
    }

    searchMemberships(page: number) {
        let membershipSearchRequest = new MembershipSearchRequest();
        membershipSearchRequest.page = page - 1;
        this.state.horse?.searchMemberships(membershipSearchRequest, {
            next: (response: ApiSearchResponse<Membership>) => {
                let memberships = response.resource;
                this.setMemberships(memberships);
            },
            error: this.onApiError
        }, FIELDS);
    }

    setMemberships(memberships: SearchResult<Membership>) {
        this.setState({
            memberships: memberships
        })
    }

    onView(membership: Membership) {
        this.navigate(Routes.partner.horse_membership(this.getPartnerKey(), this.getHorseKey(), membership.key!));
    }

    renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {

        let memberships = this.state.memberships;

        return <>

            <SectionHeader
                title="Memberships"
                description="The memberships of this horse are shown below. Users of the app may purchase these membership to view, own and trade."
                actions={
                    <>
                        <Confirmable
                            renderConfirm={(onOk, onCancel) => {
                                let membership: Membership = new Membership();
                                membership.manageableMetadataAttributeGroup = new BlockchainAttributeGroup();
                                return (
                                    <MembershipDialog
                                        index={index}
                                        partner={partner}
                                        horse={horse}
                                        membership={membership}
                                        onSave={(response: ApiResponse<Membership>) => {
                                            this.onSave(response);
                                            onOk();
                                        }}
                                        onDelete={() => {
                                            this.searchMemberships(1);
                                            onOk();
                                        }}
                                        onCancel={onCancel}/>
                                );
                            }}
                            renderTrigger={(onShow) => {
                                return (
                                    <Button
                                        icon="plus"
                                        bsStyle="primary"
                                        bsSize="xs"
                                        className="pull-right"
                                        onClick={onShow}
                                        disabled={!horse.isAddCollectibleAllowed()}>Add Membership</Button>
                                );
                            }}/>
                    </>
                }/>

            <MembershipTable
                memberships={memberships}
                sort={this.state.membershipSearchRequest.sort}
                onReload={this.onEnter}
                onView={this.onView}/>

        </>;

    }

};
