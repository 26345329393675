import React, {useEffect, useState} from 'react';
import {InputSelect, InputText} from 'startupbox-react';
import SectionHeader from '../components/SectionHeader';
import Post from "../models/Post";
import Divider from "../components/Divider";
import PremiumContentTier from "../models/PremiumContentTier";
import ApiIndex from "../models/ApiIndex";
import Partner from "../models/Partner";
import PremiumContentConfiguration from "../models/PremiumContentConfiguration";
import Horse from "../models/Horse";
import Context from "../Context";
import Panel from "../components/Panel";

type PostPremiumContentConfigurationPanelProps = {
  index: ApiIndex;
  prefix: 'post' | 'partner' | 'horse';
  entity: Post | Partner | Horse;
  title: string | null | undefined;
  description: string | null | undefined;
  applePremiumContentDefaultProductId: string | null | undefined;
  applePremiumContentDefaultTier: PremiumContentTier | null | undefined;
  googlePremiumContentDefaultProductId: string | null | undefined;
  googlePremiumContentDefaultTier: PremiumContentTier | null | undefined;
  errors: { [key: string]: string } | undefined;
  onFieldChange: ((e: Event, callback?: () => void) => void) | undefined;
};

const PremiumContentConfigurationPanel = (props: PostPremiumContentConfigurationPanelProps) => {

  let [premiumContentTiersForApple, setPremiumContentTiersForApple] = useState<PremiumContentTier[]>([]);
  let [premiumContentTiersForGoogle, setPremiumContentTiersForGoogle] = useState<PremiumContentTier[]>([]);

  let index = props.index;
  let prefix = props.prefix;
  let entity = props.entity;
  let errors = props.errors;
  let onFieldChange = props.onFieldChange;
  let isReadOnly = !onFieldChange;

  useEffect(() => {
    if (isPremium()) {
      ensureConfigurationIsNonNull();
      loadPremiumContentTiersForApple();
      loadPremiumContentTiersForGoogle();
    }
  }, []);

  let isPremium = () => {
    return prefix !== 'post' || (entity as Post).isPremium();
  }

  let ensureConfigurationIsNonNull = () => {
    if (!entity.applePremiumContentConfiguration) {
      entity.applePremiumContentConfiguration = new PremiumContentConfiguration(props.applePremiumContentDefaultProductId, props.applePremiumContentDefaultTier);
    }
    if (!entity.googlePremiumContentConfiguration) {
      entity.googlePremiumContentConfiguration = new PremiumContentConfiguration(props.googlePremiumContentDefaultProductId, props.googlePremiumContentDefaultTier);
    }
  }

  let loadPremiumContentTiersForApple = () => {
    index?.listPremiumContentTiersForApple({
      next: (response) => {
        setPremiumContentTiersForApple(response.resource!.values);
      },
      error: response => {
      }
    });
  }

  let loadPremiumContentTiersForGoogle = () => {
    index?.listPremiumContentTiersForGoogle({
      next: (response) => {
        setPremiumContentTiersForGoogle(response.resource!.values);
      },
      error: response => {
      }
    });
  }

  if (!isPremium()) {
    return <></>;
  }
  else if (!Context.isAdmin()) {
    return <></>;
  }

  return (

    <>

      <Panel>

        {
          (props.title || props.description) &&
          <SectionHeader
            title={props.title}
            description={props.description}/>
        }

        <SectionHeader
          title="Apple In-App Purchase Configuration"
          description="On Apple devices, premium content is sold as in-app purchases. This must first be defined and approved by Apple for sale. The Product ID is the id of the in-app purchase as defined exactly and published on App Store Connect. Users will not be able to purchase if the id or tier defined below does not match what is published."/>

        <div className="row">
          <div className="col-md-6">
            <InputText
              id={prefix + '.applePremiumContentConfiguration.productId'}
              label="App Store Product ID Suffix"
              value={entity.applePremiumContentConfiguration?.productId}
              error={errors?.['request.applePremiumContentConfiguration.productId']}
              onChange={onFieldChange}
              readOnly={isReadOnly}
              disabled={!!props.applePremiumContentDefaultProductId && !!entity.applePremiumContentConfiguration?.productId}/>
          </div>
          <div className="col-md-6">
            <InputSelect
              id={prefix + '.applePremiumContentConfiguration.tier'}
              label="App Store Product Tier"
              value={entity.applePremiumContentConfiguration?.tier}
              options={premiumContentTiersForApple}
              error={errors?.['request.applePremiumContentConfiguration.tier']}
              onChange={onFieldChange}
              readOnly={isReadOnly}
              disabled={!!props.applePremiumContentDefaultTier && !!entity.applePremiumContentConfiguration?.tier}/>
          </div>
        </div>

        <Divider/>

        <SectionHeader
          title="Google In-App Purchase Configuration"
          description="On Android devices, premium content is sold as in-app purchases. This must first be defined and approved by Google for sale. The Product ID is the id of the in-app purchase as defined exactly and published on Google Play. Users will not be able to purchase if the id or tier defined below does not match what is published."/>

        <div className="row">
          <div className="col-md-6">
            <InputText
              id={prefix + '.googlePremiumContentConfiguration.productId'}
              label="Google Play Product ID Suffix"
              value={entity.googlePremiumContentConfiguration?.productId}
              error={errors?.['request.googlePremiumContentConfiguration.productId']}
              onChange={onFieldChange}
              readOnly={isReadOnly}
              disabled={!!props.googlePremiumContentDefaultProductId && !!entity.googlePremiumContentConfiguration?.productId}/>
          </div>
          <div className="col-md-6">
            <InputSelect
              id={prefix + '.googlePremiumContentConfiguration.tier'}
              label="Google Play Product Tier"
              value={entity.googlePremiumContentConfiguration?.tier}
              options={premiumContentTiersForGoogle}
              error={errors?.['request.googlePremiumContentConfiguration.tier']}
              onChange={onFieldChange}
              readOnly={isReadOnly}
              disabled={!!props.googlePremiumContentDefaultTier && !!entity.googlePremiumContentConfiguration?.tier}/>
          </div>
        </div>

      </Panel>

    </>

  );

};

export default PremiumContentConfigurationPanel;
