export default class Address {

  street?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  country?: string | null;

  singleLineAddress?: string | null;

}