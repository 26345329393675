export const acceptedFileMimeTypes = [
  'text/plain',
  'application/pdf',
  'image/*',
  'audio/mpeg'
];

export const acceptedImageMimeTypes = [
  'image/png',
  'image/jpeg'
];
