import React from 'react';
import {SearchResult, StatusButton, Table, TableHeaderCell} from "startupbox-react";
import BlockchainMintable from "../models/BlockchainMintable";
import FontAwesome from "react-fontawesome";

type BlockchainMintableActualsTableProps = {
  actuals?: SearchResult<BlockchainMintable> | null;
  onPaginate: (page: number) => void;
};

const BlockchainMintableActualsTable = (props: BlockchainMintableActualsTableProps) => {

  let actuals = props.actuals;
  let onPaginate = props.onPaginate;

  let onView = (actual: BlockchainMintable) => {
    if (actual.viewUrl) {
      window.location.href = actual.viewUrl!;
    }
  }

  if (!actuals) {
    return <></>;
  }

  return (

    <Table
      items={actuals}
      onPaginate={onPaginate}
      blankMessage="No actuals found"
      renderHeaderRow={() => {
        return (
          <tr>
            <TableHeaderCell label="BlockChain Transaction Hash" className="col-md-5"/>
            <TableHeaderCell label="BlockChain ID"/>
            <TableHeaderCell label="Status" className="col-md-2 text-right"/>
            <TableHeaderCell label="&nbsp;" className="col-chevron"/>
          </tr>
        );
      }}
      renderItemRow={(actual: BlockchainMintable, a: number) => {
        return (
          <tr key={a} className="clickable" onClick={() => {
            onView(actual);
          }}>
            <td>{actual.blockchainTransactionHash}</td>
            <td>{actual.blockchainId}</td>
            <td className="text-right">
              <StatusButton
                id={'status-' + a}
                value={actual.mintStatus}/>
            </td>
            <td className="text-right">
              <FontAwesome name="chevron-right"/>
            </td>
          </tr>
        );
      }}
    />

  );

};

export default BlockchainMintableActualsTable;