import React, {ReactNode} from 'react';
import './PageHeader.css';
import Binary from "../models/Binary";
import {ImageWithUpload} from 'startupbox-react';
import {acceptedImageMimeTypes} from "../utils/acceptedFileMimeTypes";

type PageHeaderProps = {
  title: string | null | undefined;
  subTitle?: string | null | undefined;
  lead?: ReactNode | string | null | undefined;
  avatar?: Binary | null;
  onChangeAvatar?: (avatar: Binary | undefined) => void
};

const PageHeader = (props: PageHeaderProps) => {

  let showAvatar = props.avatar || props.avatar === null;

  let onChangeAvatar = props.onChangeAvatar ? (e: any) => {
    let files = e.target.value;
    props.onChangeAvatar!(files && files.length > 0 ? files[0]: undefined);
  } : undefined;

  return (

    <div className="page-header">
      <div className="row">
        {
          showAvatar &&
          <div className="col-md-2 col-avatar">
            <ImageWithUpload
              id="page-header-images"
              upload={!!props.onChangeAvatar}
              files={props.avatar ? [props.avatar] : []}
              accept={acceptedImageMimeTypes}
              className="avatar"
              onChange={onChangeAvatar}/>
          </div>
        }
        <div className={showAvatar ? 'col-md-10' : 'col-md-12'}>
          <h1 className="title">
            {props.title}
            {props.subTitle && <span className="text-muted marginLeft15">{props.subTitle}</span>}
          </h1>
          {props.lead && <p className="lead">{props.lead}</p>}
        </div>
      </div>
    </div>

  );

}

export default PageHeader;