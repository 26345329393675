import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, navigate, SearchResult} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Routes from '../Routes';
import User from "../models/User";
import UserStatus from "../models/UserStatus";
import ApiIndex from "../models/ApiIndex";
import {ApiErrorResponse} from "../models/ApiError";
import SectionHeader from "../components/SectionHeader";
import BankDetails from "../models/BankDetails";
import WalletPanel from "../wallet/WalletPanel";
import Device from "../models/Device";
import DeviceSearchRequest from "../models/DeviceSearchRequest";
import DevicePanel from "../device/DevicePanel";
import {PaymentMethodPanel} from "../payment_method/PaymentMethodPanel";
import {BankDetailsPanel} from "../bank/BankDetailsPanel";
import {UserProfilePanel} from "./UserProfilePanel";
import PushNotification from "../models/PushNotification";
import PushNotificationSearchRequest from "../models/PushNotificationSearchRequest";
import PushNotificationTable from "../push_notification/PushNotificationTable";

let FIELDS = 'avatar,address,bankDetails,wallets,paymentMethods';

export type UserPageProps = PageProps & {};

export type UserPageState = PageState & {
  user?: User;
  bankDetails?: BankDetails;
  devices?: SearchResult<Device>;
  pushNotifications?: SearchResult<PushNotification>;
};

export default class UserPage extends Page<UserPageProps, UserPageState> {

  constructor(props: UserPageProps) {
    super(props);
    this.setInitialState({});
  }

  onLoad() {
    if (!this.isAdding()) {
      this.loadUser();
    }
  }

  loadUser() {
    this.state.index?.findUserByKey(this.props.params.key, {
      next: this.setUser,
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, FIELDS);
  }

  setUser(response: ApiResponse<User>) {
    let user = response.resource!;
    this.publish(Events.system.pageTitle, user.name);
    this.setState({
      mode: '',
      user: user
    }, () => {
      this.loadBankDetails();
      this.loadDevices();
      this.loadPushNotifications();
    });
  }

  onSave(response: ApiResponse<User>) {
    this.setUser(response);
  }

  onChangeStatus(status: UserStatus) {
    this.state.user?.modifyStatus(status, {
      next: this.setUser,
      error: this.onApiError
    }, FIELDS);
  }

  isAdding() {
    let key = this.props.params.key;
    return !key || key === 'add';
  }

  onNavigateToUsers(e: any) {
    this.preventDefault(e);
    this.navigate(Routes.admin.users());
  }

  // bank ...........................................................................................................................................

  loadBankDetails() {
    let user = this.state.user;
    user?.findBankDetails({
      next: this.setBankDetails,
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
      }
    }, null);
  }

  onSaveBankDetails(response: ApiResponse<BankDetails>) {
    this.setBankDetails(response);
    this.loadUser();
  }

  setBankDetails(response: ApiResponse<BankDetails>) {
    let bankDetails = response.resource!;
    this.setState({
      bankDetails: bankDetails
    });
  }

  // wallet .........................................................................................................................................

  onCreateDefaultWallet() {
    let user = this.state.user;
    user?.createDefaultWallet({
      next: this.setUser,
      error: this.onApiError
    }, FIELDS);
  }

  // device .........................................................................................................................................

  loadDevices() {
    let user = this.state.user;
    let request = new DeviceSearchRequest();
    request.active = true;
    user?.searchDevices(request, {
      next: this.setDevices,
      error: this.onApiError
    }, null);
  }

  setDevices(response: ApiSearchResponse<Device>) {
    this.setState({
      devices: response.resource
    })
  }

  // push notification ..............................................................................................................................

  loadPushNotifications() {
    let user = this.state.user;
    let request = new PushNotificationSearchRequest();
    user?.searchPushNotifications(request, {
      next: this.setPushNotifications,
      error: this.onApiError
    }, null);
  }

  setPushNotifications(response: ApiSearchResponse<PushNotification>) {
    this.setState({
      pushNotifications: response.resource
    })
  }

  // render .........................................................................................................................................

  renderWithIndex(index: ApiIndex) {

    let user = this.state.user;
    let bankDetails = this.state.bankDetails;
    let devices = this.state.devices;
    let pushNotifications = this.state.pushNotifications;

    if (!user) {
      return <></>;
    }

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title={user.name}
            avatar={user.avatar || null}
            lead={
              <>
                You are editing an existing <strong>{user.admin ? 'admin' : 'regular'}</strong> user
                called <strong>{user.name}</strong> on the system. <a href="/" className="back" onClick={this.onNavigateToUsers}>Pick another user.</a>
              </>
            }/>

          <UserProfilePanel
            index={index}
            user={user}
            bankDetails={bankDetails}
            onSave={this.onSave}
            onChangeStatus={this.onChangeStatus}
            fields={FIELDS}/>

          <SectionHeader
            title="Bank Details"
            description="The bank details associated with this user account is shown below. These are are required for purchasing horse tranches. They will be used to disburse any winnings."/>

          <BankDetailsPanel
            user={user}
            bankDetails={bankDetails}
            onSaveBankDetails={this.onSaveBankDetails}
            fields={FIELDS}/>

          {
            user.wallets &&
            <>
              <SectionHeader
                title="Wallets"
                description="The wallets associated with this user account are shown below. BTX currently only support BTX managed wallets and a future release will provide access to self custody wallets."/>
              {
                user.wallets.map((wallet, w) => (
                  <WalletPanel
                    key={w}
                    userKey={user!.key!}
                    wallet={wallet}
                    onView={() => {
                      navigate(Routes.admin.user_wallet(user?.key!, wallet.key!, 'transactions'));
                    }}/>
                ))
              }
              {
                user.wallets.length === 0 &&
                <>
                  <p>It doesn't look like this user possess a valid wallet. This would have occurred if the user was created whilst the Blockchain service was not enabled.</p>
                  <Button
                    bsStyle="primary"
                    bsSize="sm"
                    disabled={!user.isCreateDefaultWalletAllowed()}
                    onClick={this.onCreateDefaultWallet}>Create Default Wallet</Button>
                </>
              }
            </>
          }

          {
            user.paymentMethods && user.paymentMethods.methods && user.paymentMethods.methods.length > 0 &&
            <>
              <SectionHeader
                title="Payment Methods"
                description="The credit cards and other payment methods associated with this user account are shown below."/>
              {
                user.paymentMethods.methods?.map((paymentMethod, p) => (
                  <PaymentMethodPanel key={p} paymentMethod={paymentMethod}/>
                ))
              }
            </>
          }

          {
            devices && devices.values.length > 0 &&
            <>
              <SectionHeader
                title="Active Devices"
                description="The active devices associated with this user account are shown below."/>
              {
                devices.values?.map((device, d) => (
                  <DevicePanel
                    key={d}
                    device={device}
                    onView={() => {
                      navigate(Routes.admin.user_device(user?.key!, device.key!, 'events'));
                    }}/>
                ))
              }
            </>
          }

          {
            pushNotifications && pushNotifications.values.length > 0 &&
            <>
              <SectionHeader
                title="Recent Push Notifications"
                description="The recent push notifications triggered to this user account are shown below."/>
              <PushNotificationTable
                pushNotifications={pushNotifications.values}/>
            </>
          }

        </div>

      </div>

    );

  }

};
