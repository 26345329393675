import Model from "../Model";
import EntitlementType from "./EntitlementType";
import EntitlementStatus from "./EntitlementStatus";
import {ApiSubscribableCallbacks} from "startupbox-react";
import ApiError from "./ApiError";
import moment from "moment";

let Rel = {
  self: 'self',
  removeFromDisbursement: 'removeFromDisbursement'
};

export const ENTITLEMENT_STANDARD_FIELDS = '';
export const ENTITLEMENT_LIST_FIELDS = '';

export default class Entitlement {

  key?: string;

  date?: Date;
  paymentDate?: Date;
  totalAmount?: number;
  type?: EntitlementType;
  status?: EntitlementStatus;
  scheduled?: boolean;
  actionLabel?: string;

  walletOwnerName?: string;
  payoutDescription?: string;
  subscribableName?: string;
  disbursementTitle?: string;
  bankDetailsForAustraliaValid?: boolean;

  href = Model.hrefFunction();

  // ................................................................................................................................................

  isScheduled() {
    return this.paymentDate ? moment(this.paymentDate).valueOf() > Date.now() : false;
  }

  // ................................................................................................................................................

  isRemoveFromDisbursementAllowed() {
    return !!this.href(Rel.removeFromDisbursement);
  }

  removeFromDisbursement(callbacks: ApiSubscribableCallbacks<Entitlement, ApiError>, fields: string | null) {
    this.href(Rel.removeFromDisbursement, true)!
      .field(fields)
      .patch({})
      .subscribe(callbacks);
  }

}
