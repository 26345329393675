import React from 'react';
import {ApiSearchResponse, Button, Confirmable, Form, InputSelect, InputText, SearchResult, StatusButton, Table, TableHeaderCell} from 'startupbox-react';
import {Avatar} from '../components/Image';
import PageHeader from '../components/PageHeader';
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from './PartnerAbstractPage';
import PartnerCollaboratorDialog from './PartnerCollaboratorDialog';
import PartnerCollaboratorSearchRequest from "../models/PartnerCollaboratorSearchRequest";
import Partner from "../models/Partner";
import PartnerCollaborator from "../models/PartnerCollaborator";
import PartnerCollaboratorStatus from "../models/PartnerCollaboratorStatus";
import ApiIndex from "../models/ApiIndex";

let FIELDS = 'userAvatar';

export type PartnerTeamPageProps = PartnerAbstractPageProps & {};

export type PartnerTeamPageState = PartnerAbstractPageState & {
  collaboratorSearchRequest: PartnerCollaboratorSearchRequest;
  collaborators: SearchResult<PartnerCollaborator> | null;
}

export default class PartnerTeamPage extends PartnerAbstractPage<PartnerTeamPageProps, PartnerTeamPageState> {

  constructor(props: PartnerTeamPageProps) {
    super(props);
    this.setInitialState({
      collaboratorSearchRequest: new PartnerCollaboratorSearchRequest(),
      collaborators: null,
    });
  }

  onEnter() {
    this.setState({
      collaboratorSearchRequest: {
        name: this.query('name'),
        type: this.query('type'),
        status: this.query('status'),
        sort: this.query('sort'),
        page: parseInt(this.query('page') || '0'),
      }
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('name', this.state.collaboratorSearchRequest.name)
      .param('type', this.state.collaboratorSearchRequest.type)
      .param('status', this.state.collaboratorSearchRequest.status)
      .param('sort', this.state.collaboratorSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoadPartner(partner: Partner) {
    partner.searchCollaborators(this.state.collaboratorSearchRequest, {
      next: this.setCollaborators,
      error: this.onApiError
    }, FIELDS);
  }

  onSave() {
    this.onSearch();
  }

  setCollaborators(response: ApiSearchResponse<PartnerCollaborator>) {
    this.setState({
      collaborators: response.resource
    })
  }

  onChangeStatusFn(collaborator: PartnerCollaborator) {
    return (status: PartnerCollaboratorStatus) => {
      collaborator.modifyStatus(status, {
        next: this.onSearch,
        error: this.onApiError
      }, FIELDS);
    }
  }

  renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title={partner.name}
            subTitle="Team"
            lead="This area allows you to manage the users with access to your partner dashboard. From here you may add a new team member and edit their access."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputText
              id="collaboratorSearchRequest.name"
              placeholder="Name"
              value={this.state.collaboratorSearchRequest.name}
              onChange={this.onFieldChange}
              className="wide"/>
            {/*<InputSelect*/}
            {/*  id="collaboratorSearchRequest.type"*/}
            {/*  blank="Type"*/}
            {/*  value={this.state.collaboratorSearchRequest.type}*/}
            {/*  onChange={this.onFieldChange}*/}
            {/*  options={index.partnerCollaboratorTypes}/>*/}
            <InputSelect
              id="collaboratorSearchRequest.status"
              blank="Status"
              value={this.state.collaboratorSearchRequest.status}
              onChange={this.onFieldChange}
              options={index.partnerCollaboratorStatuses}/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              {
                partner.isAddCollaboratorAllowed() &&
                <Confirmable
                  renderTrigger={(onShow) => {
                    return (
                      <Button
                        icon="plus"
                        bsStyle="default"
                        className="pull-right"
                        onClick={onShow}>Add</Button>
                    );
                  }}
                  renderConfirm={(onOk, onCancel) => {
                    return (
                      <PartnerCollaboratorDialog
                        partner={partner}
                        collaborator={new PartnerCollaborator()}
                        onSave={() => {
                          this.onSave();
                          onOk();
                        }}
                        onCancel={onCancel}/>
                    );
                  }}/>
              }
            </span>
          </Form>

          <br/>

          <Table
            items={this.state.collaborators}
            sort={this.state.collaboratorSearchRequest.sort}
            onReload={this.onEnter}
            blankMessage="No team members found"
            renderHeaderRow={() => {
              return (
                <tr>
                  <TableHeaderCell label="Name" sort="name"/>
                  <TableHeaderCell label="Type" sort="type" className="col-md-2"/>
                  <TableHeaderCell label="Status" sort="status" className="col-md-2 text-right"/>
                </tr>
              );
            }}
            renderItemRow={(collaborator: PartnerCollaborator, i: number) => {
              return (
                <tr key={i} className="clickable">
                  <td>
                    <Avatar src={collaborator.userAvatar} transforms="w_300"/>
                    <strong>{collaborator.userName}</strong>
                  </td>
                  <td>{collaborator.type?.name}</td>
                  <td className="text-right">
                    <StatusButton
                      id={'status-' + i}
                      value={collaborator.status}
                      options={index.partnerCollaboratorStatuses}
                      onEdit={collaborator.isModifyStatusAllowed() ? this.onChangeStatusFn(collaborator) : null}/>
                  </td>
                </tr>
              );
            }}
          />

        </div>

      </div>

    );

  }

};