import React from 'react';
import {ApiSubscribableCallbacks, Button, Form, InputMonthYearPicker, InputSelect, Str} from 'startupbox-react';
import ApiIndex from "../models/ApiIndex";
import StatisticLineChart from "../components/StatisticLineChart";
import Statistic from "../models/Statistic";
import ApiError from "../models/ApiError";
import User from "../models/User";
import AdminDashboardAbstractPage, {AdminDashboardAbstractPageProps, AdminDashboardAbstractPageState} from "./AdminDashboardAbstractPage";
import SubscriptionStatisticSearchRequest from "../models/SubscriptionStatisticSearchRequest";

export type AdminDashboardSubscriptionsPageProps = AdminDashboardAbstractPageProps & {};

export type AdminDashboardSubscriptionsPageState = AdminDashboardAbstractPageState & {
  searchRequest: SubscriptionStatisticSearchRequest;
};

export default class AdminDashboardUsersPage extends AdminDashboardAbstractPage<AdminDashboardSubscriptionsPageProps, AdminDashboardSubscriptionsPageState> {

  constructor(props: AdminDashboardSubscriptionsPageProps) {
    super(props);
    this.activeTab = 'subscriptions';
    this.subTitle = 'Subscriptions';
    this.description = 'This area allows you monitor subscription related statistics useful to the business. Filter the matching data by using the fields below.';
    setTimeout(() => {
      this.setState({
        searchRequest: new SubscriptionStatisticSearchRequest(),
      })
    }, 0);
  }

  onSearch() {
    this.setState({
      timestamp: Date.now()
    });
  }

  renderWithIndexAndUser(index: ApiIndex, me: User, timestamp: number) {

    let searchRequest = this.state.searchRequest;

    if (!me) {
      return <></>;
    }

    return (

      <>

        <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
          <InputMonthYearPicker
            id="searchRequest.fromYYYYMM"
            placeholder="Date From"
            years={[2022]}
            value={searchRequest.fromYYYYMM}
            onChange={this.onFieldChange}
            getFormattedValueFn={value => Str.formatStartOfMonth(value, 'DD/MM/YYYY')}
            clearable/>
          {/*
              <InputMonthYearPicker
                id="searchRequest.toYYYYMM"
                placeholder="Date To"
                years={[2022]}
                value={searchRequest.toYYYYMM}
                onChange={this.onFieldChange}
                getFormattedValueFn={value => Str.formatEndOfMonth(value, 'DD/MM/YYYY')}
                clearable/>
          */}
          <InputSelect
            id="searchRequest.status"
            blank="Status"
            value={searchRequest.status}
            onChange={this.onFieldChange}
            options={index.subscriptionStatuses}/>
          <span className="actions">
            <Button
              icon="search"
              bsStyle="primary"
              type="submit">Search</Button>
          </span>
        </Form>

        <StatisticLineChart
          chartContainerId="creation-day-chart-container"
          title="Subscriptions By Day"
          what="subscription"
          timestamp={timestamp}
          refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
            searchRequest.statisticType = 'CREATION_DAY';
            me?.findSubscriptionStatistic(searchRequest, callbacks);
          }}/>

        <StatisticLineChart
          chartContainerId="creation-month-chart-container"
          title="Subscriptions By Month"
          what="subscription"
          timestamp={timestamp}
          refresh={(callbacks: ApiSubscribableCallbacks<Statistic, ApiError>) => {
            searchRequest.statisticType = 'CREATION_MONTH';
            me?.findSubscriptionStatistic(searchRequest, callbacks);
          }}/>

      </>

    );

  }

}
