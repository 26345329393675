import React, {ReactNode} from 'react';
import {DialogPanel, FullScreenModal} from 'startupbox-react';
import './SecurityDialog.css';
import {DialogProps} from '../Dialog';

export type SecurityDialogProps = DialogProps & {
  animate?: boolean,
  onCancel?: () => void
  footnote?: ReactNode
  children?: ReactNode
};

const SecurityDialog = (props: SecurityDialogProps) => (
  <FullScreenModal columnClassNames="col-md-12" onHide={props.onCancel} animate={props.animate}>
    <DialogPanel columnClassNames="security-dialog-panel col-md-10 col-md-offset-1">
      <div className="row row-eq-height">
        <div className="col-md-6 col-padding">
        </div>
        <div className="col-md-6 col-xs-12">
          {props.children}
        </div>
      </div>
    </DialogPanel>
    {props.footnote}
  </FullScreenModal>
);

export default SecurityDialog;