import React from 'react';
import Page, {PageProps, PageState} from '../Page';
import ApiIndex from "../models/ApiIndex";
import {ApiResponse, DialogPanel, FullScreenModal} from "startupbox-react";
import User from "../models/User";
import Routes from "../Routes";

export type FrankieOnePendingPageProps = PageProps & {};

export type FrankieOnePendingPageState = PageState & {};

export default class FrankieOnePendingPage extends Page<FrankieOnePendingPageProps, FrankieOnePendingPageState> {

  constructor(props: FrankieOnePendingPageProps) {
    super(props);
    this.setInitialState({});
  }

  onLoad() {
    this.addBackgroundRepeatingTask('poll', 5000, this.onReload);
  }

  onReload() {
    let frankieOneAccessKey = this.query('frankieOneAccessKey');
    if (this.getIndex()) {
      this.getIndex()?.findUserByFrankieOneAccessKey(frankieOneAccessKey, {
        next: (response: ApiResponse<User>) => {
          let user = response.resource;
          if (user?.frankieOneEntityActionRecommended?.startsWith('PASS')) {
            this.navigate(Routes.frankieOne.success(frankieOneAccessKey));
          }
          else if (user?.frankieOneEntityActionRecommended?.startsWith('FAIL')) {
            this.navigate(Routes.frankieOne.failure(frankieOneAccessKey));
          }
        },
        error: this.onApiError
      }, null);
    }
  }

  renderWithIndex(index: ApiIndex) {

    return <>

      <FullScreenModal className="centered-title">

        <DialogPanel>

          <h2 className="marginTop50">BTX Identity Pending Verification</h2>
          <p>Thank you for submitting your identity and supporting documentation. We are
            verifying the details you have submitted. This can take up to 5 mins. This screen
            will update automatically when the verification completes. You can close this
            screen and browse around or wait for the the verification to complete.</p>

        </DialogPanel>

      </FullScreenModal>

    </>;

  }

}
