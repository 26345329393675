import React from 'react';
import {ApiResponse, Button, Form, FullScreenModal, GlobalMessage, InputDatePicker, InputSelect, InputText} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import Wallet from "../models/Wallet";
import ApiIndex from "../models/ApiIndex";
import AppCredit from "../models/AppCredit";

export type WalletAppCreditDialogProps = DialogProps & {
  wallet: Wallet;
  appCredit: AppCredit;
  onSave: (response: ApiResponse<AppCredit>) => void;
  onCancel: () => void;
};

export type WalletAppCreditDialogState = DialogState & {
  appCredit: AppCredit;
};

export default class WalletAppCreditDialog extends Dialog<WalletAppCreditDialogProps, WalletAppCreditDialogState> {

  constructor(props: WalletAppCreditDialogProps) {
    super(props);
    this.setInitialState({
      appCredit: this.props.appCredit
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let appCredit = this.state.appCredit;
      if (appCredit.key) {
        // not allowed
      }
      else {
        let wallet = this.props.wallet;
        wallet.addAppCredit(appCredit, {
          next: this.props.onSave,
          error: this.onApiError
        }, null);
      }
    }
  }

  onCancel() {
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let appCredit = this.state.appCredit;

    return (

      <div>

        <FullScreenModal title={!appCredit.key ? 'New App Credit' : 'Edit App Credit'} onHide={this.onCancel}>

          <p className="lead">Please enter the details of the app credit below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <InputText
                id="appCredit.description"
                label="App Credit Description"
                value={appCredit.description}
                error={this.state.errors?.['request.description']}
                onChange={this.onFieldChange}
                className="lg"
                required/>

              <div className="row">
                <div className="col-md-6">
                  <InputDatePicker
                    id="appCredit.date"
                    label="Date"
                    value={appCredit.date}
                    dateFormat="DD/MM/YYYY"
                    error={this.state.errors?.['request.date']}
                    onChange={this.onFieldChange}
                    required/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="appCredit.amount"
                    label="Amount"
                    value={appCredit.amount}
                    format="currency"
                    error={this.state.errors?.['request.amount']}
                    onChange={this.onFieldChange}
                    className="lg"
                    required/>
                </div>
              </div>

              <InputSelect
                id="appCredit.type"
                label="Type"
                value={appCredit.type}
                error={this.state.errors?.['request.type']}
                onChange={this.onFieldChange}
                options={index.appCreditTypes}
                required/>

            </Panel>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Save Changes</Button>
              <Button
                onClick={this.onCancel}
                bsSize="large">Cancel</Button>
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
