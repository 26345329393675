import React from 'react';
import PartnerHorseAbstractPage, {PartnerHorseAbstractPageProps, PartnerHorseAbstractPageState} from './PartnerHorseAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Horse from "../models/Horse";
import Partner from "../models/Partner";
import SectionHeader from "../components/SectionHeader";
import {ApiSearchResponse, Button, Confirmable, SearchResult} from "startupbox-react";
import CollectibleDialog from "../collectible/CollectibleDialog";
import Collectible, {COLLECTIBLE_LIST_FIELDS} from "../models/Collectible";
import CollectibleSearchRequest from "../models/CollectibleSearchRequest";
import Routes from "../Routes";
import BlockchainAttributeGroup from "../models/BlockchainAttributeGroup";
import CollectibleTable from "../collectible/CollectibleTable";

let FIELDS = COLLECTIBLE_LIST_FIELDS;

export type PartnerHorseCollectiblesPageProps = PartnerHorseAbstractPageProps & {};

export type PartnerHorseCollectiblesPageState = PartnerHorseAbstractPageState & {
  collectibles: SearchResult<Collectible> | null;
}

export default class PartnerHorseCollectiblesPage extends PartnerHorseAbstractPage<PartnerHorseCollectiblesPageProps, PartnerHorseCollectiblesPageState> {

  constructor(props: PartnerHorseCollectiblesPageProps) {
    super(props);
    this.activeTab = 'collectibles';
  }

  onLoadHorse(horse: Horse | undefined) {
    this.searchCollectibles(1);
  }

  searchCollectibles(page: number) {
    let collectibleSearchRequest = new CollectibleSearchRequest();
    collectibleSearchRequest.page = page - 1;
    this.state.horse?.searchCollectibles(collectibleSearchRequest, {
      next: (response: ApiSearchResponse<Collectible>) => {
        let collectibles = response.resource;
        this.setCollectibles(collectibles);
      },
      error: this.onApiError
    }, FIELDS);
  }

  setCollectibles(collectibles: SearchResult<Collectible>) {
    this.setState({
      collectibles: collectibles
    })
  }

  onView(collectible: Collectible) {
    this.navigate(Routes.partner.horse_collectible(this.getPartnerKey(), this.getHorseKey(), collectible.key!));
  }

  renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {

    let collectibles = this.state.collectibles;

    return <>

      <SectionHeader
        title="Collectibles"
        description="The collectibles of this horse are shown below. Users of the app may purchase these collectibles to view, own and trade."
        actions={
          <>
            <Confirmable
              renderConfirm={(onOk, onCancel) => {
                let collectible: Collectible = new Collectible();
                collectible.manageableMetadataAttributeGroup = new BlockchainAttributeGroup();
                return (
                  <CollectibleDialog
                    index={index}
                    horse={horse}
                    collectible={collectible}
                    onSave={(response) => {
                      let collectible = response.resource;
                      this.navigate(Routes.partner.horse_collectible(this.getPartnerKey(), this.getHorseKey(), collectible?.key!))
                      onOk();
                    }}
                    onDelete={() => {
                      this.searchCollectibles(1);
                      onOk();
                    }}
                    onCancel={onCancel}/>
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <Button
                    icon="plus"
                    bsStyle="primary"
                    bsSize="xs"
                    className="pull-right"
                    onClick={onShow}
                    disabled={!horse.isAddCollectibleAllowed()}>Add Collectible</Button>
                );
              }}/>
          </>
        }/>

      <CollectibleTable
        collectibles={collectibles}
        onReload={this.onEnter}
        onPaginate={this.searchCollectibles}
        onView={this.onView}/>

    </>;

  }

};
