import React from 'react';
import PartnerHorseAbstractPage, {PartnerHorseAbstractPageProps, PartnerHorseAbstractPageState} from './PartnerHorseAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Horse from "../models/Horse";
import Partner from "../models/Partner";
import Collectible, {COLLECTIBLE_STANDARD_FIELDS} from "../models/Collectible";
import {ApiErrorResponse} from "../models/ApiError";
import {ApiResponse, ApiSearchResponse, Button, GlobalMessage, SearchResult} from "startupbox-react";
import Routes from "../Routes";
import SectionHeader from "../components/SectionHeader";
import CollectibleSearchRequest from "../models/CollectibleSearchRequest";
import BlockchainMintableActualsTable from "../components/BlockchainMintableActualsTable";

let FIELDS = COLLECTIBLE_STANDARD_FIELDS;

export type PartnerHorseCollectibleActualsPageProps = PartnerHorseAbstractPageProps & {};

export type PartnerHorseCollectibleActualsPageState = PartnerHorseAbstractPageState & {
  collectible: Collectible | null | undefined;
  actuals?: SearchResult<Collectible> | null;
}

export default class PartnerHorseCollectibleActualsPage extends PartnerHorseAbstractPage<PartnerHorseCollectibleActualsPageProps, PartnerHorseCollectibleActualsPageState> {

  constructor(props: PartnerHorseCollectibleActualsPageProps) {
    super(props);
    this.activeTab = 'collectibles';
  }

  onLoadHorse(horse: Horse | undefined) {
    this.loadCollectible();
  }

  loadCollectible() {
    this.state.horse?.findCollectibleByKey(this.props.params.collectibleKey, {
      next: (response => {
        this.setCollectible(response, () => {
          this.searchActuals(1);
        });
      }),
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, FIELDS);
  }

  searchActuals(page: number) {
    let searchRequest = new CollectibleSearchRequest();
    searchRequest.page = page - 1;
    this.state.collectible?.searchActuals(searchRequest, {
      next: (response: ApiSearchResponse<Collectible>) => {
        let actuals = response.resource;
        this.setActuals(actuals);
      },
      error: this.onApiError
    }, null);
  }

  onBack() {
    this.navigate(Routes.partner.horse_collectible(this.getPartnerKey(), this.getHorseKey(), this.props.params.collectibleKey))
  }

  setCollectible(response: ApiResponse<Collectible>, callback?: () => void) {
    let collectible = response.resource;
    this.setState({
      collectible: collectible
    }, callback);
  }

  setActuals(collectibles: SearchResult<Collectible>) {
    this.setState({
      actuals: collectibles
    });
  }

  renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {

    let collectible = this.state.collectible;
    let actuals = this.state.actuals;

    if (!collectible) {
      return <></>;
    }

    return <>

      <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

      <SectionHeader
        title="Collectible Actuals"
        description={
          <>
            The actual instances of this collectible are shown below. Actual instances are what users buy. The characteristics
            and metadata of an actual instance are the same as the template from which it is created from.
          </>
        }
        actions={
          <>
            <Button
              icon="chevron-left"
              iconPosition="left"
              bsStyle="default"
              bsSize="xs"
              className="pull-right"
              onClick={this.onBack}>Back To Template</Button>
          </>
        }/>

      <BlockchainMintableActualsTable
        actuals={actuals}
        onPaginate={this.searchActuals}/>

    </>;

  }

};
