import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, FileWithUpload, Form, FullScreenModal, GlobalMessage, InputSelect, SearchResult, StatusButton, Str, Table, TableHeaderCell} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import UserImportRunSearchRequest from "../models/UserImportRunSearchRequest";
import ApiIndex from "../models/ApiIndex";
import UserImportRun from "../models/UserImportRun";
import Routes from "../Routes";
import UserImportRunUser from "../models/UserImportRunUser";
import Page, {PageProps, PageState} from "../Page";
import Events from "../Events";

let FIELDS = '';

export type UserImportRunsPageProps = PageProps & {};

export type UserImportRunsPageState = PageState & {
  userImportRunSearchRequest: UserImportRunSearchRequest;
  userImportRuns: SearchResult<UserImportRun> | null;
  files: any[]
}

export default class UserImportRunsPage extends Page<UserImportRunsPageProps, UserImportRunsPageState> {

  constructor(props: UserImportRunsPageProps) {
    super(props);
    this.setInitialState({
      userImportRunSearchRequest: new UserImportRunSearchRequest(),
      userImportRuns: null,
      files: [],
    });
  }

  onEnter() {
    this.setState({
      userImportRunSearchRequest: {
        status: this.query('status'),
        sort: this.query('sort'),
        page: this.query('page'),
      }
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('status', this.state.userImportRunSearchRequest.status)
      .param('sort', this.state.userImportRunSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'User Import Runs');
    this.state.index?.searchUserImportRuns(this.state.userImportRunSearchRequest, {
      next: this.setUserImportRuns,
      error: this.onApiError
    }, FIELDS);
  }

  onSave() {
    this.onSearch();
  }

  onAdd() {
    let run = new UserImportRun();
    run.users = [UserImportRunUser.buildNew()];
    this.state.index?.addUserImportRun(run, {
      next: (response: ApiResponse<UserImportRun>) => {
        this.onView(response.resource!);
      },
      error: this.onApiError
    }, null);
  }

  // -------------------------------------------------------------------------------------------------------------------

  onShowUploadDialog() {
    this.setMode('upload');
  }

  onHideUploadDialog() {
    this.setMode('');
  }

  onUpload(e: any) {
    this.preventDefault(e);
    if (this.isEnabled()) {
      this.disable();
      let file = this.state.files[0];
      this.state.index?.uploadUserImportRun(file, {
        next: (response: ApiResponse<UserImportRun>) => {
          this.onView(response.resource!);
        },
        error: this.onApiError
      }, null);
    }
  }

  onAddFile(acceptedFiles: any) {
    for (let file of acceptedFiles) {
      this.setState({
        files: [file]
      });
    }
  }

  onRemoveFile() {
    this.setState({
      files: []
    });
  }

  // -------------------------------------------------------------------------------------------------------------------

  onView(userImportRun: UserImportRun) {
    this.navigate(Routes.admin.userImportRun(userImportRun.key!));
  }

  setUserImportRuns(response: ApiSearchResponse<UserImportRun>) {
    this.setState({
      userImportRuns: response.resource
    })
  }

  renderWithIndex(index: ApiIndex) {

    let mode = this.state.mode;

    return (

      <div className="row">

        <div className="col-md-12">

          {
            mode === 'upload' &&
            <FullScreenModal title="Upload Users" onHide={this.onHideUploadDialog}>

              <Form onSubmit={this.onUpload} disabled={this.isDisabled()}>

                <p className="lead">Please select the file to import below.</p>

                <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

                <FileWithUpload
                  id="file"
                  type="file"
                  fileOpenTarget="_blank"
                  files={this.state.files}
                  onAdd={this.onAddFile}
                  onRemove={this.onRemoveFile}
                  multiple={false}/>

                <div className="actions">
                  <Button
                    bsStyle="primary"
                    bsSize="large"
                    type="submit"
                    disabled={this.state.files.length === 0 || this.isDisabled()}>Upload Import</Button>
                  <Button
                    onClick={this.onHideUploadDialog}
                    bsSize="large">Cancel</Button>
                </div>

              </Form>

            </FullScreenModal>
          }

          <PageHeader
            title="User Import Runs"
            lead="This area allows you to manage the user import runs. From here you may add a run and kick off an import when ready."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputSelect
              id="userImportRunSearchRequest.status"
              blank="Status"
              value={this.state.userImportRunSearchRequest.status}
              onChange={this.onFieldChange}
              options={index.userImportRunStatuses}/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              {
                index.isAddUserImportRunAllowed() &&
                <Button
                  icon="plus"
                  bsStyle="default"
                  className="pull-right"
                  onClick={this.onAdd}>Add</Button>
              }
              {
                index.isUploadUserImportRunAllowed() &&
                <Button
                  icon="plus"
                  bsStyle="default"
                  className="pull-right"
                  onClick={this.onShowUploadDialog}>Upload</Button>
              }
            </span>
          </Form>

          <br/>

          <Table
            items={this.state.userImportRuns}
            sort={this.state.userImportRunSearchRequest.sort}
            onReload={this.onEnter}
            blankMessage="No user import runs found"
            renderHeaderRow={() => {
              return (
                <tr>
                  <TableHeaderCell label="Created At" sort="createdAt"/>
                  <TableHeaderCell label="Status" sort="status" className="col-md-2 text-right"/>
                </tr>
              );
            }}
            renderItemRow={(userImportRun: UserImportRun, i: number) => {
              return (
                <tr key={i} className="clickable" onClick={this.fn(this.onView, userImportRun)}>
                  <td>
                    {Str.formatDate(userImportRun.createdAt)}
                  </td>
                  <td className="text-right">
                    <StatusButton
                      id={'status-' + i}
                      value={userImportRun.status}/>
                  </td>
                </tr>
              );
            }}
          />

        </div>

      </div>

    );

  }

};
