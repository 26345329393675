export default class HubSpotAsyncMessageSearchRequest {

  key?: string;
  description?: string;
  type?: string;
  status?: string;

  sort?: string;
  page?: string | number;
  size?: string | number;

}
