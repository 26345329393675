import React from 'react';
import {Alert, Environment, Href} from 'startupbox-react';
import FontAwesome from "react-fontawesome";

type ExportDialogProps = {
  title?: string;
  instructions?: string;
  label?: string;
  href: Href;
  count: number;
  size?: number;
  className?: string;
  onClose: () => void;
};

const ExportDialog = (props: ExportDialogProps) => {

  let getTitle = () => {
    return props.title || 'Select File';
  }

  let getInstructions = () => {
    return props.instructions || 'Please select the file to download from the list below.';
  }

  let getLabel = () => {
    return props.label || 'Download';
  }

  let getHref = (page: number) => {
    return new Href(props.href.toString())
      .param('page', page)
      .param('size', getSize())
      .param("_timestamp", Date.now());
  }

  let getCount = () => {
    return props.count;
  }

  let getSize = () => {
    return props.size || 500;
  }

  let onExport = (page: number) => {
    let path = Environment.current().baseBackendUrl + getHref(page).toString();
    window.open(path, '_blank');
  }

  let count = getCount();
  let size = getSize();
  let batches = Math.floor(count / size + (count % size === 0 ? 0 : 1));

  return (

    <Alert
      className="big modern"
      title={getTitle()}
      okButtonLabel="Close"
      onOk={props.onClose}>

      <div className="alert alert-warning marginBottom20" role="alert">
        <p>{getInstructions()}</p>
      </div>

      <div className="text-left">
        {
          [...Array(batches)].map((e, i) => {
            let lower = i * size + 1;
            let upper = (i + 1) * size;
            upper = upper > count ? count : upper;
            return (
              <div key={i} className="clickable" onClick={() => {
                onExport(i);
              }}>
                <div className="form-section">
                  <div className="pull-right">
                    <FontAwesome name="download"/>
                  </div>
                  <div>{getLabel()}&nbsp;&nbsp;&nbsp;{lower} - {upper}</div>
                </div>
              </div>
            )
          })
        }
      </div>

    </Alert>

  );

};

export default ExportDialog;