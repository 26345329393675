import React from 'react';
import {ApiResponse, Button, Form, FullScreenModal, InputWrap, InputCheckbox, GlobalMessage, InputSelect, InputText, InputDatePicker} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import User from "../models/User";
import ApiIndex from "../models/ApiIndex";
import Divider from "../components/Divider";

export type UserDialogProps = DialogProps & {
  user: User;
  fields: string | null;
  onSave: (response: ApiResponse<User>) => void;
  onCancel: () => void;
};

export type UserDialogState = DialogState & {
  user: User;
};

export default class UserDialog extends Dialog<UserDialogProps, UserDialogState> {

  constructor(props: UserDialogProps) {
    super(props);
    this.setInitialState({
      user: this.props.user
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let user = this.state.user;
      if (!user.key) {
        this.state.index?.createUser(user, {
          next: this.onSave,
          error: this.onApiError
        }, this.props.fields);
      }
      else {
        user.save({
          next: this.onSave,
          error: this.onApiError
        }, this.props.fields);
      }
    }
  }

  onSave(response: ApiResponse<User>) {
    this.enable();
    this.props.onSave(response);
  }

  onCancel() {
    this.enable();
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let user = this.state.user;

    return (

      <div>

        <FullScreenModal title={!user.key ? 'New User' : 'Edit User'} onHide={this.onCancel}>

          <p className="lead">Please enter the details of this user below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <div className="row">
                <div className="col-md-4">
                  <InputText
                    id="user.firstName"
                    label="First Name"
                    value={user.firstName}
                    error={this.state.errors?.['request.firstName']}
                    onChange={this.onFieldChange}
                    className="lg"
                    required/>
                </div>
                <div className="col-md-4">
                  <InputText
                    id="user.middleName"
                    label="Middle Name"
                    value={user.middleName}
                    error={this.state.errors?.['request.middleName']}
                    onChange={this.onFieldChange}
                    className="lg"/>
                </div>
                <div className="col-md-4">
                  <InputText
                    id="user.lastName"
                    label="Last Name"
                    value={user.lastName}
                    error={this.state.errors?.['request.lastName']}
                    onChange={this.onFieldChange}
                    className="lg"
                    required/>
                </div>
              </div>

              <InputText
                id="user.username"
                label="Username"
                help="An optional alphanumeric username to login with, which can be used instead of email address (eg joebloggs)."
                value={user.username}
                error={this.state.errors?.['request.username']}
                onChange={this.onFieldChange}/>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="user.emailAddress"
                    label="Email Address"
                    placeholder="eg bob@example.com"
                    value={user.emailAddress}
                    error={this.state.errors?.['request.emailAddress']}
                    onChange={this.onFieldChange}
                    required/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="user.phoneNumber"
                    label="Phone Number"
                    placeholder="eg 0401 222 333"
                    value={user.phoneNumber}
                    error={this.state.errors?.['request.phoneNumber']}
                    onChange={this.onFieldChange}/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputDatePicker
                    id="user.dob"
                    label="Date of Birth"
                    value={user.dob}
                    dateFormat="DD/MM/YYYY"
                    utcOffset={0}
                    error={this.state.errors?.['request.phoneNumber']}
                    onChange={this.onFieldChange}/>
                </div>
                <div className="col-md-6">
                </div>
              </div>

              <InputText
                id="user.password"
                label="Password"
                value={user.password}
                error={this.state.errors?.['request.password']}
                onChange={this.onFieldChange}
                rendered={!user.key}
                required/>

              <Divider/>

              <div className="row">
                <div className="col-md-12">
                  <InputText
                    id="user.address.street"
                    label="Street"
                    value={user.address!.street}
                    error={this.state.errors?.['request.address.street']}
                    onChange={this.onFieldChange}/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="user.address.suburb"
                    label="Suburb"
                    value={user.address!.suburb}
                    error={this.state.errors?.['request.address.suburb']}
                    onChange={this.onFieldChange}/>
                </div>
                <div className="col-md-3">
                  <InputText
                    id="user.address.state"
                    label="State"
                    value={user.address!.state}
                    error={this.state.errors?.['request.address.state']}
                    onChange={this.onFieldChange}/>
                </div>
                <div className="col-md-3">
                  <InputText
                    id="user.address.postcode"
                    label="Postcode"
                    value={user.address!.postcode}
                    error={this.state.errors?.['request.address.postcode']}
                    onChange={this.onFieldChange}/>
                </div>
                <div className="col-md-12">
                  <InputText
                    id="user.address.country"
                    label="Country"
                    value={user.address!.country}
                    error={this.state.errors?.['request.address.country']}
                    onChange={this.onFieldChange}/>
                </div>
              </div>

              <Divider/>

              <InputWrap label="Verification, Declaration and Compliance">

                <InputCheckbox
                  id="user.identityVerified"
                  value={user.identityVerified}
                  error={this.state.errors?.['request.identityVerified']}
                  onChange={this.onFieldChange}>Identity Verified</InputCheckbox>

                <InputCheckbox
                  id="user.australianResidencyDeclared"
                  value={user.australianResidencyDeclared}
                  error={this.state.errors?.['request.australianResidencyDeclared']}
                  onChange={this.onFieldChange}>Australian Residency Declared</InputCheckbox>

                <InputCheckbox
                  id="user.noCriminalRecordDeclared"
                  value={user.noCriminalRecordDeclared}
                  error={this.state.errors?.['request.noCriminalRecordDeclared']}
                  onChange={this.onFieldChange}>No Criminal Record Declared</InputCheckbox>

                <InputCheckbox
                  id="user.compliant"
                  value={user.compliant}
                  error={this.state.errors?.['request.compliant']}
                  onChange={this.onFieldChange}
                  disabled>Compliant for purchasing subscriptions and ownership Scheme Tokens</InputCheckbox>

              </InputWrap>

              <Divider/>

              <InputWrap label="Premium Content">

                <InputCheckbox
                  id="user.applePremiumContentSingleAllowed"
                  value={user.applePremiumContentSingleAllowed}
                  error={this.state.errors?.['request.applePremiumContentSingleAllowed']}
                  onChange={this.onFieldChange}>Apple Single Premium Content Is Allowed</InputCheckbox>

                <InputCheckbox
                  id="user.applePremiumContentRecurringAllowed"
                  value={user.applePremiumContentRecurringAllowed}
                  error={this.state.errors?.['request.applePremiumContentRecurringAllowed']}
                  onChange={this.onFieldChange}>Apple Recurring Premium Content Is Allowed</InputCheckbox>

                <InputCheckbox
                  id="user.googlePremiumContentSingleAllowed"
                  value={user.googlePremiumContentSingleAllowed}
                  error={this.state.errors?.['request.googlePremiumContentSingleAllowed']}
                  onChange={this.onFieldChange}>Google Single Premium Content Is Allowed</InputCheckbox>

                <InputCheckbox
                  id="user.googlePremiumContentRecurringAllowed"
                  value={user.googlePremiumContentRecurringAllowed}
                  error={this.state.errors?.['request.googlePremiumContentRecurringAllowed']}
                  onChange={this.onFieldChange}>Google Recurring Premium Content Is Allowed</InputCheckbox>

              </InputWrap>

              <Divider/>

              <div className="row">
                <div className="col-md-6">
                  <InputSelect
                    id="user.type"
                    label="Type"
                    value={user.type}
                    error={this.state.errors?.['request.type']}
                    options={index.userTypes}
                    onChange={this.onFieldChange}
                    required/>
                </div>
                <div className="col-md-6">
                  <InputSelect
                    id="user.status"
                    label="Status"
                    value={user.status}
                    options={index.userStatuses}
                    onChange={this.onFieldChange}
                    disabled/>
                </div>
              </div>

            </Panel>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Save Changes</Button>
              <Button
                onClick={this.onCancel}
                bsSize="large">Cancel</Button>
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
