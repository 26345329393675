import React from 'react';
import {ApiResponse, Button, Confirmable, ImageWithUpload, InputDatePicker, InputInlineEditor, InputText, InputWrap, StatusButton, VideoWithUpload} from 'startupbox-react';
import Panel from '../components/Panel';
import Horse from "../models/Horse";
import ApiIndex from "../models/ApiIndex";
import Collectible from "../models/Collectible";
import SectionHeader from "../components/SectionHeader";
import CollectibleStatus from "../models/CollectibleStatus";
import CollectibleDialog from "./CollectibleDialog";
import CollectibleNameDialog from "./CollectibleNameDialog";
import CollectibleBadgeDialog from "./CollectibleBadgeDialog";
import CollectibleMediaDialog from "./CollectibleMediaDialog";
import Partner from "../models/Partner";

type CollectibleDetailsPanelProps = {
  index: ApiIndex,
  horse?: Horse,
  partner?: Partner,
  collectible: Collectible,
  onSave: (response: ApiResponse<Collectible>) => void,
  onDelete: () => void,
  onChangeFeatured: (option: any) => void
  onChangeStatus: (status: CollectibleStatus) => void
};

const CollectibleDetailsPanel = (props: CollectibleDetailsPanelProps) => {

  let index = props.index;
  let horse = props.horse;
  let partner = props.partner;
  let collectible = props.collectible;
  let onSave = props.onSave;
  let onDelete = props.onDelete;
  let onChangeFeatured = props.onChangeFeatured;
  let onChangeStatus = props.onChangeStatus;

  return (

    <Panel>

      {
        collectible.isModifyAllowed() &&
        <Confirmable
          renderConfirm={(onOk, onCancel) => {
            return (
              <CollectibleDialog
                index={index}
                horse={horse}
                partner={partner}
                collectible={collectible}
                onSave={(response) => {
                  onSave(response);
                  onOk();
                }}
                onDelete={() => {
                  onDelete();
                  onOk();
                }}
                onCancel={onCancel}/>
            );
          }}
          renderTrigger={(onShow) => {
            return (
              <Button
                icon="external-link"
                bsStyle="primary"
                bsSize="xs"
                className="pull-right"
                onClick={onShow}>Edit</Button>
            );
          }}/>
      }

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="collectible.name"
            label="Collectible Name"
            value={collectible.name}
            readOnly/>
        </div>
        <div className="col-md-6">
          {
            !collectible.isModifyAllowed() && collectible.isModifyNameAllowed() &&
            <Confirmable
              renderConfirm={(onOk, onCancel) => {
                return (
                  <CollectibleNameDialog
                    collectible={collectible}
                    onSave={(response) => {
                      onSave(response);
                      onOk();
                    }}
                    onCancel={onCancel}/>
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <Button
                    icon="external-link"
                    bsStyle="primary"
                    bsSize="xs"
                    className="pull-right"
                    onClick={onShow}>Edit</Button>
                );
              }}/>
          }
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="collectible.badgeLabel"
            label="Badge"
            value={collectible.badgeLabel}
            readOnly/>
        </div>
        <div className="col-md-6">
          {
            !collectible.isModifyAllowed() && collectible.isModifyBadgeAllowed() &&
            <Confirmable
              renderConfirm={(onOk, onCancel) => {
                return (
                  <CollectibleBadgeDialog
                    collectible={collectible}
                    onSave={(response) => {
                      onSave(response);
                      onOk();
                    }}
                    onCancel={onCancel}/>
                );
              }}
              renderTrigger={(onShow) => {
                return (
                  <Button
                    icon="external-link"
                    bsStyle="primary"
                    bsSize="xs"
                    className="pull-right"
                    onClick={onShow}>Edit</Button>
                );
              }}/>
          }
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputDatePicker
            id="collectible.startAt"
            label="Start Date"
            value={collectible.startAt}
            dateFormat="DD/MM/YYYY"
            readOnly/>
        </div>
        <div className="col-xs-6 text-right">
          <InputDatePicker
            id="collectible.endAt"
            label="End Date"
            renderReadOnlyFn={() => 'No End Date'}
            dateFormat="DD/MM/YYYY"
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="collectible.unitCount"
            label="Number of Units"
            value={collectible.getCountText()}
            readOnly/>
        </div>
        <div className="col-xs-6 text-right">
          <InputText
            id="collectible.unitPrice"
            label="Unit Price"
            value={collectible.unitPrice}
            format="currency"
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <InputText
            id="collectible.limitPerUser"
            label="Limit per User"
            value={collectible.limitPerUser ? collectible.limitPerUser : 'Unlimited'}
            readOnly/>
        </div>
        <div className="col-md-6 text-right">
          <InputText
            id="collectible.paymentAccountProductId"
            label="Stripe Product Id"
            value={collectible.paymentAccountProductId ? collectible.paymentAccountProductId : 'Not Created Yet'}
            readOnly/>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <InputWrap id="featured" label="Featured">
            <div>
              <StatusButton
                id="featured"
                value={collectible.getFeaturedOption()}
                options={collectible.getFeaturedOptions()}
                onEdit={collectible.isModifyFeaturedAllowed() ? onChangeFeatured : null}/>
            </div>
          </InputWrap>
        </div>
        <div className="col-xs-6 text-right">
          <InputWrap id="status" label="Status">
            <div>
              <StatusButton
                id="status"
                value={collectible.status}
                options={index.collectibleStatuses}
                onEdit={collectible.isModifyStatusAllowed() ? onChangeStatus : null}/>
            </div>
          </InputWrap>
        </div>
      </div>

      <SectionHeader
        title="Media"
        divider
        actions={
          <>
            {
              !collectible.isModifyAllowed() && collectible.isModifyMediaAllowed() &&
              <Confirmable
                renderConfirm={(onOk, onCancel) => {
                  return (
                    <CollectibleMediaDialog
                      collectible={collectible}
                      onSave={(response) => {
                        onSave(response);
                        onOk();
                      }}
                      onCancel={onCancel}/>
                  );
                }}
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="external-link"
                      bsStyle="primary"
                      bsSize="xs"
                      className="pull-right"
                      onClick={onShow}>Edit</Button>
                  );
                }}/>
            }
          </>
        }/>

      <ImageWithUpload
        id="collectible.coverImage"
        label="Cover Image"
        files={collectible.coverImage ? [collectible.coverImage] : []}
        className="images-across-1"
        lightbox
        readOnly/>

      <VideoWithUpload
        id="collectible.promotionalVideos"
        label="Promotional Videos"
        multiple
        files={collectible.promotionalVideos || []}
        readOnly/>

      <ImageWithUpload
        id="collectible.promotionalImages"
        label="Promotional Images"
        multiple
        lightbox
        files={collectible.promotionalImages || []}
        readOnly/>

      <SectionHeader title="Terms" divider/>

      {
        collectible.hasTerms() ?
          <InputInlineEditor
            id="collectible.terms.body"
            elementId="details-terms"
            label="Terms"
            value={collectible.terms?.body}
            readOnly/> :
          <InputText
            id="collectible.terms.body"
            label="Terms"
            value={null}
            readOnly/>
      }

    </Panel>

  );

}

export default CollectibleDetailsPanel;