export default class LegalDocumentSearchRequest {

  keywords?: string;
  key?: string;
  type?: string;
  title?: string;

  sort?: string;
  page?: string | number;
  size?: string | number;

}
