export default class EntitlementSearchRequest {

  keywords?: string;

  type?: string;
  status?: string;

  disbursementAllowed?: boolean;

  sort?: string;
  page?: string | number;
  size?: string | number;

}
