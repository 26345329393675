import React from 'react';
import {ApiResponse, Button, Form, GlobalMessage, InputText} from 'startupbox-react';
import SecurityDialog from '../components/SecurityDialog';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import Credentials from "../models/Credentials";
import AuthenticationResponse from "../models/AuthenticationResponse";
import {ApiErrorResponse} from "../models/ApiError";

export type LoginDialogProps = DialogProps & {
  onForgotPassword: () => void,
  onLogin: (response: ApiResponse<AuthenticationResponse>) => void,
  onCancel?: () => void,
};

export type LoginDialogState = DialogState & {
  credentials: Credentials
};

export default class LoginDialog extends Dialog<LoginDialogProps, LoginDialogState> {

  constructor(props: LoginDialogProps) {
    super(props);
    this.setInitialState({
      disabled: false,
      credentials: new Credentials()
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      this.state.index?.authenticate(this.state.credentials, {
        next: this.onLogin,
        error: this.onApiError
      });
    }
  }

  onLogin(response: ApiResponse<AuthenticationResponse>) {
    this.enable();
    if (this.props.onLogin) {
      this.props.onLogin(response);
    }
  }

  onCancel() {
    this.enable();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onApiError(response: ApiErrorResponse) {
    this.enable();
    super.onApiError(response);
    this.setState({
      globalMessage: 'Invalid username and password.'
    });
  }

  render() {

    return (

      <div>

        <SecurityDialog>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <h1 className="title">Login to BTX</h1>
            <p className="lead">Please enter your credentials below.</p>

            <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

            <InputText
              id="credentials.username"
              label="Username"
              placeholder=""
              value={this.state.credentials.username}
              error={this.state.errors?.['request.username']}
              onChange={this.onFieldChange}
              required/>

            <InputText
              id="credentials.password"
              label="Password"
              placeholder=""
              type="password"
              value={this.state.credentials.password}
              error={this.state.errors?.['request.password']}
              onChange={this.onFieldChange}
              required/>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Login</Button>
            </div>

            <p><a href="/" onClick={this.props.onForgotPassword}>I have forgotten my password</a></p>

          </Form>

        </SecurityDialog>

      </div>

    );

  }

}