import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, Form, FullScreenModal, GlobalMessage, InputSelect} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import ApiIndex from "../models/ApiIndex";
import Pack, {PACK_STANDARD_FIELDS} from "../models/Pack";
import Subscribable from "../models/Subscribable";
import SubscribableSearchRequest from "../models/SubscribableSearchRequest";

export type PackAddItemDialogProps = DialogProps & {
  index: ApiIndex;
  pack: Pack;
  onSave: (response: ApiResponse<Pack>) => void;
  onCancel: () => void;
};

export type PackAddItemDialogState = DialogState & {
  subscribables?: Array<Subscribable> | null;
  subscribable: Subscribable;
};

export default class PackAddItemDialog extends Dialog<PackAddItemDialogProps, PackAddItemDialogState> {

  constructor(props: PackAddItemDialogProps) {
    super(props);
    this.setInitialState({});
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let pack = this.props.pack;
      let subscribable = this.state.subscribable;
      pack.addItem(subscribable, {
        next: this.props.onSave,
        error: this.onApiError
      }, PACK_STANDARD_FIELDS);
    }
  }

  onSearchSubscribables(inputValue: string) {
    let subscribableSearchRequest = new SubscribableSearchRequest();
    subscribableSearchRequest.keywords = inputValue;
    subscribableSearchRequest.partnerKey = this.props.pack.partnerKey;
    subscribableSearchRequest.salesChannel = 'PACK';
    subscribableSearchRequest.inPack = false;
    this.props.index?.searchSubscribables(subscribableSearchRequest, {
      next: this.setSubscribables,
      error: this.onApiError
    }, null);
  }

  setSubscribables(response: ApiSearchResponse<Subscribable>) {
    this.setState({
      subscribables: response.resource.values
    })
  }

  onCancel() {
    this.props.onCancel();
  }

  renderWithIndex(index: ApiIndex) {

    let subscribable = this.state.subscribable;

    return (

      <div>

        <FullScreenModal title="Add Tranche to Pack" onHide={this.onCancel}>

          <p className="lead">Please select a subscribable to add to the pack.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <InputSelect
                id="subscribable"
                label="Item"
                value={subscribable}
                error={this.state.errors?.['request.subscribable']}
                onChange={this.onFieldChange}
                options={this.state.subscribables}
                className="lg"
                enhanced={{onInputChange: this.onSearchSubscribables}}
                required/>

            </Panel>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Submit</Button>
              <Button
                onClick={this.onCancel}
                bsSize="large">Cancel</Button>
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
