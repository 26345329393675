import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, Confirmable, Form, InputText, SearchResult} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from './PartnerAbstractPage';
import Partner from "../models/Partner";
import ApiIndex from "../models/ApiIndex";
import Routes from "../Routes";
import Membership, {MEMBERSHIP_STANDARD_FIELDS} from "../models/Membership";
import MembershipSearchRequest from "../models/MembershipSearchRequest";
import MembershipTable from "../membership/MembershipTable";
import MembershipDialog from "../membership/MembershipDialog";
import BlockchainAttributeGroup from "../models/BlockchainAttributeGroup";

let FIELDS = MEMBERSHIP_STANDARD_FIELDS;

export type PartnerMembershipsPageProps = PartnerAbstractPageProps & {};

export type PartnerMembershipsPageState = PartnerAbstractPageState & {
    membershipSearchRequest: MembershipSearchRequest;
    memberships: SearchResult<Membership> | null;
}

export default class PartnerMembershipsPage extends PartnerAbstractPage<PartnerMembershipsPageProps, PartnerMembershipsPageState> {

    constructor(props: PartnerMembershipsPageProps) {
        super(props);
        this.setInitialState({
            membershipSearchRequest: new MembershipSearchRequest(),
            memberships: null,
        });
    }

    onEnter() {
        this.setState({
            membershipSearchRequest: {
                keywords: this.query('keywords'),
                sort: this.query('sort'),
                page: parseInt(this.query('page') || '0'),
            }
        }, this.onLoad);
    }

    onSearch() {
        this.path()
            .param('keywords', this.state.membershipSearchRequest.keywords)
            .param('sort', this.state.membershipSearchRequest.sort)
            .navigate(this.onLoad);
    }

    onLoadPartner(partner: Partner) {
        this.searchMemberships(this.state.membershipSearchRequest.page + 1);
    }

    searchMemberships(page: number) {
        let partner = this.state.partner;
        if (partner) {
            let membershipSearchRequest = this.state.membershipSearchRequest;
            membershipSearchRequest.page = page - 1;
            partner.searchMemberships(membershipSearchRequest, {
                next: this.setMemberships,
                error: this.onApiError
            }, FIELDS);
        }
    }

    onSave(response: ApiResponse<Membership>) {
        this.onView(response.resource!);
    }

    onView(membership: Membership) {
        let partner = this.state.partner;
        if (partner) {
            this.navigate(Routes.partner.membership(partner.key!, membership.key!));
        }
    }

    setMemberships(response: ApiSearchResponse<Membership>) {
        this.setState({
            memberships: response.resource
        })
    }

    renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

        return (

            <div className="row">

                <div className="col-md-8 col-md-offset-2">

                    <PageHeader
                        title={partner.name}
                        subTitle="Memberships"
                        lead="This area allows you to manage the memberships offered for sale for this partner, not tied to a horse. Users of the app may purchase these memberships to view, own and trade."/>

                    <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
                        <InputText
                            id="membershipSearchRequest.keywords"
                            placeholder="Keywords"
                            value={this.state.membershipSearchRequest.keywords}
                            onChange={this.onFieldChange}
                            className="wide"/>
                        <span className="actions">
              <Button
                  icon="search"
                  bsStyle="primary"
                  type="submit">Search</Button>
                            {
                                // partner.isAddMembershipAllowed() &&
                                <Confirmable
                                    renderTrigger={(onShow) => {
                                        return (
                                            <Button
                                                icon="plus"
                                                bsStyle="default"
                                                className="pull-right"
                                                onClick={onShow}>Add</Button>
                                        );
                                    }}
                                    renderConfirm={(onOk, onCancel) => {
                                        let membership: Membership = new Membership();
                                        membership.manageableMetadataAttributeGroup = new BlockchainAttributeGroup();

                                        return (
                                            <MembershipDialog
                                                index={index}
                                                partner={partner}
                                                membership={membership}
                                                onSave={(response: ApiResponse<Membership>) => {
                                                    this.onSave(response);
                                                    onOk();
                                                }}
                                                onDelete={() => {
                                                    this.searchMemberships(1);
                                                    onOk();
                                                }}
                                                onCancel={onCancel}/>
                                        );
                                    }}/>
                            }
            </span>
                    </Form>

                    <br/>

                    <MembershipTable
                        memberships={this.state.memberships}
                        sort={this.state.membershipSearchRequest.sort}
                        onReload={this.onEnter}
                        onView={this.onView}/>

                </div>

            </div>

        );

    }

};