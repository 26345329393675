import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, FileWithUpload, Form, FullScreenModal, GlobalMessage, InputSelect, SearchResult, StatusButton, Str, Table, TableHeaderCell} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from './PartnerAbstractPage';
import HorseImportRunSearchRequest from "../models/HorseImportRunSearchRequest";
import Partner from "../models/Partner";
import PartnerCollaborator from "../models/PartnerCollaborator";
import ApiIndex from "../models/ApiIndex";
import HorseImportRun from "../models/HorseImportRun";
import Routes from "../Routes";
import HorseImportRunHorse from "../models/HorseImportRunHorse";

let FIELDS = '';

export type PartnerHorseImportRunsPageProps = PartnerAbstractPageProps & {};

export type PartnerHorseImportRunsPageState = PartnerAbstractPageState & {
  horseImportRunSearchRequest: HorseImportRunSearchRequest;
  horseImportRuns: SearchResult<PartnerCollaborator> | null;
  files: any[]
}

export default class PartnerHorseImportRunsPage extends PartnerAbstractPage<PartnerHorseImportRunsPageProps, PartnerHorseImportRunsPageState> {

  constructor(props: PartnerHorseImportRunsPageProps) {
    super(props);
    this.setInitialState({
      horseImportRunSearchRequest: new HorseImportRunSearchRequest(),
      horseImportRuns: null,
      files: [],
    });
  }

  onEnter() {
    this.setState({
      horseImportRunSearchRequest: {
        status: this.query('status'),
        sort: this.query('sort'),
        page: this.query('page'),
      }
    }, this.onLoad);
  }

  onSearch() {
    this.path()
      .param('status', this.state.horseImportRunSearchRequest.status)
      .param('sort', this.state.horseImportRunSearchRequest.sort)
      .navigate(this.onLoad);
  }

  onLoadPartner(partner: Partner) {
    partner.searchHorseImportRuns(this.state.horseImportRunSearchRequest, {
      next: this.setHorseImportRuns,
      error: this.onApiError
    }, FIELDS);
  }

  onSave() {
    this.onSearch();
  }

  onAdd() {
    let partner = this.state.partner;
    let run = new HorseImportRun();
    run.horses = [HorseImportRunHorse.buildNew(this.getIndex()!)];
    partner?.addHorseImportRun(run, {
      next: (response: ApiResponse<HorseImportRun>) => {
        this.onView(response.resource!);
      },
      error: this.onApiError
    }, null);
  }

  // -------------------------------------------------------------------------------------------------------------------

  onShowUploadDialog() {
    this.setMode('upload');
  }


  onHideUploadDialog() {
    this.setMode('');
  }

  onUpload(e: any) {
    this.preventDefault(e);
    if (this.isEnabled()) {
      this.disable();
      let file = this.state.files[0];
      let partner = this.state.partner;
      partner?.uploadHorseImportRun(file, {
        next: (response: ApiResponse<HorseImportRun>) => {
          this.onView(response.resource!);
        },
        error: this.onApiError
      }, null);
    }
  }

  onAddFile(acceptedFiles: any) {
    for (let file of acceptedFiles) {
      this.setState({
        files: [file]
      });
    }
  }

  onRemoveFile() {
    this.setState({
      files: []
    });
  }

  // -------------------------------------------------------------------------------------------------------------------

  onView(horseImportRun: HorseImportRun) {
    let partner = this.state.partner!;
    this.navigate(Routes.partner.horseImportRun(partner.key!, horseImportRun.key!));
  }

  setHorseImportRuns(response: ApiSearchResponse<PartnerCollaborator>) {
    this.setState({
      horseImportRuns: response.resource
    })
  }

  renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

    let mode = this.state.mode;

    return (

      <div className="row">

        <div className="col-md-12">

          {
            mode === 'upload' &&
            <FullScreenModal title="Upload Horses" onHide={this.onHideUploadDialog}>

              <Form onSubmit={this.onUpload} disabled={this.isDisabled()}>

                <p className="lead">Please select the file to import below.</p>

                <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

                <FileWithUpload
                  id="file"
                  type="file"
                  fileOpenTarget="_blank"
                  files={this.state.files}
                  onAdd={this.onAddFile}
                  onRemove={this.onRemoveFile}
                  multiple={false}/>

                <div className="actions">
                  <Button
                    bsStyle="primary"
                    bsSize="large"
                    type="submit"
                    disabled={this.state.files.length === 0 || this.isDisabled()}>Upload Import</Button>
                  <Button
                    onClick={this.onHideUploadDialog}
                    bsSize="large">Cancel</Button>
                </div>

              </Form>

            </FullScreenModal>
          }

          <PageHeader
            title={partner.name}
            subTitle="Horse Import Runs"
            lead="This area allows you to manage the import runs for this partner. From here you may add a run and kick off an import when ready."/>

          <Form className="form-inline" onSubmit={this.fn(this.onSearch)}>
            <InputSelect
              id="horseImportRunSearchRequest.status"
              blank="Status"
              value={this.state.horseImportRunSearchRequest.status}
              onChange={this.onFieldChange}
              options={index.horseImportRunStatuses}/>
            <span className="actions">
              <Button
                icon="search"
                bsStyle="primary"
                type="submit">Search</Button>
              {
                partner.isAddHorseImportRunAllowed() &&
                <Button
                  icon="plus"
                  bsStyle="default"
                  className="pull-right"
                  onClick={this.onAdd}>Add</Button>
              }
              {
                partner.isUploadHorseImportRunAllowed() &&
                <Button
                  icon="plus"
                  bsStyle="default"
                  className="pull-right"
                  onClick={this.onShowUploadDialog}>Upload</Button>
              }
            </span>
          </Form>

          <br/>

          <Table
            items={this.state.horseImportRuns}
            sort={this.state.horseImportRunSearchRequest.sort}
            onReload={this.onEnter}
            blankMessage="No horse import runs found"
            renderHeaderRow={() => {
              return (
                <tr>
                  <TableHeaderCell label="Created At" sort="createdAt"/>
                  <TableHeaderCell label="Status" sort="status" className="col-md-2 text-right"/>
                </tr>
              );
            }}
            renderItemRow={(horseImportRun: HorseImportRun, i: number) => {
              return (
                <tr key={i} className="clickable" onClick={this.fn(this.onView, horseImportRun)}>
                  <td>
                    {Str.formatDate(horseImportRun.createdAt)}
                  </td>
                  <td className="text-right">
                    <StatusButton
                      id={'status-' + i}
                      value={horseImportRun.status}/>
                  </td>
                </tr>
              );
            }}
          />

        </div>

      </div>

    );

  }

};
