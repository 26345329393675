import React from 'react';
import {Button, Form, FullScreenModal, GlobalMessage, InputDatePicker, InputText} from 'startupbox-react';
import Panel from '../components/Panel';
import Dialog, {DialogProps, DialogState} from '../Dialog';
import ApiIndex from "../models/ApiIndex";
import Tranche from "../models/Tranche";
import Payout from "../models/Payout";

export type PayoutDialogProps = DialogProps & {
  tranche: Tranche;
  payout: Payout;
  onClose: () => void;
};

export type PayoutDialogState = DialogState & {
  payout: Payout;
};

export default class PayoutDialog extends Dialog<PayoutDialogProps, PayoutDialogState> {

  constructor(props: PayoutDialogProps) {
    super(props);
    this.setInitialState({
      payout: this.props.payout
    });
  }

  onSubmit() {
    if (this.isEnabled()) {
      this.disable();
      let payout = this.state.payout;
      if (payout.key) {
        // edit is disallowed
      }
      else {
        let tranche = this.props.tranche;
        tranche.addPayout(payout, {
          next: this.onClose,
          error: this.onApiError
        }, null);
      }
    }
  }

  onClose() {
    this.props.onClose();
  }

  renderWithIndex(index: ApiIndex) {

    let payout = this.state.payout;

    return (

      <div>

        <FullScreenModal title={!payout.key ? 'New Payout' : 'Edit Payout'} onHide={this.onClose}>

          <p className="lead">Please enter the details of the payout below.</p>

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity}/>

          <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

            <Panel>

              <div className="row">
                <div className="col-md-6">
                  <InputText
                    id="payout.description"
                    label="Description"
                    placeholder="eg Race 8 - Randwick"
                    value={payout.description}
                    error={this.state.errors?.['request.description']}
                    onChange={this.onFieldChange}
                    className="lg"
                    required/>
                </div>
                <div className="col-md-6">
                  <InputText
                    id="payout.totalAmount"
                    label="Total Amount"
                    value={payout.totalAmount}
                    format="currency"
                    error={this.state.errors?.['request.totalAmount']}
                    onChange={this.onFieldChange}
                    className="lg"
                    required/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <InputDatePicker
                    id="payout.date"
                    label="Event Date"
                    placeholder="eg race date"
                    value={payout.date}
                    error={this.state.errors?.['request.date']}
                    dateFormat="DD/MM/YYYY"
                    onChange={this.onFieldChange}
                    required/>
                </div>
                <div className="col-md-6">
                  <InputDatePicker
                    id="payout.paymentDate"
                    label="Payment Date"
                    placeholder="eg 6 months from race date"
                    value={payout.paymentDate}
                    error={this.state.errors?.['request.paymentDate']}
                    dateFormat="DD/MM/YYYY"
                    onChange={this.onFieldChange}
                    required/>
                </div>
              </div>

            </Panel>

            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Save Changes</Button>
              <Button
                onClick={this.onClose}
                bsSize="large">Cancel</Button>
            </div>

          </Form>

        </FullScreenModal>

      </div>

    );

  }

}
