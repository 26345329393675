import React from 'react';
import {Navbar} from 'react-bootstrap';
import {Link} from 'react-router';
import {Button, Environment} from 'startupbox-react';
import Context from '../Context';
import Events from '../Events';
import Routes from '../Routes';

type PrimaryNavProps = {}

const PrimaryNav = (props: PrimaryNavProps) => {

  let onShowLoginDialog = () => {
    Events.app.showLoginDialog.publish('true');
  }

  return (

    <Navbar id="primary-nav">
      <div className="row">
        <div className="col-md-12">
          <Navbar.Header>
            <Navbar.Brand>
              <span id="app-title"><a href="/">{Environment.getAppTitle()}</a></span>
              <span id="environment" className={Environment.current().name}>{Environment.current().name}</span>
            </Navbar.Brand>
            <Navbar.Toggle/>
          </Navbar.Header>
          <Navbar.Collapse id="user-nav">
            <ul className="nav navbar-nav">
              {Context.isAdmin() && <li><Link to={Routes.admin.index()}>Admin Dashboard</Link></li>}
              {Context.isAdmin() && <li><Link to={Routes.tool.index()}>Tools</Link></li>}
              {Context.isAuthenticated() && <li><Link to={Routes.account.index()}>Your Account</Link></li>}
              {!Context.isAuthenticated() && <li><Button bsSize="xs" bsStyle="primary" onClick={onShowLoginDialog}>Login</Button></li>}
            </ul>
          </Navbar.Collapse>
        </div>
      </div>
    </Navbar>

  );

};

export default PrimaryNav;