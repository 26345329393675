import Model from '../Model';
import HubSpotAsyncMessageType from "./HubSpotAsyncMessageType";
import HubSpotAsyncMessageStatus from "./HubSpotAsyncMessageStatus";

// let Rel = {
//   self: 'self',
// };

export const HUBSPOT_ASYNC_MESSAGE_LIST_FIELDS = '';
export const HUBSPOT_ASYNC_MESSAGE_STANDARD_FIELDS = '';

export default class HubSpotAsyncMessage {

  key?: string;

  createdAt?: Date;
  processedAt?: Date;
  description?: string;
  entityKey?: string;
  body?: string;
  errorMessage?: string;
  type?: HubSpotAsyncMessageType;
  status?: HubSpotAsyncMessageStatus;

  href = Model.hrefFunction();

}
