import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, SearchResult} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import Routes from '../Routes';
import Membership, {MEMBERSHIP_STANDARD_FIELDS} from "../models/Membership";
import ApiIndex from "../models/ApiIndex";
import {ApiErrorResponse} from "../models/ApiError";
import BlockchainMintableActualsTable from "../components/BlockchainMintableActualsTable";
import MembershipSearchRequest from "../models/MembershipSearchRequest";
import SectionHeader from "../components/SectionHeader";

let FIELDS = MEMBERSHIP_STANDARD_FIELDS;

export type MembershipActualsPageProps = PageProps & {};

export type AdminMembershipActualsPageState = PageState & {
  membership?: Membership | null | undefined;
  actuals?: SearchResult<Membership> | null;
};

export default class MembershipActualsPage extends Page<MembershipActualsPageProps, AdminMembershipActualsPageState> {

  constructor(props: MembershipActualsPageProps) {
    super(props);
    this.setInitialState({});
  }

  onLoad() {
    this.publish(Events.system.pageTitle, 'Membership');
    this.state.index?.findMembershipByKey(this.props.params.key, {
      next: response => {
        this.setMembership(response, () => {
          this.searchActuals(1);
        });
      },
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, FIELDS);
  }

  searchActuals(page: number) {
    let searchRequest = new MembershipSearchRequest();
    searchRequest.page = page - 1;
    this.state.membership?.searchActuals(searchRequest, {
      next: (response: ApiSearchResponse<Membership>) => {
        let actuals = response.resource;
        this.setActuals(actuals);
      },
      error: this.onApiError
    }, null);
  }

  onBack() {
    this.navigate(Routes.admin.membership(this.props.params.key))
  }

  setMembership(response: ApiResponse<Membership>, callback?: () => void) {
    this.setState({
      membership: response.resource
    }, callback);
  }

  setActuals(collectibles: SearchResult<Membership>) {
    this.setState({
      actuals: collectibles
    });
  }

  renderWithIndex(index: ApiIndex) {

    let membership = this.state.membership;
    let actuals = this.state.actuals;

    if (!membership) {
      return <></>;
    }

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title="Memberships"
            subTitle={membership.name}
            lead={
              <>
                The actual instances of this membership are shown below. Actual instances are what users buy. The characteristics
                and metadata of an actual instance are the same as the template from which it is created from.
              </>
            }/>

          <SectionHeader
            title="Membership"
            actions={
              <>
                <Button
                  icon="chevron-left"
                  iconPosition="left"
                  bsStyle="default"
                  bsSize="xs"
                  className="pull-right"
                  onClick={this.onBack}>Back To Template</Button>
              </>
            }/>

          <BlockchainMintableActualsTable
            actuals={actuals}
            onPaginate={this.searchActuals}/>

        </div>

      </div>

    );

  }

}
