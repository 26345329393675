export default class TransactionSearchRequest {

  subscribableName?: string;
  walletOwnerName?: string;

  type?: string;
  status?: string;

  sort?: string;
  page = 0;
  size?: string | number;

}
