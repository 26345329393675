import React from "react";
import HorseRacingStatus from "./HorseRacingStatus";
import HorseStatus from "./HorseStatus";
import Binary from "./Binary";
import HorseAttributeGroup from "./HorseAttributeGroup";
import Content from "./Content";
import {FileWithUpload, ImageWithUpload, InputInlineEditor, InputSelect, InputText, InputTextArea, Str, TableHeaderCell, VideoWithUpload} from "startupbox-react";
import ApiIndex from "./ApiIndex";
import HorseAttribute from "./HorseAttribute";
import HorseAttributeValue from "../horse/HorseAttributeValue";
import Model from "../Model";

export default class HorseImportRunHorse {

  name?: string;
  shortDescription?: string;
  summaryDescription?: string;
  microchipNumber?: string;
  overview?: Content = new Content();
  attributeGroup?: HorseAttributeGroup;
  racingStatus?: HorseRacingStatus;
  status?: HorseStatus;

  thumbnailImage?: Binary;
  coverImage?: Binary;
  ipfsMedia?: Binary;

  // ................................................................................................................................................

  static buildNew(index: ApiIndex) {
    let horse = new HorseImportRunHorse();
    horse.overview = new Content();
    horse.attributeGroup = new HorseAttributeGroup('Attributes');
    horse.attributeGroup.attributes.push(new HorseAttribute('Sire', index.findHorseAttributeType('STRING_SINGLE_LINE')));
    horse.attributeGroup.attributes.push(new HorseAttribute('Dam', index.findHorseAttributeType('STRING_SINGLE_LINE')));
    horse.attributeGroup.attributes.push(new HorseAttribute('Foaled', index.findHorseAttributeType('DATE')));
    horse.attributeGroup.attributes.push(new HorseAttribute('Age', index.findHorseAttributeType('INTEGER')));
    horse.attributeGroup.attributes.push(new HorseAttribute('Sex', index.findHorseAttributeType('SEX')));
    horse.attributeGroup.attributes.push(new HorseAttribute('Colour', index.findHorseAttributeType('COLOR')));
    horse.attributeGroup.attributes.push(new HorseAttribute('Place of Birth', index.findHorseAttributeType('STRING_SINGLE_LINE')));
    horse.attributeGroup.attributes.push(new HorseAttribute('Farm', index.findHorseAttributeType('STRING_SINGLE_LINE')));
    return horse;
  }

  getCols() {

    // top level attributes
    let cols = [
      new HorseImportRunHorseCols(this, 'Name', 'name', 'input-text'),
      new HorseImportRunHorseCols(this, 'Microchip Number', 'microchipNumber', 'input-text'),
      new HorseImportRunHorseCols(this, 'Summary Description', 'summaryDescription', 'input-text-area'),
      new HorseImportRunHorseCols(this, 'Overview', 'overview', 'input-inline-editor'),
      new HorseImportRunHorseCols(this, 'Racing Status', 'racingStatus', 'input-racing-status'),
      new HorseImportRunHorseCols(this, 'Status', 'status', 'input-status'),
    ];

    // attributes from attribute group (eg Sire, Dam etc)
    if (this.attributeGroup && this.attributeGroup.attributes) {
      let attributes = this.attributeGroup.attributes;
      for (let a = 0; a < attributes.length; a++) {
        let attribute = attributes[a];
        cols.push(new HorseImportRunHorseCols(this, attribute.name!, 'attributeGroup.attributes[' + a + ']', 'input-attribute'));
      }
    }

    // images
    cols.push(new HorseImportRunHorseCols(this, 'Thumbnail Image', 'thumbnailImage', 'input-image'));
    cols.push(new HorseImportRunHorseCols(this, 'Cover Image', 'coverImage', 'input-image'));
    cols.push(new HorseImportRunHorseCols(this, 'IPFS Media', 'ipfsMedia', 'input-video'));

    return cols;

  }

}

// ................................................................................................................................................

export type HorseImportRunHorseColType = 'input-text' | 'input-text-area' | 'input-inline-editor' | 'input-racing-status' | 'input-status' | 'input-file' | 'input-image' | 'input-video' | 'input-attribute';

export class HorseImportRunHorseCols {

  horse: HorseImportRunHorse;
  label: string;
  fieldName: keyof HorseImportRunHorse | string;
  type: HorseImportRunHorseColType;

  constructor(horse: HorseImportRunHorse, name: string, fieldName: keyof HorseImportRunHorse | string, type: HorseImportRunHorseColType) {
    this.horse = horse;
    this.label = name;
    this.fieldName = fieldName;
    this.type = type;
  }

  resolveClassName() {
    return 'col-' + this.type + ' col-' + Str.replaceAll(this.label, ' ', '-').toLowerCase();
  }

  renderHeaderCell(c: number) {
    return (
      <TableHeaderCell
        key={c}
        label={this.label}
        className={this.resolveClassName()}/>
    );
  }

  renderHorseCell(index: ApiIndex, errors: { [key: string]: string } | undefined, rowIndex: number, colIndex: number, prefix: string, onFieldChange: ((e: Event, callback?: () => void) => void) | undefined) {

    let fieldName = this.fieldName;
    let errorKey = 'request.horses[' + rowIndex + '].' + fieldName;
    let value: any = Model.getFieldValue(this.horse, fieldName);
    let field: any = 'Type not implemented: ' + this.type;
    let isReadOnly = !onFieldChange;

    if (this.type === 'input-text') {
      field = <InputText
        id={prefix + fieldName}
        value={value}
        error={errors ? errors[errorKey] : null}
        onChange={onFieldChange}
        readOnly={isReadOnly}/>;
    }
    else if (this.type === 'input-text-area') {
      field = <InputTextArea
        id={prefix + fieldName}
        value={value}
        onChange={onFieldChange}
        readOnly={isReadOnly}/>;
    }
    else if (this.type === 'input-inline-editor') {
      field = <InputInlineEditor
        id={prefix + fieldName + '.body'}
        elementId={prefix + fieldName + '-' + colIndex}
        value={value?.body}
        onChange={onFieldChange}
        readOnly={isReadOnly}/>;
    }
    else if (this.type === 'input-racing-status') {
      field = <InputSelect
        id={prefix + fieldName}
        value={value}
        error={errors ? errors[errorKey] : null}
        options={index.horseRacingStatuses}
        onChange={onFieldChange}
        readOnly={isReadOnly}/>;
    }
    else if (this.type === 'input-status') {
      field = <InputSelect
        id={prefix + fieldName}
        value={value}
        options={index.horseStatuses}
        onChange={onFieldChange}
        readOnly={isReadOnly}/>;
    }
    else if (this.type === 'input-file') {
      field = <FileWithUpload
        id={prefix + fieldName}
        upload
        files={value ? [value] : []}
        onChange={e => {
          let files = e.target.value;
          let file = files && files.length > 0 ? files[0] : null;
          if (!file || !!file.externalReference) {
            onFieldChange?.({
              target: {
                id: e.target.id,
                value: file
              }
            } as any);
          }
        }}
        readOnly={isReadOnly}/>;
    }
    else if (this.type === 'input-image') {
      field = <ImageWithUpload
        id={prefix + fieldName}
        upload
        files={value ? [value] : []}
        onChange={e => {
          let files = e.target.value;
          let file = files && files.length > 0 ? files[0] : null;
          if (!file || !!file.externalReference) {
            onFieldChange?.({
              target: {
                id: e.target.id,
                value: file
              }
            } as any);
          }
        }}
        readOnly={isReadOnly}/>;
    }
    else if (this.type === 'input-video') {
      field = <VideoWithUpload
        id={prefix + fieldName}
        accept="video/mp4"
        upload
        files={value ? [value] : []}
        onChange={e => {
          let files = e.target.value;
          let file = files && files.length > 0 ? files[0] : null;
          if (!file || !!file.externalReference) {
            onFieldChange?.({
              target: {
                id: e.target.id,
                value: file
              }
            } as any);
          }
        }}
        readOnly={isReadOnly}/>;
    }
    else if (this.type === 'input-attribute') {
      field = <HorseAttributeValue
        hideLabel
        attribute={value}
        onChange={!isReadOnly ? (stringValue: string) => {
          value.stringValue = stringValue;
          onFieldChange?.({
            target: {
              id: '_',
              value: undefined
            }
          } as any);
        } : undefined}/>;
    }

    return (
      <td key={prefix + fieldName} className={this.resolveClassName()}>
        {field}
      </td>
    );

  }

}
