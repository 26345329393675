import React from 'react';
import {Nav, NavItem} from 'react-bootstrap';

type AdminDashboardTabsProps = {
  active: string;
  onSelect: (value?: any) => void;
};

const AdminDashboardTabs = (props: AdminDashboardTabsProps) => {

  return (
    <div id="admin-dashboard-tabs" className="tabs">
      <Nav id="admin-dashboard-tabs-nav" bsStyle="pills" justified activeKey={props.active} onSelect={props.onSelect}>
        <NavItem eventKey="users">Users</NavItem>
        <NavItem eventKey="devices">Devices</NavItem>
        <NavItem eventKey="subscriptions">Subscriptions</NavItem>
      </Nav>
    </div>
  );

}

export default AdminDashboardTabs;
