import React from 'react';
import {ApiResponse, ApiSearchResponse, Button, Confirm, Confirmable, GlobalMessage} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import PartnerAbstractPage, {PartnerAbstractPageProps, PartnerAbstractPageState} from './PartnerAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Partner from "../models/Partner";
import Collectible, {COLLECTIBLE_STANDARD_FIELDS} from "../models/Collectible";
import BlockchainMintStatus from "../models/BlockchainMintStatus";
import Routes from "../Routes";
import CollectibleStatus from "../models/CollectibleStatus";
import BlockchainMintable from "../models/BlockchainMintable";
import CollectibleDetailsPanel from "../collectible/CollectibleDetailsPanel";
import BlockchainMintablePanel from "../blockchain/BlockchainMintablePanel";
import SectionHeader from "../components/SectionHeader";
import PartnerHorseCollectibleGiftToUserDialog from "./PartnerHorseCollectibleGiftToUserDialog";

let FIELDS = COLLECTIBLE_STANDARD_FIELDS;

export type PartnerCollectiblePageProps = PartnerAbstractPageProps & {};

export type PartnerCollectiblePageState = PartnerAbstractPageState & {
  collectible?: Collectible | null | undefined;
}

export default class PartnerCollectiblePage extends PartnerAbstractPage<PartnerCollectiblePageProps, PartnerCollectiblePageState> {

  constructor(props: PartnerCollectiblePageProps) {
    super(props);
    this.setInitialState({
      collectible: null,
    });
  }

  onLoadPartner(partner: Partner) {
    this.loadCollectible();
  }

  loadCollectible() {
    let partner = this.state.partner;
    partner?.findCollectibleByKey(this.props.params.collectibleKey, {
      next: this.setCollectible,
      error: this.onApiError
    }, FIELDS);
  }

  onReloadCollectible(response: ApiResponse<BlockchainMintable>) {
    this.setCollectible(response as ApiResponse<Collectible>);
  }

  onSave(response: ApiResponse<Collectible>) {
    this.setCollectible(response);
  }

  onDelete() {
    this.navigate(Routes.partner.collectibles(this.state.partner?.key!))
  }

  onChangeFeatured(option: any) {
    this.state.collectible?.modifyFeatured(option.key === 'true', {
      next: this.setCollectible,
      error: this.onApiError
    }, FIELDS);
  }

  onChangeStatus(status: CollectibleStatus) {
    this.state.collectible?.modifyStatus(status, {
      next: this.setCollectible,
      error: this.onApiError
    }, FIELDS);
  }

  onChangeMintStatus(mintStatus: BlockchainMintStatus) {
    this.state.collectible?.modifyMintStatus(mintStatus, {
      next: this.setCollectible,
      error: this.onApiError
    }, FIELDS);
  }

  setCollectible(response: ApiSearchResponse<Collectible>) {
    this.setState({
      collectible: response.resource
    });
  }

  onShowActuals() {
    this.navigate(Routes.partner.collectible_actuals(this.state.partner!.key!, this.props.params.collectibleKey))
  }

  renderWithIndexAndPartner(index: ApiIndex, partner: Partner) {

    let collectible = this.state.collectible;

    if (!collectible) {
      return <></>;
    }

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity} fieldErrors={(this.state.errors as any)._}/>

          <PageHeader
            title={collectible.name}
            lead={
              <>
                The details of this collectible is shown below. This collectible is currently <strong>{collectible!.status!.name}</strong> and <strong>{collectible!.mintStatus!.name}</strong>. Please note that
                users can only see collectibles in the Marketplace if the collectible is both public and minted.
              </>
            }/>

          <SectionHeader
            title="Collectible"
            actions={
              <>
                {
                  collectible.isSendReviewRequestNotificationAllowed() &&
                  <Confirmable
                    renderConfirm={(onOk, onCancel) => {
                      return (
                        <Confirm
                          title="Send Review Request"
                          message="Would you like to send a review request to BTX for this collectible to be minted and published on the public blockchain?"
                          onOk={() => {
                            collectible!.sendReviewRequestNotification({
                              next: (response) => {
                              },
                              error: this.onApiError
                            }, FIELDS);
                            onOk();
                          }}
                          onCancel={onCancel}/>
                      );
                    }}
                    renderTrigger={(onShow) => {
                      return (
                        <Button
                          icon="external-link"
                          bsStyle="primary"
                          bsSize="xs"
                          onClick={onShow}>Send Review Request</Button>
                      );
                    }}/>
                }
                {
                  collectible.isGiftToUserAllowed() &&
                  <Confirmable
                    renderConfirm={(onOk, onCancel) => {
                      return (
                        <PartnerHorseCollectibleGiftToUserDialog
                          index={index}
                          collectible={collectible!}
                          onSave={(response) => {
                            onOk();
                            this.setGlobalMessage("Successfully gifted!", "success")
                            this.loadCollectible();
                          }}
                          onCancel={onCancel}/>
                      );
                    }}
                    renderTrigger={(onShow) => {
                      return (
                        <Button
                          icon="external-link"
                          bsStyle="primary"
                          bsSize="xs"
                          onClick={onShow}>Gift to User</Button>
                      );
                    }}/>
                }
                {
                  collectible.isMinted() &&
                  <Button
                    icon="chevron-right"
                    bsStyle="primary"
                    bsSize="xs"
                    onClick={this.onShowActuals}>Show Actuals</Button>
                }
              </>
            }/>

          <CollectibleDetailsPanel
            index={index}
            partner={partner}
            collectible={collectible}
            onSave={this.onSave}
            onDelete={this.onDelete}
            onChangeFeatured={this.onChangeFeatured}
            onChangeStatus={this.onChangeStatus}/>

          <BlockchainMintablePanel
            type="Collectible"
            entity={collectible}
            entityFields={FIELDS}
            statuses={index.mintChooseableStatuses}
            errors={this.state.errors}
            onReload={this.onReloadCollectible}
            onChangeMintStatus={this.onChangeMintStatus}/>

        </div>

      </div>

    );

  }

};
