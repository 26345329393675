import React from 'react';
import {ApiResponse} from 'startupbox-react';
import PageHeader from '../components/PageHeader';
import Events from '../Events';
import Page, {PageProps, PageState} from '../Page';
import User from "../models/User";
import ApiIndex from "../models/ApiIndex";
import WalletTabs from "../wallet/WalletTabs";
import Routes from "../Routes";
import {ApiErrorResponse} from "../models/ApiError";
import Wallet from "../models/Wallet";
import WalletPanel from "../wallet/WalletPanel";

export type UserWalletAbstractPageProps = PageProps & {};

export type UserWalletAbstractPageState = PageState & {
  user?: User;
  wallet?: Wallet;
};

export default class UserWalletAbstractPage<P extends UserWalletAbstractPageProps, S extends UserWalletAbstractPageState> extends Page<P, S> {

  activeTab: string | null = null;

  constructor(props: P) {
    super(props);
    let initialState = {} as S;
    this.setInitialState(initialState);
    this.reload = this.reload.bind(this);
    this.onNavigateToUser = this.onNavigateToUser.bind(this);
    this.getUserKey = this.getUserKey.bind(this);
    this.setUser = this.setUser.bind(this);
    this.setWallet = this.setWallet.bind(this);
    this.onSelectTab = this.onSelectTab.bind(this);
    this.getWalletFields = this.getWalletFields.bind(this);
  }

  onLoad() {
    this.loadUser();
  }

  reload() {
    this.loadUser();
  }

  loadUser() {
    this.state.index?.findUserByKey(this.getUserKey(), {
      next: this.setUser,
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, 'avatar');
  }

  loadWallet() {
    this.state.index?.findWalletByKey(this.getWalletKey(), {
      next: this.setWallet,
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, this.getWalletFields());
  }

  // noinspection JSUnusedLocalSymbols
  onLoadUserAndWallet(user: User, wallet: Wallet) {
    // override this in sub-classes if required
  }

  setUser(response: ApiResponse<User>) {
    let user = response.resource!;
    this.publish(Events.system.pageTitle, user.name);
    this.setState({
      mode: '',
      user: user
    }, () => {
      this.loadWallet();
    });
  }

  setWallet(response: ApiResponse<Wallet>) {
    let wallet = response.resource!;
    this.setState({
      mode: '',
      wallet: wallet
    }, () => {
      this.onLoadUserAndWallet(this.state.user!, wallet);
    });
  }

  onNavigateToUser() {
    this.navigate(Routes.admin.user(this.getUserKey()));
  }

  getUserKey() {
    return this.props.params.key;
  }

  getWalletKey() {
    return this.props.params.walletKey;
  }

  onSelectTab(tab: string) {
    this.navigate(Routes.admin.user_wallet(this.getUserKey(), this.getWalletKey(), tab));
  }

  getWalletFields() {
    return '';
  }

  renderWithIndex(index: ApiIndex) {

    let user = this.state.user;
    let wallet = this.state.wallet;

    if (!user || !wallet) {
      return <></>;
    }

    return (

      <div className="row">

        <div className="col-md-8 col-md-offset-2">

          <PageHeader
            title={user.name}
            subTitle="Wallet"
            avatar={user.avatar || null}
            lead={
              <>
                You are viewing the wallet called <strong>{wallet.name}</strong> for user <strong>{user.name}</strong> on the
                system. <a href="/" className="back" onClick={this.fn(this.onNavigateToUser)}>Back to user profile.</a>
              </>
            }/>

          <WalletPanel
            userKey={this.getUserKey()}
            wallet={wallet!}
            onView={null}/>

          <WalletTabs
            wallet={wallet!}
            active={this.activeTab!}
            onSelect={this.onSelectTab}/>

          {this.renderWithIndexUserAndWallet(index, user!, wallet!)}

        </div>

      </div>

    );

  }

  renderWithIndexUserAndWallet(index: ApiIndex, user: User, wallet: Wallet) {
    return <></>;
  }

};
