import Model from "../Model";
import DeviceEventType from "./DeviceEventType";

// let Rel = {
//   self: 'self',
// };

export const DEVICE_EVENT_LIST_FIELDS = '';
export const DEVICE_EVENT_STANDARD_FIELDS = '';

export default class DeviceEvent {

  key?: string;

  ipAddress?: string;
  type?: DeviceEventType;
  details?: string;
  createdAt?: string;

  href = Model.hrefFunction();

};
