import React from 'react';

type DividerProps = {
  className?: string;
  style?: any;
};

const Divider = (props: DividerProps) => (
  <div className={'divider' + (props.className ? ' ' + props.className : '')} style={props.style}/>
);

export default Divider;