import React from 'react';
import Events from '../../app/Events';
import Page from '../../app/Page';
import '../specification.css';
import {FundProductMockup_details_tab, FundProductMockup_horses_tab, FundProductMockup_investors_tab, FundProductMockup_list_page, FundProductMockup_ownerships_tab, FundProductMockup_secondary_nav, FundProductMockup_tranche_details_tab} from './FundProductMockups';

/* eslint-disable react/jsx-pascal-case*/
export default class FundProductSpecificationPage extends Page {

  constructor(props) {
    super(props);
    this.setInitialState({});
  }

  init() {
    super.init();
  }

  load() {
    this.publish(Events.system.pageTitle, 'Fund Product');
    this.publish(Events.system.metaDescription, 'Fund Product');
  }

  render() {

    return (

      <div id="sample-specification-page" className="specification-page">

        <div className="container">

          <div className="row">

            <div className="col-md-10 col-md-offset-1">

              <h1>Sample Specification Page</h1>

              <h2>New menu item in the trainer dashboard</h2>

              <p>Funds are specific to a single trainer. To access the funds feature, the trainer or
                admin user will go to the trainer dashboard and access a new <code>Funds</code> menu item
                in the secondary navigation bar.</p>

              <FundProductMockup_secondary_nav/>

              <h2>List existing funds for a given trainer</h2>

              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla accumsan non velit sit amet porta. Phasellus
                bibendum quis magna sit amet fermentum. Nulla dapibus, massa quis tempor varius, tortor nunc faucibus velit,
                vitae maximus erat orci ac tortor. Donec vitae semper dolor. Etiam eu condimentum lacus. Fusce vestibulum felis
                fermentum ligula sodales varius. In blandit convallis lorem, quis pellentesque metus imperdiet in. Donec rutrum,
                mi quis cursus imperdiet, enim est bibendum dolor, non dapibus nulla urna eget sem. Duis ultrices mauris in
                volutpat posuere. Maecenas vitae enim at nunc rutrum finibus.</p>

              <FundProductMockup_list_page/>

              <h2>View existing fund - details</h2>

              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla accumsan non velit sit amet porta. Phasellus
                bibendum quis magna sit amet fermentum. Nulla dapibus, massa quis tempor varius, tortor nunc faucibus velit,
                vitae maximus erat orci ac tortor. Donec vitae semper dolor. Etiam eu condimentum lacus. Fusce vestibulum felis
                fermentum ligula sodales varius. In blandit convallis lorem, quis pellentesque metus imperdiet in. Donec rutrum,
                mi quis cursus imperdiet, enim est bibendum dolor, non dapibus nulla urna eget sem. Duis ultrices mauris in
                volutpat posuere. Maecenas vitae enim at nunc rutrum finibus.</p>

              <FundProductMockup_details_tab/>

              <h2>View existing fund - horses</h2>

              <FundProductMockup_horses_tab/>

              <h2>View existing fund - investors</h2>

              <FundProductMockup_investors_tab/>

              <h2>Tranche Add - additional fund mapping</h2>

              <FundProductMockup_ownerships_tab/>
              {/*<FundProductMockup_tranche_add/>*/}

              <h2>Tranche Details - additional fund mapping</h2>

              <FundProductMockup_tranche_details_tab/>

            </div>

          </div>

        </div>

      </div>

    );

  }

}
