import React from 'react';
import ApiIndex from "../models/ApiIndex";
import UserImportRun, {USER_IMPORT_RUN_STANDARD_FIELDS} from "../models/UserImportRun";
import {ApiErrorResponse} from "../models/ApiError";
import {ApiResponse, ApiSearchResponse, Button, Form, FullScreenModal, GlobalMessage, SearchResult, Str, Table} from "startupbox-react";
import UserImportRunStatus from "../models/UserImportRunStatus";
import UserImportRunUser from "../models/UserImportRunUser";
import PageHeader from "../components/PageHeader";
import "./UserImportRunPage.css";
import Routes from "../Routes";
import FontAwesome from "react-fontawesome";
import Page, {PageProps, PageState} from "../Page";
import MembershipSearchRequest from "../models/MembershipSearchRequest";
import Membership from "../models/Membership";

export type UserImportRunPageProps = PageProps & {};

export type UserImportRunPageState = PageState & {
  userImportRun: UserImportRun | null | undefined;
  memberships: SearchResult<Membership> | null;
}

export default class UserImportRunPage extends Page<UserImportRunPageProps, UserImportRunPageState> {

  constructor(props: UserImportRunPageProps) {
    super(props);
    this.setInitialState({
      userImportRun: null,
      memberships: null
    });
  }

  onLoad() {
    this.loadUserImportRun();
    this.loadMemberships();
  }

  loadUserImportRun() {
    this.state.index?.findUserImportRunByKey(this.props.params.userImportRunKey, {
      next: this.setUserImportRun,
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, USER_IMPORT_RUN_STANDARD_FIELDS);
  }

  loadMemberships() {
    let membershipSearchRequest = new MembershipSearchRequest();
    membershipSearchRequest.size = 100;
    this.state.index?.searchMemberships(membershipSearchRequest, {
      next: this.setMemberships,
      error: this.onApiError
    }, null);

  }

  setMemberships(response: ApiSearchResponse<Membership>) {
    this.setState({
      memberships: response.resource
    })
  }

  // -------------------------------------------------------------------------------------------------------------------

  onSubmit() {
    this.setMode('save');
  }

  onHideSaveDialog() {
    this.setMode('');
  }

  onSaveOnly() {
    this.performSave()
  }

  onSaveAndSubmit() {
    this.performSave(response => this.onChangeStatus({key: 'SUBMITTED'}))
  }

  performSave(callback?: (response: ApiResponse<UserImportRun>) => void) {
    if (this.isEnabled()) {
      this.disable();
      let userImportRun = this.state.userImportRun;
      userImportRun?.save({
        next: (callback ? callback : this.onSave),
        error: response => {
          this.onApiError(response);
          this.setMode('');
        }
      }, USER_IMPORT_RUN_STANDARD_FIELDS);
    }
  }

  onChangeStatus(status: UserImportRunStatus) {
    this.state.userImportRun?.modifyStatus(status, {
      next: this.onSave,
      error: this.onApiError
    }, USER_IMPORT_RUN_STANDARD_FIELDS);
  }

  onSave(response: ApiResponse<UserImportRun>) {
    this.onNavigateToRuns();
  }

  // -------------------------------------------------------------------------------------------------------------------

  onCancel() {
    this.onNavigateToRuns();
  }

  onDelete(h: number) {
    let userImportRun = this.state.userImportRun;
    userImportRun?.users?.splice(h, 1);
    this.setState({
      userImportRun: userImportRun
    });
  }

  onAppend() {
    let userImportRun = this.state.userImportRun;
    userImportRun?.users?.push(UserImportRunUser.buildNew());
    this.setState({
      userImportRun: userImportRun
    });
  }

  isModifyAllowed() {
    let userImportRun = this.state.userImportRun;
    return userImportRun && userImportRun.isModifyAllowed();
  }

  onNavigateToRuns() {
    this.navigate(Routes.admin.userImportRuns());
  }

  setUserImportRun(response: ApiResponse<UserImportRun>) {
    let userImportRun = response.resource;
    this.setState({
      userImportRun: userImportRun
    })
  }

  renderWithIndex(index: ApiIndex) {

    let userImportRun = this.state.userImportRun;
    let isModifyAllowed = this.isModifyAllowed();
    let mode = this.state.mode;

    if (!userImportRun) {
      return <></>;
    }

    return <>

      <div id="user-import-run-page">

        <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity} fieldErrors={(this.state.errors as any)._}/>

        {
          mode === 'save' &&
          <FullScreenModal title="Save Import Run" onHide={this.onHideSaveDialog}>

            <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity} fieldErrors={(this.state.errors as any)._}/>

            <Form disabled={this.isDisabled()}>

              <p className="lead">Would you like to Submit and create the Users?</p>

              <div className="actions">
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  onClick={this.onSaveOnly}>Save Draft</Button>
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  onClick={this.onSaveAndSubmit}>Save and Submit</Button>
                <Button
                  onClick={this.onHideSaveDialog}
                  bsSize="large">Cancel</Button>
              </div>

            </Form>

          </FullScreenModal>
        }

        <Form onSubmit={this.fn(this.onSubmit)} disabled={this.isDisabled()}>

          <PageHeader
            title="User Import Run"
            subTitle={Str.formatDate(userImportRun.createdAt)}
            lead="This area allows you to view and edit an import run."/>

          <DetailsPanel
            index={index}
            errors={this.state.errors}
            memberships={this.state.memberships}
            userImportRun={userImportRun}
            onFieldChange={isModifyAllowed ? this.onFieldChange : undefined}
            onDelete={isModifyAllowed ? this.onDelete : undefined}/>

          {
            isModifyAllowed &&
            <div className="actions">
              <Button
                bsStyle="primary"
                bsSize="large"
                type="submit">Save Changes</Button>
              <Button
                onClick={this.onCancel}
                bsSize="large">Cancel</Button>
              <Button
                bsStyle="primary"
                bsSize="large"
                className="pull-right"
                onClick={this.onAppend}>Append Row</Button>
            </div>
          }
          {
            !isModifyAllowed &&
            <div className="actions">
              <Button
                bsStyle="primary"
                onClick={this.onCancel}
                bsSize="large">Close</Button>
            </div>
          }

        </Form>

      </div>

    </>;

  }

};

// ................................................................................................................................................

const DetailsPanel = (props: {
  index: ApiIndex,
  errors?: { [key: string]: string },
  memberships: SearchResult<Membership> | null,
  userImportRun: UserImportRun,
  onFieldChange: ((e: Event, callback?: () => void) => void) | undefined,
  onDelete: ((h: number) => void) | undefined,
}) => {

  let index = props.index;
  let errors = props.errors;
  let memberships = props.memberships;
  let userImportRun = props.userImportRun;
  let onFieldChange = props.onFieldChange;
  let onDelete = props.onDelete;
  let isDeleteAllowed = !!onDelete && userImportRun.users!.length > 1;

  return (

    <>

      <Table
        items={userImportRun.users}
        blankMessage="No users found"
        renderHeaderRow={() => {
          if (userImportRun) {
            let firstUser = userImportRun.users![0];
            let cols = firstUser.getCols();
            return (
              <tr>
                <th className="col-delete">&nbsp;</th>
                {
                  cols.map((col, c) => {
                    return (
                      col.renderHeaderCell(c)
                    );
                  })
                }
              </tr>
            );
          }
          return null;
        }}
        renderItemRow={(user: UserImportRunUser, r: number) => {
          let prefix = 'userImportRun.users[' + r + '].';
          let cols = user.getCols();
          return (
            <tr key={r}>
              <td className="col-delete">
                {
                  isDeleteAllowed &&
                  <FontAwesome name="times" onClick={() => {
                    onDelete!(r);
                  }}/>
                }
              </td>
              {
                cols.map((col, c) => {
                  return (
                    col.renderUserCell(index, errors, memberships, r, c, prefix, onFieldChange)
                  );
                })
              }
            </tr>
          );
        }}
      />

    </>

  );

}
