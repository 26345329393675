export default class HorseSearchRequest {

  keywords?: string;
  key?: string;
  name?: string;
  microchipNumber?: string;
  racingStatus?: string;
  status?: string;

  sort?: string;
  page = 0;
  size?: string | number;

}
