import {Api, ApiMultiFileSubscribable, ApiMultiFileSubscribableCallbacks, ApiSubscribable, ApiSubscribableCallbacks, Environment, Href, SearchResult} from 'startupbox-react';
import Model from '../Model';
import Setting from "./Setting";
import SettingSearchRequest from "./SettingSearchRequest";
import Credentials from "./Credentials";
import UserSearchRequest from "./UserSearchRequest";
import HorseSearchRequest from "./HorseSearchRequest";
import PartnerSearchRequest from "./PartnerSearchRequest";
import User from "./User";
import PartnerStatus from "./PartnerStatus";
import PartnerCollaboratorType from "./PartnerCollaboratorType";
import PartnerCollaboratorStatus from "./PartnerCollaboratorStatus";
import HorseStatus from "./HorseStatus";
import UserStatus from "./UserStatus";
import UserType from "./UserType";
import ApiError from "./ApiError";
import Horse from "./Horse";
import Partner from "./Partner";
import AuthenticationResponse from "./AuthenticationResponse";
import Binary from "./Binary";
import HorseRacingStatus from "./HorseRacingStatus";
import VisibilityLevel from "./VisibilityLevel";
import HorseAttributeType, {HorseAttributeTypeKey} from "./HorseAttributeType";
import PostStatus from "./PostStatus";
import TrancheStatus from "./TrancheStatus";
import PostSearchRequest from "./PostSearchRequest";
import Post from "./Post";
import CollectibleStatus from "./CollectibleStatus";
import Collectible from "./Collectible";
import Tranche from "./Tranche";
import BlockchainAttributeType from "./BlockchainAttributeType";
import BlockchainMintStatus from "./BlockchainMintStatus";
import HorseImportRunStatus from "./HorseImportRunStatus";
import HorseImportRun from "./HorseImportRun";
import Membership from "./Membership";
import MembershipSearchRequest from "./MembershipSearchRequest";
import MembershipStatus from "./MembershipStatus";
import UserImportRun from "./UserImportRun";
import UserImportRunSearchRequest from "./UserImportRunSearchRequest";
import UserImportRunStatus from "./UserImportRunStatus";
import LegalDocument from "./LegalDocument";
import LegalDocumentSearchRequest from "./LegalDocumentSearchRequest";
import LegalDocumentType from "./LegalDocumentType";
import Payout from "./Payout";
import AppCredit from "./AppCredit";
import Wallet from "./Wallet";
import SalesChannel from "./SalesChannel";
import PackStatus from "./PackStatus";
import PushNotificationPreparationTiming from "./PushNotificationPreparationTiming";
import TrainingRecordStatus from "./TrainingRecordStatus";
import MedicalRecordStatus from "./MedicalRecordStatus";
import Device from "./Device";
import SubscriptionStatus from "./SubscriptionStatus";
import DevicePlatformType from "./DevicePlatformType";
import SubscriptionSearchRequest from "./SubscriptionSearchRequest";
import Subscription from "./Subscription";
import TransactionSearchRequest from "./TransactionSearchRequest";
import Transaction from "./Transaction";
import TransactionStatus from "./TransactionStatus";
import TransactionType from "./TransactionType";
import Context from "../Context";
import SubscribableSearchRequest from "./SubscribableSearchRequest";
import Subscribable from "./Subscribable";
import PartnerType from "./PartnerType";
import PremiumContentTier from "./PremiumContentTier";
import HubSpotAsyncMessageSearchRequest from "./HubSpotAsyncMessageSearchRequest";
import HubSpotAsyncMessage from "./HubSpotAsyncMessage";
import HubSpotAsyncMessageStatus from "./HubSpotAsyncMessageStatus";
import HubSpotAsyncMessageType from "./HubSpotAsyncMessageType";
import AppCreditType from "./AppCreditType";
import DisbursementStatus from "./DisbursementStatus";
import Disbursement from "./Disbursement";
import DisbursementSearchRequest from "./DisbursementSearchRequest";
import EntitlementWithdrawalPendingDisbursementCount from "./EntitlementWithdrawalPendingDisbursementCount";
import EntitlementSearchRequest from "./EntitlementSearchRequest";
import Entitlement from "./Entitlement";
import EntitlementType from "./EntitlementType";
import EntitlementStatus from "./EntitlementStatus";
import CollectibleSearchRequest from "./CollectibleSearchRequest";
import Banner from "./Banner";
import BannerSearchRequest from "./BannerSearchRequest";
import BannerType from "./BannerType";
import PackSearchRequest from "./PackSearchRequest";
import Pack from "./Pack";
import TrancheSearchRequest from "./TrancheSearchRequest";
import LegalOwnerSearchRequest from "./LegalOwnerSearchRequest";
import LegalOwner from "./LegalOwner";

let Rel = {

  // app credit
  appCredit_find: 'appCredit_find',

  // banner
  banner_create: 'banner_create',
  banner_search: 'banner_search',

  // collectible
  collectible_create: 'collectible_create',
  collectible_find: 'collectible_find',
  collectible_search: 'collectible_search',

  // device
  device_find: 'device_find',

  // disbursement
  disbursement_create: 'disbursement_create',
  disbursement_find: 'disbursement_find',
  disbursement_search: 'disbursement_search',

  // entitlement
  entitlement_search: 'entitlement_search',
  entitlement_exportAsCsv: 'entitlement_exportAsCsv',
  entitlementWithdrawalPendingDisbursementCount_find: 'entitlementWithdrawalPendingDisbursementCount_find',

  // horse
  horseImportRun_find: 'horseImportRun_find',
  horse_search: 'horse_search',

  // legal owner
  legalOwner_search: 'legalOwner_search',

  // hubspot
  hubSpotAsyncMessage_find: 'hubSpotAsyncMessage_find',
  hubSpotAsyncMessage_search: 'hubSpotAsyncMessage_search',

  // legal document
  legalDocument_create: 'legalDocument_create',
  legalDocument_search: 'legalDocument_search',

  // membership
  membership_create: 'membership_create',
  membership_find: 'membership_find',
  membership_search: 'membership_search',

  // pack
  pack_search: 'pack_search',

  // partner
  partner_create: 'partner_create',
  partner_find: 'partner_find',
  partner_search: 'partner_search',

  // payout
  payout_find: 'payout_find',

  // post
  post_create: 'post_create',
  post_search: 'post_search',

  // premium content
  premiumContentTierList_find: 'premiumContentTierList_find',

  // setting
  setting_create: 'setting_create',
  setting_find: 'setting_find',
  setting_search: 'setting_search',

  // subscribable
  subscribable_search: 'subscribable_search',

  // subscription
  subscription_search: 'subscription_search',

  // tranche
  tranche_find: 'tranche_find',
  tranche_search: 'tranche_search',

  // transaction
  transaction_search: 'transaction_search',
  transaction_exportAsCsv: 'transaction_exportAsCsv',

  // user
  user_findMe: 'user_findMe',
  user_modifyMyPassword: 'user_modifyMyPassword',
  user_find: 'user_find',
  user_findByFrankieOneAccessKey: 'user_findByFrankieOneAccessKey',
  user_authenticate: 'user_authenticate',
  user_logout: 'user_logout',
  user_create: 'user_create',
  user_frankieOneSuccess: 'user_frankieOneSuccess',
  user_search: 'user_search',
  user_exportAsCsv: 'user_exportAsCsv',
  user_requestResetPassword: 'user_requestResetPassword',
  user_confirmResetPasswordToken: 'user_confirmResetPasswordToken',
  user_resetPassword: 'user_resetPassword',

  // user import run
  userImportRun_add: 'userImportRun_add',
  userImportRun_find: 'userImportRun_find',
  userImportRun_search: 'userImportRun_search',
  userImportRun_upload: 'userImportRun_upload',

  // wallet
  wallet_find: 'wallet_find',

};

// noinspection JSUnusedGlobalSymbols
export default class ApiIndex {

  appCreditTypes: Array<AppCreditType> = [];

  bannerTypes: Array<BannerType> = [];

  blockchainAttributeTypes: Array<BlockchainAttributeType> = [];

  collectibleStatuses: Array<CollectibleStatus> = [];

  devicePlatformTypes: Array<DevicePlatformType> = [];

  disbursementStatuses: Array<DisbursementStatus> = [];

  entitlementTypes: Array<EntitlementType> = [];
  entitlementStatuses: Array<EntitlementStatus> = [];

  horseAttributeTypes: Array<HorseAttributeType> = [];
  horseRacingStatuses: Array<HorseRacingStatus> = [];
  horseImportRunStatuses: Array<HorseImportRunStatus> = [];
  horseStatuses: Array<HorseStatus> = [];

  hubSpotAsyncMessageTypes: Array<HubSpotAsyncMessageType> = [];
  hubSpotAsyncMessageStatuses: Array<HubSpotAsyncMessageStatus> = [];

  legalDocumentTypes: Array<LegalDocumentType> = [];

  medicalRecordStatuses: Array<MedicalRecordStatus> = [];

  membershipStatuses: Array<MembershipStatus> = [];

  mintChooseableStatuses: Array<BlockchainMintStatus> = [];

  packStatuses: Array<PackStatus> = [];

  postStatuses: Array<PostStatus> = [];

  pushNotificationPreparationTimings: Array<PushNotificationPreparationTiming> = [];

  salesChannels: Array<SalesChannel> = [];

  subscriptionStatuses: Array<SubscriptionStatus> = [];

  partnerStatuses: Array<PartnerStatus> = [];
  partnerTypes: Array<PartnerType> = [];
  partnerCollaboratorStatuses: Array<PartnerCollaboratorStatus> = [];
  partnerCollaboratorTypes: Array<PartnerCollaboratorType> = [];

  trainingRecordStatuses: Array<TrainingRecordStatus> = [];

  trancheStatuses: Array<TrancheStatus> = [];

  transactionTypes: Array<TransactionType> = [];
  transactionStatuses: Array<TransactionStatus> = [];

  userStatuses: Array<UserStatus> = [];
  userTypes: Array<UserType> = [];

  userImportRunStatuses: Array<UserImportRunStatus> = [];

  visibilityLevels: Array<VisibilityLevel> = [];
  visibilityLevelsForNews: Array<VisibilityLevel> = [];

  applePremiumContentDefaultTierForPartner?: PremiumContentTier;
  googlePremiumContentDefaultTierForPartner?: PremiumContentTier;

  href = Model.hrefFunction();
  _links = {};

  // index ..........................................................................................................................................

  static load(callbacks: ApiSubscribableCallbacks<ApiIndex, ApiError>) {
    let href = new Href('/api/1/index');
    Api.get<ApiIndex, ApiError>(href)
      .subscribe(callbacks);
  }

  // app credit .....................................................................................................................................

  findAppCreditByKey(key: string, callbacks: ApiSubscribableCallbacks<AppCredit, ApiError>, fields: string | null) {
    this.href(Rel.appCredit_find, true)
      .replace('key', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // banner ...........................................................................................................................................

  isCreateBannerAllowed() {
    return !!this.href(Rel.banner_create);
  }

  createBanner(banner: Banner, callbacks: ApiSubscribableCallbacks<Banner, ApiError>, fields: string | null) {
    this.href(Rel.banner_create, true)
      .field(fields)
      .post(banner)
      .subscribe(callbacks);
  }

  searchBanners(request: BannerSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Banner>, ApiError>, fields: string | null) {
    this.href(Rel.banner_search, true)!
      .param('key', request.key)
      .param('keywords', request.keywords)
      .param('sort', request.sort || 'createdAt,desc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // collectible ....................................................................................................................................

  isCreateCollectibleAllowed() {
    return !!this.href(Rel.collectible_create);
  }

  createCollectible(collectible: Collectible, callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.collectible_create, true)
      .field(fields)
      .post(collectible)
      .subscribe(callbacks);
  }

  findCollectibleByKey(key: string, callbacks: ApiSubscribableCallbacks<Collectible, ApiError>, fields: string | null) {
    this.href(Rel.collectible_find, true)
      .replace('key', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  searchCollectibles(request: CollectibleSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Collectible>, ApiError>, fields: string | null) {
    this.href(Rel.collectible_search, true)!
      .param('keywords', request.keywords)
      .param('btx', request.btx)
      .param('key', request.key)
      .param('name', request.name)
      .param('sort', request.sort || 'name,asc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // device .........................................................................................................................................

  findDeviceByKey(key: string, callbacks: ApiSubscribableCallbacks<Device, ApiError>, fields: string | null) {
    this.href(Rel.device_find, true)
      .replace('key', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // disbursement ...................................................................................................................................

  createDisbursement(disbursement: Disbursement, callbacks: ApiSubscribableCallbacks<Disbursement, ApiError>, fields: string | null) {
    this.href(Rel.disbursement_create, true)
      .field(fields)
      .post(disbursement)
      .subscribe(callbacks);
  }

  findDisbursementByKey(key: string, callbacks: ApiSubscribableCallbacks<Disbursement, ApiError>, fields: string | null) {
    this.href(Rel.disbursement_find, true)
      .replace('key', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  searchDisbursements(params: DisbursementSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Disbursement>, ApiError>, fields: string | null) {
    this.href(Rel.disbursement_search, true)
      .param('key', params.key)
      .param('status', params.status)
      .param('sort', params.sort || 'createdAt,desc')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // entitlement ....................................................................................................................................

  findEntitlementWithdrawalPendingDisbursementCount(callbacks: ApiSubscribableCallbacks<SearchResult<EntitlementWithdrawalPendingDisbursementCount>, ApiError>, fields: string | null) {
    this.href(Rel.entitlementWithdrawalPendingDisbursementCount_find, true)!
      .get()
      .subscribe(callbacks);
  }

  searchEntitlements(params: EntitlementSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Entitlement>, ApiError>, fields: string | null) {
    this.href(Rel.entitlement_search, true)
      .param('keywords', params.keywords)
      .param('type', params.type)
      .param('status', params.status)
      .param('sort', params.sort || 'paymentDate,asc')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  getExportEntitlementHref(params: EntitlementSearchRequest) {
    return this.href(Rel.entitlement_exportAsCsv, true)
      .param("authorization", Environment.current().name === 'localhost' ? Context.getAccessToken() : undefined)
      .param('keywords', params.keywords)
      .param('type', params.type)
      .param('status', params.status)
      .param('sort', params.sort || 'paymentDate,desc')
      .param('size', params.size)
  }

  // horse ..........................................................................................................................................

  findHorseAttributeType(key: HorseAttributeTypeKey) {
    return this.horseAttributeTypes.find((type) => type.key === key);
  }

  searchHorses(params: HorseSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Horse>, ApiError>, fields: string | null) {
    this.href(Rel.horse_search, true)
      .param('keywords', params.keywords)
      .param('key', params.key)
      .param('name', params.name)
      .param('microchipNumber', params.microchipNumber)
      .param('racingStatus', params.racingStatus)
      .param('status', params.status)
      .param('sort', params.sort)
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  searchLegalOwners(request: LegalOwnerSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<LegalOwner>, ApiError>, fields: string | null) {
    this.href(Rel.legalOwner_search, true)!
        .param('keywords', request.keywords)
        .param('key', request.key)
        .param('name', request.name)
        .param('sort', request.sort || 'name,asc')
        .param('page', request.page)
        .param('size', request.size)
        .field(fields)
        .get()
        .subscribe(callbacks);
  }

  // horse import run ...........................................................................................................................

  findHorseImportRunByKey(key: string, callbacks: ApiSubscribableCallbacks<HorseImportRun, ApiError>, fields: string | null) {
    this.href(Rel.horseImportRun_find, true)
      .replace('key', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // hubspot ........................................................................................................................................

  findHubSpotAsyncMessageByKey(key: string, callbacks: ApiSubscribableCallbacks<HubSpotAsyncMessage, ApiError>, fields: string | null) {
    this.href(Rel.hubSpotAsyncMessage_find, true)
      .replace('key', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  searchHubSpotAsyncMessages(request: HubSpotAsyncMessageSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<HubSpotAsyncMessage>, ApiError>, fields: string | null) {
    this.href(Rel.hubSpotAsyncMessage_search, true)!
      .param('key', request.key)
      .param('description', request.description)
      .param('type', request.type)
      .param('status', request.status)
      .param('sort', request.sort || 'createdAt,desc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // legal document .................................................................................................................................

  createLegalDocument(legalDocument: LegalDocument, callbacks: ApiSubscribableCallbacks<LegalDocument, ApiError>, fields: string | null) {
    this.href(Rel.legalDocument_create, true)
      .field(fields)
      .post(legalDocument)
      .subscribe(callbacks);
  }

  searchLegalDocuments(request: LegalDocumentSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<LegalDocument>, ApiError>, fields: string | null) {
    this.href(Rel.legalDocument_search, true)!
      .param('key', request.key)
      .param('keywords', request.keywords)
      .param('title', request.title)
      .param('type', request.type)
      .param('sort', request.sort || 'title,asc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // membership .....................................................................................................................................

  isAddMembershipAllowed() {
    return !!this.href(Rel.membership_create);
  }

  createMembership(membership: Membership, callbacks: ApiSubscribableCallbacks<Membership, ApiError>, fields: string | null) {
    this.href(Rel.membership_create, true)
      .field(fields)
      .post(membership)
      .subscribe(callbacks);
  }

  findMembershipByKey(key: string, callbacks: ApiSubscribableCallbacks<Membership, ApiError>, fields: string | null) {
    this.href(Rel.membership_find, true)
      .replace('key', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  searchMemberships(request: MembershipSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Membership>, ApiError>, fields: string | null) {
    this.href(Rel.membership_search, true)!
      .param('keywords', request.keywords)
      .param('key', request.key)
      .param('name', request.name)
      .param('sort', request.sort || 'name,asc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // news ...........................................................................................................................................

  addNews(post: Post, callbacks: ApiSubscribableCallbacks<Post, ApiError>, fields: string | null) {
    this.href(Rel.post_create, true)
      .field(fields)
      .post(post)
      .subscribe(callbacks);
  }

  searchNews(request: PostSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Post>, ApiError>, fields: string | null) {
    this.href(Rel.post_search, true)!
      .param('key', request.key)
      .param('keywords', request.keywords)
      .param('newsOnly', true)
      .param('sort', request.sort || 'date,desc')
      .param('page', request.page)
      .param('size', request.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // pack ...........................................................................................................................................

  searchPacks(params: PackSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Pack>, ApiError>, fields: string | null) {
    this.href(Rel.pack_search, true)
      .param('key', params.key)
      .param('keywords', params.keywords)
      .param('name', params.name)
      .param('status', params.status)
      .param('sort', params.sort || 'name,asc')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // partner ........................................................................................................................................

  createPartner(partner: Partner, callbacks: ApiSubscribableCallbacks<Partner, ApiError>, fields: string | null) {
    this.href(Rel.partner_create, true)
      .field(fields)
      .post(partner)
      .subscribe(callbacks);
  }

  findPartnerByKey(key: string, callbacks: ApiSubscribableCallbacks<Partner, ApiError>, fields: string | null) {
    this.href(Rel.partner_find, true)
      .replace('key', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  searchPartners(params: PartnerSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Partner>, ApiError>, fields: string | null) {
    this.href(Rel.partner_search, true)
      .param('key', params.key)
      .param('keywords', params.keywords)
      .param('name', params.name)
      .param('type', params.type)
      .param('status', params.status)
      .param('sort', params.sort || 'name,asc')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // payout .........................................................................................................................................

  findPayoutByKey(key: string, callbacks: ApiSubscribableCallbacks<Payout, ApiError>, fields: string | null) {
    this.href(Rel.payout_find, true)
      .replace('key', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // premium content ................................................................................................................................

  listPremiumContentTiersForApple(callbacks: ApiSubscribableCallbacks<{ values: PremiumContentTier[] }, ApiError>) {
    this.href(Rel.premiumContentTierList_find, true)
      .replace('key', 'APPLE')
      .get()
      .subscribe(callbacks);
  }

  listPremiumContentTiersForGoogle(callbacks: ApiSubscribableCallbacks<{ values: PremiumContentTier[] }, ApiError>) {
    this.href(Rel.premiumContentTierList_find, true)
      .replace('key', 'GOOGLE')
      .get()
      .subscribe(callbacks);
  }

  // security .......................................................................................................................................

  authenticate(credentials: Credentials, callbacks: ApiSubscribableCallbacks<AuthenticationResponse, ApiError>) {
    this.href(Rel.user_authenticate, true)
      .field('user')
      .post(credentials)
      .subscribe(callbacks);
  }

  logout(callbacks: ApiSubscribableCallbacks<void, ApiError>) {
    this.href(Rel.user_logout, true)
      .post({})
      .subscribe(callbacks);
  }

  findMe(callbacks: ApiSubscribableCallbacks<User, ApiError>, fields: string | null) {
    this.href(Rel.user_findMe, true)
      .field('avatar')
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  modifyMyPassword(credentials: Credentials, callbacks: ApiSubscribableCallbacks<User, ApiError>, fields: string | null) {
    this.href(Rel.user_modifyMyPassword, true)
      .field(fields)
      .patch(credentials)
      .subscribe(callbacks);
  }

  requestResetPassword(credentials: Credentials, callbacks: ApiSubscribableCallbacks<null, ApiError>) {
    this.href(Rel.user_requestResetPassword, true)
      .post(credentials)
      .subscribe(callbacks);
  }

  confirmPasswordResetToken(token: string, callbacks: ApiSubscribableCallbacks<null, ApiError>) {
    this.href(Rel.user_confirmResetPasswordToken, true)
      .param('token', token)
      .post({})
      .subscribe(callbacks);
  }

  resetPassword(credentials: Credentials, callbacks: ApiSubscribableCallbacks<AuthenticationResponse, ApiError>, fields: string | null) {
    this.href(Rel.user_resetPassword, true)
      .field(fields)
      .post(credentials)
      .subscribe(callbacks);
  }

  // setting ........................................................................................................................................

  createSetting(setting: Setting, callbacks: ApiSubscribableCallbacks<Setting, ApiError>, fields: string | null) {
    this.href(Rel.setting_create, true)
      .field(fields)
      .post(setting)
      .subscribe(callbacks);
  }

  findSettingByKey(key: string, callbacks: ApiSubscribableCallbacks<Setting, ApiError>, fields: string | null) {
    this.href(Rel.setting_find, true)
      .replace('key', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  searchSettings(params: SettingSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Setting>, ApiError>, fields: string | null) {
    this.href(Rel.setting_search, true)
      .param('name', params.name)
      .param('value', params.value)
      .param('sort', params.sort || 'name')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // subscribable ...................................................................................................................................

  searchSubscribables(params: SubscribableSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Subscribable>, ApiError>, fields: string | null) {
    this.href(Rel.subscribable_search, true)
      .param('keywords', params.keywords)
      .param('key', params.key)
      .param('targetEntityKey', params.targetEntityKey)
      .param('type', params.type)
      .param('partnerKey', params.partnerKey)
      .param('salesChannel', params.salesChannel)
      .param('inPack', params.inPack)
      .param('sort', params.sort || 'name')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // subscription ...................................................................................................................................

  searchSubscriptions(params: SubscriptionSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Subscription>, ApiError>, fields: string | null) {
    this.href(Rel.subscription_search, true)
      .param('key', params.key)
      .param('sort', params.sort || 'id,desc')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // tranche ........................................................................................................................................

  findTrancheByKey(key: string, callbacks: ApiSubscribableCallbacks<Tranche, ApiError>, fields: string | null) {
    this.href(Rel.tranche_find, true)
      .replace('key', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  searchTranches(params: TrancheSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Tranche>, ApiError>, fields: string | null) {
    this.href(Rel.tranche_search, true)
      .param('keywords', params.keywords)
      .param('key', params.key)
      .param('name', params.name)
      .param('sort', params.sort)
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // transaction ...................................................................................................................................

  searchTransactions(params: TransactionSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<Transaction>, ApiError>, fields: string | null) {
    this.href(Rel.transaction_search, true)
      .param('subscribableName', params.subscribableName)
      .param('walletOwnerName', params.walletOwnerName)
      .param('type', params.type)
      .param('status', params.status)
      .param('sort', params.sort || 'date,desc')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  getExportTransactionHref(params: TransactionSearchRequest) {
    return this.href(Rel.transaction_exportAsCsv, true)
      .param("authorization", Environment.current().name === 'localhost' ? Context.getAccessToken() : undefined)
      .param('subscribableName', params.subscribableName)
      .param('walletOwnerName', params.walletOwnerName)
      .param('type', params.type)
      .param('status', params.status)
      .param('sort', params.sort || 'date,desc')
      .param('size', params.size)
  }

  // user ...........................................................................................................................................

  createUser(user: User, callbacks: ApiSubscribableCallbacks<User, ApiError>, fields: string | null) {
    this.href(Rel.user_create, true)
      .field(fields)
      .post(user)
      .subscribe(callbacks);
  }

  markUserIdentityVerified(frankieOneAccessKey: string, frankieOneResult: any, callbacks: ApiSubscribableCallbacks<void, ApiError>, fields: string | null) {
    this.href(Rel.user_frankieOneSuccess, true)
      .param('frankieOneAccessKey', frankieOneAccessKey)
      .post(frankieOneResult)
      .subscribe(callbacks);
  }

  findUserByKey(key: string, callbacks: ApiSubscribableCallbacks<User, ApiError>, fields: string | null) {
    this.href(Rel.user_find, true)
      .replace('key', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  findUserByFrankieOneAccessKey(frankieOneAccessKey: string, callbacks: ApiSubscribableCallbacks<User, ApiError>, fields: string | null) {
    this.href(Rel.user_findByFrankieOneAccessKey, true)
      .replace('frankieOneAccessKey', frankieOneAccessKey)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  searchUsers(params: UserSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<User>, ApiError>, fields: string | null) {
    this.href(Rel.user_search, true)
      .param('key', params.key)
      .param('name', params.name)
      .param('emailAddress', params.emailAddress)
      .param('status', params.status)
      .param('sort', params.sort || 'name')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  getExportUserHref(params: UserSearchRequest) {
    return this.href(Rel.user_exportAsCsv, true)
      .param("authorization", Environment.current().name === 'localhost' ? Context.getAccessToken() : undefined)
      .param('key', params.key)
      .param('name', params.name)
      .param('emailAddress', params.emailAddress)
      .param('status', params.status)
      .param('sort', params.sort || 'name')
      .param('size', params.size)
  }

  // user import run ...............................................................................................................................

  findUserImportRunByKey(key: string, callbacks: ApiSubscribableCallbacks<UserImportRun, ApiError>, fields: string | null) {
    this.href(Rel.userImportRun_find, true)
      .replace('key', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  isAddUserImportRunAllowed() {
    return !!this.href(Rel.userImportRun_add);
  }

  addUserImportRun(user: UserImportRun, callbacks: ApiSubscribableCallbacks<UserImportRun, ApiError>, fields: string | null) {
    this.href(Rel.userImportRun_add, true)
      .field(fields)
      .post(user)
      .subscribe(callbacks);
  }

  searchUserImportRuns(params: UserImportRunSearchRequest, callbacks: ApiSubscribableCallbacks<SearchResult<UserImportRun>, ApiError>, fields: string | null) {
    this.href(Rel.userImportRun_search, true)
      .param('key', params.key)
      .param('status', params.status)
      .param('sort', params.sort || 'createdAt,desc')
      .param('page', params.page)
      .param('size', params.size)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  isUploadUserImportRunAllowed() {
    return !!this.href(Rel.userImportRun_upload);
  }

  uploadUserImportRun(file: any, callbacks: ApiSubscribableCallbacks<UserImportRun, ApiError>, fields: string | null) {
    let href = this.href(Rel.userImportRun_upload, true);
    href
      .field(fields)
      .uploadLocalFile(href.toString(), file)
      .subscribe(callbacks);
  }

  // wallet .........................................................................................................................................

  findWalletByKey(key: string, callbacks: ApiSubscribableCallbacks<Wallet, ApiError>, fields: string | null) {
    this.href(Rel.wallet_find, true)
      .replace('key', key)
      .field(fields)
      .get()
      .subscribe(callbacks);
  }

  // files ..........................................................................................................................................

  uploadFile(file: any, callbacks: ApiSubscribableCallbacks<Binary, ApiError>) {
    (Api.uploadFile(file) as ApiSubscribable<Binary, ApiError>)
      .subscribe(callbacks);
  }

  uploadFiles(files: Array<any>, callbacks: ApiMultiFileSubscribableCallbacks<Binary, ApiError>) {
    (Api.uploadFiles(files) as ApiMultiFileSubscribable<Binary, ApiError>)
      .subscribe(callbacks);
  }

  // images .........................................................................................................................................

  uploadImage(file: any, callbacks: ApiSubscribableCallbacks<Binary, ApiError>) {
    (Api.uploadImage(file) as ApiSubscribable<Binary, ApiError>)
      .subscribe(callbacks);
  }

  uploadImages(files: Array<any>, callbacks: ApiMultiFileSubscribableCallbacks<Binary, ApiError>) {
    (Api.uploadImages(files) as ApiMultiFileSubscribable<Binary, ApiError>)
      .subscribe(callbacks);
  }

  // videos .........................................................................................................................................

  uploadVideo(file: any, callbacks: ApiSubscribableCallbacks<Binary, ApiError>) {
    (Api.uploadVideo(file) as ApiSubscribable<Binary, ApiError>)
      .subscribe(callbacks);
  }

  uploadVideos(files: Array<any>, callbacks: ApiMultiFileSubscribableCallbacks<Binary, ApiError>) {
    (Api.uploadVideos(files) as ApiMultiFileSubscribable<Binary, ApiError>)
      .subscribe(callbacks);
  }

}
