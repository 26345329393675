export default class DeviceSearchRequest {

  key?: string;
  physicalIdentifier?: string;
  sessionIdentifier?: string;
  platformIdentifier?: string;
  clientVersion?: number;
  platformType?: string;
  brand?: string;
  modelName?: string;
  osName?: string;
  osVersion?: string;
  userAgent?: string;
  active?: boolean;

  sort?: string;
  page?: string | number;
  size?: string | number;

}
