import React from 'react';
import PartnerHorseAbstractPage, {PartnerHorseAbstractPageProps, PartnerHorseAbstractPageState} from './PartnerHorseAbstractPage';
import ApiIndex from "../models/ApiIndex";
import Horse from "../models/Horse";
import Partner from "../models/Partner";
import Collectible, {COLLECTIBLE_STANDARD_FIELDS} from "../models/Collectible";
import {ApiErrorResponse} from "../models/ApiError";
import {ApiResponse, Button, Confirm, Confirmable, GlobalMessage} from "startupbox-react";
import CollectibleStatus from "../models/CollectibleStatus";
import Routes from "../Routes";
import BlockchainMintable from "../models/BlockchainMintable";
import BlockchainMintablePanel from "../blockchain/BlockchainMintablePanel";
import BlockchainMintStatus from "../models/BlockchainMintStatus";
import SectionHeader from "../components/SectionHeader";
import PartnerHorseCollectibleGiftToUserDialog from "./PartnerHorseCollectibleGiftToUserDialog";
import CollectibleDetailsPanel from "../collectible/CollectibleDetailsPanel";

let FIELDS = COLLECTIBLE_STANDARD_FIELDS;

export type PartnerHorseCollectiblePageProps = PartnerHorseAbstractPageProps & {};

export type PartnerHorseCollectiblePageState = PartnerHorseAbstractPageState & {
  collectible: Collectible | null | undefined;
}

export default class PartnerHorseCollectiblePage extends PartnerHorseAbstractPage<PartnerHorseCollectiblePageProps, PartnerHorseCollectiblePageState> {

  constructor(props: PartnerHorseCollectiblePageProps) {
    super(props);
    this.activeTab = 'collectibles';
  }

  onLoadHorse(horse: Horse | undefined) {
    this.loadCollectible();
  }

  onReloadCollectible(response: ApiResponse<BlockchainMintable>) {
    this.setCollectible(response as ApiResponse<Collectible>);
  }

  loadCollectible() {
    this.state.horse?.findCollectibleByKey(this.props.params.collectibleKey, {
      next: this.setCollectible,
      error: (response: ApiErrorResponse) => {
        this.onApiError(response);
        this.showNotFound(response.status === 404);
      }
    }, FIELDS);
  }

  onSave(response: ApiResponse<Collectible>) {
    this.setCollectible(response);
  }

  onDelete() {
    this.navigate(Routes.partner.horse_collectibles(this.getPartnerKey(), this.getHorseKey()))
  }

  onBack() {
    this.navigate(Routes.partner.horse_collectibles(this.getPartnerKey(), this.getHorseKey()))
  }

  onShowActuals() {
    this.navigate(Routes.partner.horse_collectible_actuals(this.getPartnerKey(), this.getHorseKey(), this.props.params.collectibleKey))
  }

  onChangeFeatured(option: any) {
    this.state.collectible?.modifyFeatured(option.key === 'true', {
      next: this.setCollectible,
      error: this.onApiError
    }, FIELDS);
  }

  onChangeStatus(status: CollectibleStatus) {
    this.state.collectible?.modifyStatus(status, {
      next: this.setCollectible,
      error: this.onApiError
    }, FIELDS);
  }

  onChangeMintStatus(mintStatus: BlockchainMintStatus) {
    this.state.collectible?.modifyMintStatus(mintStatus, {
      next: this.setCollectible,
      error: this.onApiError
    }, FIELDS);
  }

  setCollectible(response: ApiResponse<Collectible>) {
    let collectible = response.resource;
    this.setState({
      collectible: collectible
    })
  }

  renderWithIndexPartnerAndHorse(index: ApiIndex, partner: Partner, horse: Horse) {

    let collectible = this.state.collectible;

    if (!collectible) {
      return <></>;
    }

    return <>

      <GlobalMessage message={this.state.globalMessage} type={this.state.globalMessageSeverity} fieldErrors={(this.state.errors as any)._}/>

      <SectionHeader
        title="Collectible"
        description={
          <>
            The details of this collectible is shown below. This collectible is currently <strong>{collectible!.status!.name}</strong> and <strong>{collectible!.mintStatus!.name}</strong>. Please note that
            users can only see collectibles in the Marketplace if the collectible is both public and minted.
          </>
        }
        actions={
          <>
            <Button
              icon="chevron-left"
              iconPosition="left"
              bsStyle="default"
              bsSize="xs"
              onClick={this.onBack}>Back To Collectibles</Button>
            {
              collectible.isSendReviewRequestNotificationAllowed() &&
              <Confirmable
                renderConfirm={(onOk, onCancel) => {
                  return (
                    <Confirm
                      title="Send Review Request"
                      message="Would you like to send a review request to BTX for this collectible to be minted and published on the public blockchain?"
                      onOk={() => {
                        collectible!.sendReviewRequestNotification({
                          next: (response) => {
                          },
                          error: this.onApiError
                        }, FIELDS);
                        onOk();
                      }}
                      onCancel={onCancel}/>
                  );
                }}
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="external-link"
                      bsStyle="primary"
                      bsSize="xs"
                      onClick={onShow}>Send Review Request</Button>
                  );
                }}/>
            }
            {
              collectible.isGiftToUserAllowed() &&
              <Confirmable
                renderConfirm={(onOk, onCancel) => {
                  return (
                    <PartnerHorseCollectibleGiftToUserDialog
                      index={index}
                      collectible={collectible!}
                      onSave={(response) => {
                        onOk();
                        this.setGlobalMessage("Successfully gifted!", "success")
                        this.loadCollectible();
                      }}
                      onCancel={onCancel}/>
                  );
                }}
                renderTrigger={(onShow) => {
                  return (
                    <Button
                      icon="external-link"
                      bsStyle="primary"
                      bsSize="xs"
                      onClick={onShow}>Gift to User</Button>
                  );
                }}/>
            }
            {
              collectible.isMinted() &&
              <Button
                icon="chevron-right"
                bsStyle="primary"
                bsSize="xs"
                onClick={this.onShowActuals}>Show Actuals</Button>
            }
          </>
        }/>

      <CollectibleDetailsPanel
        index={index}
        horse={horse}
        collectible={collectible}
        onSave={this.onSave}
        onDelete={this.onDelete}
        onChangeFeatured={this.onChangeFeatured}
        onChangeStatus={this.onChangeStatus}/>

      <BlockchainMintablePanel
        type="Collectible"
        entity={collectible}
        entityFields={FIELDS}
        statuses={index.mintChooseableStatuses}
        errors={this.state.errors}
        onReload={this.onReloadCollectible}
        onChangeMintStatus={this.onChangeMintStatus}/>

    </>;

  }

  renderPageActions(index: ApiIndex, partner: Partner, horse: Horse): JSX.Element {

    return (

      <div className="actions">
        <Button
          icon="chevron-left"
          iconPosition="left"
          bsStyle="default"
          bsSize="large"
          onClick={() => {
            this.navigate(Routes.partner.horse_collectibles(this.getPartnerKey(), this.getHorseKey()));
          }}>Back to Collectibles</Button>
      </div>

    );

  }

};

