// ................................................................................................................................................

import FontAwesome from "react-fontawesome";
import ApiIndex from "../models/ApiIndex";
import Horse from "../models/Horse";
import LegalOwner from "../models/LegalOwner";
import Tranche from "../models/Tranche";
import React, {useState} from "react";
import {Button, Confirmable, StatusButton, Str, Table, TableHeaderCell, Util} from "startupbox-react";
import LegalOwnerDialog from "../legal_owner/LegalOwnerDialog";
import {SalesChannelMarketplace} from "../models/SalesChannel";
import BlockchainAttributeGroup from "../models/BlockchainAttributeGroup";
import TrancheDialog from "./TrancheDialog";

type LegalOwnerRowProps = {
    index: ApiIndex,
    legalOwner: LegalOwner,
    onAddTranche: (tranche: Tranche) => void,
    onEdited: () => void,
    onApiError: (response: any) => void,
    onViewTranche: (tranche: Tranche) => void,
    horse: Horse
};

type LegalOwnerTableProps = {
    legalOwners: Array<LegalOwner> | null,
    searchLegalOwners: (page: number) => void,
    index: ApiIndex,
    onViewTranche: (tranche: Tranche) => void,
    onApiError: (response: any) => void,
}
let LegalOwnerTable = (props: LegalOwnerTableProps) => {
    return (<Table
        items={props.legalOwners}
        onPaginate={props.searchLegalOwners}
        blankMessage="No legal owners found"
        renderHeaderRow={() => {
            return (
                <tr>
                    <TableHeaderCell label="&nbsp;" style={{width: '40px'}}/>
                    <TableHeaderCell label="Name"/>
                    <TableHeaderCell className="col-md-1"/>
                    <TableHeaderCell label="Percentage" className="col-md-2 text-right"/>
                    <TableHeaderCell label="&nbsp;" className="col-chevron"/>
                </tr>
            );
        }}
        renderItemRow={(legalOwner:LegalOwner, l: number) => {
            return (
                <LegalOwnerRow
                    key={l}
                    index={props.index}
                    horse={legalOwner.horse!}
                    legalOwner={legalOwner}
                    onAddTranche={props.onViewTranche}
                    onEdited={() => {
                        props.searchLegalOwners(1);
                    }}
                    onApiError={props.onApiError}
                    onViewTranche={props.onViewTranche}/>
            );
        }}
    />)
}

let LegalOwnerRow = (props: LegalOwnerRowProps) => {

    let [expanded, setExpanded] = useState(false);

    let index = props.index;
    let legalOwner = props.legalOwner;
    let isModifyAllowed = legalOwner.isModifyAllowed();
    let isMicroOwnershipSupported = legalOwner.microOwnershipSupported;

    let onToggleTrancheVisibility = (e: any) => {
        setExpanded(!expanded);
    }

    return <>

        <tr className="clickable"
            onClick={isMicroOwnershipSupported ? onToggleTrancheVisibility : undefined}>
            <td className="text-muted">
                {
                    isMicroOwnershipSupported &&
                    <FontAwesome name={expanded ? 'minus' : 'plus'}/>
                }
            </td>
            <td><strong>{legalOwner.name} {legalOwner.tranches?.length > 0 ? ' - ' + legalOwner.tranches[0].name : '' }</strong></td>
            <td>&nbsp;</td>
            <td className="text-right">{Str.formatNumber(legalOwner.percentage, 2, '.', ',')}%</td>
            <td className="text-right text-muted">
                <Confirmable
                    renderConfirm={(onOk) => {
                        return (
                            <LegalOwnerDialog
                                horse={legalOwner.horse!}
                                legalOwner={legalOwner}
                                onClose={() => {
                                    props.onEdited();
                                    onOk();
                                }}
                            />
                        );
                    }}
                    renderTrigger={(onShow) => {
                        if (isModifyAllowed) {
                            return (
                                <a href="/" className="text-muted" onClick={(e: any) => {
                                    Util.preventDefault(e);
                                    onShow();
                                }}>Edit</a>
                            );
                        }
                        return null;
                    }}/>
            </td>
        </tr>

        {
            expanded &&
            legalOwner.tranches.map((tranche: Tranche, j: number) => {
                return (
                    <TrancheRow
                        key={'tranche' + j}
                        index={index}
                        tranche={tranche}
                        onView={() => {
                            props.onViewTranche(tranche)
                        }}/>
                );
            })
        }

        {
            expanded && legalOwner.isAddTrancheAllowed() &&
            <Confirmable
                renderConfirm={(onOk, onCancel) => {
                    let tranche: Tranche = new Tranche();
                    tranche.salesChannel = SalesChannelMarketplace;
                    tranche.manageableMetadataAttributeGroup = new BlockchainAttributeGroup();
                    return (
                        <TrancheDialog
                            horse={legalOwner.horse!}
                            legalOwner={legalOwner}
                            tranche={tranche}
                            onSave={(response) => {
                                props.onAddTranche(response.resource!);
                                onOk();
                            }}
                            onDelete={() => {
                                props.onEdited();
                                onOk();
                            }}
                            onCancel={() => {
                                props.onEdited();
                                onOk();
                            }}/>
                    );
                }}
                renderTrigger={(onShow) => {
                    return (
                        <tr>
                            <td></td>
                            <td className="text-muted">
                                <Button
                                    icon="plus"
                                    bsStyle="primary"
                                    bsSize="xs"
                                    onClick={onShow}>Add Tranche</Button>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    );
                }}/>
        }

    </>;

}

// ................................................................................................................................................

type TrancheRowProps = {
    index: ApiIndex,
    tranche: Tranche,
    onView: () => void,
};

let TrancheRow = (props: TrancheRowProps) => {

    let index = props.index;
    let tranche = props.tranche;

    return (
        <tr className="clickable" onClick={props.onView}>
            <td></td>
            <td>
                <div>{tranche.name} <span className="text-muted">({tranche.getCountText()})</span></div>
                <div className="text-muted">{Str.formatDate(tranche.startAt)} - {Str.formatDate(tranche.endAt)}</div>
                <div className="text-muted">{tranche.unitCount} units at ${tranche.unitPrice} each</div>
            </td>
            <td>
                <StatusButton
                    id={'status-' + tranche.key}
                    value={tranche.mintStatus}
                    options={index.trancheStatuses}/>
            </td>
            <td className="text-right">
                <div>{tranche.absoluteOwnershipPercent + '%'}</div>
            </td>
            <td className="text-right">
                <FontAwesome name="chevron-right"/>
            </td>
        </tr>
    );

}

export default LegalOwnerTable;